import gql from 'graphql-tag';

export const START_CHECKOUT_SECURITY_DEPOSIT_PAYMENT_MUTATION = gql`
  mutation ($checkoutToken: String!) {
    startCheckoutSecurityDepositPayment(checkoutToken: $checkoutToken) {
      incomingPayment {
        id
        totalAmount {
          formattedValue
        }
      }
    }
  }
`;
