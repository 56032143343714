import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { IDivider } from './types';

const StyledDivider = styled.hr<IDivider>`
  border: 0;
  height: ${px2Rem(1)};
  ${({ height }) => height && `height: ${px2Rem(height)};`}
  ${({ color }) => color && `background: ${color};`}
  ${({ padding }) => padding && `margin: 0 ${px2Rem(padding)};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ styles }) => styles}
`;

export { StyledDivider };
