import { FadeIn } from 'components/animations/Transitions';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import EditContentBox from 'components/EditContentBox';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useCheckoutMobileContext } from 'screens/Checkout/containers/StepMobileJob/hooks/useCheckoutMobileContext';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import { StyledMobileConfirm } from './styles';
import FormErrorMessages from 'components/FormErrorMessages';

export const MobileConfirm = ({ dataTestId }: ITestDataId) => {
  const { number, setNumber, setFormSubmitted, formErrors } = useCheckoutMobileContext();

  const handleResetNumber = () => {
    setNumber(undefined);
  };

  const handleSubmit = () => {
    setFormSubmitted(true);
  };

  return (
    <FadeIn>
      <StyledMobileConfirm data-testid={dataTestId}>
        <Header level={2} color={primaryColors.c01}>
          Is this mobile number correct?
        </Header>
        <Spacer height={24} />
        <EditContentBox
          onClick={handleResetNumber}
          backgroundColor={secondaryColors.c04}
          className="sentry-mask"
        >
          {number}
        </EditContentBox>
        <Spacer height={40} />
        <DoubleButtonFullWidth
          topButtonText="Yes"
          bottomButtonText="No"
          topButtonOnClick={handleSubmit}
          bottomButtonOnClick={handleResetNumber}
          topButtonTestId="stepMobileNext"
        />
        <Spacer height={24} />
        <FormErrorMessages formErrors={formErrors} />
      </StyledMobileConfirm>
    </FadeIn>
  );
};
