import { Button, Copy, Header, Icon, Spacer, TextLink } from '@raylo-tech/raylopay-ui';

export const ReturnFailedError = () => {
  return (
    <>
      <Icon name="XCircle" $margin="auto" />
      <Spacer height={24} />
      <Header dataTestId="returns-error-header" centered fontSize={24}>Oops something went wrong</Header>
      <Spacer height={24} />
      <Copy textAlign="center">
        We are unable to take you to our returns platform.
      </Copy>
      <Spacer height={8} />
      <Copy textAlign="center">
        Please try again, and if it doesn&apos;t work, {' '}
        <TextLink
          href="https://help.raylo.com/en/articles/3271547-contact-raylo"
          target="_blank"
          noUnderline
        >
          contact our support team.
        </TextLink>
      </Copy>
      <Spacer height={24} />
      <Button onClick={returnToAccount} secondary dataTestId="form-secondary-button">Return to account</Button>
    </>
  );
};

const returnToAccount = () => {
  window.location.assign('/account');
};
