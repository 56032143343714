import { UseSegmentCheckoutTrackingParams } from 'graphql/queries/segment/interfaces/useSegmentCheckoutTrackingParams.interface';
import { useProductPropertiesEventQuery } from 'graphql/queries/segment/useProductPropertiesQuery';
import { track } from 'integrations/segment/events';

export const useSegmentCheckoutTracking = (
  options: UseSegmentCheckoutTrackingParams
) => {
  useProductPropertiesEventQuery((data: object) => {
    const segmentEvent = {
      checkout_area: options.checkoutArea,
      checkout_screen: options.checkoutScreen,
      when: options.when,
      ...data,
    };

    track(options.eventName, segmentEvent);
  });
};
