import { FC, useState, useEffect } from 'react';
import { ICountdown } from './Countdown.types';

export const Countdown: FC<ICountdown> = ({ seconds }) => {
  const [count, setCount] = useState<number>(seconds);

  useEffect(() => {
    const timer: any =
      count > 0 &&
      setInterval(() => {
        setCount(count - 1);
      }, 1000);
    return () => clearInterval(timer);
  }, [count]);

  return <span>{count}</span>;
};
