import useRayloQuery from './useRayloQuery'
import store from 'store'
import { useSharedCustomer } from './useSharedCustomer'

const useCustomerQuery = (query, options) => {
  const userToken = store.get('userToken');

  const { data: { customer }, loading } = useRayloQuery(query, {
    skip: !userToken,
    ...options
  })

  if(userToken) {
    return [ customer, loading ]
  } else {
    return [ undefined, false ]
  }

}

export default useCustomerQuery
