import { Button, Spacer } from '@raylo-tech/raylopay-ui';
import { Countdown } from 'components/Countdown/Countdown';
import { Copy } from 'elements';
import { primaryColors } from 'styles/variables/colors';
import { useCheckoutDecisionContext } from '../hooks/useCheckoutDecisionContext';

export const BackToMerchant = () => {
  const { merchantName, handleRedirect } = useCheckoutDecisionContext();
  return (
    <>
      <Spacer height={16} />
      <Copy bold fontSize={16} color={primaryColors.c01}>
        You will be automatically redirected back to {merchantName} in{' '}
        <Countdown seconds={10} /> seconds.
      </Copy>
      <Spacer height={24} />
      <Copy fontSize={16} color={primaryColors.c01}>
        If nothing happens please click the button below
      </Copy>
      <Spacer height={32} />
      <Button onClick={handleRedirect} dataTestId="backToMerchantButton">
        {`Back to ${merchantName}`}
      </Button>
    </>
  );
};
