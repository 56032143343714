import TradeInCopyWithIcons from 'screens/Checkout/components/TradeInCopyWithIcons';
import { useUiContext } from 'utils/useUiContext';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import ITestDataId from 'types/ITestDataId.type';
import { RayloModal } from '@raylo-tech/raylopay-ui';
import { StyledTradeInModalWithIcons } from './styles';

export const TradeInModalWithIcons = ({ dataTestId }: ITestDataId) => {
  const { headlineTradeInValues } = useStepTradeInContext();
  const { setShowModal, setShowTradeInModal, showTradeInModal } = useUiContext();
  const handleModalClose = () => {
    setShowModal(false);
    setShowTradeInModal(false);
  };

  return (
    <StyledTradeInModalWithIcons>
      <RayloModal
        title="How trade in works"
        dataTestId={dataTestId}
        handleCloseModal={handleModalClose}
        modalOpen={showTradeInModal}
      >
        <TradeInCopyWithIcons
          tradeInValues={headlineTradeInValues}
        />
      </RayloModal>
    </StyledTradeInModalWithIcons>
  );
};
