import styled from 'styled-components';

const StyledLearnMoreModalContent = styled.div`
  & h3 {
    padding: 0;
    margin: 0;
  }
`;

export { StyledLearnMoreModalContent };
