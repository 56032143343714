import styled from 'styled-components';
import { primaryColors, secondaryColors } from 'styles/variables/colors';

const StyledStepAddressNew = styled.div`
  & input {
    color: ${primaryColors.c01};
    &:focus {
      border: 1px solid ${secondaryColors.c03};
    }
  }
`;

export { StyledStepAddressNew };
