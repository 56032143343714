import { filter, find } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import StepInsuranceContent from 'screens/Checkout/components/StepInsuranceContent';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import useRayloQuery from 'utils/useRayloQuery';
import { useUiContext } from 'utils/useUiContext';
import useForceUpdate from 'use-force-update';
import { MU_SWAP_CHECKOUT } from 'screens/Checkout/graphQL/mutations';
import { SoldOut } from 'screens/Errors/SoldOut';
import { InvalidChoice } from 'screens/Errors/InvalidChoice';
import { CatchAll } from 'screens/Errors/CatchAll';
import { Modal } from 'components/common/Modal';
import { useMerchantContext } from 'utils/useMerchantContext';
import { IStepInsurance } from './types';
import { CheckoutItem, CostSummaries, CostSummary } from 'types/Checkout/types';

export const StepInsuranceContainer = ({
  checkoutToken,
  onSuccess,
}: IStepInsurance) => {
  const [costSummaryWithInsurance, setCostSummaryWithInsurance] = useState<CostSummary>();
  const [costSummaryWithoutInsurance, setCostSummaryWithoutInsurance] =
    useState<CostSummary>();
  const [originalCostSummary, setOriginalCostSummary] = useState<CostSummary>();
  const [variant, setVariant] = useState<CostSummaries>();
  const [item, setItem] = useState<CheckoutItem>();
  const [errorCode, setErrorCode] = useState('');

  const {
    uiCostSummary,
    setUiCostSummary,
    setShowInsuranceModal,
    setShowModal,
  } = useUiContext();

  const forceUpdate = useForceUpdate();
  const { merchantId } = useMerchantContext();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepInsurance.query, {
    variables: {
      token: checkoutToken,
      merchantId,
    },
  });

  const merchantPrice = checkout?.items[0].costSummary.merchantPrice.valueAfterTax.formattedValue;

  const [swapCheckoutItem] = useMutation(MU_SWAP_CHECKOUT, {
    update: (
      proxy,
      {
        data: {
          swapCheckoutItem: { errors },
        },
      }
    ) => {
      if (errors?.length > 0) {
        if (filter(errors, { code: 'sold_out' }).length > 0) {
          setErrorCode('sold_out');
        } else if (filter(errors, { code: 'blank' }).length > 0) {
          setErrorCode('bad_choice');
        } else {
          setErrorCode('generic');
        }
      } else {
        onSuccess();
      }
    },
    refetchQueries: ['getProducts', 'queryPhoneSummary'],
  });

  useEffect(() => {
    if (checkout) {
      const { variant: variant2, costSummary } = checkout.items[0];
      const currentCostSummary = find(variant2.costSummaries, {
        includesInsurance: costSummary.includesInsurance,
        termLength: costSummary.termLength,
        recurringPeriod: costSummary.recurringPeriod,
      });

      setUiCostSummary(currentCostSummary);
      setOriginalCostSummary(currentCostSummary);
      setVariant(variant2);
      setItem(checkout.items[0]);

      setCostSummaryWithInsurance(
        find(variant2.costSummaries, {
          includesInsurance: true,
          termLength: costSummary.termLength,
          recurringPeriod: costSummary.recurringPeriod,
        })
      );

      setCostSummaryWithoutInsurance(
        find(variant2.costSummaries, {
          includesInsurance: false,
          termLength: costSummary.termLength,
          recurringPeriod: costSummary.recurringPeriod,
        })
      );
      forceUpdate();
    }
  }, [checkout, forceUpdate, setUiCostSummary]);

  const showInsuranceModal = () => {
    setShowModal(true);
    setShowInsuranceModal(true);
  };

  const onSubmit = () => {
    if (!uiCostSummary) return;
    if (originalCostSummary?.id === (uiCostSummary as CostSummary)?.id) {
      onSuccess();
      return;
    }

    swapCheckoutItem({
      variables: {
        variantId: variant?.id,
        costSummaryId: (uiCostSummary as CostSummary)?.id,
        checkoutToken,
        itemId: item?.id,
        merchantId,
      },
    });
  };

  if (errorCode === 'sold_out') {
    return <SoldOut onClick={() => setErrorCode('')} buttonStyle="secondary" />;
  }

  if (errorCode === 'bad_choice') {
    return (
      <InvalidChoice onClick={() => setErrorCode('')} buttonStyle="secondary" />
    );
  }

  if (errorCode === 'generic') {
    return (
      <CatchAll onClick={() => setErrorCode('')} buttonStyle="secondary" />
    );
  }

  const insuranceTotal =
    costSummaryWithInsurance?.recurring?.insuranceAmount?.valueAfterTax?.value as number;
  const totalPrice = (uiCostSummary as unknown as CostSummary)?.recurring?.totalAmount
    ?.valueAfterTax?.value;

  if (loading) {
    return <Modal visible text="Loading..." />;
  }

  const onRadioSelect = (insuranceSelected: boolean) => {
    const updatedCostSummary = (insuranceSelected ? costSummaryWithInsurance : costSummaryWithoutInsurance) as any;
    setUiCostSummary(updatedCostSummary);
  };

  return (
    <StepInsuranceContent
      onRadioSelect={onRadioSelect}
      insuranceTotal={insuranceTotal}
      totalPrice={totalPrice}
      merchantPrice={merchantPrice}
      onSubmit={onSubmit}
      onShowPolicyInfo={() => showInsuranceModal()}
    />
  );
};
