import DirectDebitInfo from 'components/DirectDebitInfo';
import FormCollection from 'components/FormCollection';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { useMemo } from 'react';
import { useStepBankContext } from 'screens/Checkout/containers/StepBank/hooks/useStepBankContext';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useUiContext } from 'utils/useUiContext';
import ITestDataId from 'types/ITestDataId.type';
import DirectDebitTotalDetails from '../DirectDebitTotalDetails';
import { StyledBankDetailsNew } from './styles';
import { track } from '../../../../integrations/segment/events';
import { fields } from './BankDetailsNew.utils';
import { FormValues } from 'screens/Checkout/containers/CheckoutStepsContainer/types';

export const BankDetailsNew = ({ dataTestId }: ITestDataId) => {
  const { setShowModal, setShowDDContactModal } = useUiContext();
  const {
    values,
    directDebitMandateAuthorised,
    setConfirmDetails,
    setConfirmNewDetails,
    setValues,
    setFormErrors,
  } = useStepBankContext();

  const presetValues = useMemo(() => {
    if (!values?.bankAccountName) return;
    return {
      accountName: values?.bankAccountName,
      accountType: values?.bankAccountType?.toUpperCase(),
      accountNumber: values?.bankAccountNumber,
      sortCode: values?.bankBranchIdentifier,
    } as FormValues;
  }, [values]);

  const handleShowContactModal = () => {
    setShowModal(true);
    setShowDDContactModal(true);
  };
  const handleSubmit = (submittedValues: any) => {
    setConfirmDetails(true);
    setFormErrors(null);
    setConfirmNewDetails(true);
    setValues({
      bankAccountName: submittedValues?.accountName,
      bankAccountType: submittedValues?.accountType?.toUpperCase(),
      bankAccountNumber: submittedValues?.accountNumber,
      bankBranchIdentifier: submittedValues?.sortCode,
    });
    track('Add Payment Info');
  };

  return (
    <StyledBankDetailsNew data-testid={dataTestId}>
      <Header level={2} color={primaryColors.c01}>
        Set up a direct debit with Raylo
      </Header>
      <Spacer height={16} />
      <DirectDebitTotalDetails
        backgroundColor={secondaryColors.c05}
        padding={px2Rem(16)}
      />
      <Spacer height={24} />
      <FormCollection
        fields={fields}
        handleSubmit={handleSubmit}
        formId="bankDetailsForm"
        dataTestId="bankDetailsFormNew"
        presetValues={presetValues}
      />
      <DirectDebitInfo />
      <Spacer height={16} />
      <TextLink onClick={handleShowContactModal}>
        Please contact us if you have any questions
      </TextLink>
      <Spacer height={32} />
      <Container noPadding>
        <Button
          disabled={!directDebitMandateAuthorised}
          type="submit"
          size="large"
          text="Next"
          fullWidth
          buttonColor={ButtonTypes.b01}
          dataTestId="bankDetailsNewSubmitButton"
          formId="bankDetailsForm"
        />
      </Container>
    </StyledBankDetailsNew>
  );
};
