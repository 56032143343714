import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useAnalyticsContext } from 'utils/AnalyticsProvider';
import { reset } from 'integrations/segment/events';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { sendMessageToApp } from 'utils/sendMessageToApp';

const Logout = () => {
  const { logout: auth0Logout } = useAuth0();
  const { removeDomainCookie, removeUserToken } = useSharedCustomer();
  const { clearAnalyticsId } = useAnalyticsContext();

  const logout = () => {
    window.sessionStorage.clear();
    removeDomainCookie('checkoutContext');
    removeDomainCookie('subscriptionId');
    removeUserToken();

    sendMessageToApp({ action: 'USER_HAS_LOGGED_OUT', payload: true });

    auth0Logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT,
      },
    });
    if (window?.Intercom) {
      window.Intercom('shutdown');
    }
    clearAnalyticsId();
    reset();
    return;
  };

  useEffect(() => {
    logout();
  }, []);

  return null;
};

export default Logout;
