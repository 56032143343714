import { useState } from 'react';
import rayloLogo from 'public/raylo-logo.svg';
import { Copy, Spacer } from 'elements';
import { DownsellContainerWrapper } from '../DownsellContainer/styles';
import { Button, Container, FormCheckbox, Icon } from '@raylo-tech/raylopay-ui';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { track } from 'integrations/segment/events';

const FeatureNotReady = () => {
  const { checkoutID } = useCheckoutContext();

  const [checked, setChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onChange = () => {
    setChecked(prev => !prev);
  };

  const submitForm = () => {
    if (checked) {
      track('Feature Not Ready Form Submitted', {
        checkoutID,
      });
      setSubmitted(true);
    }
  };

  return (
    <DownsellContainerWrapper data-testid="feature-not-ready">
      <header>
        <img src={rayloLogo} alt="Raylo Logo" />
      </header>
      <div className="downsell-content">
        {submitted ? (
          <Container width="100%" alignCenter>
            <Icon name="CheckCircle" $height={64} />
            <Spacer height={24} />
            <Copy bold lineHeight={30} fontSize={24} dataTestId="fake-door-thanks-copy">
              Thanks, we&apos;ll be in touch
            </Copy>
          </Container>
        ) : (
          <>
            <Copy fontSize={24} lineHeight={30} bold>
              Sorry, but we&apos;re not quite ready!
            </Copy>
            <Spacer height={24} />
            <Copy fontSize={16} lineHeight={20} bold dataTestId="fake-door-product-copy">
              We can&apos;t lease this device to you as we are still finalising this feature. We understand how frustrating this can be and we apologise for any disappointment.
            </Copy>
            <Spacer height={24} />
            <Copy fontSize={16} lineHeight={20}>
              We&apos;re working hard putting the finishing touches together so that we can offer this option in the future.
              If you&apos;d like to know as soon as this feature is available, just tick below and we&apos;ll email you.
            </Copy>
            <Spacer height={24} />
            <FormCheckbox
              checked={checked}
              dataTestId="fake-door-checkbox"
              label="Let me know when this is available"
              onChange={onChange}
            />
            <Spacer height={24} />
            <Button onClick={submitForm} disabled={!checked} dataTestId="fake-door-submit-button">
              Submit
            </Button>
          </>
        )}
      </div>
    </DownsellContainerWrapper>
  );
};

export default FeatureNotReady;
