import { Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Callback, Error, Login, NotFound } from './LoginRoutes';

const SentryRoute = Sentry.withSentryRouting(Route);

export const LoginRouter = () => {
  return (
    <Switch>
      <SentryRoute path="/login/error">
        <Error />
      </SentryRoute>
      <SentryRoute path="/login/callback">
        <Callback />
      </SentryRoute>
      {/* TODO: Only for compatibility, remove soon after release */}
      <SentryRoute path="/login/invalid-email">
        <NotFound />
      </SentryRoute>
      <SentryRoute path="/login">
        <Login />
      </SentryRoute>
    </Switch>
  );
};
