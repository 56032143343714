import gql from 'graphql-tag';

export interface ICustomerIncomingPaymentsQuery {
  customer: {
    subscriptions: {
      id: string;
      incomingPayments: {
        id: string;
        state: string;
        partnerReferences: {
          id: string;
          referenceType: string;
          reference: string;
        }[];
      }[];
    }[];
  };
}

export const CUSTOMER_INCOMING_PAYMENTS = gql`
  query CustomerIncomingPaymentsQuery {
    customer {
      subscriptions {
        id
        incomingPayments {
          id
          state
          partnerReferences {
            id
            referenceType
            reference
          }
        }
      }
    }
  }
`;
