import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Header from 'elements/Header';
import ITradeInCopy from './types';
import { secondaryColors, primaryColors } from 'styles/variables/colors';
import { Container } from '@raylo-tech/raylopay-ui';
import { Spacer } from 'elements';
import { px2Rem } from 'utils/px2Rem';

export const TradeInCopyWithIcons = ({
  dataTestId,
  tradeInValues,
}: ITradeInCopy) => {
  const listItem = (title: string, copy: string, icon: string) => {
    return (
      <Container key={title[0]} flexRow alignCenter noPadding>
        <Icon name={icon} size={px2Rem(64)} color={secondaryColors.p02} />
        <Container padding={`0 0 0 ${px2Rem(24)}`}>
          <Header fontSize={16} color={primaryColors.c01}>
            {title}
          </Header>
          <Spacer height={8} />
          <Copy
            color={secondaryColors.c02}
            fontSize={14}
            styles={`paddingBottom: ${px2Rem(12)};`}
          >
            {copy}
          </Copy>
          <Spacer height={24} />
        </Container>
      </Container>
    );
  };

  const listItems = [
    listItem('Instant valuation', "Answer a few questions and discover your device's value instantly", 'DeviceCheck'),
    listItem('Send it', 'Get your new device then choose how you want to send it off. No printer required', 'DeliveryTruck'),
    listItem('Get cashback', `You'll get paid monthly cashback for ${tradeInValues?.recurring?.termLength} months paid directly into your bank account`, 'PiggyBank'),
  ];

  return (
    <Container dataTestId={dataTestId} padding={`${px2Rem(0)} ${px2Rem(16)} ${px2Rem(16)} ${px2Rem(16)}`}>
      {listItems}
    </Container>
  );
};
