import React from 'react';
import { StyledTrustPilotBar } from './TrustPilotBar.styles';
import { ITrustPilotBar } from './TrustPilotBar.types';
import { palette } from '../../colors';
import Container from '../../elements/Container';
import Icon from '../../elements/Icon';
import Copy from '../../elements/Copy';
import { px2Rem } from '../../utils/px2Rem';

export const TrustPilotBar = ({ dataTestId, RPHeader }: ITrustPilotBar) => {
  return (
    <StyledTrustPilotBar data-testid={dataTestId}>
      <Container
        flexRow
        padding={RPHeader ? '0' : `${px2Rem(12)} ${px2Rem(20)}`}
        center
        backgroundColor={RPHeader ? 'transparent' : palette.charcoal[100]}
        width="initial"
      >
        <Container noPadding>
          <Icon name="TrustPilot" $height={20} />
        </Container>
        <Container padding={RPHeader ? `${px2Rem(4)} 0 0 0` : '0'} alignRight>
          <Container fitContent flexRow>
            {RPHeader && (
              <Container margin={`${px2Rem(2)} ${px2Rem(10)} 0 0`}>
                <Copy color={palette.charcoal[500]} fontSize={14} medium>
                  Excellent
                </Copy>
              </Container>
            )}
            <img
              src="https://emailsignature.trustpilot.com/signature/en-GB/3/5cdd6c38b4acc6000128faf9/stars.png"
              alt="Trustpilot stars"
              width="90"
            />
          </Container>
        </Container>
      </Container>
    </StyledTrustPilotBar>
  );
};
