import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledStepNameEmailContent = styled.div`
  margin-top: ${px2Rem(40)};

  @media (min-width: 500px) {
    margin-top: ${px2Rem(55)};
  }
`;

export { StyledStepNameEmailContent };
