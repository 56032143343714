import React from 'react';
import { palette } from '../../colors';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Icon from '../../elements/Icon';
import TextLink from '../../elements/TextLink';
import { px2Rem } from '../../utils/px2Rem';
import { StyledIconWithTextLink } from './IconWithTextLink.styles';
import { IIconWithTextLink } from './IconWithTextLink.types';

export const IconWithTextLink = ({
  dataTestId,
  icon,
  copy,
  linkText,
  linkTextOnClick,
}: IIconWithTextLink) => {
  return (
    <StyledIconWithTextLink data-testid={dataTestId}>
      <Container flexRow centerHorizontal>
        <Container fitContent>
          <Icon name={icon} />
        </Container>
        <Container fitContent noPadding margin={`0 0 0 ${px2Rem(12)}`}>
          <Copy bold fontSize={14} color={palette.charcoal[500]}>
            {copy}
          </Copy>
        </Container>
        <Container fitContent margin={`0 0 0 ${px2Rem(6)}`}>
          <TextLink
            fontColor={palette.blue[500]}
            fontSize={14}
            onClick={linkTextOnClick}
            noUnderline
          >
            {linkText}
          </TextLink>
        </Container>
      </Container>
    </StyledIconWithTextLink>
  );
};
