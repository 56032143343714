import { useEffect, useState, useCallback, useMemo } from 'react';
import constate from 'constate';
import useRayloQuery from 'utils/useRayloQuery';
import gql from 'graphql-tag';
import { each, flatten, map, orderBy, reduce } from 'lodash-es';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useMutation } from '@apollo/client';
import type { IValidationErrors } from 'types/GlobalTypes.d.ts';
import type {
  IFormattedTradeInQuestion,
  IFormattedTradeInAnswer,
  ITradeInAnswer,
  ITradeInQuestion,
  IManufacturerOption,
  IVariantForTradeIn,
  ITradeInCostSummary,
  IStorageOption,
  IManufacturerProduct
} from '../types';
import { ICheckoutItemCostSummary } from 'types/Checkout/types';
import { getOptionValue } from 'utils/getOptionValue';
import { useUiContext } from 'utils/useUiContext';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { useMerchantContext } from 'utils/useMerchantContext';
import { VariantOptionSlugs } from 'types/variants/options/variantOptionSlugs';
import { IValue } from '@raylo-tech/raylopay-ui/build/cjs/components/SelectDropdown/SelectDropdown.types';

interface IUseStepTradeIn {
  onSuccess: () => void;
}
const useStepTradeIn = ({ onSuccess }: IUseStepTradeIn) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [tradeInAccepted, setTradeInAccepted] = useState(false);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [manufacturerOptions, setManufacturerOptions] = useState<Array<IManufacturerOption>>([]);
  const [manufacturerId, setManufacturerId] = useState<string>();
  const [modelId, setModelId] = useState<string>();
  const [modelOptions, setModelOptions] = useState<Array<IValue | null>>([]);
  const [manufacturerProducts, setManufacturerProducts] = useState<{ [key: string]: Array<IManufacturerProduct> }>({});
  const [variantIds, setVariantIds] = useState<Array<string>>();
  const [sizes, setSizes] = useState<Array<IVariantForTradeIn>>();
  const [variantId, setVariantId] = useState<string | null>();
  const [productName, setProductName] = useState<string | null>();
  const [rayloDeviceCheckComplete, setRayloDeviceCheckComplete] = useState<boolean>(false);
  const [headlineTradeInValues, setHeadlineTradeInValues] = useState<ITradeInCostSummary>({ total: undefined, recurring: undefined });
  const [tradeInValues, setTradeInValues] = useState<ITradeInCostSummary>({ total: undefined, recurring: undefined });
  const [tradeInQuestionsList, setTradeInQuestionsList] = useState<{
    [key: string]: IFormattedTradeInQuestion;
  }>({});
  const [visibleTradeInQuestions, setVisibleQuestions] = useState<
    Array<string>
  >([]);
  const [gradeId, setGradeId] = useState<string>();
  const [gradeSlug, setGradeSlug] = useState<string>();
  const [termLength, setTermLength] = useState<number>();
  const [tradeInAnswers, setTradeInAnswers] = useState<{
    [key: string]: string;
  }>({});
  const [formErrors, setFormErrors] = useState<IValidationErrors | []>([]);
  const [tradeInExists, setTradeInExists] = useState<boolean>(false);
  const [tradeInId, setTradeInId] = useState<string>();
  const [costSummary, setCostSummary] = useState<ICheckoutItemCostSummary>();

  const { checkoutToken, checkoutCategoryId } = useCheckoutContext();
  const { setHideProgressBar } = useUiContext();
  const { merchantId } = useMerchantContext();

  useEffect(() => {
    if (tradeInAccepted) {
      setHideProgressBar(true);
    } else {
      setHideProgressBar(false);
    }
  }, [tradeInAccepted, setHideProgressBar]);

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepTradeIn.query, {
    variables: {
      token: checkoutToken,
    },
  });

  const {
    data: { checkout: checkoutTwo },
    loading: loadingTwo,
  } = useRayloQuery(CheckoutSteps.stepTradeIn.queryMax, {
    skip: !costSummary,
    variables: {
      token: checkoutToken,
      input: {
        countryId: costSummary?.country.id,
        originId: checkout?.id,
      },
      costSummaryInput: {
        countryId: costSummary?.country.id,
        originId: checkout?.id,
      },
    },
  });

  useEffect(() => {
    if (checkout) {
      setCostSummary(checkout.items[0].costSummary);
    }
  }, [checkout]);

  useEffect(() => {
    if (checkout) {
      setTermLength(costSummary?.termLength);
      const tradeIn = checkout.tradeIns?.[0];
      if (tradeIn) {
        setTradeInValues({
          total: tradeIn.costSummary?.total,
          recurring: tradeIn.costSummary?.recurring,
        });
        setTradeInExists(true);
        setTradeInId(tradeIn?.id);
        setGradeSlug(tradeIn?.grade?.slug);
        setProductName(tradeIn?.costSummary?.displayName);
        setStepIndex(-1);
      }
    }
  }, [checkout, costSummary]);

  useEffect(() => {
    if (checkoutTwo) {
      const tradeInCostSummmary =
        checkoutTwo.items[0]?.variant?.headlineTradeInMaxCostSummary;
      if (tradeInCostSummmary) {
        setHeadlineTradeInValues({
          total: tradeInCostSummmary.total,
          recurring: tradeInCostSummmary.recurring,
        });
      } else {
        onSuccess();
      }
    }
  }, [checkoutTwo, onSuccess]);

  const {
    data: { manufacturers },
    loading: loadingManufacturers,
  } = useRayloQuery(
    CheckoutSteps.stepTradeIn.queryManufacturers,
    {
      variables: {
        merchantId,
        productCategoryIds: [checkoutCategoryId],
      },
      skip: !tradeInAccepted || !merchantId || !checkoutCategoryId,
    }
  );

  useEffect(() => {
    if (!loadingManufacturers) {
      const options = manufacturers?.map(({ displayName, id }: { displayName: string; id: string }) => {
        return {
          label: displayName,
          large: false,
          id,
        };
      });
      setManufacturerOptions(orderBy(options, ['label']));
      setManufacturerProducts(reduce(manufacturers, (result: { [key: string]: Array<IManufacturerProduct> }, manufacturer) => {
        result[manufacturer.id as string] = manufacturer.products as Array<IManufacturerProduct>;
        return result;
      }, {}));
    }
  }, [manufacturers, loadingManufacturers]);

  const handleManufacturerOnChange = (value: string) => {
    const selectedManufacturerId = manufacturerOptions.find((option: IManufacturerOption) => option.label === value)?.id;
    setManufacturerId(selectedManufacturerId);
    const products = manufacturerProducts[selectedManufacturerId ?? ''];
    const productOptions = products?.map(
      ({ displayName, id, position }: IManufacturerProduct) => {
        return {
          position,
          value: id,
          label: displayName,
        };
      }
    );
    setModelOptions(orderBy(productOptions, ['position']));
    setModelId(undefined);
    setVariantId(undefined);
    setSizes(undefined);
  };

  const handleProductSelect = ({ value }: { value: string }) => {
    setModelId(value);
    setProductName(Object.values(manufacturerProducts).flat().find(product => product.id === value)?.displayName);
    setVariantId(undefined);
    setSizes(undefined);
  };

  const {
    data: { products: productVariants },
    loading: loadingProductVariants,
  } = useRayloQuery(
    gql`
      query queryProducts($ids: [ID!], $merchantId: ID!) {
        products(
          ids: $ids
          forTradeIn: true
          forCheckout: false
          merchantId: $merchantId
        ) {
          id
          variants(
            forTradeIn: true
            forCheckout: false
            merchantId: $merchantId
          ) {
            id
          }
        }
      }
    `,
    {
      variables: {
        ids: [modelId],
        merchantId,
      },
      skip: !modelId || modelId === 'other' || !merchantId,
    }
  );

  useEffect(() => {
    if (productVariants) {
      setVariantIds(map(flatten(map(productVariants, 'variants')), 'id'));
    }
  }, [productVariants]);

  const {
    data: { variants },
    loading: loadingVariants,
  } = useRayloQuery(
    gql`
      query queryVariantsForTradeInStep(
        $ids: [ID!]
        $merchantId: ID!
        $costSummaryInput: TradeInMaxCostSummaryInput!
      ) {
        variants(
          ids: $ids
          forTradeIn: true
          forCheckout: false
          merchantId: $merchantId
        ) {
          id
          displayName
          tradeInMaxCostSummary(input: $costSummaryInput) {
            total {
              value
              formattedValue
              currencyCode
            }
            recurring {
              amount {
                value
                formattedValue
                currencyCode
              }
              termLength
            }
          }
          optionValues {
            id
            displayName
            raw
            position
            optionType {
              id
              slug
            }
          }
        }
      }
    `,
    {
      variables: {
        ids: variantIds,
        merchantId,
        costSummaryInput: {
          countryId: costSummary?.country.id,
          originId: checkout?.id,
        },
      },
      skip:
        !variantIds ||
        variantIds.length === 0 ||
        !checkout?.id ||
        !costSummary ||
        !merchantId,
    }
  );

  useEffect(() => {
    if (variants) {
      const variant: { [key: string]: IVariantForTradeIn } = {};
      each(variants, (v) => {
        const storage: IStorageOption = getOptionValue(v, VariantOptionSlugs.Storage);
        if (!variant?.[storage.id]) {
          variant[storage.id] = {
            ...storage,
            variantId: v.id,
            tradeInMaxRecurringAmount:
              v.tradeInMaxCostSummary?.recurring?.amount,
            tradeInMaxTotalAmount:
              v.tradeInMaxCostSummary?.total,
          };
        }

        if (
          variant?.[storage.id].tradeInMaxRecurringAmount.value < v.tradeInMaxCostSummary?.recurring?.amount?.value
        ) {
          variant[storage.id].tradeInMaxRecurringAmount =
            v.tradeInMaxCostSummary?.recurring?.amount?.value;
        }
      });

      setSizes(orderBy(variant, 'position'));
    }
  }, [variants]);

  const sizeOptionsWithTotal = useMemo(() => {
    return sizes?.map((size: IVariantForTradeIn) => {
      return {
        value: size.variantId,
        label: `${size.displayName} up to ${size.tradeInMaxTotalAmount.formattedValue}`,
      };
    });
  }, [sizes]);

  const handleVariantSelect = ({ value }: { value: string }) => {
    setVariantId(value);
  };

  const {
    data: { tradeInGradingQuestions },
  } = useRayloQuery(
    gql`
      query queryTradeInGradingQuestions($variantId: ID!) {
        tradeInGradingQuestions(variantId: $variantId) {
          id
          displayName
          notes
          gradingAnswers {
            id
            displayName
            followUpQuestion {
              id
            }
            grade {
              id
              displayName
              slug
            }
          }
          hasLeadingGradingAnswers
          notes
        }
      }
    `,
    {
      variables: {
        variantId,
      },
      skip: !tradeInAccepted || !variantId,
    }
  );

  const formatAnswer = (
    answer: ITradeInAnswer,
    question: ITradeInQuestion
  ): IFormattedTradeInAnswer => {
    return {
      id: answer.id,
      label: answer.displayName,
      grade: answer.grade,
      followUpQuestion: answer.followUpQuestion,
      question: {
        id: question.id,
      },
    };
  };

  const formatQuestion = useCallback((
    question: ITradeInQuestion
  ): IFormattedTradeInQuestion => {
    return {
      id: question.id,
      label: question.displayName,
      notes: question.notes,
      answers: question.gradingAnswers.map((answer: ITradeInAnswer) =>
        formatAnswer(answer, question)
      ),
    };
  }, []);

  useEffect(() => {
    setTradeInQuestionsList(
      tradeInGradingQuestions?.reduce(
        (
          collection: {
            [key: string]: IFormattedTradeInQuestion;
          },
          question: ITradeInQuestion
        ) => {
          if (!question.hasLeadingGradingAnswers) {
            setVisibleQuestions([question.id]);
          }

          collection[question.id] = formatQuestion(question);

          return collection;
        },
        {}
      )
    );
  }, [tradeInGradingQuestions, formatQuestion]);

  const handleQuestionAndAnswerReset = (answer: IFormattedTradeInAnswer) => {
    const currentQuestionIndex = visibleTradeInQuestions.indexOf(
      answer.question.id
    );

    const remainingQuestions = [...visibleTradeInQuestions];

    const removedQuestionIds = remainingQuestions.splice(
      currentQuestionIndex + 1,
      visibleTradeInQuestions.length - 1
    );

    const updatedAnswersForRemovedQuestions = removedQuestionIds?.reduce(
      (collection: { [key: string]: string }, questionId: string) => {
        collection[questionId] = '';
        return collection;
      },
      {}
    );

    setTradeInAnswers({
      ...tradeInAnswers,
      ...updatedAnswersForRemovedQuestions,
      [answer.question.id]: answer.id,
    });
    setVisibleQuestions(remainingQuestions);
  };

  const handleGradeReached = (answer: IFormattedTradeInAnswer) => {
    const allQuestionsAnswered =
      answer.question.id ===
      visibleTradeInQuestions[visibleTradeInQuestions.length - 1];

    if (!allQuestionsAnswered) {
      handleQuestionAndAnswerReset(answer);
    } else {
      setTradeInAnswers({ ...tradeInAnswers, [answer.question.id]: answer.id });
    }
  };

  const handleResponseToQuestions = (answer: IFormattedTradeInAnswer | undefined): void => {
    if (answer?.grade) {
      handleGradeReached(answer);
      setGradeId(answer.grade.id);
      setGradeSlug(answer.grade.slug);
    } else if (answer?.followUpQuestion) {
      setTradeInAnswers({ ...tradeInAnswers, [answer.question.id]: answer.id });
      setGradeSlug(undefined);
      if (!visibleTradeInQuestions.includes(answer.followUpQuestion.id)) {
        setVisibleQuestions([
          ...visibleTradeInQuestions,
          answer.followUpQuestion.id,
        ]);
      }
    }
  };

  const {
    data: { variants: finalVariants },
  } = useRayloQuery(
    gql`
      query queryFinalVariants(
        $ids: [ID!]
        $merchantId: ID!
        $costSummaryInput: TradeInMaxCostSummaryInput!
      ) {
        variants(
          ids: $ids
          forTradeIn: true
          forCheckout: false
          merchantId: $merchantId
        ) {
          id
          displayName
          tradeInMaxCostSummary(input: $costSummaryInput) {
            total {
              value
              formattedValue
              currencyCode
            }
            recurring {
              amount {
                value
                formattedValue
                currencyCode
              }
              termLength
            }
          }
          product {
            id
            displayName
          }
          optionValues {
            id
            displayName
            raw
            optionType {
              id
              slug
            }
          }
        }
      }
    `,
    {
      variables: {
        ids: [variantId],
        merchantId,
        costSummaryInput: {
          gradeId,
          originId: checkout?.id,
          countryId: costSummary?.country.id,
        },
      },
      skip: !variantId || !merchantId || !checkout?.id || !costSummary,
    }
  );

  useEffect(() => {
    if (finalVariants?.length) {
      setTradeInValues({
        total: finalVariants?.[0]?.tradeInMaxCostSummary?.total,
        recurring: finalVariants?.[0]?.tradeInMaxCostSummary?.recurring,
      });
    }
  }, [finalVariants]);

  useEffect(() => {
    const tradeIn = checkout?.tradeIns?.[0];
    if (tradeIn && termLength) {
      setTradeInValues({
        total: tradeIn?.costSummary?.total,
        recurring: tradeIn?.costSummary?.recurring,
      });
    }
  }, [checkout, termLength]);

  const [updateMutation, { loading: tradeInCreationInProgress }] = useMutation(
    gql`
      mutation CreateCheckoutTradeIn(
        $checkoutToken: String!
        $variantId: ID!
        $gradeId: ID!
        $gradingAnswerIds: [ID!]!
      ) {
        createCheckoutTradeIn(
          checkoutToken: $checkoutToken
          input: {
            variantId: $variantId
            gradeId: $gradeId
            gradingAnswerIds: $gradingAnswerIds
          }
        ) {
          tradeIn {
            createdAt
            id
            updatedAt
          }
          errors {
            code
            message
            field
          }
        }
      }
    `,
    {
      update: (
        proxy,
        {
          data: {
            createCheckoutTradeIn: { errors },
          },
        }
      ) => {
        if (errors && errors.length > 0) {
          setFormErrors(errors);
        } else {
          setFormSubmitted(true);
        }
      },
      refetchQueries: ['queryPhoneSummary', 'CheckoutPhoneSummaryQuery'],
    }
  );

  const commit = () => {
    if (tradeInExists) {
      onSuccess();
    } else {
      updateMutation({
        variables: {
          checkoutToken,
          variantId,
          gradeId,
          gradingAnswerIds: Object.values(tradeInAnswers).filter(n => n),
        },
      });
    }
  };

  const [updateMutationRemoveTradeIn] = useMutation(
    gql`
      mutation RemoveCheckoutTradeIn($checkoutToken: String!, $tradeInId: ID!) {
        removeCheckoutTradeIn(
          checkoutToken: $checkoutToken
          tradeInId: $tradeInId
        ) {
          success
        }
      }
    `,
    {
      update: (
        proxy,
        {
          data: {
            removeCheckoutTradeIn: { success },
          },
        }
      ) => {
        if (success) {
          setTradeInExists(false);
          setTradeInAccepted(false);
          setStepIndex(0);
        }
      },
      refetchQueries: ['queryPhoneSummary', 'CheckoutPhoneSummaryQuery'],
    }
  );

  const removeTradeIn = () => {
    updateMutationRemoveTradeIn({
      variables: {
        checkoutToken,
        tradeInId,
      },
    });
  };

  useEffect(() => {
    if (formSubmitted) {
      setHideProgressBar(false);
      onSuccess();
    }
  }, [formSubmitted, setHideProgressBar, onSuccess]);

  useEffect(() => {
    if (modelId && sizes?.length === 1 && !variantId) {
      handleVariantSelect({ value: sizes[0].variantId });
    }
  }, [sizes, variantId, modelId]);

  return {
    loadingProductVariants,
    loadingVariants,
    loading,
    loadingTwo,
    stepIndex,
    tradeInAccepted,
    formSubmitted,
    manufacturerOptions,
    modelOptions,
    manufacturerId,
    modelId,
    variants,
    sizeOptionsWithTotal,
    variantId,
    productName,
    visibleTradeInQuestions,
    tradeInQuestionsList,
    gradeSlug,
    termLength,
    formErrors,
    tradeInExists,
    headlineTradeInValues,
    tradeInValues,
    tradeInAnswers,
    commit,
    setStepIndex,
    setFormSubmitted,
    setTradeInAccepted,
    setModelId,
    setGradeId,
    handleManufacturerOnChange,
    handleProductSelect,
    handleVariantSelect,
    handleResponseToQuestions,
    removeTradeIn,
    rayloDeviceCheckComplete,
    setRayloDeviceCheckComplete,
    tradeInCreationInProgress,
  };
};

const [StepTradeInProvider, useStepTradeInContext] = constate(useStepTradeIn);
export { StepTradeInProvider, useStepTradeInContext };
