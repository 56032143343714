import { FadeIn } from 'components/animations/Transitions';
import FormCollection from 'components/FormCollection';
import FormErrorMessages from 'components/FormErrorMessages';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useEffect, useState } from 'react';
import { useCheckoutMobileContext } from 'screens/Checkout/containers/StepMobileJob/hooks/useCheckoutMobileContext';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { primaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import { StyledMobileNew } from './styles';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { FormValues } from './types';

export const MobileNew = ({ dataTestId }: ITestDataId) => {
  const [accountCustomer, setAccountCustomer] = useState<boolean>(false);

  const {
    setNumber,
    setDateOfBirth,
    setFormSubmitted,
    setFormErrors,
    presetValues,
    formErrors,
  } = useCheckoutMobileContext();
  const { checkoutMode } = useCheckoutContext();

  useEffect(() => {
    setAccountCustomer(checkoutMode !== CheckoutMode.NEW_CUSTOMER);
  }, [checkoutMode]);

  const fields = [
    {
      field: 'mobilePhoneNumber',
      name: 'Mobile Number',
      type: 'tel',
      required: true,
      tooltip:
        'We need a mobile phone number so we can contact you if there are any problems with your order',
    },
    {
      field: 'dateOfBirth',
      name: "What's your date of birth?",
      type: 'text',
      pattern: 'd*',
      required: true,
    },
  ];

  const handleSubmit = (values: FormValues) => {
    setFormErrors(null);
    const { mobilePhoneNumber, dateOfBirth } = values;
    const { DD, MM, YYYY } = dateOfBirth;
    setNumber(mobilePhoneNumber);
    setDateOfBirth(`${YYYY}-${MM}-${DD}`);
    if (checkoutMode === CheckoutMode.NEW_CUSTOMER) {
      setFormSubmitted(true);
    }
  };

  return (
    <FadeIn>
      <StyledMobileNew data-testid={dataTestId}>
        <Header level={2} color={primaryColors.c01}>
          Your details
        </Header>
        <Spacer height={24} />
        <Container noPadding>
          {!accountCustomer ? (
            <FormCollection
              fields={fields}
              handleSubmit={handleSubmit}
              formId="mobilePhoneNumberForm"
              presetValues={presetValues}
            />
          ) : (
            <FormCollection
              fields={[fields[0]]}
              handleSubmit={handleSubmit}
              formId="mobilePhoneNumberForm"
              presetValues={presetValues}
            />
          )}
          <Spacer height={24} />
          <FormErrorMessages formErrors={formErrors} />
          <Button
            type="submit"
            size="large"
            text="Next"
            fullWidth
            buttonColor={ButtonTypes.b01}
            formId="mobilePhoneNumberForm"
          />
        </Container>
      </StyledMobileNew>
    </FadeIn>
  );
};
