import { px2Rem } from './../../utils/px2Rem';
import styled from 'styled-components';
import { palette } from '../../colors';

const StyledSquareNumber = styled.div<{ background?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background || palette.blue[500]};
  height: ${px2Rem(26)};
  width: ${px2Rem(26)};
  color: ${palette.white};
  font-size: 16px;
`;

export { StyledSquareNumber };
