import gql from 'graphql-tag';

export const CHECKOUT_ANALYTICS_ID = gql`
  query CheckoutAnalyticsId($token: String!) {
    checkout(token: $token) {
      id
      analyticsId
    }
  }
`;
