import styled from 'styled-components';
import colors from './colors';
import { px2Rem } from '../utils/px2Rem';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

interface IRayloCardProps {
  width?: number;
  height?: number;
  styles?: string;
}
export const RayloCard = styled.div<IRayloCardProps>`
  margin: ${px2Rem(24)} auto;
  width: ${({ width }) => width && px2Rem(width)};
  max-width: 95%;
  padding: ${px2Rem(24)} ${px2Rem(10)} ${px2Rem(48)} ${px2Rem(10)};
  height: ${({ height }) => height ?? 'auto'};
  background-color: ${colors.white};
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
  -webkit-box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
  -moz-box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  font-size: 0.8125em;
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  transition: all 0.35s;
  ${({ styles }) => styles}
`;
