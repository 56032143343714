import Container from 'elements/Container';
import StepNameEmailContent from 'screens/Checkout/components/StepNameEmailContent';
import { StepNameEmailProvider } from './hooks/useStepNameEmailContext';
import { IStepNameEmail } from './types';

export const StepNameEmailContainer = ({
  dataTestId,
  onSuccess,
}: IStepNameEmail) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepNameEmailProvider onSuccess={onSuccess}>
        <StepNameEmailContent />
      </StepNameEmailProvider>
    </Container>
  );
};
