import React from 'react';
import { primaryColors } from '../../../../styles/variables/colors';
import { px2Rem } from '../../../../utils/px2Rem';
import Header from '../../../../elements/Header';
import Spacer from '../../../../elements/Spacer';
import { Loader } from '../../../../components/common/Loader';
import { FadeIn } from '../../../../components/animations/Transitions';

export const PaymentProcessing = () => {
  return (
    <FadeIn>
      <div>
        <Spacer height={160} />
        <Header level={1} color={primaryColors.c01} fontSize={24} centered>
          Processing
        </Header>
        <Header
          level={1}
          color={primaryColors.c01}
          fontSize={16}
          style={{ marginTop: px2Rem(12) }}
          centered
        >
          Please wait a moment...
        </Header>
        <Spacer height={48} />
        <div
          style={{
            margin: 'auto',
            width: 'fit-content',
          }}
        >
          <Loader type="quad" />
        </div>
        <Spacer height={384} />
      </div>
    </FadeIn>
  );
};
