import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { ASSETS_SVGS_BASE_URL, CSS_FONT_WEIGHT, palette } from '@raylo-tech/raylopay-ui';

const StyledDropdown = styled.select`
  appearance: none;
  cursor: pointer;
  display: block;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: ${px2Rem(14)};
  width: 100%;
  height: ${px2Rem(56)};
  padding: ${px2Rem(2)} ${px2Rem(56)} ${px2Rem(2)} ${px2Rem(12)};
  border: ${px2Rem(1)} solid ${palette.charcoal[300]};
  border-radius: 0;
  margin: ${px2Rem(16)} 0 ${px2Rem(4)} 0;
  color: ${palette.charcoal[500]};
  background-color: ${primaryColors.w01};
  background-image: url("${ASSETS_SVGS_BASE_URL}/chevron-down-c02.svg");
  background-repeat: no-repeat;
  background-size: ${px2Rem(16)} auto;
  background-position: right ${px2Rem(16)} center;

  &:invalid {
    color: ${palette.charcoal[400]};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: ${primaryColors.c01};
    background-color: ${secondaryColors.c05};
  }
`;

export { StyledDropdown };
