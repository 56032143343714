import CancelLease from 'components/CancelLease';
import TextLink from 'elements/TextLink';
import { IParseOrder } from 'screens/Account/utils/parseOrder.helper';
import { YourTechStatus } from 'screens/Account/YourTechStatus';

// NOTE: We are passing the `upgradeAvailable` prop and not reading it from the order object,
// as if the order is upgradable, but another order is in arrears, we disable the upgrade option.
export const DeviceLeaseSecondaryAction = ({
  order,
  upgradeAvailable,
  onUpgrade,
}: {
  order: IParseOrder;
  upgradeAvailable: boolean;
  onUpgrade: () => void;
}) => {
  // if return is not started, upgrade would be a primary action and we still show
  // cancel lease as a secondary action
  if ([YourTechStatus.returnStarted, YourTechStatus.returnDue].includes(order.status)) {
    return upgradeAvailable && (
      <TextLink onClick={onUpgrade}>
        <span data-testid="upgrade-lease">Upgrade available</span>
      </TextLink>
    );
  }

  if (upgradeAvailable) { return; }

  return (
    <CancelLease order={order} />
  );
};
