import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from '../../colors';

const StyledRadioItem = styled.div<{
  $error?: boolean;
  $marginBottom?: number;
}>`
  ${({ $error, $marginBottom }) => `
    position: relative;
    width: 100%;
    margin-bottom: ${px2Rem($marginBottom ?? 16)};

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      background-color: ${palette.charcoal[100]};
      padding: ${px2Rem(12)} ${px2Rem(12)} ${px2Rem(12)} ${px2Rem(48)};
      border-radius: ${px2Rem(4)};
      cursor: pointer;
      font-size: ${px2Rem(16)};
      line-height: ${px2Rem(20)};
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: ${palette.charcoal[500]};
      border: 2px solid ${$error ? palette.error[500] : palette.charcoal[100]};
      margin-bottom: ${$error ? px2Rem(4) : 0};

      &:hover {
        background-color: ${palette.charcoal[200]};
        border: 2px solid ${$error ? palette.error[500] : palette.charcoal[200]};
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .radio-circle {
      position: absolute;
      top: ${px2Rem(12)};
      left: ${px2Rem(12)};
      height: ${px2Rem(24)};
      width: ${px2Rem(24)};
      background-color: ${palette.white};
      border: 1px solid ${palette.charcoal[300]};
      border-radius: 50%;
      pointer-events: none;
    }

    input:checked ~ .radio-circle::after {
      content: '';
      background-color: ${palette.blue[500]};
      border-radius: 50%;
      display: block;
      height: ${px2Rem(16)};
      width: ${px2Rem(16)};
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    input:checked + label {
      border: 2px solid ${palette.blue[500]};
    }
  `}
`;

export { StyledRadioItem };
