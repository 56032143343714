import { useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import constate from 'constate';
import store from 'store';
import { useLazyQuery } from '@apollo/client';
import { CUSTOMER_ANALYTICS_ID } from 'graphql/queries/customer/analyticsId.graphql';
import { identify, reset, track } from 'integrations/segment/events';
import { CHECKOUT_ANALYTICS_ID } from 'graphql/queries/checkout/analyticsId.graphql';

const useAnalytics = () => {
  const [analyticsId, setAnalyticsId] = useState<string | null>(null);
  const [getCustomerAnalyticsId] = useLazyQuery<{ customer: { analyticsId: string } }>(CUSTOMER_ANALYTICS_ID);
  const [getCheckoutAnalyticsId] = useLazyQuery<{ checkout: { id: string; analyticsId: string } }>(CHECKOUT_ANALYTICS_ID);

  const setupAnalyticsIdOnClarity = (id: string | null) => {
    const setupAnalyticsIdOnClarityInterval = setInterval(function () {
      if (typeof clarity === 'function') {
        clarity('identify', id);
        clearInterval(setupAnalyticsIdOnClarityInterval);
      }
    }, 100);
  };

  const setAnalyticsIdFromCustomer = () => {
    const userToken = store.get('userToken');

    if (!userToken) return;

    getCustomerAnalyticsId().then((res) => {
      const customer = res.data?.customer;
      if (!customer) {
        return;
      }
      const customerAnalyticsId = customer.analyticsId;
      setAnalyticsId(customerAnalyticsId);

      identify(customerAnalyticsId, () => {
        track('Signed In');
      });

      setupAnalyticsIdOnClarity(customerAnalyticsId);
      Sentry.setUser({ id: customerAnalyticsId });
    });
  };

  const setAnalyticsIdFromCheckout = useCallback((token: string) => {
    getCheckoutAnalyticsId({ variables: { token } }).then((res) => {
      const checkout = res.data?.checkout;
      if (!checkout) {
        return;
      }
      const checkoutAnalyticsId = checkout.analyticsId;
      const checkoutId = checkout.id;
      setAnalyticsId(checkoutAnalyticsId);
      identify(checkoutAnalyticsId);
      setupAnalyticsIdOnClarity(checkoutAnalyticsId);
      Sentry.setUser({ id: checkoutAnalyticsId, checkoutId });
    });
  }, [getCheckoutAnalyticsId]);

  const clearAnalyticsId = () => {
    setAnalyticsId(null);
    reset();
    setupAnalyticsIdOnClarity(null);
    Sentry.setUser(null);
  };

  return {
    analyticsId,
    setAnalyticsIdFromCustomer,
    setAnalyticsIdFromCheckout,
    clearAnalyticsId,
  };
};

const [AnalyticsProvider, useAnalyticsContext] = constate(useAnalytics);
export { AnalyticsProvider, useAnalyticsContext };
