import { IncomingPaymentStatus } from 'graphql/mutations/incomingPayment/incomingPaymentStatuses.enum';
import {
  ICheckoutIncomingPaymentsQuery
} from 'graphql/queries/checkout/incomingPayment/incomingPayments.graphql';

export const stripeClientSecret = (checkout: ICheckoutIncomingPaymentsQuery['checkout']) => {
  return checkout?.incomingPayments
    .find(
      (incomingPayment: any) =>
        incomingPayment.state === IncomingPaymentStatus.Prepared
    )
    ?.partnerReferences.find(
      (partner: any) =>
        partner.referenceType === 'stripe_setup_intent_client_secret'
    );
};
