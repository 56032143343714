import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { ICopy } from './types';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

const StyledCopy = styled.p<ICopy>`
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ bold }) => bold && `font-weight: ${CSS_FONT_WEIGHT.vars.bold};`}
  ${({ italic }) => italic && 'font-style: italic;'}
  ${({ lineHeight }) => lineHeight && `line-height: ${px2Rem(lineHeight)};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ styles }) => styles}
`;

export { StyledCopy };
