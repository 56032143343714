import React from 'react';
import { ISectionMessageBase } from './SectionMessageWithIcon.types';
import Container from '../../../elements/Container';
import { palette } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import Icon from '../../../elements/Icon';
import SectionMessageBase from '../SectionMessageBase';

export const SectionMessageWithIcon = (
  {
    dataTestId,
    header,
    textLink,
    copy,
    icon,
    iconUrl,
    content,
    iconSize,
    maxIconHeight,
    linkOnClick,
    bulletList,
    iconLeftAlign,
    backgroundColor = palette.blue[100],
    borderColor = palette.blue[200],
  }: ISectionMessageBase) => {
  const iconPadding = iconLeftAlign ? `0 ${px2Rem(16)} 0 0` : `0 0 0 ${px2Rem(16)}`;

  return (
    <SectionMessageBase
      linkOnClick={linkOnClick}
      bulletList={bulletList}
      dataTestId={`${dataTestId}-base`}
      header={header}
      textLink={textLink}
      copy={copy}
      content={content}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <Container
        fitContent
        padding={iconPadding}
        order={iconLeftAlign ? '-1' : '1'}
        dataTestId={`${dataTestId}-icon`}
      >
        {!iconUrl && icon && <Icon name={icon} dataTestId={icon} $width={iconSize} />}
        {iconUrl && <img data-testid={`${dataTestId}-img`} src={iconUrl} alt={icon} style={{ maxHeight: maxIconHeight && px2Rem(maxIconHeight) }} />}
      </Container>
    </SectionMessageBase>

  );
};
