import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import useRayloQuery from 'utils/useRayloQuery';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { QL_SEGMENT_EVENT } from './checkoutSegmentEventProperties.graphql';

type CheckoutCategories = 'Upgrade' | 'Additional tech' | 'New';

const mapRawProductDataToEventProperties = (data: any, checkoutCategory: CheckoutCategories): object => {
  const products = data.checkout.items.map((item: any) => {
    const { costSummary, variant } = item;
    const product: any = {
      product_id: variant.id,
      condition: variant.condition,
      insurance: costSummary.includesInsurance,
      lease_term: `${costSummary.termLength} ${costSummary.recurringPeriod}`,
      manufacturer: variant.product.manufacturer.displayName,
      monthly_total: costSummary.recurring.totalAmount.valueAfterTax.value,
      name: variant.displayName,
      price: costSummary.recurring.totalAmount.valueAfterTax.value,
      product_category: variant.product.category.displayName,
    };

    variant.optionValues.forEach((optionValue: any) => {
      const optionName = optionValue.optionType.slug;
      product[optionName] = optionValue.displayName;
    });

    return product;
  });

  return {
    merchant_name: data.checkout.merchant.displayName,
    order_id: data.checkout.id,
    checkout_type: checkoutCategory,
    products,
  };
};

export const useProductPropertiesEventQuery = (
  onCompleted: (result: object) => void
) => {
  const { checkoutToken, checkoutMode } = useCheckoutContext();

  const checkoutCategory = () => {
    switch (checkoutMode) {
      case CheckoutMode.ADDITIONAL_TECH:
        return 'Additional tech';
      case CheckoutMode.UPGRADE:
        return 'Upgrade';
      default:
        return 'New';
    }
  };

  useRayloQuery(QL_SEGMENT_EVENT, {
    variables: { token: checkoutToken },
    onCompleted: (data: object) => {
      onCompleted(mapRawProductDataToEventProperties(data, checkoutCategory()));
    },
  });
};
