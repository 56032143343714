import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useCheckoutAddressContext } from 'screens/Checkout/containers/StepAddress/hooks/useCheckoutAddressContext';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { primaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import StepAddressNew from '../StepAddressNew';
import { StyledAddressNew } from './styles';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const AddressNew = ({ dataTestId }: ITestDataId) => {
  const { newAddressSet, addPreviousAddress } = useCheckoutAddressContext();
  const { checkoutMode } = useCheckoutContext();
  const newCustomer = checkoutMode === CheckoutMode.NEW_CUSTOMER;
  return (
    <StyledAddressNew data-testid={dataTestId}>
      {!newCustomer && (
        <Header level={2} color={primaryColors.c01}>
          Your Address
        </Header>
      )}
      <Spacer height={24} />
      <Header level={4} color={primaryColors.c01} fontSize={16}>
        {!addPreviousAddress ?
          'Delivery & billing address' :
          'Previous home address'}
      </Header>
      <Spacer height={newAddressSet ? 24 : 16} />
      <StepAddressNew />
    </StyledAddressNew>
  );
};
