import { BulletList, Button, Header, SectionMessageWithIcon, Spacer } from '@raylo-tech/raylopay-ui';
import { ICopy } from '../utils/subscriptionCancellationCopy';

interface IWinBack {
  copy?: undefined | ICopy;
  onNext: () => void;
  onClose: () => void;
}

export const WinBackCopyContainer = ({ copy, onNext, onClose }: IWinBack) => {
  if (!copy) return;

  return (
    <>
      {copy.heading && <Header fontSize={24} lineHeight={28}>{copy.heading}</Header>}
      {copy.bulletPoints?.length > 0 && (
        <>
          <Spacer height={24} />
          <SectionMessageWithIcon
            dataTestId="win-back-section-message"
            icon="RayloDesignInfo"
            iconSize={16}
            header={copy.sectionMessageHeading}
            content={(
              <>
                <Spacer height={16} />
                <BulletList
                  items={copy.bulletPoints}
                  bulletIcon="Success"
                />
              </>
            )}
          />
        </>
      )}
      {copy.footer && (
        <>
          <Spacer height={24} />
          {copy.footer}
        </>
      )}
      <Spacer height={24} />
      <Button onClick={onNext} dataTestId="form-primary-button">Next</Button>
      <Spacer height={24} />
      <Button onClick={onClose} secondary dataTestId="form-secondary-button">Don&apos;t Cancel</Button>
    </>
  );
};
