import { Copy } from '@raylo-tech/raylopay-ui';

export const sectionMessageCopy =
  'We may use your card details to take payments due under your agreement. This is known as a continuous payment authority (CPA). See below for more information.';

export const continuousPaymentAuthorityContent = (
  <>
    <Copy className="cpa-body">
      A Continuous Payment Authority (“CPA”) is a payment arrangement set up
      using your debit/credit card. Under a CPA you give us recurring authority
      to take payments from your card. A CPA is different from a Standing Order,
      because the timing and amount of payments may vary. CPA also differs from
      a Direct Debit as CPA is not covered by a guarantee scheme.
    </Copy>
    <Copy className="cpa-body">
      We may request you to provide credit or debit card details either before
      your agreement has started or during your agreement. In providing these,
      you authorise us to charge to such card via a CPA and the provisions of
      this Clause are applicable.
    </Copy>
    <Copy className="cpa-body">
      By agreeing to the use of CPA you authorise us to take payment(s) from
      your account using your card details. Payments includes both your hire
      payments and any charges that you owe us.
    </Copy>
    <Copy className="cpa-body">
      Where there is no direct debit instruction on your agreement, payments may
      be taken using these details on the date Hire Payments or other payments
      are due. We will always initially try to take the full amount due. If we
      cannot take full payment due we may (but do not have to) try to take a
      part payment of half the amount due on the due date.
    </Copy>
    <Copy className="cpa-body">
      Where the debit instruction or CPA on your agreement has failed to collect
      the Hire Payment or other payment due, a payment will be attempted after
      Raylo is notified of the failure of the original payment attempt. We may
      (but do not have to) attempt to take payment by CPA for any late payment
      fee incurred.
    </Copy>
    <Copy className="cpa-body">
      Again, if we cannot recover the full amount due, we may (but do not have
      to) try to take a part payment of half the amount due.
    </Copy>
    <Copy className="cpa-body">
      If after the above attempts there is only part of the instalment or other
      payment outstanding we may (but do not have to) make one further attempt
      to take the rest of the payment on the next working day.
    </Copy>
    <Copy className="cpa-body">
      If your hire payment is not taken on the due date then a late payment fee
      of £19 will be incurred, even if we subsequently manage to take your
      payment using the CPA.
    </Copy>
    <Copy className="cpa-body">
      If after the above attempts there is still a sum outstanding in respect of
      the instalment or other payment due we will try to contact you to discuss
      the state of your account. Any further use of the CPA in respect of that
      payment will be in accordance with those discussions. If you are in
      financial difficulties we will show forbearance and attempt to agree a
      suitable alternative payment date with you.
    </Copy>
    <Copy className="cpa-body">
      If we are unable to contact you we may:
      <br />
      (a) Make one further attempt to take any outstanding part of the missed
      payment and late payment fee again following the same procedure as set out
      above.
      <br />
      (b) At the same time as we try to take payments under (a) above use the
      CPA to try to take other instalments or fees that have fallen due
      following the same procedure set out above.
      <br />
      (c) Continue to use the CPA for future payments under the hire agreement,
      including any fees due under the hire agreement (unless we have reason to
      believe that you are in financial difficulties and cannot afford the
      payment - see below).
    </Copy>
    <Copy className="cpa-body">
      If your circumstances change and you will have insufficient funds in your
      account when payments are due, or if you are experiencing financial
      difficulties, please get in touch with us as soon as possible so we can
      discuss the options available to you.
    </Copy>
    <Copy className="cpa-body">
      We will never exercise our rights under the CPA if we have reason to
      believe that you are in financial difficulties and cannot afford the
      payment.
    </Copy>
    <Copy className="cpa-body">
      You will not be charged any fee by us or your bank for failures of payment
      attempts under any CPA, though as set out above you will still be charged
      a Late Payment Fee if this failure means that you are unable to make a
      payment on its due date.
    </Copy>
    <Copy className="cpa-body">
      You can cancel your CPA at any time by contacting us or your card
      provider. Please note that if you cancel the CPA, you will still need to
      arrange an alternative method of repayment with us.
    </Copy>
    <Copy className="cpa-body">
      You have the right under the hire agreement to ask us to change your
      payment date (although we do not have to do this).
    </Copy>
  </>
);
