import React, { useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { Button } from 'elements/Button/Button';
import { StripeError } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { captureErrorEvent } from 'utils/errorTracking';
import Spacer from '../../elements/Spacer';
import { ButtonTypes } from '../../elements/Button/types';
import { FadeIn } from '../../components/animations/Transitions';
import { px2Rem } from '../../utils/px2Rem';
import { IPaymentForm } from './paymentForm.interface';
import { uiStateColors } from '../../styles/variables/colors';

export const PaymentForm = ({ successUrl, amountValid }: IPaymentForm) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [formNotReady, setFormNotReady] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const enableButtonOnCompletion = (element: any) => {
    setFormNotReady(!element.complete);
  };

  const handleSubmit = async (event: any | null) => {
    event?.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setErrorMessage('');
    setFormNotReady(true);

    await stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}${successUrl}`,
        },
      })
      .then((value: { error: StripeError }) => {
        if (value.error) {
          if (value.error.setup_intent?.status === 'succeeded') {
            history.push(successUrl);
            return;
          }

          if (value.error.type === 'rate_limit_error') {
            setTimeout(() => {
              handleSubmit(null);
            }, 1050);
          } else if (value.error.type === 'card_error') {
            setErrorMessage(
              'Please try again ensuring your card details are correct and you have sufficient funds. If you can’t pay the full amount, try making a partial payment. If your payment is still declined, try another card.'
            );
            setFormNotReady(false);
          } else {
            captureErrorEvent('unhandled Stripe Error', value.error);
          }
        }
      });
  };

  return (
    <form>
      <FadeIn>
        <div style={{ minHeight: px2Rem(330) }}>
          <PaymentElement
            onChange={element => enableButtonOnCompletion(element)}
          />
        </div>
      </FadeIn>
      <Spacer height={16} />
      <div
        style={{
          color: uiStateColors.error,
          fontSize: px2Rem(14),
          width: '100%',
        }}
      >
        {errorMessage}
      </div>
      <Spacer height={16} />
      <div
        style={{
          width: '100%',
        }}
      >
        <Button
          dataTestId="payNowButtonOnForm"
          disabled={formNotReady || !amountValid}
          size="large"
          buttonType="solid"
          buttonColor={ButtonTypes.b01}
          onClick={handleSubmit as unknown as () => void}
          styles={`
              display: inherit;
              margin-left: auto;
              padding: ${px2Rem(20.5)} ${px2Rem(67.5)};
              width: 100%;
            `}
        >
          Pay Now
        </Button>
      </div>
    </form>
  );
};
