// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom

/* eslint-disable */
export const loadIntercom = (id: string) => {
  // @ts-ignore
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      var i: any = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args: any) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${id}`;
        const x = d.getElementsByTagName('script')[0];
        x?.parentNode?.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};
/* eslint-enable */

// Initializes Intercom
export const bootIntercom = (options = {}) => {
  if (window?.Intercom) {
    window.Intercom('boot', {
      // hide_default_launcher: true,
      ...options,
    });
  }
};
