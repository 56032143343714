import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { NavDataModel } from '../../../types/navData.types';
import { ConsumerTypesEnum } from '../../../types/consumerTypes.types';

type NavBarContextProps = {
  navData: NavDataModel;
  accountHomeUrl: string;
  productsUrl: string;
  LinkComponent?: React.ElementType;
  consumerType: ConsumerTypesEnum;
  toggleUrl?: string;
  /**
   * @todo remove this once SMB goes live
   */
  showConsumerToggle: boolean;
};

export type NavBarContextType = NavBarContextProps & {
  sideNavOpen: boolean;
  setSideNavOpen: Dispatch<SetStateAction<boolean>>;
  subMenuOpen: string;
  setSubMenuOpen: Dispatch<SetStateAction<string>>;
  handleCloseSideNav: () => void;
};

export const initialNavBarContextState: NavBarContextType = {
  navData: {} as NavDataModel,
  accountHomeUrl: '',
  productsUrl: '',
  LinkComponent: undefined,
  consumerType: ConsumerTypesEnum.PERSONAL,
  showConsumerToggle: false,
  sideNavOpen: false,
  setSideNavOpen: () => {},
  subMenuOpen: 'categories',
  setSubMenuOpen: () => {},
  handleCloseSideNav: () => {},
};

export const NavBarContext = createContext<NavBarContextType>(initialNavBarContextState);

export const NavBarContextProvider = ({ navData, accountHomeUrl, productsUrl, LinkComponent, consumerType, showConsumerToggle, toggleUrl, children }: PropsWithChildren<NavBarContextProps>) => {
  const [sideNavOpen, setSideNavOpen] = useState(initialNavBarContextState.sideNavOpen);
  const [subMenuOpen, setSubMenuOpen] = useState(initialNavBarContextState.subMenuOpen);

  useEffect(() => {
    document.documentElement.style.overflow = sideNavOpen ? 'hidden' : 'auto';
  }, [sideNavOpen]);

  const handleCloseSideNav = () => {
    setSideNavOpen(false);
    setTimeout(() => {
      setSubMenuOpen('categories');
    }, 300);
  };

  const value = useMemo(
    () => ({
      navData,
      accountHomeUrl,
      productsUrl,
      LinkComponent,
      consumerType: consumerType,
      showConsumerToggle,
      toggleUrl,

      sideNavOpen,
      setSideNavOpen,
      subMenuOpen,
      setSubMenuOpen,
      handleCloseSideNav,
    }),
    [
      navData,
      accountHomeUrl,
      productsUrl,
      LinkComponent,
      consumerType,
      showConsumerToggle,
      toggleUrl,
      sideNavOpen,
      subMenuOpen,
      handleCloseSideNav,
    ]
  );

  return (
    <NavBarContext.Provider value={value}>
      {children}
    </NavBarContext.Provider>
  );
};

export const useNavBarContext = () => {
  return useContext(NavBarContext);
};
