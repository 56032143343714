import { useEffect, useState } from 'react';
import Copy from 'elements/Copy';
import Spacer from 'elements/Spacer';
import { errorDisplayMessages } from 'screens/Checkout/helpers/errorDisplayMessages';
import { uiStateColors } from 'styles/variables/colors';
import { StyledFormErrorMessages } from './styles';
import { IFormErrorMessages } from './types';

export const FormErrorMessages = ({
  dataTestId,
  formErrors,
}: IFormErrorMessages) => {
  const [displayMessages, setDisplayMessages] = useState<any>([]);

  useEffect(() => {
    const messages: any = [];
    formErrors?.forEach((error: any) => {
      messages.push(
        errorDisplayMessages.find(message => message.code === error.code)
          ?.displayMessage || error.message
      );
    });
    setDisplayMessages(messages);
  }, [formErrors]);

  if (!displayMessages.length) return null;

  return (
    <StyledFormErrorMessages data-testid={dataTestId}>
      {displayMessages.map((message: any) => (
        <Copy color={uiStateColors.error} fontSize={16} key={message}>
          {message}
        </Copy>
      ))}
      <Spacer height={24} />
    </StyledFormErrorMessages>
  );
};
