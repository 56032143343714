import gql from 'graphql-tag';
import { subscriptionNextPaymentScheduleEvent } from 'graphql/fragments/subscriptionNextPaymentScheduleEvent.fragment';

export const GET_CUSTOMER_ACCOUNT_OVERVIEW = gql`
    ${subscriptionNextPaymentScheduleEvent}
    query AccountCustomerOverviewQuery {
    customer {
      id
      firstName
      lastName
      email
      preApproval {
        successful
        checkout {
          decision {
            id
            outcome
          }
        }
        recurringTaxableAmount {
          valueAfterTax {
            formattedValue
            value
          }
        }
      }
      orders {
        id
        state
        knownDeliveryDates
        items {
          id
          fulfillmentItem {
            id
            deliveredAt
            dispatchedAt
            expectedDeliveryAt
            trackingUrl
            trackingReference
            courierName
          }
          subscription {
            id
            state
            createdAt
            startedOn
            upgrade {
              canStartAssetReturn
              dueOn
              eligible
            }
            activeAsset {
              id
              imeiNumber
              serialNumber
              blockState
            }
            assetReturns {
              id
              customerCollectionUrl
              state
            }
            arrearsAmount {
              currencyCode
              currencySymbol
              formattedValue
              valueInSubunit
            }
            ...SubscriptionPaymentScheduleEvent,
          }
          deliveryExpectedBetween {
            max
            min
          }
          deliveryEstimatedBetween {
            max
            min
          }
          costSummary {
            currencyCode
            includesInsurance
            recurring {
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
            initial {
              totalAmount {
                valueAfterTax {
                  value
                  currencyCode
                }
              }
            }
          }
          variant {
            id
            displayName
            optionValues {
              id
              displayName
              optionType {
                id
                slug
              }
            }
            product {
              id
              displayName
              preOrder
              releasedAt
              category {
                displayName
              }
            }
            images {
              alt
              position
              tag
              title
              url
            }
          }
        }
      }
    }
  }
`;
