import { Container } from 'elements';
import { Spacer } from '@raylo-tech/raylopay-ui';
import { StyledCheckoutWrapper } from './styles';
import { ICheckoutDecisionWrapper } from './types';
import RayloPayHeader from 'elements/RayloPayHeader/RayloPayHeader';

export const CheckoutDecisionWrapper = ({
  dataTestId,
  children,
}: ICheckoutDecisionWrapper) => {
  return (
    <StyledCheckoutWrapper data-testid={dataTestId}>
      <Container width="100vw" centerVertical noPadding>
        <RayloPayHeader />
        <Spacer height={32} />
        {children}
      </Container>
    </StyledCheckoutWrapper>
  );
};
