import { ReactNode, useEffect } from 'react';
import { useIntercomContext } from 'components/Intercom/useIntercom';
import { AccountFooter } from 'components/Account/AccountFooter';
import { useAnalyticsContext } from 'utils/AnalyticsProvider';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { ExistingCustomerNavBar } from '@raylo-tech/raylopay-ui';
import { useHistory } from 'react-router';

const links = [
  {
    title: 'Account Overview',
    href: '/account',
  },
  {
    title: 'Account Details',
    href: '/account/your-account',
  },
  {
    title: 'Referrals',
    href: '/account/refer-a-friend',
  },
];

export interface IAccountWrapper {
  children?: ReactNode;
  showFooter?: boolean;
}

export const AccountWrapper = ({
  children,
  showFooter = false,
}: IAccountWrapper) => {
  const { setAnalyticsIdFromCustomer } = useAnalyticsContext();
  const { showIntercomWidget } = useIntercomContext();
  const { customerIsInMobileApp } = useSharedCustomer();
  const history = useHistory();

  useEffect(() => {
    setAnalyticsIdFromCustomer();
    showIntercomWidget();
  }, []);

  const logout = () => {
    history.push('/logout');
  };

  if (customerIsInMobileApp) return children;

  return (
    <>
      <ExistingCustomerNavBar
        logoLink="/account"
        onLogout={logout}
        links={links}
      />
      {children}
      {showFooter && <AccountFooter />}
    </>
  );
};
