import { HireAgreement } from 'components/Checkout/HireAgreement';
import TermsAndConditions from 'components/TermsAndConditions';
import Container from 'elements/Container';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { useEffect } from 'react';
import { useStepSignContext } from 'screens/Checkout/containers/StepSign/hooks/useStepSignContext';
import { primaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import { StyledStepSignContent } from './styles';
import { Loader } from '../../../../components/common/Loader';
import { FadeIn } from '../../../../components/animations/Transitions';

export const StepSignContent = ({ dataTestId }: ITestDataId) => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'terms',
    checkoutScreen: 'accept terms',
    when: 'on page load',
  });
  const { hireAgreementDetails, orderPlaced, loading } = useStepSignContext();
  useEffect(() => {
    if (orderPlaced) {
      window.location.pathname = 'checkout/decision';
    }
  }, [orderPlaced]);

  return (
    <StyledStepSignContent data-testid={dataTestId}>
      <Container noPadding>
        <Header level={2} color={primaryColors.c01}>
          One last step...
        </Header>
        <Spacer height={16} />
        <Header level={4} color={primaryColors.c01}>
          Please accept the T&amp;C’s to proceed
        </Header>
        <Spacer height={16} />
        <TermsAndConditions>
          {loading ? (
            <FadeIn>
              <Container center>
                <Spacer height={208} />
                <Loader type="quad" />
              </Container>
            </FadeIn>
          ) : (
            <FadeIn>
              <HireAgreement details={hireAgreementDetails} />
            </FadeIn>
          )}
        </TermsAndConditions>
      </Container>
    </StyledStepSignContent>
  );
};
