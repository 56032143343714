import { affordableMobiles } from './affordableMobiles';
import { rayloLight } from './rayloLight';
import { uSwitch } from './uSwitch';

export const themes = [
  {
    name: 'affordableMobiles',
    domain: 'affordablemobiles.raylopay.com',
    stagingDomain: 'affordablemobiles.staging.raylopay.com',
    theme: affordableMobiles,
  },
  {
    name: 'rayloLight',
    domain: '',
    stagingDomain: '',
    theme: rayloLight,
  },
  {
    name: 'uSwitch',
    domain: 'uswitch.raylopay.com',
    stagingDomain: 'uswitch.staging.raylopay.com',
    theme: uSwitch,
  },
];
