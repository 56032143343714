
import gql from 'graphql-tag'

import { Checkout } from 'screens/Checkout/graphQL/fragments'

export const MU_CREATE_PAYMENT = gql`
  mutation CreateCheckoutPaymentMutation($checkoutToken: String!) {
    createCheckoutPayment(checkoutToken: $checkoutToken) {
      clientSecret
    }
  }
`

export const MU_START_BANKING_CONNECTION = gql`
    mutation StartBankingConnectionMutation($checkoutToken: String!) {
        startBankingConnection(
            checkoutToken: $checkoutToken
        ) {
            success
        }
    }`

export const MU_VERIFY_BANKING_CONNECTION = gql`
  mutation VerifyBankingConnection($checkoutToken: String) {
    verifyBankingConnection(checkoutToken: $checkoutToken) {
      success
    }
  }
`

export const MU_SWAP_CHECKOUT = gql`
  mutation SwapCheckoutItemMutation(
    $costSummaryId: ID!,
    $variantId: ID!,
    $itemId: ID!,
    $merchantId: ID!,
    $checkoutToken: String!) {
    swapCheckoutItem(
      checkoutToken: $checkoutToken,
      input: {
        existingItemId: $itemId,
        newItem: {
          variantId: $variantId,
          costSummaryId: $costSummaryId
        }
      }
    ) {
      checkout {
        ...CheckoutBase,
        ...CheckoutCustomerInfo,
        ...CheckoutPaymentInfo,
        ...CheckoutAddress,
        ...CheckoutIncomplete,
        items {
          id
          variant {
            id
            displayName
            optionValues {
              id
              displayName
              raw
              optionType {
                id
                slug
              }
            }
            product {
              id
              displayName
            },
            images {
              alt
              position
              tag
              title
              url
            }
            ...VariantCostSummaries
          }
          ...CheckoutItemCostSummary
        }
      },
      errors {
        field,
        code,
        message
      }
    }
  }
  ${Checkout.fragments.base}
  ${Checkout.fragments.customerInfo}
  ${Checkout.fragments.paymentInfo}
  ${Checkout.fragments.address}
  ${Checkout.fragments.incomplete}
  ${Checkout.fragments.variantCostSummaries}
  ${Checkout.fragments.checkoutItemCostSummary}
`;

export const MU_RESUME_CHECKOUT = gql`
  mutation ResumeCheckoutMutation(
    $checkoutToken: String!) {
    resumeCheckout(
      checkoutToken: $checkoutToken,
    ) {
      checkout {
        id
        state
        token
        decision {
          id
          outcome
          outcomeReason
        }
      }
      errors {
        field
        code
        message
      }
    }
  }
`

export const MU_SUBMIT_CHECKOUT = gql`
  mutation SubmitCheckoutMutation(
    $checkoutToken: String!) {
    submitCheckout(
      checkoutToken: $checkoutToken,
    ) {
      checkout {
        id,
        state,
        decision {
          id
          updatedAt,
          outcome
          outcomeReason
        }
      }
      errors {
        field
        code
        message
      }
    }
  }
`

export const MU_TRACKING_CHECKOUT = gql`
  mutation TrackCheckoutMutation($token: String!, $inputs: [UpdateCheckoutTrackingInput!]!) {
    trackCheckout(
      checkoutToken: $token
      inputs: $inputs
    ) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`

export const MU_CANCEL_EXISTING_TERMINATION = gql`
  mutation CancelExistingTermination($subscriptionId: ID!) {
    cancelSubscriptionTermination(
      subscriptionId: $subscriptionId
    ) {
      success
      errors {
        code
        field
        message
      }
    }
  }
`
