import RayloModal from 'components/RayloModal';
import Spacer from 'elements/Spacer';
import { useUiContext } from 'utils/useUiContext';
import ITestDataId from 'types/ITestDataId.type';

export const PrivacyModal = ({ dataTestId }: ITestDataId) => {
  const { setShowModal, setShowPrivacyModal } = useUiContext();
  const handleModalClose = () => {
    setShowModal(false);
    setShowPrivacyModal(false);
  };
  return (
    <RayloModal
      header="Raylo Privacy Policy"
      onClose={handleModalClose}
      dataTestId={dataTestId}
    >
      <p>Raylo Modal</p>
      <Spacer height={40} />
    </RayloModal>
  );
};
