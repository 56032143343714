import React from 'react';
import styled from 'styled-components';
import { palette } from '../../colors';
import Copy from '../Copy';
import { INumberStepContainer } from './NumberStepContainer.types';
import { RoundNumber } from './NumberStepContainer.styles';

export const NumberStepContainer = ({
  dataTestId,
  step,
  small,
}: INumberStepContainer) => {
  return (
    <RoundNumber data-testid={dataTestId} small={small}>
      <Copy color={palette.white}>{step}</Copy>
    </RoundNumber>
  );
};
