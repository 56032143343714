import ChevronLeft from '../../icons/ChevronLeft';
import { StyledBrowserBackArrow, StyledBrowserBackArrowText } from './BrowserBackArrow.styles';
import { px2Rem } from 'utils/px2Rem';

type BrowserBackArrowProps = {
  color: string;
  text: string;
  onClick: () => void;
};

export function BrowserBackArrow({ color, text, onClick }: BrowserBackArrowProps) {
  return (
    <StyledBrowserBackArrow onClick={onClick}>
      <div>
        <span style={{ marginRight: px2Rem(8) }}>
          <ChevronLeft color={color} />
        </span>
        <StyledBrowserBackArrowText $color={color}>{text}</StyledBrowserBackArrowText>
      </div>
    </StyledBrowserBackArrow>
  );
}
