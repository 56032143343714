import { IButton } from './types';
import { StyledButton } from './styles';

export const Button = ({
  dataTestId,
  text,
  onClick,
  size,
  buttonColor,
  buttonType,
  active,
  disabled,
  type,
  formId,
  fullWidth,
  selected,
  children,
  iconText,
  fontSize,
  styles,
}: IButton) => {
  const buttonProps = {
    dataTestId,
    onClick,
    size,
    buttonColor,
    buttonType,
    active,
    disabled,
    type,
    fullWidth,
    selected,
    children,
    iconText,
    fontSize,
    styles,
  };
  if (type === 'submit') {
    return (
      <StyledButton
        {...buttonProps}
        type="submit"
        data-testid={dataTestId}
        form={formId}
      >
        {text}
      </StyledButton>
    );
  }
  if (iconText) {
    return <StyledButton {...buttonProps}>{children}</StyledButton>;
  }
  return (
    <StyledButton {...buttonProps} data-testid={dataTestId}>
      {text}
      {children}
    </StyledButton>
  );
};
