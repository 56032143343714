import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_SUBSCRIPTION,
  IGetSubscriptionQuery,
  IGetSubscriptionQueryOrder,
  IGetSubscriptionQueryOrderItem
} from 'screens/Account/Forms/graphql/subscription.graphql';
import { px2Rem } from 'utils/px2Rem';
import { Container, Copy, Spacer } from '@raylo-tech/raylopay-ui';
import { Loader } from 'components/common/Loader';
import { UserReturnConfirmations } from 'screens/Account/Forms/components/UserReturnConfirmations/UserReturnConfirmations';
import { ReturnItemOverview } from 'screens/Account/Forms/components/ReturnItemOverview/ReturnItemOverview';
import { IStartAssetReturnMutation, START_ASSET_RETURN } from 'screens/Account/Forms/graphql/start_asset_return.graphql';
import { getActiveAssetReturn } from 'utils/subscription/getActiveAssetReturn';
import { IGetCustomerDeviceOrderSubscription } from 'screens/Account/DeviceProfile/graphql/deviceProfile.graphql';
import { ReturnsRedirectLoading } from './ReturnsRedirectLoading/ReturnsRedirectLoading';
import { ReturnFailedError } from './ReturnFailedError/ReturnFailedError';
import { useFormContext } from 'screens/Account/Forms/context/useFormContext';
import { Form } from 'screens/Account/Forms/Form';
import { track } from '../../../../integrations/segment/events';

export const PostUpgradeForm = () => {
  const [orderItem, setOrderItem] = useState<IGetSubscriptionQueryOrderItem>();
  const [showError, setShowError] = useState(false);
  const { currentPage, setCurrentPage, setShowExitModal } = useFormContext();

  const { subscriptionId } = useParams<{ subscriptionId: string }>();

  useQuery(GET_SUBSCRIPTION, {
    onCompleted(data: IGetSubscriptionQuery) {
      const orderItem = findOrderItem(data, subscriptionId);
      if (!orderItem) return;

      const trackingUrl = getCustomerCollectionUrl(orderItem);
      if (trackingUrl) window.location.assign(trackingUrl);

      setOrderItem(orderItem);
    },
  });

  const [getSubscription, { startPolling, stopPolling }] = useLazyQuery(GET_SUBSCRIPTION, {
    onCompleted(data: IGetSubscriptionQuery) {
      const orderItem = findOrderItem(data, subscriptionId);
      const trackingUrl = getCustomerCollectionUrl(orderItem);

      if (trackingUrl) {
        stopPolling();
        window.location.assign(trackingUrl);
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [startAssetReturn] = useMutation(START_ASSET_RETURN,
    {
      variables: {
        subscriptionId,
      },
      onCompleted(data: IStartAssetReturnMutation) {
        if (data.start.success) {
          getSubscription();
          startPolling(2000);
        } else {
          setShowError(true);
        }
      },
      onError() {
        setShowError(true);
      },
    }
  );

  useEffect(() => {
    trackEvent(currentPage);
  }, [currentPage]);

  const onClose = () => {
    if (currentPage === 1) return history.back();

    setShowExitModal('close');
  };

  const onNext = () => {
    if (currentPage === 2) startAssetReturn();

    setCurrentPage((previousPage: number) => previousPage + 1);
  };

  const CurrentPage = () => {
    if (showError) return (<ReturnFailedError />);

    if (!orderItem) {
      return (
        <Container center height={px2Rem(300)} width="auto">
          <Loader type="quad" />
        </Container>
      );
    }

    switch (currentPage) {
      case 1:
        return (
          <ReturnItemOverview
            onNext={onNext}
            onClose={onClose}
            orderItem={orderItem}
            header="Are you sure you want to return?"
            primaryButtonText="Yes, Return"
            secondaryButtonText="Don't Return"
          >
            <Copy>You must return the correct device to Raylo, or there will be a charge.</Copy>
            <Spacer height={24} />
            <Copy>If you would like to continue leasing your device, you don’t need to do anything.</Copy>
          </ReturnItemOverview>
        );
      case 2:
        return (
          <UserReturnConfirmations
            onNext={onNext}
            onClose={onClose}
            secondaryButtonText="Don't Return"
          />
        );
      case 3:
        return (<ReturnsRedirectLoading />);
    }
  };

  return (
    <Form
      title="Start return"
      totalPages={3}
    >
      {CurrentPage()}
    </Form>
  );
};

const findOrderItem = (data: IGetSubscriptionQuery, subscriptionId: string) => {
  return data.customer.orders.find((order: IGetSubscriptionQueryOrder) => {
    return order.items.some((item: IGetSubscriptionQueryOrderItem) => item.subscription?.id === subscriptionId);
  })?.items[0];
};

const getCustomerCollectionUrl = (orderItem?: IGetSubscriptionQueryOrderItem) => {
  if (!orderItem) return;

  const assetReturn = getActiveAssetReturn(orderItem?.subscription as Partial<IGetCustomerDeviceOrderSubscription>);
  return assetReturn?.customerCollectionUrl;
};

const trackEvent = (currentPage: number) => {
  const pageViewed = ['Return item overview', 'User confirmations', 'Redirect Loading'];

  track('Cancel Lease Post upgrade Form', { pageViewed: pageViewed[currentPage - 1] });
};
