import Container from 'elements/Container';
import { StepAddressCheck } from 'screens/Checkout/components/StepAddressCheck';
import { IStepAddressCheck } from './types';

export const StepAddressCheckContainer = ({
  dataTestId,
  slug,
  checkoutToken,
  onBack,
  onSuccess,
}: IStepAddressCheck) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepAddressCheck
        slug={slug}
        checkoutToken={checkoutToken}
        onBack={onBack}
        onSuccess={onSuccess}
      />
    </Container>
  );
};
