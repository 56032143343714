import { Modal } from 'components/common/Modal';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { useEffect, useState } from 'react';
import { useCheckoutMobileContext } from 'screens/Checkout/containers/StepMobileJob/hooks/useCheckoutMobileContext';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import ITestDataId from 'types/ITestDataId.type';
import MobileConfirm from '../MobileConfirm';
import MobileNew from '../MobileNew';
import { StyledStepMobileContent } from './styles';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const StepMobileContent = ({ dataTestId }: ITestDataId) => {
  const [confirm, setConfirm] = useState(false);
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'your details',
    checkoutScreen: 'add mobile number',
    when: 'on page load',
  });

  const { number, loading } = useCheckoutMobileContext();
  const { checkoutMode } = useCheckoutContext();

  useEffect(() => {
    if (checkoutMode !== CheckoutMode.NEW_CUSTOMER && number) {
      setConfirm(true);
    } else {
      setConfirm(false);
    }
  }, [checkoutMode, number]);

  if (loading) {
    return <Modal visible text="Loading..." />;
  }
  return (
    <StyledStepMobileContent data-testid={dataTestId}>
      {confirm ? <MobileConfirm /> : <MobileNew />}
    </StyledStepMobileContent>
  );
};
