import { GrowthBook } from '@growthbook/growthbook-react';

export const initialiseGrowthBook = () => {
  const GROWTH_BOOK_API_HOST = 'https://cdn.growthbook.io';

  const growthBook = new GrowthBook({
    enableDevMode: !!process.env.REACT_APP_GROWTH_BOOK_ENABLE_DEV_MODE,
    apiHost: GROWTH_BOOK_API_HOST,
    clientKey: process.env.REACT_APP_GROWTH_BOOK_CLIENT_KEY,
    trackingCallback: (experiment, result) => {
      if (!window?.analytics?.user) {
        return;
      }

      window.analytics.track('Experiment Viewed', {
        experimentId: experiment.key,
        variationId: result.key,
      });
    },
  });

  growthBook.setAttributes({
    domain: window?.location?.hostname,
  });

  growthBook.init({ streaming: true });

  return growthBook;
};
