/*
  NOTE: these values could change - product/Cat still fleshing them out
*/

export const fields = [
  {
    field: 'businessType',
    name: 'Business Type',
    type: 'radio',
    required: true,
    options: [
      {
        id: 'LIMITED_COMPANY',
        label: 'Limited Company',
      },
      {
        id: 'SOLE_TRADER',
        label: 'Sole Trader',
      },
      {
        id: 'PARTNERSHIP',
        label: 'Partnership',
      },
      {
        id: 'OTHER',
        label: 'Other',
      },
    ],
  },
];

export const hiddenFields = {
  LIMITED_COMPANY: [
    {
      field: 'registeredCompanyName',
      name: 'Registered Company Name',
      type: 'text',
      required: true,
    },
    {
      field: 'numberOfEmployees',
      name: 'Number of Employees',
      type: 'dropdown',
      // these will change - product to supply
      options: [
        {
          id: '1-10',
          label: '1-10',
        },
        {
          id: '11-50',
          label: '11-50',
        },
      ],
      required: true,
    },
    {
      field: 'roleWithinBusiness',
      name: 'Your role within the business',
      type: 'dropdown',
      // these will change - product to supply
      options: [
        {
          id: 'DIRECTOR',
          label: 'Director',
        },
        {
          id: 'OTHER',
          label: 'Other',
        },
      ],
      required: true,
    },
  ],
  SOLE_TRADER: [
    {
      field: 'registeredCompanyName',
      name: 'Company Name',
      type: 'text',
      required: true,
    },
    {
      field: 'numberOfEmployees',
      name: 'Number of Employees',
      type: 'dropdown',
      // these will change - product to supply
      options: [
        {
          id: '1',
          label: '1',
        },
      ],
      required: true,
    },
  ],
  PARTNERSHIP: [
    {
      field: 'registeredCompanyName',
      name: 'Company Name',
      type: 'text',
      required: true,
    },
    {
      field: 'numberOfPartners',
      name: 'Number of Partners',
      type: 'dropdown',
      // these will change - product to supply
      options: [
        {
          id: '1',
          label: '1',
        },
        {
          id: '2',
          label: '2',
        },
        {
          id: '3',
          label: '3',
        },
        {
          id: '4+',
          label: '4+',
        },
      ],
      required: true,
    },
  ],
  OTHER: [],
};
