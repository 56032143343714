import * as React from 'react';

function PurchaseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" {...props}>
      <g stroke="currentColor" strokeWidth="1.5" clipPath="url(#a)">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 25.584a2 2 0 0 1-2.828 0L2 18.414a2 2 0 0 1 0-2.83l14-14A2 2 0 0 1 17.415 1h7.17a2 2 0 0 1 2 2v7.172A2 2 0 0 1 26 11.584" />
        <path d="M21.586 6.499a.5.5 0 0 1 0-1M21.586 6.499a.5.5 0 1 0 0-1" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M22.586 30.999a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M24.571 20.38a1.714 1.714 0 1 0-3.428 0v5.144A1.143 1.143 0 0 1 20 26.666h4.571M20 23.238h3.429" />
      </g>
    </svg>
  );
}

const MemoPurchaseIcon = React.memo(PurchaseIcon);
export default MemoPurchaseIcon;
