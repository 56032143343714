import React, { useEffect } from 'react';

// GRAPHQL
import { QL_SUMMARY } from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';
import { Button } from 'components/common/Button';
import { Modal } from 'components/common/Modal';


import useRayloQuery from 'utils/useRayloQuery';
import { useHistory } from 'react-router';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useErrorNavigator}  from 'utils/useErrorNavigator'
import { useUiContext } from 'utils/useUiContext';
import {useSegmentCheckoutTracking} from 'integrations/segment/hooks'
import {useMerchantContext} from "utils/useMerchantContext"

export const DecisionCounterOffer = () => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'counter offer',
    checkoutScreen: 'counter offer intro',
    when: 'on page load'
  });

  const history = useHistory()
  const errorNav = useErrorNavigator();
  const { merchantId } = useMerchantContext();

  const { checkoutToken } = useCheckoutContext()

  const { setUiCostSummary } = useUiContext()

  const { data: {checkout}, error, loading } = useRayloQuery(QL_SUMMARY, {
    variables: {
      token: checkoutToken,
      merchantId
    },
    skip: !merchantId
  })

  useEffect(() => {
    if(checkout?.items[0]) {
      setUiCostSummary(checkout?.items[0].costSummary)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout])

  if(error) {
    errorNav("Decision: failure", error)
  }

  const product = checkout?.items[0]?.variant?.product

  if(loading || !product) {
    return <Modal visible text="Loading..." />
  }

  return (
    <div className="checkout--index">
      <div className="checkout--main">
        <div className="checkout--step">
          <FadeIn>
            <T.RayloBodyHeading margin="28px"><strong>It's mixed news</strong>, you can still get
              an {product.displayName}, but based on the credit check the price will be a little
              higher.</T.RayloBodyHeading>
          </FadeIn>

          <FadeIn>
            <div style={{marginBottom: 28, paddingBottom: 28, borderBottom: '1px solid #434343'}}>
              <T.RayloBodyType>We want as many people as possible to enjoy Raylo, so we would rather give you the
                option of paying more than just saying no.
              </T.RayloBodyType>
            </div>
          </FadeIn>


          <SlideDownFadeIn>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 40}}>
                <Button buttonStyle="secondary"
                        onClick={() => history.push(`/checkout/decision/later`)}>No
                  Thanks</Button>
              </div>
              <div>
                <Button buttonStyle="primaryBlue"
                        onClick={() => history.push(`/checkout/decision/offer-details`)}>Show
                  Details</Button>
              </div>
            </div>
          </SlideDownFadeIn>
        </div>
      </div>

      <FadeIn>
        <BlocksAnimation/>
      </FadeIn>
    </div>
  )
}
