import { primaryColors } from 'styles/variables/colors';
import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

export const StyledBannerFooter = styled.div`
    background-color: ${primaryColors.w01};
    padding: 24px;
`;

export const StyledLinkButton = styled.button`
    display: inline;
    background: none;
    border: none;
    cursor: pointer;
    color: ${primaryColors.b01};
    font-weight: 700;
    font-size: ${px2Rem(14)};
    text-decoration: underline;
`;
