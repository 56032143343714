import * as React from 'react';

function ContinueIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path d="M11 23H3a2 2 0 01-2-2V5a2 2 0 012-2h18a2 2 0 012 2v6M1 9h22M7 5V1m10 4V1m3 24h-5v5" />
        <path d="M30.222 25.979A7.43 7.43 0 0115.902 25M26 21h5v-5M15.777 20.023a7.43 7.43 0 0114.32.977" />
      </g>
    </svg>
  );
}

const MemoContinueIcon = React.memo(ContinueIcon);
export default MemoContinueIcon;
