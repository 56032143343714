import { StyledCopy } from './styles';
import { ICopy } from './types';

/**
 * @deprecated This component has been replaced with the `<Typography />` component in `@raylo-tech/raylopay-ui`.
 */
const Copy = ({
  children,
  color,
  fontSize,
  dataTestId,
  bold,
  italic,
  lineHeight,
  textAlign,
  styles,
}: ICopy) => {
  return (
    <StyledCopy
      color={color}
      fontSize={fontSize}
      data-testid={dataTestId}
      bold={bold}
      italic={italic}
      lineHeight={lineHeight}
      textAlign={textAlign}
      styles={styles}
    >
      {children}
    </StyledCopy>
  );
};

export default Copy;
