import Container from 'elements/Container';
import { Fragment } from 'react';
import Spacer from 'elements/Spacer';
import { StyledNumberedList, StyledOutlinedNumber } from './styles';
import { INumberedList } from './types';

export const NumberedList = ({ dataTestId, listItems }: INumberedList) => {
  return (
    <StyledNumberedList data-testid={dataTestId}>
      {listItems?.map((item, i) => (
        <Fragment key={i}>
          <Container key={`${dataTestId}-${i + 1}`} noPadding flexRow>
            <StyledOutlinedNumber>{i + 1}</StyledOutlinedNumber>
            <Container padding="0 0 0 16px">
              {item}
            </Container>
          </Container>
          <Spacer height={24} />
        </Fragment>
      ))}
    </StyledNumberedList>
  );
};
