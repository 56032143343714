import amazonCardTop from 'public/amazon-card.svg';
import amazonCardBottom from 'public/amazon-card--bottom.svg';
import ITestDataId from 'types/ITestDataId.type';
import {
  StyledAmazonCard,
  StyledAmazonCardAnimation,
  StyledAmazonCardBottom
} from './styles';

export const AmazonCardAnimation = ({ dataTestId }: ITestDataId) => {
  return (
    <StyledAmazonCardAnimation data-testid={dataTestId}>
      <StyledAmazonCardBottom>
        <img src={amazonCardBottom} alt="amazon card" />
      </StyledAmazonCardBottom>
      <StyledAmazonCard>
        <img src={amazonCardTop} alt="amazon card" />
      </StyledAmazonCard>
    </StyledAmazonCardAnimation>
  );
};
