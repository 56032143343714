import gql from 'graphql-tag';

export const QL_SEGMENT_EVENT = gql`
  query SegmentEventQuery($token: String!) {
    checkout(token: $token) {
      id
      items {
        variant {
          id
          displayName
          condition
          optionValues {
            displayName
            optionType {
              slug
            }
          }
          product {
            category {
              displayName
            }
            manufacturer {
              displayName
            }
          }
        }
        costSummary {
          includesInsurance
          termLength
          recurringPeriod
          recurring {
            totalAmount {
              valueAfterTax {
                value
              }
            }
          }
        }
      }
      merchant {
        id
        displayName
      }
    }
  }
`;
