// Mike Bostock's & Ulf Aslak's adaptation of the Fisher-Yates shuffle algorithm
// https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed/53758827#53758827
export default function shuffle(array: unknown[], seed: number) {
  let m = array.length, t, i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
}

function random(seed: number) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}
