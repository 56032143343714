import StepSignContent from 'screens/Checkout/components/StepSignContent';
import { StepSignProvider } from './hooks/useStepSignContext';
import { useEffect } from 'react';
import { useUiContext } from 'utils/useUiContext';

export const StepSignContainer = () => {
  const { setHideProgressBar } = useUiContext();

  useEffect(() => {
    setHideProgressBar(true);

    return () => {
      setHideProgressBar(false);
    };
  }, []);

  return (
    <StepSignProvider>
      <StepSignContent />
    </StepSignProvider>
  );
};
