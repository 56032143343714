import { useEffect, useState } from 'react';
import constate from 'constate';
import store from 'store';
import { useCheckoutContext } from './useCheckoutContext';
import { useTrackCheckout } from './trackCheckout';

const useTrackCheckoutExperiment = () => {
  const [experimentName, setExperimentName] = useState();
  const [experimentVariable, setExperimentVariable] = useState();
  const [experimentData, setExperimentData] = useState<any>();
  const [experimentTrackingSubmitted, setExperimentTrackingSubmitted] =
    useState<boolean>(false);
  const { trackCheckout } = useTrackCheckout();
  const { checkoutToken } = useCheckoutContext();

  useEffect(() => {
    setExperimentName(store.get('experiment_name'));
    setExperimentVariable(store.get('experiment_variable'));
  }, [checkoutToken, store]);

  useEffect(() => {
    if (experimentName && experimentVariable) {
      setExperimentData({
        campaignReference: experimentName,
        contentReference: experimentVariable,
        sourceReference: document.referrer,
        extractedAt: new Date().toJSON(),
        provider: 'raylo',
        category: 'EXPERIMENTS',
      });
    }
  }, [experimentName, experimentVariable, setExperimentData]);

  useEffect(() => {
    if (experimentData && checkoutToken) {
      trackCheckout(checkoutToken, experimentData, () => {
        setExperimentTrackingSubmitted(true);
      });
    }
  }, [experimentData, checkoutToken]);

  return {
    experimentData,
    experimentTrackingSubmitted,
    setExperimentData,
    setExperimentName,
    setExperimentVariable,
    trackCheckout,
  };
};

const [TrackCheckoutExperimentProvider, useTrackCheckoutExperimentContext] =
  constate(useTrackCheckoutExperiment);
export { TrackCheckoutExperimentProvider, useTrackCheckoutExperimentContext };
