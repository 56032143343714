import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useMedia from 'use-media';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { PaymentForm } from './PaymentForm';
import { stripeFormStyles } from '../../styles/screens/Payment/stripeFormStyles';
import { FadeIn } from '../../components/animations/Transitions';
import { LEGACY_BREAKPOINTS } from '../../styles/LEGACY_BREAKPOINTS';
import {
  CUSTOMER_INCOMING_PAYMENTS,
  ICustomerIncomingPaymentsQuery
} from '../../graphql/queries/customer/incomingPayment/incomingPayments.graphql';
import { Loader } from '../../components/common/Loader';
import { px2Rem } from '../../utils/px2Rem';
import { IPaymentFormWrapper } from './paymentFormWrapper.interface';
import { IncomingPaymentStatus } from '../../graphql/mutations/incomingPayment/incomingPaymentStatuses.enum';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

export const PaymentFormWrapper = ({ successUrl, amountValid }: IPaymentFormWrapper) => {
  const [clientSecret, setClientSecret] = useState('');
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });
  const { subscriptionId }: { subscriptionId: string } = useParams();

  const customerIncomingPaymentsQuery = useQuery(CUSTOMER_INCOMING_PAYMENTS, {
    pollInterval: 500,
    notifyOnNetworkStatusChange: true,
    onCompleted(data: ICustomerIncomingPaymentsQuery) {
      const subscription = data.customer?.subscriptions.find(
        s => s.id === subscriptionId
      );
      const partnerReference = subscription?.incomingPayments
        .find(
          payment => payment.state === IncomingPaymentStatus.Prepared
        )
        ?.partnerReferences.find(
          partner =>
            partner.referenceType === 'stripe_setup_intent_client_secret'
        );
      if (partnerReference) {
        customerIncomingPaymentsQuery.stopPolling();
        setClientSecret(partnerReference.reference);
      }
    },
  });

  const options = {
    clientSecret,
    appearance: stripeFormStyles(isMobile),
  };

  return clientSecret ? (
    <FadeIn>
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm successUrl={successUrl} amountValid={amountValid} />
      </Elements>
    </FadeIn>
  ) : (
    <FadeIn>
      <div style={{ margin: `${px2Rem(50)} auto`, width: 'fit-content' }}>
        <Loader type="quad" />
      </div>
    </FadeIn>
  );
};
