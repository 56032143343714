import React, { useEffect, useState } from 'react';
import * as A from 'styles/account';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@raylo-tech/raylopay-ui';
import { useParams } from 'react-router';
import { track } from 'integrations/segment/events';
import {
  START_SUBSCRIPTION_ARREARS_PAYMENT_MUTATION
} from 'graphql/mutations/subscription/startSubscriptionArrearsPaymentMutation.graphql';
import {
  CUSTOMER_SUBSCRIPTIONS,
  ICustomerSubscription
} from 'graphql/queries/customer/subscription/subscriptions.graphql';
import { checkInArrears } from 'helpers/subscriptions/checkInArrears';
import { PaymentFormWrapper } from '../../Payment/PaymentFormWrapper';
import { Card } from '../../../components/common/Card';
import Container from '../../../elements/Container';
import Spacer from '../../../elements/Spacer';
import Header from '../../../elements/Header';
import { primaryColors, secondaryColors, uiStateColors } from '../../../styles/variables/colors';
import { SecurePaymentInfo } from '../../Payment/SecurePaymentInfo';
import { BackArrowLink } from '../../../components/common/BackArrowLink/BackArrowLink';
import Copy from '../../../elements/Copy';
import { FadeIn } from '../../../components/animations/Transitions';
import { px2Rem } from '../../../utils/px2Rem';
import { LEGACY_BREAKPOINTS } from '../../../styles/LEGACY_BREAKPOINTS';
import { Loader } from '../../../components/common/Loader';
import { MoneyInput } from '../../../components/common/MoneyInput/MoneyInput';
import { formatMoney } from '../../../utils/formatMoney';

export const PaymentScreen = () => {
  const [fromPaymentFailedScreen, setFromPaymentFailedScreen] = useState(false);
  const [inputAmount, setInputAmount] = useState('');
  const [incomingPayment, setIncomingPayment] = useState<any>(undefined);
  const [subscription, setSubscription] = useState<ICustomerSubscription | undefined>(undefined);
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] =
    useState(false);
  const [editMode, setEditMode] = useState(false);

  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const MINIMUM_PAYMENT_AMOUNT = 0.30;

  const setDefaultInputAmount = (subscription?: ICustomerSubscription) => {
    if (!subscription) return;

    const inArrears = checkInArrears(subscription);
    const arrearsAmount = subscription?.arrearsAmount?.value.toString();
    const nextPaymentAmount = subscription?.nextPaymentScheduleEvent?.amount.value.toString();

    setInputAmount((inArrears ? arrearsAmount : nextPaymentAmount) || '');
  };

  useQuery(CUSTOMER_SUBSCRIPTIONS, {
    onCompleted(data: any) {
      const subscription = data.customer.subscriptions.find(
        (s: any) => s.id === subscriptionId
      );
      setSubscription(subscription);
      setDefaultInputAmount(subscription);
    },
  });

  const [startSubscriptionArrearsPayment] = useMutation(
    START_SUBSCRIPTION_ARREARS_PAYMENT_MUTATION,
    {
      variables: {
        subscriptionId,
      },
      onCompleted(data: any) {
        setIncomingPayment(
          data.startSubscriptionArrearsPayment.incomingPayment
        );
      },
    }
  );

  const balance = checkInArrears(subscription) ?
    `-${subscription?.arrearsAmount?.formattedValue}` :
    `${incomingPayment?.totalAmount.currencySymbol}0.00`;

  useEffect(() => {
    if (localStorage.getItem('previousScreen') === 'paymentFailed') {
      setFromPaymentFailedScreen(true);
      localStorage.removeItem('previousScreen');
    }
  }, []);

  useEffect(() => {
    startSubscriptionArrearsPayment();
  }, []);

  useEffect(() => {
    if (!trackedAccountViewedEvent) {
      track('My Account Viewed', {
        screen: 'payment input',
        when: 'on page load',
        url: window.location.href,
      });

      setTrackedAccountViewedEvent(true);
    }
  });

  const editInputErrorMessage = () => {
    if (parseFloat(inputAmount) < MINIMUM_PAYMENT_AMOUNT) {
      return `Minimum payment amount is ${formatMoney(MINIMUM_PAYMENT_AMOUNT, incomingPayment.totalAmount.currencySymbol)}`;
    }

    if (parseFloat(inputAmount) > parseFloat(incomingPayment.totalAmount.value)) {
      return `Payment amount cannot be more than ${incomingPayment.totalAmount.formattedValue}`;
    }

    const remainingAmount = parseFloat(incomingPayment.totalAmount.value) - parseFloat(inputAmount);
    if (remainingAmount !== 0 && remainingAmount <= MINIMUM_PAYMENT_AMOUNT) {
      return `You cannot make a payment that leaves a remaining balance of less than ${formatMoney(MINIMUM_PAYMENT_AMOUNT, incomingPayment.totalAmount.currencySymbol)}`;
    }
  };

  const editPaymentOnBlur = () => {
    if (editInputErrorMessage()) {
      setDefaultInputAmount(subscription);
    }

    setEditMode(false);
  };

  const updateValue = (value: string) => {
    setInputAmount(value || '0');
  };

  const titleText = () => {
    const baseText = 'Make a one-off payment';
    return checkInArrears(subscription) ? baseText : `${baseText} today`;
  };

  const DirectDebitDisclaimer = () => {
    const notOverPayable = !subscription?.nextPaymentScheduleEvent?.payable;
    const inArrears = checkInArrears(subscription);

    const text = notOverPayable && inArrears ?
      'If your direct debit has failed, your balance owing may include fees incurred.' :
      `If your direct debit has failed, please pay ${incomingPayment.totalAmount.formattedValue} today to avoid late
          fees. Failed direct debits can take one working day to show, therefore may not be reflected in your balance.`;

    return (
      <>
        <Spacer height={24} />
        <Copy
          color={secondaryColors.c02}
          fontSize={14}
          dataTestId="directDebitDisclaimer"
        >
          {text}
        </Copy>
      </>
    );
  };

  if (!incomingPayment) {
    return (
      <A.AccountContainer>
        <FadeIn>
          <Container center height={px2Rem(300)} width="auto">
            <Loader type="quad" />
          </Container>
        </FadeIn>
      </A.AccountContainer>
    );
  }

  return (
    <A.AccountContainer>
      <FadeIn>
        <>
          <Container
            width="1200px"
            styles={{
              maxWidth: '100%',
              margin: 'auto',
              padding: `${px2Rem(16)} ${px2Rem(16)} ${px2Rem(4)} ${px2Rem(16)}`,
            }}
          >
            <BackArrowLink
              text={fromPaymentFailedScreen ? 'Account Overview' : 'Back'}
              styling="margin-left: 2.5%;"
              goBack={!fromPaymentFailedScreen}
              linkLocation={fromPaymentFailedScreen ? '/account' : undefined}
            />
            <Spacer height={8} />
            <Header
              level={1}
              color={primaryColors.c01}
              fontSize={24}
              centered
            >
              {titleText()}
            </Header>
            <Spacer height={16} />
            <Copy textAlign="center" color={secondaryColors.c02}>
              Your balance is:&nbsp;
              <span style={{ color: checkInArrears(subscription) ? uiStateColors.error : uiStateColors.success }}>
                {balance}
              </span>
            </Copy>
          </Container>
          <Card
            width={1200}
            styles={`
            min-height: ${px2Rem(800)}
            
            @media (max-width: ${LEGACY_BREAKPOINTS.mobile}px) {
              padding: ${px2Rem(20)} ${px2Rem(20)} ${px2Rem(20)} ${px2Rem(20)};
              max-width: 100%;
              box-shadow: none;
            }
          `}
          >
            <Container
              width="474px"
              styles={{
                maxWidth: '100%',
                margin: 'auto',
                padding: 0,
              }}
            >
              <div style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${editMode ? 1 : 2}, 1fr)`,
              }}
              >
                <div>
                  <Copy
                    color={primaryColors.c01}
                    fontSize={16}
                    bold
                  >
                    Payment amount
                  </Copy>
                  <Spacer height={8} />
                  {!editMode && (
                    <Copy
                      dataTestId="paymentAmountCopy"
                      color={primaryColors.c01}
                      fontSize={16}
                    >
                      {formatMoney(parseFloat(inputAmount), incomingPayment.totalAmount.currencySymbol)}
                    </Copy>
                  )}
                </div>
                {!editMode && (
                  <div style={{ width: px2Rem(120), marginRight: 0, marginLeft: 'auto' }}>
                    <Button
                      dataTestId="editPaymentButton"
                      secondary
                      medium
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      EDIT
                    </Button>
                  </div>
                )}

                {editMode && (
                  <MoneyInput
                    name="amountInput"
                    dataTestId="amountInput"
                    prefix={incomingPayment.totalAmount.currencySymbol}
                    onAccept={updateValue}
                    initialValue={inputAmount}
                    errorMessage={editInputErrorMessage()}
                    onBlur={editPaymentOnBlur}
                    focusOnLoad
                  />
                )}
              </div>
              <DirectDebitDisclaimer />
              <SecurePaymentInfo />
              <Spacer height={24} />
              <PaymentFormWrapper
                successUrl={`/account/payment/${incomingPayment.id}/processing/${inputAmount}/${encodeURIComponent(incomingPayment.totalAmount.currencySymbol)}`}
                amountValid={!editInputErrorMessage()}
              />
            </Container>
          </Card>
          <Spacer height={48} />
        </>
      </FadeIn>
    </A.AccountContainer>
  );
};
