import React from 'react';
import TextAccordion from '../../components/TextAccordion';
import { faqsData, faqsFooterContent } from './FAQs.util';
import { palette } from '../../colors';
import { IFAQs } from './FAQs.types';

export const FAQs = ({ showFooter, headerPadding, primaryColour }: IFAQs) => (
  <TextAccordion
    dataTestId="FAQs"
    footerContent={showFooter ? faqsFooterContent : undefined}
    title="Frequently asked questions"
    primaryColour={primaryColour ?? palette.blue[500]}
    data={faqsData}
    headerPadding={headerPadding}
    iconPaddingLeft={0}
  />
);
