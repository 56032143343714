import {
  Container,
  Header,
  Spacer,
  SectionMessage
} from '@raylo-tech/raylopay-ui';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { PIICopy } from 'elements/PIICopy/PIICopy';
import { IAddressLine } from './DecisionAccepted.types';
import { px2Rem } from 'utils/px2Rem';

import { useCheckoutDecisionContext } from '../../hooks/useCheckoutDecisionContext';

export const DeliveryConfirmation = () => {
  const { deliveryAddress } = useCheckoutDecisionContext();

  const AddressLine = ({ line }: IAddressLine) => (
    <PIICopy fontSize={16} lineHeight={24} color={primaryColors.c01}>
      {line}
    </PIICopy>
  );

  return (
    <Container dataTestId="deliveryConfirmation" maxWidth={px2Rem(414)} padding={`0 ${px2Rem(24)} ${px2Rem(24)} ${px2Rem(24)}`}>
      <Header level={3} fontSize={16} color={primaryColors.c01}>
        Delivery Address
      </Header>
      <Spacer height={16} />
      <AddressLine line={deliveryAddress?.line1} />
      <AddressLine line={deliveryAddress?.line2} />
      <AddressLine line={deliveryAddress?.line3} />
      <AddressLine line={deliveryAddress?.postcode} />
      <AddressLine line={deliveryAddress?.city} />
      <Spacer height={16} />
      <SectionMessage
        backgroundColor={secondaryColors.b05}
        borderColor={secondaryColors.b04}
        copy="Raylo is proud to be B Corporation Certified"
        header="You've made a greener choice"
        icon="RayloDesignCertified"
        iconLeftAlign
      />
    </Container>
  );
};
