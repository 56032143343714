import { useState } from 'react';
import { RayloModal } from '@raylo-tech/raylopay-ui';
import Copy from 'elements/Copy';
import TextLink from 'elements/TextLink';
import Spacer from 'elements/Spacer';
import { primaryColors } from 'styles/variables/colors';

import { ICancelMerchantLease } from './types';

export const CancelMerchantLease = ({
  merchantName,
  merchantReference,
}: ICancelMerchantLease) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <TextLink onClick={openModal}>Cancel lease</TextLink>

      <RayloModal
        dataTestId="cancel-order-modal"
        modalOpen={modalOpen}
        title={`Please contact ${merchantName} to cancel your lease`}
        handleCloseModal={closeModal}
      >
        <>
          <Copy fontSize={16} lineHeight={20} color={primaryColors.c01}>
            You placed this order with {merchantName}. You will need to contact them to cancel your order,
            as you are still within your cooling off period.
          </Copy>
          <Spacer height={24} />
          <Copy fontSize={16} lineHeight={20} color={primaryColors.c01}>
            Your order reference is
          </Copy>
          <Copy fontSize={16} lineHeight={20} color={primaryColors.c01}>
            {merchantReference}
          </Copy>
        </>
      </RayloModal>
    </>
  );
};
