import { Copy } from '@raylo-tech/raylopay-ui';
import { IPIICopy } from './types';

export const PIICopy = ({
  dataTestId,
  children,
  color,
  fontSize,
  bold,
  medium,
  italic,
  lineHeight,
  textAlign,
  styles,
  verticalAlign,
  letterSpacing,
  fontFamily,
  hoverBold,
}: IPIICopy) => {
  return (
    <Copy
      className="sentry-mask"
      color={color}
      fontSize={fontSize}
      data-testid={dataTestId}
      bold={bold}
      medium={medium}
      italic={italic}
      lineHeight={lineHeight}
      textAlign={textAlign}
      verticalAlign={verticalAlign}
      styles={styles}
      letterSpacing={letterSpacing}
      fontFamily={fontFamily}
      hoverBold={hoverBold}
    >
      {children}
    </Copy>
  );
};
