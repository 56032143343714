import gql from 'graphql-tag';

export const CHECKOUT_PAYMENT_INFO = gql`
  query CheckoutPaymentInfoQuery($token: String!) {
    checkout(token: $token) {
      id
      paymentInfo {
        securityDepositAmount {
          formattedValue
        }
      }
    }
  }
`;
