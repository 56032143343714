import React, { useState } from 'react';
import * as I from 'styles/inputs';
import { Tooltip } from 'components/common/Tooltip';
import { IMaskMixin } from 'react-imask';

const MaskedInput = IMaskMixin(({ inputRef, ...props }) => (
  <I.RayloTextInput
    {...props}
    ref={inputRef}
  />
));

export const TextInput = (
  {
    label,
    tooltip,
    placeholder,
    errorMessage,
    field,
    inputmode = null,
    type = 'text',
    pattern = null,
    autoComplete,
    mask,
    className,
    noError = false,
  }) => {
  const [isFocused, setIsFocused] = useState(false);
  const toggle = () => setIsFocused(!isFocused);
  const { error } = field;
  return (
    <I.RayloFormRow>
      <I.RayloFieldHeader>
        {label && (
          <I.RayloFieldLabel isFocused={isFocused} htmlFor={field.id}>
            {label}
          </I.RayloFieldLabel>
        )}

        {tooltip && process.env.NODE_ENV !== 'test' &&
          <Tooltip direction="left">{tooltip}</Tooltip>}
      </I.RayloFieldHeader>
      <MaskedInput
        {...mask}
        {...field}
        type={type}
        autoComplete={autoComplete}
        inputMode={inputmode}
        pattern={pattern}
        data-testid={field.id}
        placeholder={placeholder}
        onFocus={toggle}
        onBlur={toggle}
        className={className}
      />
      {noError ? null : (
        <I.RayloError visible={error}>
          {errorMessage || <span>&nbsp;</span>}
        </I.RayloError>
      )}
    </I.RayloFormRow>
  );
};
