import gql from 'graphql-tag';

export const PROCESS_INCOMING_PAYMENT_MUTATION = gql`
  mutation ($incomingPaymentId: ID!, $checkoutToken: String, $amount: Float) {
    processIncomingPayment(
      incomingPaymentId: $incomingPaymentId
      checkoutToken: $checkoutToken
      amount: $amount
    ) {
      incomingPayment {
        id
        state
      }
    }
  }
`;
