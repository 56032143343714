import React from 'react';
import Button from '../../designSystem/components/Button';
import { palette } from '../../colors';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Divider from '../../elements/Divider';
import Header from '../../elements/Header';
import Icon from '../../elements/Icon';
import Spacer from '../../elements/Spacer';
import { formatDisplaySortCode } from '../../utils/formatDisplaySortCode';
import { px2Rem } from '../../utils/px2Rem';
import { StyledAccountScore } from './AccountScore.styles';
import { IAccountScore } from './AccountScore.types';
import SectionMessageWithIcon from '../../designSystem/components/SectionMessageWithIcon';
import { icons } from '../../elements/Icon/Icons';
import { CSS_FONT_FAMILY } from '../../constants';

export const AccountScoreComponent = ({
  dataTestId,
  formAction,
  formFields,
  sortCode,
  accountNumber,
  heading,
  copy,
  connectionCopy,
  onSubmitForm,
}: IAccountScore) => {
  const {
    client,
    externalRef,
    firstName,
    lastName,
    email,
    telephone,
    postcode,
    completeUrl,
    cancelUrl,
    startBank,
  } = formFields || {};

  const sectionMessageProps = {
    copy: 'Both Raylo and consents.online are authorised by the Financial Conduct Authority (FCA), so you can trust that your data will be held securely.',
    icon: 'ShieldLockRayloBlue' as keyof typeof icons,
  };

  const MainHeader = () => (
    <Header
      level={2}
      fontSize={24}
      dataTestId="AccountScoreNeedMoreInformationHeader"
    >
      {heading}
    </Header>
  );

  const IntroCopy = () => (
    <Copy fontSize={16} color={palette.charcoal[500]}>
      {copy}
    </Copy>
  );

  const AccountDetails = () => (
    <Container>
      <Icon name="ConsentsLogo" />
      <Spacer height={12} />
      <Copy bold>{connectionCopy}</Copy>
      <Spacer height={24} />
      <Copy bold letterSpacing={2}>
        Sort code
      </Copy>
      <Copy fontFamily={CSS_FONT_FAMILY.vars.mono}>
        {formatDisplaySortCode(sortCode)}
      </Copy>
      <Spacer height={16} />
      <Copy bold letterSpacing={2.5}>
        Account number
      </Copy>
      <Copy fontFamily={CSS_FONT_FAMILY.vars.mono}>{accountNumber}</Copy>
    </Container>
  );

  const AccountScoreForm = () => (
    <form
      id="accountScoreForm"
      action={formAction}
      method="POST"
      data-testid="AccountScoreSubmissionForm"
      onSubmit={onSubmitForm}
    >
      <input
        name="client"
        value={client}
        type="hidden"
        data-testid="client-field"
      />
      <input
        name="externalRef"
        value={externalRef}
        type="hidden"
        data-testid="externalRef-field"
      />
      <input
        name="firstname"
        value={firstName}
        type="hidden"
        data-testid="firstName-field"
      />
      <input
        name="lastName"
        value={lastName}
        type="hidden"
        data-testid="lastName-field"
      />
      <input
        name="email"
        value={email}
        type="hidden"
        data-testid="email-field"
      />
      <input
        name="telephone"
        value={telephone}
        type="hidden"
        data-testid="telephone-field"
      />
      <input
        name="postcode"
        value={postcode}
        type="hidden"
        data-testid="postcode-field"
      />
      <input
        name="completeUrl"
        value={completeUrl}
        type="hidden"
        data-testid="completeUrl-field"
      />
      <input
        name="cancelUrl"
        value={cancelUrl}
        type="hidden"
        data-testid="cancelUrl-field"
      />
      <input
        name="startBank"
        value={startBank}
        type="hidden"
        data-testid="startBank-field"
      />
      <Button type="submit" dataTestId="accountScoreSubmit">
        Connect to consents.online
      </Button>
    </form>
  );

  return (
    <StyledAccountScore data-testid={dataTestId}>
      <Container maxWidth={px2Rem(450)}>
        <MainHeader />
        <Spacer height={24} />
        <IntroCopy />
        <Spacer height={24} />
        <Divider color={palette.charcoal[200]} width="100%" />
        <Spacer height={24} />
        <AccountDetails />
        <Spacer height={24} />
        <Divider color={palette.charcoal[200]} width="100%" />
        <Spacer height={24} />
        <AccountScoreForm />
        <Spacer height={40} />
        <SectionMessageWithIcon {...sectionMessageProps} />
      </Container>
    </StyledAccountScore>
  );
};
