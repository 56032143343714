const colors = {
  rayloBlue: '#404EFF',
  rayloBlue2Light: '#EFF1FE',
  rayloBlue2Dark: '#989CFF',
  rayloBlue3Light: '#BEBFFF',
  rayloBlue3Dark: '#7277FF',

  rayloPink: '#FFCEC7',
  rayloPink2Light: '#FFFDFE',
  rayloPink2Dark: '#FFBFB5',
  rayloPink3Light: '#FFEAE5',
  rayloPink3Dark: '#ED7D7B',
  rayloPink4Dark: '#FF7577',

  rayloLight: '#FFF',
  raylo2Light: '#F4F4F4',
  raylo3Light: '#A0A0A0',
  raylo4Light: '#F7F7F7',

  rayloDark: '#2D2D2D',
  raylo2Dark: '#ABABAB',
  raylo3Dark: '#6C6C6C',

  rayloBGDark: '#060606',
  black: '#000',
  white: '#FFF'
}

export default colors;
