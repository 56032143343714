import styled from 'styled-components';
import { Button, Spacer } from '@raylo-tech/raylopay-ui';

import { OverduePayment } from 'components/Account/OverduePayment';
import { NextPayment } from 'components/Account/NextPayment';
import { RecentPayments } from './RecentPayments/RecentPayments';

import { px2Rem } from 'utils/px2Rem';
import { checkInArrears } from 'helpers/subscriptions/checkInArrears';
import { useParams } from 'react-router';
import {primaryColors} from 'styles/variables/colors';
import {LEGACY_BREAKPOINTS} from 'styles/LEGACY_BREAKPOINTS';

export const PaymentSummary = ({ subscription }) => {
  const { orderId } = useParams()

  if (subscription) {
    return (
      <PaymentSummaryOuter>
        <PaymentSummaryInner>
          <OverduePayment subscription={subscription} />
          <NextPayment nextPaymentScheduleEvent={subscription.nextPaymentScheduleEvent} />
          <RecentPayments transactions={subscription.transactions} />
          {subscription.nextPaymentScheduleEvent?.payable && !checkInArrears(subscription) && (
            <div style={{ margin: 'auto', width: px2Rem(250) }}>
              <Button
                dataTestId='make-payment-button'
                onClick={() => window.location.assign(`/account/payment/${subscription.id}`)}
                small>
                Make a Payment
              </Button>
              <Spacer height={24} />
            </div>
          )}

          {subscription.transactions.length > 0 && (
            <div style={{ margin: 'auto', width: px2Rem(250) }}>
              <Button
                dataTestId='view-statement-button'
                onClick={() => window.location.assign(`/account/payments/${orderId}`)}
                small
                secondary
              >View Full Statement</Button>
            </div>
          )}
        </PaymentSummaryInner>
      </PaymentSummaryOuter>
    );
  } else {
    return null;
  }
};

const PaymentSummaryOuter = styled.section`
    background-color: ${primaryColors.w01};
    padding: 75px 0;

    @media (max-width: 768px) {
        padding: 40px 0;
    }
`;

const PaymentSummaryInner = styled.div`
    max-width: ${px2Rem(LEGACY_BREAKPOINTS.smallDesktop)};
    margin: 0 auto;
    padding: 0 ${px2Rem(40)};

    @media (max-width: 540px) {
        padding: 0 20px;
    }
`;
