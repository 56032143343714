import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { primaryColors } from 'styles/variables/colors';

const StyledRecommendations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: ${primaryColors.c01};
  padding: ${px2Rem(32)} ${px2Rem(24)} ${px2Rem(24)} ${px2Rem(24)};
`;

export { StyledRecommendations };
