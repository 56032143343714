import { Typography } from '@raylo-tech/raylopay-ui';
import FormCollection from 'components/FormCollection';
import FormErrorMessages from 'components/FormErrorMessages';
import { IFormErrorMessages } from 'components/FormErrorMessages/types';
import { personalDetailsFields } from './constants';
import { Button } from 'elements';
import { ButtonTypes } from 'elements/Button/types';
import { useMutation } from '@apollo/client';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { useState } from 'react';
import type { FormValues } from '../CheckoutStepsContainer/types';
import { px2Rem } from 'utils/px2Rem';

const StepPersonalDetails = ({
  onSuccess,
  presetValues,
  setPresetValues,
  checkoutToken,
}: {
  onSuccess: () => void;
  presetValues?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPresetValues: (vals?: any) => void;
  checkoutToken?: string;
}) => {
  const [formErrors, setFormErrors] = useState<IFormErrorMessages['formErrors']>([]);

  const [updateMutation] = useMutation(CheckoutSteps.StepPersonalDetails.mutation, {
    update: (
      proxy,
      {
        data,
      }
    ) => {
      const { errors } = data.updateCheckout;
      if (errors && errors.length > 0) {
        setFormErrors(errors);
      } else {
        setFormErrors(null);
        onSuccess();
      }
    },
  });

  const onSubmit = (vals: FormValues) => {
    if (!vals?.dateOfBirth) return;

    setPresetValues((oldState: FormValues) => ({
      ...oldState,
      ...vals,
    }));

    let formattedDateOfBirth = vals.dateOfBirth;
    if (typeof vals.dateOfBirth !== 'string') {
      const { DD, MM, YYYY } = vals.dateOfBirth;
      formattedDateOfBirth = `${YYYY}-${MM}-${DD}`;
    }

    updateMutation({
      variables: {
        firstName: vals.firstName,
        middleName: vals?.middleName,
        lastName: vals.lastName,
        mobilePhoneNumber: vals.mobilePhoneNumber,
        dateOfBirth: formattedDateOfBirth,
        checkoutToken,
      },
    });
  };

  return (
    <>
      <Typography
        bold
        variant="h2"
        element="h2"
        style={{ paddingBottom: px2Rem(24) }}
      >
        Your details
      </Typography>
      <FormCollection
        presetValues={presetValues}
        dataTestId="step-personal-details"
        formId="step-personal-details"
        fields={personalDetailsFields}
        handleSubmit={onSubmit}
      />
      <FormErrorMessages formErrors={formErrors} />
      <Button
        type="submit"
        size="large"
        text="Next"
        styles={`margin-top: ${px2Rem(24)}`}
        fullWidth
        buttonColor={ButtonTypes.b01}
        formId="step-personal-details"
      />
    </>
  );
};

export default StepPersonalDetails;
