import { primaryColors } from 'styles/variables/colors';
import { rayloDark } from '../rayloDark';

export const components = {
  ...rayloDark.components,
  trustPilot: {
    ...rayloDark.components.trustPilot,
    iconColor: '#00b67A',
    titleColor: primaryColors.c01,
  },
};
