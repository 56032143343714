import { useState, useEffect } from 'react';
import { SlideDownFadeIn } from 'components/animations/Transitions';
import BackArrow from 'components/BackArrow';
import RadioButtonBoxGroup from 'components/RadioButtonBoxGroup';
import { ButtonTypes } from 'elements/Button/types';
import Button from 'elements/Button';
import Header from 'elements/Header';
import { track } from 'integrations/segment/events';
import SelectComponent from 'elements/SelectComponent';
import { Container, FormCheckbox, Copy, SectionMessage } from '@raylo-tech/raylopay-ui';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import Spacer from 'elements/Spacer';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import { primaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { StyledTradeInJourneyStepOne } from './styles';
import { findIndex } from 'lodash-es';
import { IValue } from '@raylo-tech/raylopay-ui/build/cjs/components/SelectDropdown/SelectDropdown.types';
import { IManufacturerOption } from 'screens/Checkout/containers/StepTradeIn/types';
import { VariantOptionSlugs } from 'types/variants/options/variantOptionSlugs';

export const TradeInJourneyStepOne = ({ dataTestId }: ITestDataId) => {
  const {
    manufacturerId,
    manufacturerOptions,
    modelOptions,
    modelId,
    sizeOptionsWithTotal,
    variantId,
    variants,
    setStepIndex,
    setTradeInAccepted,
    handleManufacturerOnChange,
    handleProductSelect,
    handleVariantSelect,
    rayloDeviceCheckComplete,
    setRayloDeviceCheckComplete,
    setFormSubmitted,
  } = useStepTradeInContext();

  const { checkoutMode, checkoutCategoryDisplayName } = useCheckoutContext();
  const [showErrors, setShowErrors] = useState(false);

  const isAddTechFlow = checkoutMode === CheckoutMode.ADDITIONAL_TECH;
  const hasCapacityOptions = !!(variants as Array<{ optionValues: Array<{ optionType: { slug: string } }> }>)?.some(v => v.optionValues.some(ov => ov.optionType.slug === VariantOptionSlugs.Storage));
  const buttonEnabled = !!variantId && (rayloDeviceCheckComplete || !isAddTechFlow);

  const [manufacturerOptionsForDisplay, setManufacturerOptionsForDisplay] = useState<Array<IManufacturerOption> | undefined>([]);
  const [modelOptionsForDisplay, setModelOptionsForDisplay] = useState<Array<IValue | null> | undefined>([]);
  const [otherOptionSelected, setOtherOptionSelected] = useState<boolean>(false);

  useEffect(() => {
    const manufacturerOtherOption = { label: 'Other', large: false, id: 'other' } as IManufacturerOption;
    setManufacturerOptionsForDisplay([...manufacturerOptions, manufacturerOtherOption]);
  }, [manufacturerOptions]);

  useEffect(() => {
    const modelOtherOption = { label: 'Other', value: 'other' } as IValue;
    setModelOptionsForDisplay([...modelOptions, modelOtherOption]);
  }, [modelOptions]);

  const handleManufacturerSelection = (value: string) => {
    handleManufacturerOnChange(value);

    if (value === 'Other') {
      setOtherOptionSelected(true);

      track('Trade-in event', {
        screen: 'trade in step one',
        where: 'manufacturer',
        when: 'selecting `other` for manufacturer',
      });

      return;
    }
    setOtherOptionSelected(false);
  };

  const handleModelSelection = ({ value }: { value: string }) => {
    handleProductSelect({ value });

    if (value === 'other') {
      setOtherOptionSelected(true);

      track('Trade-in event', {
        screen: 'trade in step one',
        where: 'model',
        when: 'selecting `other` for model',
      });

      return;
    }
    setOtherOptionSelected(false);
  };

  const skipTradeIn = () => setFormSubmitted(true);
  // end of growthBook A/B test for trade-in

  const index = findIndex(manufacturerOptions, { id: manufacturerId });

  const productValue =
    modelOptions?.find((option: IValue | null) => option?.value === modelId) ?? undefined;

  const variantValue = sizeOptionsWithTotal?.find((option: IValue | null) => option?.value === variantId);

  const selectedManufacturer =
    manufacturerOptions?.find(({ id }: IManufacturerOption) => id === manufacturerId)?.label.toLowerCase();

  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'trade in',
    checkoutScreen: 'trade in device info',
    when: 'on page load',
  });

  const categoryName = checkoutCategoryDisplayName?.toLowerCase() ?? '';

  const laptopAppleSubCopyModel = (
    <Copy color={primaryColors.c01} dataTestId="sub-copy-1">
      On your laptop go to {'\uf8ff'} {'>'} About this Mac
    </Copy>
  );

  const laptopAppleSubCopyStorage = (
    <Copy color={primaryColors.c01} dataTestId="sub-copy-2">
      On your laptop go to {'\uf8ff'} {'>'} About this Mac {'>'} More info
    </Copy>
  );

  const appleSubCopyModel = (
    <Copy color={primaryColors.c01} dataTestId="sub-copy-1">
      On your Apple {categoryName}, go to <strong>Settings {'>'} Your Name</strong> and scroll
      down to see the model
    </Copy>
  );

  const appleSubCopyStorage = (
    <Copy color={primaryColors.c01} dataTestId="sub-copy-2">
      On your Apple {categoryName}, go to{' '}
      <strong>
        Settings {'>'} General {'>'} About
      </strong>{' '}
    </Copy>
  );

  const samsungSubCopyModel = (
    <Copy color={primaryColors.c01} dataTestId="sub-copy-1">
      On your Samsung {categoryName}, go to <strong>Settings {'>'} About {categoryName}</strong>
    </Copy>
  );

  const samsungSubCopyStorage = (
    <Copy color={primaryColors.c01} dataTestId="sub-copy-2">
      On your Samsung {categoryName}, go to{' '}
      <strong>
        Settings {'>'} Device maintenance {'>'} Device care {'>'} Storage
      </strong>
    </Copy>
  );

  const howToFindModelCopyMap: { [category: string]: { [manufacturer: string]: React.JSX.Element } } = {
    laptop: { apple: laptopAppleSubCopyModel },
    phone: { apple: appleSubCopyModel, samsung: samsungSubCopyModel },
    tablet: { apple: appleSubCopyModel, samsung: samsungSubCopyModel },
  };

  const howToFindStorageCopyMap: { [category: string]: { [manufacturer: string]: React.JSX.Element } } = {
    laptop: { apple: laptopAppleSubCopyStorage },
    phone: { apple: appleSubCopyStorage, samsung: samsungSubCopyStorage },
    tablet: { apple: appleSubCopyStorage, samsung: samsungSubCopyStorage },
  };

  const handleCheckboxSelect = () => {
    setRayloDeviceCheckComplete(!rayloDeviceCheckComplete);
    setShowErrors(false);
  };

  return (
    <StyledTradeInJourneyStepOne data-testid={dataTestId}>
      <BackArrow onClick={() => setTradeInAccepted(false)} />
      <Header level={2} color={primaryColors.c01}>
        Great. Let&apos;s get going
      </Header>
      <Spacer height={24} />
      <Container noPadding>
        <RadioButtonBoxGroup
          options={manufacturerOptionsForDisplay}
          label={`What brand of ${categoryName} do you have?`}
          initialActiveIndex={index}
          onChange={handleManufacturerSelection}
          flexRow
          dataTestId="TradeInQuestion0"
        />
      </Container>
      {!!manufacturerId && (
        <>
          <Spacer height={40} />
          <SelectComponent
            dataTestId="tradeInModelSelect"
            label="What model do you have?"
            subCopy={howToFindModelCopyMap[categoryName]?.[selectedManufacturer ?? '']}
            options={modelOptionsForDisplay}
            value={productValue}
            onChange={handleModelSelection}
          />
          {!!modelId && !otherOptionSelected && hasCapacityOptions && (
            <SlideDownFadeIn>
              <>
                <Spacer height={24} />
                <SelectComponent
                  dataTestId="storageCapacitySelect"
                  label={`What’s your ${categoryName}’s storage capacity?`}
                  subCopy={howToFindStorageCopyMap[categoryName]?.[selectedManufacturer ?? '']}
                  options={sizeOptionsWithTotal}
                  value={variantValue}
                  onChange={handleVariantSelect}
                />
              </>
            </SlideDownFadeIn>
          )}
          {!!variantId && isAddTechFlow && (
            <>
              <Spacer height={32} />
              <Copy medium>
                Confirm this is not a Raylo device
              </Copy>
              <Spacer height={12} />
              <FormCheckbox
                onChange={handleCheckboxSelect}
                checked={rayloDeviceCheckComplete}
                label="This is not a Raylo device"
                dataTestId="deviceCheckCheckbox"
                errorMessage="Confirm this is not a Raylo device to continue"
                showError={showErrors}
              />
              <Spacer height={12} />
            </>
          )}
          {
            !otherOptionSelected && (
              <Container onClick={() => { setShowErrors(true); }}>
                <Spacer height={32} />
                <Button
                  dataTestId="tradeInNextButton"
                  size="large"
                  buttonColor={ButtonTypes.b01}
                  onClick={() => setStepIndex(1)}
                  disabled={!buttonEnabled}
                  buttonType="solid"
                  text="Next"
                  fullWidth
                />
              </Container>
            )
          }
        </>
      )}
      {otherOptionSelected && (
        <>
          <Spacer height={24} />
          <SectionMessage icon="RayloDesignInfo" header="Sorry, your device isn't eligible yet" copy="You can still get your new device. We’ll let you know when we expand our trade in options." />
          <Spacer height={24} />
          <Button
            dataTestId="tradeInNextButton"
            size="large"
            buttonColor={ButtonTypes.b01}
            onClick={skipTradeIn}
            buttonType="solid"
            text="Back to checkout"
            fullWidth
          />
        </>
      )}
    </StyledTradeInJourneyStepOne>
  );
};
