import { StyledGrid, StyledGridColumn } from './styles';
import { IGrid } from './types';

export const Grid = ({ dataTestId, children, center }: IGrid) => {
  return (
    <StyledGrid data-testid={dataTestId} center={center}>
      {children}
    </StyledGrid>
  );
};

export const GridColumn = ({
  children,
  flexBasis,
  colPadding,
  columns,
  md,
  sm,
}: IGrid) => {
  return (
    <StyledGridColumn
      flexBasis={flexBasis}
      colPadding={colPadding}
      columns={columns}
      md={md}
      sm={sm}
    >
      {children}
    </StyledGridColumn>
  );
};
