import { AccountScoreComponent } from '@raylo-tech/raylopay-ui';
import * as Sentry from '@sentry/browser';
import { Helmet } from 'react-helmet';
import { useStepOpenBankingContext } from 'screens/Checkout/containers/StepOpenBanking/hooks/useStepOpenBankingContext';
import ITestDataId from 'types/ITestDataId.type';
import CheckoutWrapper from '../CheckoutWrapper';
import { Decision } from '../Decision';
import { StyledAccountScore } from './styles';

export const AccountScoreContainer = ({ dataTestId }: ITestDataId) => {
  const {
    formAction,
    formFields,
    bankDetails,
    checkoutToken,
    heading,
    showWaiting,
    startBankingConnection,
  } = useStepOpenBankingContext() || {};
  const { sortCode, accountNumber } = bankDetails || {
    sortCode: '',
    accountNumber: '',
  };
  const copy =
    'Connect to consents.online to securely share a snapshot of your account history. Once this is completed your device will be on its way.';
  const connectionCopy =
    'Please connect to consents.online with the following bank account:';

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();
    try {
      await startBankingConnection({
        variables: {
          checkoutToken,
        },
        ignoreResults: true,
      });
      const form: any = document.getElementById('accountScoreForm');
      if (form) {
        form.submit();
      } else {
        Sentry.captureException('Banking Connection form not found');
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Information needed - Raylo</title>
      </Helmet>
      <StyledAccountScore data-testid={dataTestId}>
        {showWaiting ? (
          <Decision />
        ) : (
          <CheckoutWrapper className="sentry-mask">
            <AccountScoreComponent
              sortCode={sortCode}
              accountNumber={accountNumber}
              formAction={formAction}
              formFields={formFields}
              heading={heading || ''}
              copy={copy}
              onSubmitForm={handleSubmitForm as any}
              connectionCopy={connectionCopy}
            />
          </CheckoutWrapper>
        )}
      </StyledAccountScore>
    </>
  );
};
