import RayloPayLogo from 'components/RayloPayLogo';
import { useUiContext } from 'utils/useUiContext';
import {
  StyledCheckoutNavBar,
  StyledCheckoutNavBarCartCta,
  StyledCheckoutNavBarLogo
} from './styles';
import { ReactComponent as RayloLogoSVG } from 'public/icon__raylo-logo-formatted.svg';
import { ReactComponent as CheckoutBagSVG } from 'public/icons/icon_custom_checkout-bag.svg';
import ITestDataId from 'types/ITestDataId.type';

export const CheckoutNavBar = ({
  dataTestId,
}: ITestDataId) => {
  const { showSummary, setShowSummary, isRayloPay, theme } = useUiContext();

  const handleButtonOnClick = () => {
    setShowSummary(!showSummary);
  };

  return (
    <StyledCheckoutNavBar data-testid={dataTestId}>
      {isRayloPay ? (
        <RayloPayLogo merchantLogo={theme.logo.src} dataTestId={dataTestId ? `${dataTestId}-RayloPayLogo` : undefined} />
      ) : (
        <StyledCheckoutNavBarLogo data-testid={dataTestId ? `${dataTestId}-RayloLogo` : undefined}>
          <RayloLogoSVG />
        </StyledCheckoutNavBarLogo>
      )}

      <StyledCheckoutNavBarCartCta
        $summaryIsOpen={showSummary}
        onClick={handleButtonOnClick}
      >
        <CheckoutBagSVG />
      </StyledCheckoutNavBarCartCta>
    </StyledCheckoutNavBar>
  );
};
