import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { primaryColors } from 'styles/variables/colors';
import { StyledMissingAddress } from './styles';
import { IMissingAddress } from './types';

export const MissingAddress = ({ dataTestId, onBack }: IMissingAddress) => {
  return (
    <StyledMissingAddress data-testid={dataTestId}>
      <Header level={2} color={primaryColors.c01}>
        It looks like Royal Mail don’t have an accurate address for you.
      </Header>
      <Spacer height={24} />
      <Copy color={primaryColors.c01}>
        Unfortunately we can’t process your order without a Royal Mail verified
        address.
      </Copy>
      <Spacer height={24} />

      <div style={{ marginBottom: 40 }}>
        <Copy color={primaryColors.c01}>
          <strong>Luckily, that’s easy to fix.</strong>
        </Copy>
        <Spacer height={16} />
        <Copy color={primaryColors.c01}>
          <li>
            Tell Royal Mail on their{' '}
            <TextLink
              href="https://www.royalmail.com/personal/receiving-mail/update-your-address"
              target="_blank"
            >
              missing address page
            </TextLink>
          </li>
        </Copy>
        <Spacer height={8} />
        <Copy>
          <li>Come back here in 10 days and try again</li>
        </Copy>
        <Spacer height={32} />
        <TextLink onClick={onBack} dataTestId="onBack">
          Go back
        </TextLink>
      </div>
    </StyledMissingAddress>
  );
};
