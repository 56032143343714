import { Helmet } from 'react-helmet';
import { primaryColors } from 'styles/variables/colors';
import { StepSecurityDepositContent } from 'screens/Checkout/components/StepSecurityDepositContent';
import { PlainHeader } from 'components/common/PlainHeader';
import { ThemeProvider } from 'styled-components';
import { rayloLight } from 'styles/themes/rayloLight';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';

export const DecisionSecurityDeposit = () => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'security deposit',
    checkoutScreen: 'security deposit intro',
    when: 'on page load',
  });

  const backgroundColor = primaryColors.w01;

  return (
    <ThemeProvider theme={rayloLight}>
      <div
        className="checkout--index"
        style={{ backgroundColor, width: '100vw' }}
      >
        <Helmet>
          <title>Security Deposit - Raylo</title>
        </Helmet>
        <div className="checkout--header">
          <PlainHeader />
        </div>
        <div className="checkout--main">
          <div className="checkout--step">
            <StepSecurityDepositContent dataTestId="securityDeposit" />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
