import { logo } from './logo';
import { navBar } from './navBar';
import { rayloLight } from '../rayloLight';

const uSwitch = {
  ...rayloLight,
  logo,
  navBar,
};

export { uSwitch };
