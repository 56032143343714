import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CheckoutProvider } from 'utils/useCheckoutContext';
import { Checkout } from '..';
import { ProtectedCheckoutRoute } from './ProtectedCheckoutRoute';
import { Decision } from './Decision';
import { DecideLater } from './DecideLater';
import { RiskPricing } from './RiskPricing';
import { DecisionCounterOffer } from './DecisionCounterOffer';
import { DecisionSecurityDeposit } from './DecisionSecurityDeposit';
import { DecisionReferred } from './DecisionReferred';

import { CheckoutResume } from './CheckoutResume';
import { SecurityDepositScreen } from 'screens/Checkout/components/StepSecurityDepositContent';
import { DepositPaymentStatusScreen } from 'screens/Checkout/components/StepSecurityDepositContent/DepositPaymentStatusScreen';
import StepOpenBanking from 'screens/Checkout/containers/StepOpenBanking';
import { DecisionDeclined } from './CheckoutDecision/DecisionDeclined/DecisionDeclined';
import { CheckoutDecisionProvider } from './CheckoutDecision/hooks/useCheckoutDecisionContext';
import { DecisionAccepted } from './CheckoutDecision/DecisionAccepted/DecisionAccepted';
import { TrackCheckoutExperimentProvider } from 'utils/useTrackCheckoutExperimentContext';
import HowWeWillUseYourCard from './HowWeWillUseYourCard';
import { useIntercomContext } from 'components/Intercom/useIntercom';
import FeatureNotReady from '../containers/FeatureNotReady/FeatureNotReady';

export const CheckoutRouter = () => {
  const { hideIntercomWidget } = useIntercomContext();

  useEffect(() => {
    hideIntercomWidget();
  }, [hideIntercomWidget]);

  return (
    <div className="checkout">
      <CheckoutProvider>
        <TrackCheckoutExperimentProvider>
          <Switch>
            <Route path="/checkout/decision/offer-details">
              <ProtectedCheckoutRoute
                conditions={[
                  {
                    state: 'STARTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'counter_offer',
                  },
                ]}
              >
                <RiskPricing />
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/decision/later">
              <ProtectedCheckoutRoute
                conditions={[
                  {
                    state: 'SUBMITTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'counter_offer',
                  },
                  {
                    state: 'STARTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'counter_offer',
                  },
                ]}
              >
                <DecideLater />
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/decision/bank-connection">
              <ProtectedCheckoutRoute
                conditions={[
                  {
                    state: 'ABANDONED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'bank_connection_requested',
                  },
                  {
                    state: 'SUBMITTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'bank_connection_requested',
                  },
                  {
                    state: 'STARTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'bank_connection_requested',
                  },
                ]}
              >
                <StepOpenBanking />
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/decision/offer">
              <ProtectedCheckoutRoute
                conditions={[
                  {
                    state: 'STARTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'counter_offer',
                  },
                ]}
              >
                <DecisionCounterOffer />
              </ProtectedCheckoutRoute>
            </Route>

            <Route path="/checkout/decision/security-deposit">
              <ProtectedCheckoutRoute
                conditions={[
                  {
                    state: 'STARTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'security_deposit',
                  },
                ]}
              >
                <DecisionSecurityDeposit />
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/decision/continuous-payment-authority">
              <ProtectedCheckoutRoute
                conditions={[
                  {
                    state: 'ABANDONED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'security_deposit',
                  },
                  {
                    state: 'STARTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'security_deposit',
                  },
                ]}
              >
                <HowWeWillUseYourCard />
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/decision/pay-deposit">
              <ProtectedCheckoutRoute
                conditions={[
                  {
                    state: 'ABANDONED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'security_deposit',
                  },
                  {
                    state: 'STARTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'security_deposit',
                  },
                ]}
              >
                <SecurityDepositScreen />
              </ProtectedCheckoutRoute>
            </Route>

            <Route path="/checkout/decision/process-deposit/:incomingPaymentId">
              <ProtectedCheckoutRoute
                conditions={[
                  {
                    state: 'ABANDONED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'security_deposit',
                  },
                  {
                    state: 'STARTED',
                    outcome: 'USER_ACTION_REQUIRED',
                    reason: 'security_deposit',
                  },
                ]}
              >
                <DepositPaymentStatusScreen />
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/decision/accepted">
              <ProtectedCheckoutRoute
                conditions={[
                  { state: 'SUBMITTED', outcome: 'ACCEPTED' },
                  { state: 'ACCEPTED' },
                ]}
              >
                <CheckoutDecisionProvider>
                  <DecisionAccepted />
                </CheckoutDecisionProvider>
              </ProtectedCheckoutRoute>
            </Route>

            <Route path="/checkout/decision/referred">
              <ProtectedCheckoutRoute
                conditions={[
                  { state: 'SUBMITTED', outcome: 'PENDING' },
                  { state: 'SUBMITTED', outcome: 'REFERRED' },
                ]}
              >
                <DecisionReferred />
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/decision/cancelled">
              <ProtectedCheckoutRoute
                conditions={[
                  { state: 'SUBMITTED', outcome: 'DECLINED' },
                  { state: 'SUBMITTED', outcome: 'CANCELLED' },
                  { state: 'CANCELLED' },
                ]}
              >
                <CheckoutDecisionProvider>
                  <DecisionDeclined />
                </CheckoutDecisionProvider>
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/decision/not-ready-yet">
              <ProtectedCheckoutRoute
                conditions={[
                  { state: 'SUBMITTED', outcome: 'DECLINED' },
                  { state: 'SUBMITTED', outcome: 'CANCELLED' },
                  { state: 'CANCELLED' },
                ]}
              >
                <CheckoutDecisionProvider>
                  <FeatureNotReady />
                </CheckoutDecisionProvider>
              </ProtectedCheckoutRoute>
            </Route>

            <Route
              path={[
                '/checkout/decision/:checkoutToken/:step',
                '/checkout/decision/:checkoutToken',
              ]}
              render={() => <Redirect to="/go/:checkoutToken" />}
            />
            <Route path="/checkout/decision">
              <ProtectedCheckoutRoute
                conditions={[{ state: 'SUBMITTED', outcome: 'PENDING' }]}
              >
                <Decision />
              </ProtectedCheckoutRoute>
            </Route>
            <Route path="/checkout/resume">
              <ProtectedCheckoutRoute conditions={[{ state: 'ABANDONED' }]}>
                <CheckoutResume />
              </ProtectedCheckoutRoute>
            </Route>

            <Route path="/checkout/step">
              <ProtectedCheckoutRoute conditions={[{ state: 'STARTED' }]}>
                <Checkout />
              </ProtectedCheckoutRoute>
            </Route>

            <Route
              path="/checkout/:variantId/:costSummaryId"
              render={() => <Redirect to="/pick/:variantId/:costSummaryId" />}
            />
            <Route
              path="/checkout/:checkoutToken"
              render={() => <Redirect to="/go/:checkoutToken" />}
            />
            <Route path="/checkout">
              <ProtectedCheckoutRoute conditions={[{ state: 'STARTED' }]}>
                <Checkout />
              </ProtectedCheckoutRoute>
            </Route>
          </Switch>
        </TrackCheckoutExperimentProvider>
      </CheckoutProvider>
    </div>
  );
};
