import { gql } from '@apollo/client';

export interface IStartAssetReturnMutation {
  start: {
    success: boolean;
    errors: IStartAssetReturnMutationErrors[];
  };
}

interface IStartAssetReturnMutationErrors {
  message: string;
}
export const START_ASSET_RETURN = gql`
    mutation StartAssetReturnMutation($subscriptionId: ID!) {
        start: startAssetReturn(subscriptionId: $subscriptionId) {
            success
            errors {
                message
            }
        }
    }
`;
