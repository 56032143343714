import React from 'react';
import Container from '../../elements/Container';
import { px2Rem } from '../../utils/px2Rem';
import Icon from '../../elements/Icon';
import { palette } from '../../colors';
import styled from 'styled-components';
import LearnMoreModalContent from '../LearnMoreModalContent';
import BaseModal from '../BaseModal';
import { IRayloPayLearnMoreModal } from './RayloPayLearnMoreModal.types';
import { StyledRayloPayLearnMoreModal } from './RayloPayLearnMoreModal.styles';

const HeaderSection = styled.div`
  padding: ${px2Rem(20)};
  padding-bottom: ${px2Rem(20)};
  background: ${palette.blue[500]};
  position: sticky;
  top: 0;
  z-index: 9999;
`;

export const RayloPayLearnMoreModal = ({
  dataTestId,
  modalOpen,
  handleCloseModal,
}: IRayloPayLearnMoreModal) => {
  return (
    <StyledRayloPayLearnMoreModal data-testid={dataTestId}>
      <BaseModal modalOpen={!!modalOpen} closeModal={handleCloseModal}>
        <Container>
          <HeaderSection>
            <Container flexRow>
              <Icon name="RpBadgeThin" />
              <Container alignRight onClick={handleCloseModal} cursorPointer>
                <Icon name="Close" $height={14} />
              </Container>
            </Container>
          </HeaderSection>
          <Container width="100%">
            <LearnMoreModalContent showFooter showTrustPilotBanner showFAQs />
          </Container>
        </Container>
      </BaseModal>
    </StyledRayloPayLearnMoreModal>
  );
};
