export const _: unique symbol = Symbol('any');
export const end: unique symbol = Symbol('end');

type TSubject = Record<string | symbol, any>;
type TPattern = Record<string | symbol, any> | typeof _;
type TMatchEvaluator =
  ((condition: TPattern, action: () => any) => TMatchEvaluator)
  |
  ((condition: typeof end, action: () => any) => any);

export function match(pattern: Record<string | symbol, any> | typeof _, obj: TSubject) {
  if (pattern === _) {
    return true;
  }
  for (const key in pattern) {
    if (obj[key] !== pattern[key] && pattern[key] !== _) {
      return false;
    }
  }
  return true;
}

function matched(result: any) {
  function matchResult(endIndicator: TPattern | typeof end): typeof matchResult | any {
    if (endIndicator === end) {
      return result;
    }
    return matchResult;
  }
  return matchResult;
}

export function when(obj: TSubject): TMatchEvaluator {
  function evaluator(condition: TPattern | typeof end, action: () => any): TMatchEvaluator {
    if (condition === end) {
      throw Error('no pattern matched the given object');
    }

    if (match(condition, obj)) {
      return matched(action());
    }
    return evaluator;
  }

  return evaluator;
}

export function then(action: () => any) {
  return action;
}
