import { useHistory } from 'react-router';
import Container from 'elements/Container';
import Button from 'elements/Button';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import AmazonGiftCard from 'public/double_amazon_gift_card.svg';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import ITestDataId from 'types/ITestDataId.type';
import { useSharedCustomer } from 'utils/useSharedCustomer';

export const ReferAFriend = ({ dataTestId }: ITestDataId) => {
  const history = useHistory();
  const handleOnClick = () => {
    history.push('/account/refer-a-friend');
  };
  const { customerIsInMobileApp } = useSharedCustomer();
  if (customerIsInMobileApp) return null;

  return (
    <Container
      dataTestId={dataTestId}
      backgroundColor="grey"
      alignCenter
      padding={px2Rem(24)}
    >
      <Icon
        name="MultipleUsers"
        size={px2Rem(24)}
        color={primaryColors.p01}
      />
      <Spacer height={24} />
      <Container
        noPadding
        styles={{
          textAlign: 'center',
        }}
      >
        <Copy fontSize={18} color="white">
          Refer a Friend and you’ll both earn a £30 Amazon Gift Card
        </Copy>
      </Container>
      <Spacer height={12} />
      <Container width={px2Rem(215)}>
        <img src={AmazonGiftCard} alt="Amazon gift card" width="100%" />
      </Container>
      <Spacer height={12} />
      <Button
        buttonType="ghost"
        text="Learn more"
        size="medium"
        onClick={handleOnClick}
      />
    </Container>
  );
};
