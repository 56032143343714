import { RayloModal } from '@raylo-tech/raylopay-ui';
import { useUiContext } from 'utils/useUiContext';

export const AfterLeaseChoicesModal = () => {
  const { afterLeaseChoiceModalOpen, setAfterLeaseChoiceModalOpen } =
    useUiContext();
  return (
    <RayloModal
      modalOpen={afterLeaseChoiceModalOpen}
      title="Choices after the lease term"
      copy={
        [
          <strong key="upgrade">Upgrade</strong>,
          'Apply for a new device of your choice with no upgrade fee.',
          <strong key="keep">Keep</strong>,
          'If you’re happy with the device you’ve been using, then just carry on paying monthly. Or if you want to purchase the device, please contact us to discuss.',
          <strong key="cancel">Cancel</strong>,
          'Hand the device back and sail off into the sunset.',
        ] as any
      }
      handleCloseModal={() => setAfterLeaseChoiceModalOpen(false)}
    />
  );
};
