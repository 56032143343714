import React from 'react';
import { CSSTransition } from 'react-transition-group';
import 'styles/transitions.css';

export const FadeIn = ({ visible = true, timeout = 1000, children, ...props }) => {
  return (
    <CSSTransition
      in={visible}
      timeout={timeout}
      classNames="fade-in"
      appear
      {...props}
    >

      {children}

    </CSSTransition>
  );
};

export const SlideDownFadeIn = ({ visible = true, timeout = 1000, children, ...props }) => {
  return (
    <CSSTransition
      in={visible}
      timeout={timeout}
      classNames="slide-down-fade-in"
      appear
      {...props}
    >

      {children}

    </CSSTransition>
  );
};
