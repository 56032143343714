import { themes } from 'styles/themes/themeList';
import store from 'store';

type BuildProductUrlArgs = {
  checkout: any;
  slug: string;
  termLength?: number;
};

export const getProductsBaseUrl = (): string => {
  let domain = process.env.REACT_APP_PRODUCTS_DOMAIN as string;
  if (process.env.REACT_APP_DYNAMIC_GRAPHQL_ENABLED && store.get('productsBaseUrl')) {
    domain = store.get('productsBaseUrl');
  }
  return domain;
};

export const buildProductURL = ({
  checkout,
  slug,
  termLength,
}: BuildProductUrlArgs) => {
  const brandingTheme = checkout?.merchant?.brandingTheme;
  const isRayloPay = brandingTheme && brandingTheme !== 'rayloLight';
  const baseUrl = getProductsBaseUrl();

  const termLengthSlug =
    (termLength ?? 0) > 1 ? `${termLength}-months` : 'monthly-rolling';
  const productSlug = `${slug}-${termLengthSlug}`;

  if (!slug || !termLength) {
    return;
  }

  const isProd = process.env.REACT_APP_ENV === 'production';

  if (isRayloPay) {
    const merchantDomain = themes.find(({ name }) => brandingTheme === name)?.[
      isProd ? 'domain' : 'stagingDomain'
    ];
    return `https://${merchantDomain}/products/${productSlug}`;
  }

  return `${baseUrl}/products/${productSlug}`;
};

/**
 * Builds the URL for a product image.
 *
 * @param productImageUrl - The URL of the product image, if there is one.
 * @returns The modified URL with additional query parameters for image manipulation, or the original URL if it doesn't meet the criteria.
 */
export const buildProductImageUrl = (productImageUrl?: string): string | undefined => {
  if (!productImageUrl) {
    return productImageUrl;
  }

  const parsedUrl = new URL(productImageUrl);
  if (!parsedUrl.host.endsWith('imgix.net')) {
    return productImageUrl;
  }

  parsedUrl.searchParams.append('fit', 'clip');
  parsedUrl.searchParams.append('trim', 'color');
  return parsedUrl.toString();
};
