import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import { palette } from '../../../colors';
import { BREAKPOINTS } from '../../../constants';
import { cssShadow } from '../../../foundations/Shadow';

export const StyledNavBarConsumerToggle = styled.div<{ $viewport: 'mobile' | 'desktop' }>`
  display: ${({ $viewport }) => $viewport === 'mobile' ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  padding: ${px2Rem(24)} ${px2Rem(24)} ${px2Rem(20)};

  @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
    display: flex;
    padding: 0;
  }
`;

export const StyledNavBarConsumerToggleAnchor = styled.a<{ $currentItemIndex: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${px2Rem(350)};
  position: relative;
  background-color: ${palette.charcoal[100]};
  outline: 1px solid ${palette.charcoal[200]};
  border-radius: ${px2Rem(4)};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    outline: 1px solid ${palette.blue[500]};
    border-radius: ${px2Rem(4)};
    background-color: ${palette.white};
    transition: transform 0.3s;
    transform: ${({ $currentItemIndex }) => `translateX(${$currentItemIndex * 100}%);`};
  }

  @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
    background-color: transparent;

    &:before {
      background-color: ${palette.white};
      outline-color: ${palette.white};
      transition: transform 0.3s, background-color 0.3s;
      box-shadow: ${cssShadow(3)};
    }

    &:hover:before {
      background-color: ${palette.blue[100]};
    }
  }
`;

export const StyledNavBarConsumerToggleItem = styled.span<{ $isCurrent: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: ${px2Rem(12)};
  position: relative;
  z-index: 3;
  color: ${({ $isCurrent }) => ($isCurrent ? palette.blue[500] : palette.charcoal[300])};
  transition: color 0.3s;

  @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
    padding: ${px2Rem(7.5)} ${px2Rem(12)};
    color: ${({ $isCurrent }) => ($isCurrent ? palette.blue[500] : palette.blue[200])};
  }
`;
