import { ReactComponent as ShipmentPackage } from 'public/icons/streamline/icon_shipment-upload.svg';
import { ReactComponent as ReturnPackage } from 'public/icons/streamline/icon_return.svg';
import { ReactComponent as TradeIn } from 'public/icons/streamline/icon_trade-in.svg';
import { ReactComponent as CustomerSupport } from 'public/icons/streamline/icon_headphones-customer-support-human.svg';
import { ReactComponent as ShieldPhone } from 'public/icons/streamline/icon_shield-phone.svg';
import { ReactComponent as CheckShield } from 'public/icons/streamline/icon_check-shield.svg';
import { ReactComponent as TrustPilotLogo } from 'public/trustpilot-vector-logo.svg';
import { ReactComponent as SynchronizeArrowClock } from 'public/icons/streamline/icon_synchronize-arrow-clock.svg';
import { ReactComponent as NetworkShield } from 'public/icons/streamline/icon_network-shield.svg';
import { ReactComponent as CheckCircle } from 'public/icons/streamline/icon_check-circle-1.svg';
import { ReactComponent as CustomScreenProtector } from 'public/icons/icon_custom_screen-protector.svg';
import { ReactComponent as PhoneDamage } from 'public/icons/icon_custom_phone-damage.svg';
import { ReactComponent as CheckCircleFill } from 'public/icons/streamline/icon_check-circle-fill.svg';
import { ReactComponent as Close } from 'public/icons/icon_custom_close.svg';
import { ReactComponent as BackArrow } from 'public/icons/icon_custom_back-arrow_.svg';
import { ReactComponent as CircleFill } from 'public/icons/icon_custom-circle-fill.svg';
import { ReactComponent as AlertCircle } from 'public/icons/streamline/icon_alert-circle.svg';
import { ReactComponent as CheckSqaured } from 'public/icons/icon_check-circle-squared.svg';
import { ReactComponent as DirectDebit } from 'public/icons/icon_direct-debit.svg';
import { ReactComponent as Add } from 'public/icons/streamline/icon_add.svg';
import { ReactComponent as RoundArrowBack } from 'public/icons/icon_arrow-round-back.svg';
import { ReactComponent as MultipleUsers } from 'public/icons/icon-multiple-users.svg';
import { ReactComponent as Email } from 'public/icons/icon--email.svg';
import { ReactComponent as Twitter } from 'public/icons/icon--twitter.svg';
import { ReactComponent as Facebook } from 'public/icons/icon--facebook.svg';
import { ReactComponent as Whatsapp } from 'public/icons/icon--whatsapp.svg';
import { ReactComponent as CheckCircle2 } from 'public/icons/icon_check-circle.svg';
import { ReactComponent as RayloLogo } from 'public/icon__raylo-logo.svg';
import { ReactComponent as InfoCircle } from 'public/icons/icon_InfoCircle.svg';
import { ReactComponent as InfoWhite } from 'public/icons/icon_InfoWhite.svg';
import { ReactComponent as PhoneRefresh } from 'public/icons/icon__phone-shield-refresh.svg';
import { ReactComponent as RedAlert } from 'public/icons/icon_red_alert.svg';
import { ReactComponent as WhiteAlert } from 'public/icons/icon_white_alert.svg';
import { ReactComponent as OrangeAlert } from 'public/icons/icon_orange_alert.svg';
import { ReactComponent as PaymentSuccessful } from 'public/icons/sd-payment-success.svg';
import { ReactComponent as PaymentUnsuccessful } from 'public/icons/sd-payment-failure.svg';
import { ReactComponent as SecurityDeposit } from 'public/icons/icon_security_deposit.svg';
import { ReactComponent as DeliveryTruck } from 'public/icons/icon_delivery_truck.svg';
import { ReactComponent as PiggyBank } from 'public/icons/icon_piggy_bank.svg';
import { ReactComponent as PricePromise } from 'public/icons/icon_price_promise.svg';
import { ReactComponent as PricePromiseWithShadow } from 'public/icons/icon_price_promise_shadow.svg';
import { ReactComponent as PricePromiseConsole } from 'public/icons/icon_price_promise_console.svg';
import { ReactComponent as DeviceCheck } from 'public/icons/icon_device_check.svg';

export const icons = [
  {
    iconName: 'CheckShield',
    icon: <CheckShield />,
  },
  {
    iconName: 'ShipmentPackage',
    icon: <ShipmentPackage />,
  },
  {
    iconName: 'ReturnPackage',
    icon: <ReturnPackage />,
  },
  {
    iconName: 'TradeIn',
    icon: <TradeIn />,
  },
  {
    iconName: 'SynchronizeArrowClock',
    icon: <SynchronizeArrowClock />,
  },
  {
    iconName: 'NetworkShield',
    icon: <NetworkShield />,
  },
  {
    iconName: 'CheckCircle',
    icon: <CheckCircle />,
  },
  {
    iconName: 'CheckCircle2',
    icon: <CheckCircle2 />,
  },
  {
    iconName: 'ShieldPhone',
    icon: <ShieldPhone />,
  },
  {
    iconName: 'CustomerSupport',
    icon: <CustomerSupport />,
  },
  {
    iconName: 'CustomScreenProtector',
    icon: <CustomScreenProtector />,
  },
  {
    iconName: 'PhoneDamage',
    icon: <PhoneDamage />,
  },
  {
    iconName: 'BackArrow',
    icon: <BackArrow />,
  },
  {
    iconName: 'CheckCircleFill',
    icon: <CheckCircleFill />,
  },
  {
    iconName: 'Close',
    icon: <Close />,
  },
  {
    iconName: 'TrustPilotLogo',
    icon: <TrustPilotLogo />,
  },
  {
    iconName: 'CircleFill',
    icon: <CircleFill />,
  },
  {
    iconName: 'AlertCircle',
    icon: <AlertCircle />,
  },
  {
    iconName: 'CheckSqaured',
    icon: <CheckSqaured />,
  },
  {
    iconName: 'DirectDebit',
    icon: <DirectDebit />,
  },
  {
    iconName: 'Add',
    icon: <Add />,
  },
  {
    iconName: 'RoundArrowBack',
    icon: <RoundArrowBack />,
  },
  {
    iconName: 'MultipleUsers',
    icon: <MultipleUsers />,
  },
  {
    iconName: 'Email',
    icon: <Email />,
  },
  {
    iconName: 'Twitter',
    icon: <Twitter />,
  },
  {
    iconName: 'Facebook',
    icon: <Facebook />,
  },
  {
    iconName: 'Whatsapp',
    icon: <Whatsapp />,
  },
  {
    iconName: 'RayloLogo',
    icon: <RayloLogo />,
  },
  {
    iconName: 'InfoCircle',
    icon: <InfoCircle />,
  },
  {
    iconName: 'InfoWhite',
    icon: <InfoWhite />,
  },
  {
    iconName: 'PhoneRefresh',
    icon: <PhoneRefresh />,
  },
  {
    iconName: 'RedAlert',
    icon: <RedAlert />,
  },
  {
    iconName: 'OrangeAlert',
    icon: <OrangeAlert />,
  },
  {
    iconName: 'WhiteAlert',
    icon: <WhiteAlert />,
  },
  {
    iconName: 'PaymentSuccessful',
    icon: <PaymentSuccessful />,
  },
  {
    iconName: 'PaymentUnsuccessful',
    icon: <PaymentUnsuccessful />,
  },
  {
    iconName: 'SecurityDeposit',
    icon: <SecurityDeposit />,
  },
  {
    iconName: 'DeliveryTruck',
    icon: <DeliveryTruck />,
  },
  {
    iconName: 'PiggyBank',
    icon: <PiggyBank />,
  },
  {
    iconName: 'PricePromiseConsole',
    icon: <PricePromiseConsole />,
  },
  {
    iconName: 'PricePromiseWithShadow',
    icon: <PricePromiseWithShadow />,
  },
  {
    iconName: 'PricePromise',
    icon: <PricePromise />,
  },
  {
    iconName: 'DeviceCheck',
    icon: <DeviceCheck />,
  },
];
