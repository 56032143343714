export const LEGACY_BREAKPOINTS = {
  smallMobile: 414,
  mobile: 600,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1200,
  account: {
    forms: {
      mobile: 555,
    },
  },
};
