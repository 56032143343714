import React from 'react';
import { Container, Icon, RayloModal, Spacer } from '@raylo-tech/raylopay-ui';
import Copy from 'elements/Copy';
import { primaryColors } from 'styles/variables/colors';

type NotificationBoxProps = {
  type: 'success' | 'error';
  title: string;
  children?: React.ReactNode;
  onClose: () => void;
};

export const NotificationBox = ({
  type,
  title,
  children,
  onClose,
}: NotificationBoxProps) => {
  const icon = type === 'success' ? 'CheckCircle' : 'XCircle';

  return (
    <RayloModal
      modalOpen={true}
      handleCloseModal={onClose}
      dataTestId="cancel-order-notification-box"
    >
      <Container alignCenter>
        <Icon name={icon} />
        <Spacer height={12} />
        <Copy fontSize={24} lineHeight={28} bold color={primaryColors.c01}>
          {title}
        </Copy>
        <Spacer height={12} />
        {children}
      </Container>
    </RayloModal>
  );
};
