import styled from 'styled-components';
import { primaryColors, secondaryColors } from '../../../styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { BREAKPOINTS } from '@raylo-tech/raylopay-ui';

export const StyledEndOfLeaseOptions = styled.div`
    background-color: ${secondaryColors.c05};
`;

export const StyledEndOfLeaseOptionsContentContainer = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${px2Rem(24)};
    max-width: ${px2Rem(1200)};
    margin: auto;
    justify-content: center;
    background-color: ${secondaryColors.c05};
`;

export const StyledQuestionsContainer = styled.div`
    background-color: ${secondaryColors.c05};
    justify-content: center;
     padding-bottom: ${px2Rem(48)};
    margin-top: ${px2Rem(24)};
`;

export const StyledQuestionsLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: ${BREAKPOINTS.smallDesktop}px) {
        flex-direction: row;
        gap: ${px2Rem(32)};
    }
`;

export const StyledQuestionsLink = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: ${primaryColors.b01};
    background: none;
    border: none;
    line-height: 1.5;
    cursor: pointer;
    margin-top: ${px2Rem(24)};
    padding: 0;
    
    &:hover {
        text-decoration: underline;
        color: ${secondaryColors.b02};
    }
`;
