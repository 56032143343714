import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { ICopy } from './Copy.types';
import { CSS_FONT_WEIGHT } from '../../constants';

const StyledCopy = styled.p<ICopy>`
  margin: ${({ margin }) => margin ? margin : 0};
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ bold }) => bold && `font-weight: ${CSS_FONT_WEIGHT.vars.bold};`}
  ${({ medium }) => medium && `font-weight: ${CSS_FONT_WEIGHT.vars.medium};`}
  ${({ italic }) => italic && 'font-style: italic;'}
  ${({ lineHeight }) => lineHeight && `line-height: ${px2Rem(lineHeight)};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ verticalAlign }) =>
      verticalAlign && `margin-top: ${px2Rem(verticalAlign)};`}
  ${({ styles }) => styles};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${px2Rem(letterSpacing)}`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}}`};
  ${({ truncate }) => truncate && 'overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'};

  &:hover {
    ${({ hoverBold }) => hoverBold && `font-weight: ${CSS_FONT_WEIGHT.vars.bold};`};
  }
`;

export { StyledCopy };
