import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { PROCESS_INCOMING_PAYMENT_MUTATION } from 'graphql/mutations/incomingPayment/processIncomingPaymentMutation.graphql';
import { rayloLight } from 'styles/themes/rayloLight';
import { PlainHeader } from 'components/common/PlainHeader';
import { primaryColors } from 'styles/variables/colors';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { PaymentProcessing } from 'screens/Account/Payment/ProcessingPayment/PaymentProcessing';
import { PaymentStatus } from 'screens/Account/Payment/ProcessingPayment/PaymentStatus.enum';
import { IncomingPaymentStatus } from 'graphql/mutations/incomingPayment/incomingPaymentStatuses.enum';
import {
  CHECKOUT_INCOMING_PAYMENTS,
  ICheckoutIncomingPaymentsQuery
} from 'graphql/queries/checkout/incomingPayment/incomingPayments.graphql';
import {
  CHECKOUT_DECISION_QUERY,
  ICheckout,
  ICheckoutDecisionQuery
} from 'graphql/queries/checkout/checkoutDecision.graphql';
import { freshApolloClient } from 'utils/graphql';
import { CheckoutDecisionOutcome } from 'types/Checkout/decisionOutcome.enum';
import { DepositPaymentSuccess } from './DepositPaymentSuccess';
import { DepositPaymentFailure } from './DepositPaymentFailure';

export const DepositPaymentStatusScreen = () => {
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.Initial);
  const [acceptableCheckoutState, setAcceptableCheckoutState] = useState(false);
  const [checkout, setCheckout] = useState<null | ICheckout>(null);

  const { incomingPaymentId }: { incomingPaymentId: string } = useParams();
  const { checkoutToken } = useCheckoutContext();

  const checkoutIncomingPaymentsQuery = useQuery(CHECKOUT_INCOMING_PAYMENTS, {
    variables: { token: checkoutToken },
    pollInterval: 2000,
    notifyOnNetworkStatusChange: true,
    onCompleted(data: ICheckoutIncomingPaymentsQuery) {
      const incomingPaymentState = data.checkout?.incomingPayments.find(
        incomingPayment => incomingPayment.id === incomingPaymentId
      )?.state;
      if (
        incomingPaymentState === IncomingPaymentStatus.Cancelled ||
        incomingPaymentState === IncomingPaymentStatus.Declined
      ) {
        checkoutIncomingPaymentsQuery.stopPolling();
        setPaymentStatus(PaymentStatus.Failed);
      }
      if (incomingPaymentState === IncomingPaymentStatus.Accepted) {
        checkoutIncomingPaymentsQuery.stopPolling();
        setPaymentStatus(PaymentStatus.Successful);
      }
    },
  });

  const checkoutDecisionQuery = useQuery(CHECKOUT_DECISION_QUERY, {
    client: freshApolloClient,
    variables: { checkoutToken },
    pollInterval: 2000,
    notifyOnNetworkStatusChange: true,
    onCompleted(data: ICheckoutDecisionQuery) {
      if (
        data?.checkout.decision.outcome !==
        CheckoutDecisionOutcome.UserActionRequired
      ) {
        checkoutDecisionQuery.stopPolling();
        setAcceptableCheckoutState(true);
        setCheckout(data.checkout);
      }
    },
  });

  const [processIncomingPayment] = useMutation(
    PROCESS_INCOMING_PAYMENT_MUTATION,
    {
      variables: {
        incomingPaymentId,
        checkoutToken,
      },
    }
  );

  useEffect(() => {
    processIncomingPayment();
  }, []);

  const renderPaymentStatus = () => {
    const successful =
      paymentStatus === PaymentStatus.Successful && acceptableCheckoutState;
    const failed = paymentStatus === PaymentStatus.Failed;

    if (successful) {
      return <DepositPaymentSuccess checkout={checkout} />;
    }
    if (failed) {
      return <DepositPaymentFailure />;
    }

    return <PaymentProcessing />;
  };

  const backgroundColor = primaryColors.w01;

  return (
    <ThemeProvider theme={rayloLight}>
      <div
        className="checkout--index"
        style={{ backgroundColor, width: '100vw' }}
      >
        <Helmet>
          <title>Security Deposit - Raylo</title>
        </Helmet>
        <div className="checkout--header">
          <PlainHeader />
        </div>
        <div className="checkout--main">
          <div className="checkout--step">{renderPaymentStatus()}</div>
        </div>
      </div>
    </ThemeProvider>
  );
};
