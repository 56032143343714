import Container from 'elements/Container';
import { StyledDivider } from './styles';
import { IDivider } from './types';

export const Divider = ({
  dataTestId,
  color,
  height,
  padding,
  width,
  styles,
  opacity,
}: IDivider) => {
  if (opacity) {
    return (
      <Container
        noPadding
        styles={{
          opacity,
        }}
      >
        <StyledDivider
          data-testid={dataTestId}
          color={color}
          height={height}
          padding={padding}
          width={width}
        />
      </Container>
    );
  }
  return (
    <StyledDivider
      data-testid={dataTestId}
      color={color}
      height={height}
      padding={padding}
      width={width}
      styles={styles}
    />
  );
};
