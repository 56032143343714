import React, { useState } from 'react';
import useRayloQuery from 'utils/useRayloQuery';
import { MERCHANTS_QUERY } from 'graphql/queries/merchant/merchants.graphql';
import { px2Rem } from 'utils/px2Rem';
import store from 'store';
import { Loader } from 'components/common/Loader';
import { FadeIn } from 'components/animations/Transitions';
import { SelectComponent } from 'elements/SelectComponent/SelectComponent';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import {
  tryGetSessionStorageObject,
  setSessionStorage
} from 'utils/handleSessionStorage';
import Spacer from 'elements/Spacer';
import Button from '../../elements/Button';

const options = (merchants: [{ id: string; displayName: string }]) => {
  return merchants.map((merchant: { id: string; displayName: string }) => {
    return { value: merchant.id, label: merchant.displayName };
  });
};

export const MerchantSelector = () => {
  const {
    data: { merchants },
  } = useRayloQuery(MERCHANTS_QUERY, { fetchPolicy: 'network-only' });
  const { removeDomainCookie } = useSharedCustomer();

  const [selectedMerchantValue, setSelectedMerchantValue] = useState(
    tryGetSessionStorageObject<{ value: string; label: string }>('TestMerchant') || {
      value: '',
      label: 'Raylo',
    }
  );

  return (
    <div style={{ position: 'relative' }}>
      {merchants ? (
        <>
          <p style={{ fontWeight: '700' }}>Select a merchant</p>
          <SelectComponent
            dataTestId="TestMerchantSelector"
            options={options(merchants)}
            value={selectedMerchantValue}
            onChange={(selectedValue) => {
              setSessionStorage('TestMerchant', selectedValue);
              setSelectedMerchantValue(selectedValue);
              // avoid resuming existing checkout under different merchant
              store.remove('checkoutToken');
              removeDomainCookie('checkoutToken');
            }}
          />
          <Spacer height={16} />
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Select Merchant
          </Button>
        </>
      ) : (
        <FadeIn>
          <div
            style={{
              position: 'absolute',
              top: `calc(50% - ${px2Rem(15)})`,
              left: `calc(50% - ${px2Rem(15)})`,
            }}
          >
            <Loader type="quad" />
          </div>
        </FadeIn>
      )}
    </div>
  );
};
