import { createGlobalStyle } from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

// CSS custom properties to be used for global variables
const cssGlobalProperties = {
  navBarHeight: '--nav-bar-height' as const,
};

// Combined object for global CSS custom properties and variables
export const CSS_GLOBALS = {
  properties: cssGlobalProperties,
  vars: {
    navBarHeight: `var(${cssGlobalProperties.navBarHeight})` as const,
  },
};

export const GlobalAppStyles = createGlobalStyle`
  :root {
    ${CSS_GLOBALS.properties.navBarHeight}: ${px2Rem(80)};
  }
`;
