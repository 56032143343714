import React, { useEffect } from 'react';
import { filter } from 'lodash-es'
import { activateOptimize } from 'utils/optimize'

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import * as I from 'styles/inputs';
import { Button } from 'components/common/Button';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';

export const CardAddress = ({onBack, address, onChange}) => {
  useEffect(() => {
    activateOptimize()
  })
  return (
    <div className="checkout--step feedback">
      <FadeIn>
        <T.RayloBodyHeading><strong>Sorry — it looks like your card address doesn’t match your home address.</strong></T.RayloBodyHeading>
      </FadeIn>

      <SlideDownFadeIn>
        <I.RayloFormRow>
          <I.RayloFieldHeader>
            <I.RayloFieldLabel isFocused={true}>It’s really important that we have your correct home address, otherwise you won’t pass the credit check. Here’s the address you gave us:</I.RayloFieldLabel>
          </I.RayloFieldHeader>
          <I.RayloFieldContent>
            {filter([address.line1,address.line2,address.line3,address.city,address.region,address.postcode], (o) => (o && o.length > 1)).map((o, index) => <T.RayloAddress className={'sentry-mask'} key={index}>{o}<br/></T.RayloAddress>)}
          </I.RayloFieldContent>
        </I.RayloFormRow>
      </SlideDownFadeIn>

      <SlideDownFadeIn>
        <L.SignupFeedbackActions>
          <div>
            <Button
              buttonStyle='secondary'
              onClick={onChange}
              style={{ height: '44px' }}>
              Change home address
            </Button>
          </div>
          <div>
            <Button
              buttonStyle='primaryBlue'
              onClick={onBack}
              style={{ height: '44px' }}>
              Use a different card
            </Button>
          </div>
        </L.SignupFeedbackActions>
      </SlideDownFadeIn>
    </div>

  )
}
