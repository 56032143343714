export const SLUGS = {
  StepInsurance: 'insurance',
  StepNameEmail: 'name-email',
  StepEmail: 'email',
  StepPersonalDetails: 'personal-details',
  StepAddress: 'address',
  StepAddressCheck: 'previous-address',
  StepMobileJob: 'mobile',
  StepStripe: 'credit-card',
  StepBank: 'bank',
  StepTerms: 'terms',
  StepSign: 'sign',
  StepSignOffer: 'sign-offer',
  StepTradeIn: 'trade-in',
  StepUpgrade: 'upgrade',
  StepLease: 'lease-terms',
  StepBusinessType: 'business-type',
  StepLeaseType: 'lease-type',
};
