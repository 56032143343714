import {
  CtaContainerStyles,
  StyledArrowContainer,
  StyledBadgeAndIconContainer,
  StyledCarbonTagContainer,
  StyledEndOfLeaseOptionsCard,
  StyledIconContainer,
  StyledLeaseButton,
  StyledLinksContainer,
  StyledMostPopularTagContainer,
  StyledTagsContainer
} from './EndOfLeaseOptionsCard.styles';
import { EndOfLeaseOptionsCardProps } from './EndOfLeaseOptionsCard.types';
import { Copy, SectionMessage, Spacer, Typography } from '@raylo-tech/raylopay-ui';
import RightLineArrowIcon from '../Icons/RightLineArrowIcon';
import { primaryColors, secondaryColors, uiStateColors } from 'styles/variables/colors';
import { ForwardedRef, forwardRef } from 'react';
import { px2Rem } from 'utils/px2Rem';
import MostPopularIcon from '../Icons/MostPopularIcon';
import CarbonIcon from '../Icons/CarbonIcon';

export const EndOfLeaseOptionsCard = forwardRef(function EndOfLeaseOptionsCard({ content }: EndOfLeaseOptionsCardProps, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <StyledEndOfLeaseOptionsCard ref={ref}>
      <StyledBadgeAndIconContainer>
        {content.badge}
        <StyledIconContainer>
          <content.icon style={{ color: secondaryColors.b03, width: px2Rem(32) }} />
        </StyledIconContainer>
      </StyledBadgeAndIconContainer>
      <Typography data-testid={`${content.title}-h2`} variant="h2" bold style={{ marginBottom: px2Rem(12) }}>
        {content.title}
      </Typography>
      <Typography variant="h3" bold style={{ marginBottom: px2Rem(12) }}>
        {content.subTitle}
      </Typography>
      <Copy fontSize={14}>
        {content.copy}
      </Copy>
      {content.type === 'upgrade' && (
        <StyledTagsContainer>
          <StyledMostPopularTagContainer>
            <MostPopularIcon color={primaryColors.b01} height={16} width={16} />
            <span>Most popular</span>
          </StyledMostPopularTagContainer>
          <StyledCarbonTagContainer>
            <CarbonIcon color={uiStateColors.success} height={16} width={16} />
            <span>56% carbon reduction</span>
          </StyledCarbonTagContainer>
        </StyledTagsContainer>
      )}

      <Spacer height={24} />
      <SectionMessage
        header={content.sectionMessageTitle}
        bulletList={content.sectionMessageBulletPoints}
      />
      {content.footer && (
        <Typography style={{ fontSize: px2Rem(12), color: secondaryColors.c02, marginTop: px2Rem(24) }}>
          {content.footer}
        </Typography>
      )}
      <StyledLinksContainer>
        {content.links.map((link, index) => (
          <StyledLeaseButton
            key={`${content.type}lease-options-link-${index}`}
            onClick={link.onClick}
          >
            <span>{link.text}</span>
            <StyledArrowContainer>
              <RightLineArrowIcon color={primaryColors.b01} height={15} width={16} />
            </StyledArrowContainer>
          </StyledLeaseButton>
        ))}
        {content.cta && (
          <CtaContainerStyles>
            {content.cta}
          </CtaContainerStyles>
        )}
      </StyledLinksContainer>
    </StyledEndOfLeaseOptionsCard>
  );
});
