import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GraphQLRequest } from '@apollo/client';
import useRayloQuery from 'utils/useRayloQuery';
import { findOrderItem } from './utils/customers';

type UseInsuranceArgs = {
  query: GraphQLRequest;
  isLostOrStolen: boolean;
  orderId: string;
};

const useInsurance = ({ query, isLostOrStolen, orderId }: UseInsuranceArgs) => {
  const history = useHistory();
  const {
    data: { customer },
    loading,
  } = useRayloQuery(query, { fetchPolicy: 'network-only' });

  const [policy, setPolicy] = useState({});
  const [orderItem, setOrderItem] = useState<any>({});

  useEffect(() => {
    if (!customer) {
      return;
    }
    const item = findOrderItem(orderId, customer);
    setOrderItem(item);

    if (!item?.costSummary?.includesInsurance) {
      history.push('/account');
    }

    if (isLostOrStolen) {
      const subscription = item?.subscription;
      if (subscription) {
        setPolicy(subscription.insurancePolicy);
      } else {
        history.push('/account');
      }
    }
  }, [customer]);

  return {
    customer,
    orderId,
    orderItem,
    loading,
    policy,
  };
};

export default useInsurance;
