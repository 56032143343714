import dayjs from 'dayjs';
import { find } from 'lodash-es';
import { checkInArrears } from 'helpers/subscriptions/checkInArrears';
import { getOptionValue } from 'utils/getOptionValue';
import { YourTechStatus } from '../YourTechStatus';
import { VariantOptionSlugs } from 'types/variants/options/variantOptionSlugs';
import {
  IGetCustomerDeviceOrderItem,
  IGetCustomerDeviceOrderFulfillmentItem,
  IGetCustomerDeviceOrderSubscription
} from '../DeviceProfile/graphql/deviceProfile.graphql';
import { getActiveAssetReturn } from 'utils/subscription/getActiveAssetReturn';

export interface IParseOrder {
  insurance: boolean;
  fulfillment: boolean;
  image: { tag: string; url?: string } | undefined;
  color: string;
  orderId: string;
  orderItem: IGetCustomerDeviceOrderItem;
  merchant: { id: string; displayName: string };
  storage: string;
  watchCaseSize: string;
  subscription: IGetCustomerDeviceOrderSubscription | null;
  directCancellationSupported: boolean;
  payMonthly: number;
  hasDeliveryDate: string;
  payRecurring: number;
  preOrder: boolean;
  model: string;
  currency: string;
  id: string;
  merchantReference: string;
  status: string;
  upgradeAvailable: boolean;
}

export const parseOrder = (order: any): IParseOrder | undefined => {
  if (order.items && order.items.length > 0) {
    const orderItem = order.items[0];
    const { merchant, merchantReference, directCancellationSupported } = order;
    const { costSummary, variant, fulfillmentItem, subscription } = orderItem;
    const { knownDeliveryDates } = order;

    const parsedOrder = {
      id: orderItem.id,
      orderId: order.id,
      model: variant.product.displayName,
      image: find(variant.images, { tag: 'front' }),
      storage: getOptionValue(variant, VariantOptionSlugs.Storage).displayName,
      color: getOptionValue(variant, VariantOptionSlugs.Colour).displayName,
      watchCaseSize: getOptionValue(variant, VariantOptionSlugs.WatchCaseSize)
        .displayName,
      payMonthly: costSummary.recurring.totalAmount.valueAfterTax.value,
      payRecurring: costSummary.recurring,
      currency: costSummary.currencyCode,
      insurance: costSummary.includesInsurance,
      fulfillment: !!fulfillmentItem,
      preOrder: variant.product.preOrder,
      subscription,
      merchant,
      orderItem,
      status: YourTechStatus.orderPlaced,
      hasDeliveryDate: knownDeliveryDates,
      directCancellationSupported,
      merchantReference,
      upgradeAvailable: isUpgradeAvailable(subscription),
    };

    if (order.state === 'CANCELLED') {
      parsedOrder.status = YourTechStatus.orderCancelled;
      return parsedOrder;
    }

    const isInArrears = checkInArrears(subscription);
    let status;
    if (isInArrears) {
      status = YourTechStatus.arrears;
    } else {
      status = getAssetReturnStatus(subscription) || getSubscriptionStatus(subscription) || getFulfillmentStatus(fulfillmentItem);
    }

    if (status) {
      parsedOrder.status = status;
    }

    return parsedOrder;
  }
  return undefined;
};

const getFulfillmentStatus = (fulfillmentItem: IGetCustomerDeviceOrderFulfillmentItem): string | null => {
  if (!fulfillmentItem) {
    return null;
  }

  if (fulfillmentItem.deliveredAt) {
    return `${YourTechStatus.delivered} on ${dayjs(fulfillmentItem.deliveredAt).format('DD.MM.YY')}`;
  }

  if (fulfillmentItem.dispatchedAt && fulfillmentItem.trackingUrl) {
    return `${YourTechStatus.dispatched} on ${dayjs(fulfillmentItem.dispatchedAt).format('DD.MM.YY')}`;
  }

  if (fulfillmentItem.expectedDeliveryAt) {
    return YourTechStatus.pending;
  }

  return null;
};

const isUpgradeAvailable = (subscription: IGetCustomerDeviceOrderSubscription): boolean => {
  if (!subscription) {
    return false;
  }

  const { dueOn, eligible, checkout } = subscription.upgrade;
  const dueOnIsInPast = dueOn && dayjs(dueOn).isBefore(dayjs());
  const hasActiveCheckout = ['STARTED', 'ABANDONED'].includes(checkout?.state);

  return !!dueOnIsInPast && (eligible || hasActiveCheckout);
};

const getSubscriptionStatus = (subscription: IGetCustomerDeviceOrderSubscription): string | null => {
  if (!subscription) {
    return null;
  }

  const { dueOn, canStartAssetReturn } = subscription.upgrade;

  if (canStartAssetReturn) {
    return YourTechStatus.returnDue;
  }

  if (subscription.state === 'CANCELLED') {
    return YourTechStatus.leaseCancelled;
  }

  if (isUpgradeAvailable(subscription)) {
    return YourTechStatus.upgradeAvailable;
  }

  if (
    subscription.startedOn &&
    dayjs(subscription.startedOn).isBefore(dayjs().subtract(14, 'd'))
  ) {
    return `${
      YourTechStatus.active
    } - Upgrade available from ${dayjs(dueOn).format('DD.MM.YY')}`;
  }

  return null;
};

const getAssetReturnStatus = (subscription: IGetCustomerDeviceOrderSubscription): string | null => {
  const assetReturn = getActiveAssetReturn(subscription);

  if (!assetReturn) {
    return null;
  }

  switch (assetReturn.state) {
    case 'COLLECTION_STARTED':
    case 'REQUESTED':
      return YourTechStatus.returnStarted;
    default:
      return null;
  }
};

export const isTV = (category?: { displayName?: string | null }) => {
  // We should rely on category slug instead of display name
  // but before we do that we'd also want to align the category slugs with the url segments and filter query string params
  // (e.g. /products?category=phones) which will require renaming the category slugs, so let's not switch to slugs just yet
  return category?.displayName?.toLowerCase() === 'tv';
};
