import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
import FormErrorMessages from 'components/FormErrorMessages';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Divider from 'elements/Divider';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import TrustPilotBanner from 'elements/TrustPilotBanner';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { useStepNameEmailContext } from 'screens/Checkout/containers/StepNameEmail/hooks/useStepNameEmailContext';
import { secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import ITestDataId from 'types/ITestDataId.type';
import { FormComponent } from './FormComponent';
import { StyledStepNameEmailContent } from './styles';
import { INameEmailValues } from './types';

export const StepNameEmailContent = ({ dataTestId }: ITestDataId) => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'your details',
    checkoutScreen: 'add name and email',
    when: 'on page load',
  });

  const { setValues, formErrors } = useStepNameEmailContext();
  const onSubmit = (values: INameEmailValues) => {
    setValues(values);
  };
  return (
    <StyledStepNameEmailContent data-testid={dataTestId}>
      <TrustPilotBanner />
      <Spacer height={24} />
      <Divider color={secondaryColors.c04} />
      <Spacer height={24} />
      <Header level={2} fontSize={24}>
        Your Details
      </Header>
      <Spacer height={24} />
      <FormComponent onSubmit={onSubmit} />
      <Container
        padding={`0 ${px2Rem(16)}`}
        backgroundColor={secondaryColors.c05}
      >
        <Spacer height={24} />
        <Copy fontSize={14} color={secondaryColors.c02}>
          By clicking next you are accepting our{' '}
          <TextLink
            href="https://www.raylo.com/legal"
            target="_blank"
            fontSize={14}
          >
            privacy policy
          </TextLink>
        </Copy>
        <Spacer height={24} />
      </Container>
      <Spacer height={24} />
      <FormErrorMessages formErrors={formErrors} />
      <DoubleButtonFullWidth
        singleButton
        topButtonText="next"
        topButtonType="submit"
        formId="nameEmail"
        topButtonTestId="nameEmailSubmit"
      />
    </StyledStepNameEmailContent>
  );
};
