import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';
import styled from 'styled-components';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

export const DownsellContainerWrapper = styled.div`
  background-color: ${primaryColors.w01};
  min-height: 100vh;
  width: 100%;
  padding-bottom: ${px2Rem(72)};

  header {
    width: 100%;
    height: ${px2Rem(60)};
    background-color: ${primaryColors.b01};
    padding: ${px2Rem(16)} 0 ${px2Rem(16)} ${px2Rem(24)};

    img {
      display: block;
      height: ${px2Rem(28)};
    }
  }

  .downsell-content {
    margin: 0 auto;
    max-width: ${px2Rem(498)};
    padding: ${px2Rem(48)} ${px2Rem(24)} 0 ${px2Rem(24)};

    b {
      font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    }
  }
`;
