import { TradeInTerms } from 'components/Checkout/TradeInTerms';
import RayloModal from 'components/RayloModal';
import Container from 'elements/Container';
import { useUiContext } from 'utils/useUiContext';
import ITestDataId from 'types/ITestDataId.type';

export const TradeInTermsModal = ({ dataTestId }: ITestDataId) => {
  const { setShowModal, setShowTradeInTermsModal } = useUiContext();
  const handleModalClose = () => {
    setShowModal(false);
    setShowTradeInTermsModal(false);
  };
  return (
    <RayloModal
      header="Trade-in terms and conditions"
      onClose={handleModalClose}
      dataTestId={dataTestId}
    >
      <Container noPadding>
        <TradeInTerms visible onBack={() => {}} />
      </Container>
    </RayloModal>
  );
};
