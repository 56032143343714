import React from 'react';
import { px2Rem } from 'utils/px2Rem';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import { primaryColors } from 'styles/variables/colors';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useHistory } from 'react-router-dom';
import { PaymentOutcomeScreen } from 'screens/Account/Payment/ProcessingPayment/PaymentOutcomeScreen';
import useMedia from 'use-media';
import { LEGACY_BREAKPOINTS } from '../../../../styles/LEGACY_BREAKPOINTS';
import routes from 'utils/routes';
import { Loader } from 'components/common/Loader';

export const DepositPaymentSuccess = (checkout: any) => {
  const history = useHistory();
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });
  const { checkoutCustomerInfo } = useCheckoutContext();

  if (!checkout) {
    return <Loader type="quad" />;
  }

  return (
    <PaymentOutcomeScreen
      title="Your payment was successful"
      preContent={(
        <Header
          level={4}
          color={primaryColors.c01}
          fontSize={16}
          style={isMobile ? { marginTop: px2Rem(30) } : undefined}
          centered
        >
          Thank you
        </Header>
      )}
      mainContent={(
        <Copy fontSize={16} textAlign="center">
          A receipt for your payment will be emailed to
          <span className="sentry-mask" style={{ fontWeight: 'bold' }}>
            {` ${checkoutCustomerInfo?.email}`}
          </span>
        </Copy>
      )}
      iconName="PaymentSuccessful"
      onClick={() => history.push(routes(checkout))}
      buttonText="continue"
    />
  );
};
