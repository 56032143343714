import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import styled from 'styled-components';
import { IGrid } from './types';

const StyledGrid = styled.div<IGrid>`
  width: 100%;
  display: flex;
  flex-flow: wrap;
  ${({ center }) =>
      center &&
      `
    align-content: center;
    justify-content: center;
  `}
`;

const StyledGridColumn = styled.div<IGrid>`
  flex-basis: 50%;
  ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis}%`};
  ${({ columns }) => columns && `flex-basis: ${100 / columns}%`};
  ${({ colPadding }) => colPadding && `padding: ${colPadding}`};
  @media (max-width: ${LEGACY_BREAKPOINTS.mobile}px) {
    ${({ sm }) => sm && `
      flex-basis: ${100 / sm}%;
      padding: 0 0 32px 0;
    `}
  }
  @media (min-width: ${LEGACY_BREAKPOINTS.mobile}px) and (max-width: ${LEGACY_BREAKPOINTS.tablet}px) {
    ${({ md }) => md && `
      flex-basis: ${100 / md}%;
    `}
  }
`;

export { StyledGrid, StyledGridColumn };
