import { StyledSpacer } from './styles';
import { ISpacer } from './types';

/**
 * @deprecated Use a margin or padding instead
 */
export const Spacer = ({ dataTestId, height, mobile }: ISpacer) => {
  return (
    <StyledSpacer data-testid={dataTestId} height={height} mobile={mobile} />
  );
};
