import React, {useEffect, useRef} from 'react'
import store from 'store'
import {useHistory, useParams} from 'react-router';
import {useLazyQuery, useMutation} from '@apollo/client';
import {useUiContext} from 'utils/useUiContext';

import {CheckoutSteps, QL_CHECKOUT_BASE} from '../graphQL/queries';
import {MU_RESUME_CHECKOUT, MU_SWAP_CHECKOUT, MU_CANCEL_EXISTING_TERMINATION} from '../graphQL/mutations';
import { filter, find, flatten, map } from 'lodash-es'
import {Modal} from 'components/common/Modal';

import {useErrorNavigator} from 'utils/useErrorNavigator'
import {useTrackCheckout} from 'utils/trackCheckout'
import useCustomerQuery from 'utils/useCustomerQuery';
import {useMerchantContext} from 'utils/useMerchantContext'
import { useSharedCustomer } from 'utils/useSharedCustomer';
import {track} from 'integrations/segment/events';
import mapCheckoutToSegmentProducts from 'utils/segment/data/mapCheckoutToSegmentProducts';
import { CheckoutMode } from '../enums/checkoutModes';

export const ProductSelector = () => {
  const {merchantId} = useMerchantContext();
  const {variantId, costSummaryId, subscriptionId} = useParams()
  const {setShowSummary} = useUiContext()
  const { readDomainCookie, removeDomainCookie, setDomainCookie, removeUserToken } = useSharedCustomer();
  const history = useHistory()
  const errorNav = useErrorNavigator()
  const checkoutToken = useRef(readDomainCookie('checkoutToken')) || useRef(store.get('checkoutToken'))
  const isAdditionalTech = readDomainCookie('checkoutContext') === CheckoutMode.ADDITIONAL_TECH;
  const isUpgrade = readDomainCookie('checkoutContext') === CheckoutMode.UPGRADE;

  const [checkoutCreateMutation] = useMutation(CheckoutSteps.step0.mutation, {
    update: (proxy, {data: {createCheckout: {errors, checkout}}}) => {
      parseResponse(checkout, errors)
    }
  })
  const [resumeCheckout] = useMutation(MU_RESUME_CHECKOUT, {
    update: (proxy, {data: {resumeCheckout: {checkout, errors}}}) => {
      if (errors && errors.length > 0) {
        errorNav('GraphQL resume validation error', {errors: errors})
      }
    }
  })
  const [swapCheckoutItem] = useMutation(MU_SWAP_CHECKOUT, {
    update: (proxy, {data: {swapCheckoutItem: {errors, checkout}}}) => {
      parseResponse(checkout, errors)
    }
  })

  const [customer, loadingCustomer] = useCustomerQuery(CheckoutSteps.step0.customer);

  const [loadCheckout, {data, loading, called}] = useLazyQuery(QL_CHECKOUT_BASE, {
    variables: {
      token: checkoutToken.current
    }
  })


  const checkoutCreate = (variantId, costSummaryId, subscriptionId) => {
    checkoutCreateMutation({
      variables: {
        variantId,
        costSummaryId,
        merchantId,
        originSubscriptionId: subscriptionId
      }
    })
  }

  const [cancelTermination] = useMutation(MU_CANCEL_EXISTING_TERMINATION);

  const {trackCheckout} = useTrackCheckout()

  const identifyCheckout = (checkout) => {
    const products = mapCheckoutToSegmentProducts(checkout);
    track('Product Added', {orderId: checkout.id, products});
    track('Checkout Started', {products});
    trackCheckout(checkout.token, [{category: 'ANALYTICS', provider: 'segment'}, {
      category: 'EXPERIMENTS',
      provider: 'raylo',
      contentReference: 'softsearch control'
    }], () => {
    });
  };

  function parseResponse(checkout, errors) {
    if (errors && errors.length > 0) {
      if (filter(errors, {code: 'sold_out'}).length > 0) {
        history.push(`/errors/sold-out`)
      } else if (filter(errors, {code: 'blank'}).length > 0) {
        history.push(`/errors/invalid-choice`)
      } else {
        errorNav('Unhandled error in GraphQL resume', {errors: errors})
      }
    } else if (checkout && checkout.state === 'ABANDONED') {
      identifyCheckout(checkout)
      resumeCheckout({
        variables: {
          checkoutToken: checkoutToken.current
        }
      })
    } else {
      identifyCheckout(checkout)
      store.set('checkoutToken', checkout.token)
      setDomainCookie('checkoutToken', checkout.token)
      setShowSummary(true)
      history.push(`/checkout`)
    }
  }

  useEffect(() => {
    // for logged in customers

    if (!loadingCustomer && merchantId) {

      if (customer && checkoutToken) {
        store.remove('checkoutToken');
        removeDomainCookie('checkoutToken');
        checkoutToken.current = undefined;
      }
      const additionalTechContext = isAdditionalTech || customer?.preApproval?.successful;

      if ((additionalTechContext || isUpgrade) && customer) {
        const item = find(
          flatten(map(customer.orders, 'items')),
          o => o.subscription && o.subscription.id === subscriptionId
        )
        // check for existing checkout tokens for upgrades
        if (item?.subscription?.upgrade?.checkout && isUpgrade) { // if the subscription in the params matches one that has an existing upgrade checkout token
          store.set('checkoutToken', item.subscription.upgrade.checkout.token)
          setDomainCookie('checkoutToken', item.subscription.upgrade.checkout.token)
          checkoutToken.current = item.subscription.upgrade.checkout.token
          loadCheckout()
        } else if (additionalTechContext && !isUpgrade) {
          setDomainCookie('checkoutContext', CheckoutMode.ADDITIONAL_TECH)

          if (customer?.preApproval?.checkout?.token) {
            store.set('checkoutToken', customer?.preApproval?.checkout?.token)
            setDomainCookie('checkoutToken', customer?.preApproval?.checkout?.token)
            checkoutToken.current = customer?.preApproval?.checkout?.token
            loadCheckout()
          } else {
            checkoutCreate(variantId, costSummaryId)
          }
        } else {
          if (subscriptionId) { // else if there is a subscription id but it's not associated to an existing checkout
            cancelTermination(
              {
                variables: { subscriptionId},
                onCompleted: () => {
                  // NOTE: we ignore any errors that happen in the cancellation.
                  // There might not be a termination, to cancel. If there is, and cancellation fails,c
                  // checkout creation will also fail and that's when we will handle the error.
                  // Generally we should be here only if there is no termination or the termination is cancellable
                  checkoutCreate(variantId, costSummaryId, subscriptionId)
              },
              onError: () => {
                // Once we do the cancelTermination on the backend ignore errors, we can remove the onError here
                checkoutCreate(variantId, costSummaryId, subscriptionId)
              },
            });
          } else { // else if there is no subscription id in the params
            history.push('/account');
          }
        }
      }
      else if (customer && (!additionalTechContext && !isUpgrade)) {
        history.push('/account');
      }
      // for new customers with abondoned checkouts
      else if (checkoutToken && checkoutToken.current) {
        loadCheckout()
      }
      // for new customers with no abondoned checkouts
      else {
        checkoutCreate(variantId, costSummaryId, subscriptionId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCustomer, merchantId, checkoutToken])

  useEffect(() => {
    if (called && !loading && !!data) {
      const checkout = data.checkout

      if (!checkout) {
        store.remove('checkoutToken')
        removeDomainCookie('checkoutToken')
        store.remove('invitationCode')
        errorNav('Checkout not present', {}) // TODO: Remove if noisy
      } else {
        if (checkout && checkout.customer && (!isAdditionalTech && !isUpgrade)) {
          store.remove('checkoutToken')
          removeDomainCookie('checkoutToken')
          store.remove('invitationCode')
          removeUserToken();

          checkoutCreate(variantId, costSummaryId)
        } else if (checkout && checkout.state === 'ABANDONED') {
          resumeCheckout({
            variables: {
              checkoutToken: checkoutToken.current
            }
          })
        } else if (checkout && checkout.state !== 'STARTED') {
          checkoutCreate(variantId, costSummaryId)
        } else {
          const item = checkout.items[0]
          const {costSummary, variant} = item
          if (variant.id === variantId && costSummary.id === costSummaryId) {
            setShowSummary(true)
            store.set('checkoutToken', checkout.token)
            setDomainCookie('checkoutToken', checkout.token)
            history.push(`/checkout`)
          } else {

            swapCheckoutItem({
              variables: {
                variantId: variantId,
                costSummaryId: costSummaryId,
                checkoutToken: checkoutToken.current,
                itemId: item.id,
                merchantId,
              }
            })
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, called, history, resumeCheckout, checkoutToken, variantId, costSummaryId, setShowSummary, swapCheckoutItem, checkoutCreateMutation])


  return (<Modal visible text="Loading..."/>)
}
