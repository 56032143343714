import styled from 'styled-components';
import { palette } from '@raylo-tech/raylopay-ui';

const StyledCheckoutStepsContainer = styled.div`
  background-color: ${({ theme }) => theme?.checkout?.wrapper?.backgroundColor};
`;

const StyledHeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 66.666vw;
  background-color: ${palette.white};

  @media screen and (max-width: 1024px) {
    width: 100vw;
  }
`;

export { StyledCheckoutStepsContainer, StyledHeaderWrapper };
