import * as React from 'react';

function CancelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" {...props}>
      <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#a)">
        <path d="M1 15.998a15 15 0 1 0 30 0 15 15 0 0 0-30 0ZM10 21.998l12-12M22 21.998l-12-12" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoCancelIcon = React.memo(CancelIcon);
export default MemoCancelIcon;
