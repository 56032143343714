import { RayloModal } from '@raylo-tech/raylopay-ui';
import { IExitModal } from './ExitModal.types';

export const ExitModal = ({ onClose, onResume, modalOpen }: IExitModal) => {
  return (
    <RayloModal
      dataTestId="exit-modal"
      modalOpen={modalOpen}
      handleCloseModal={onResume}
      title="Are you sure you want to exit?"
      copy={["If you exit now, you'll lose your progress and will have to start again."]}
      primaryButtonText="Exit"
      primaryButtonOnClick={onClose}
      secondaryButtonText="Resume"
      secondaryButtonOnClick={onResume}
    />
  );
};
