import { primaryColors } from 'styles/variables/colors';
import styled from 'styled-components';

const StyledInsuranceBanner = styled.div`
  & button {
    color: ${primaryColors.c01};
    border-color: ${primaryColors.c01};
  }
`;

export { StyledInsuranceBanner };
