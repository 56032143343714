import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledBulletList } from './styles';
import { IBulletList } from './types';
import { Fragment } from 'react';

export const BulletList = ({ dataTestId, listItems }: IBulletList) => {
  return (
    <StyledBulletList data-testid={dataTestId}>
      {listItems?.map((item, index) => (
        <Fragment key={index}>
          <Container key={item} padding={`0 0 0 ${px2Rem(4)}`} flexRow>
            <Icon
              name="CircleFill"
              color={primaryColors.c01}
              size={px2Rem(8)}
            />
            <Container padding="0 0 0 12px">
              <Copy color={primaryColors.c01} lineHeight={22}>
                {item}
              </Copy>
            </Container>
          </Container>
          <Spacer height={8} />
        </Fragment>
      ))}
    </StyledBulletList>
  );
};
