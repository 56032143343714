import { Container, Copy, Spacer } from '@raylo-tech/raylopay-ui';
import { primaryColors } from 'styles/variables/colors';
import TextLink from 'elements/TextLink';
import Icon from 'elements/Icon';
import { Button } from 'elements';
import { ITradeInView } from './types';
import { Modal } from 'components/common/Modal';
import { useUiContext } from 'utils/useUiContext';
import { ButtonTypes } from 'elements/Button/types';
import { StyledTradeInView } from './styles';
import { px2Rem } from 'utils/px2Rem';
import TradeInModalWithIcons from 'components/TradeInModalWithIcons';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const TradeInView = ({ dataTestId, isSmallScreen }: ITradeInView) => {
  const {
    setFormSubmitted,
    setTradeInAccepted,
    loading,
    loadingTwo,
    headlineTradeInValues,
  } = useStepTradeInContext();

  const {
    checkoutCategoryDisplayName,
  } = useCheckoutContext();

  const handleFormSubmit = () => setFormSubmitted(true);
  const { setShowModal, setShowTradeInModal, showTradeInModal } =
    useUiContext();

  const handleShowModal = () => {
    setShowModal(true);
    setShowTradeInModal(true);
  };

  if (loading || loadingTwo) {
    return <Modal visible text="Loading..." />;
  }

  const iconMap: { [category: string]: string } = {
    console: 'PricePromiseConsole',
  };

  const category = checkoutCategoryDisplayName.toLowerCase();

  const iconName = iconMap[category] ?? (isSmallScreen ? 'PricePromiseWithShadow' : 'PricePromise');

  const copyColor = primaryColors.c01;
  const linkCopyColor = primaryColors.b01;
  const buttonType = ButtonTypes.b01;

  return (
    <StyledTradeInView data-testid={dataTestId}>
      <Container
        backgroundColor={primaryColors.w01}
        alignCenter
        minHeight="100vh"
        padding={isSmallScreen ? `${px2Rem(160)} ${px2Rem(24)} ${px2Rem(24)} ${px2Rem(24)}` : `${px2Rem(160)} ${px2Rem(40)} ${px2Rem(40)} ${px2Rem(40)}`}
      >
        <Container noPadding maxWidth={px2Rem(414)}>
          <Copy bold color={copyColor} fontSize={24} lineHeight={30}>
            Get up to {headlineTradeInValues?.total?.formattedValue} cashback when you trade in your old {category}
          </Copy>
          <Spacer height={24} />
          <Copy color={copyColor} fontSize={16}>
            Average cashback is over £180, and faulty devices get cashback too.
          </Copy>
          <Spacer height={24} />
          <Container flexRow justifyCenter>
            <Icon name={iconName} size={px2Rem(260)} />
          </Container>
          <Container flexRow noPadding justifyCenter>
            <TextLink fontColor={linkCopyColor} onClick={handleShowModal}>
              <Copy bold>Trade in and get cashback in 3 easy steps</Copy>
            </TextLink>
          </Container>
          <Spacer height={48} />
          <Button
            fontSize={16}
            buttonColor={buttonType}
            size="large"
            dataTestId="selectTradeIn"
            onClick={() => setTradeInAccepted(true)}
          >
            GET INSTANT VALUATION
          </Button>
          <Spacer height={32} />
          <Container flexRow noPadding justifyCenter>
            <TextLink dataTestId="skipTradeIn" fontColor={linkCopyColor} onClick={handleFormSubmit}>
              No thanks
            </TextLink>
          </Container>
          <Spacer height={24} />
        </Container>
      </Container>

      {showTradeInModal && <TradeInModalWithIcons dataTestId="TradeInModalWithIcons" />}
    </StyledTradeInView>

  );
};
