import { palette } from './../../colors';
import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { CSS_FONT_WEIGHT } from '../../constants';

export const StyledRecommendationCard = styled.div`
  padding: ${px2Rem(24)};
  margin-top: ${px2Rem(16)};
  border-radius: ${px2Rem(4)};
  background-color: ${palette.white};
  cursor: pointer;
  min-width: ${px2Rem(245)};
  box-sizing: border-box;
`;

export const StyledRecommendationImage = styled.div`
  padding: ${px2Rem(24)};
  background-color: ${palette.charcoal[100]};
  border-radius: ${px2Rem(4)};
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: auto;
  }
`;

export const StyledRecommendationCardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: ${px2Rem(8)} 0;
  font-size: ${px2Rem(14)};
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  color: ${palette.charcoal[500]};
`;

export const StyledRecommendationCardText = styled.div`
  display: flex;
  align-items: end;
  font-size: ${px2Rem(14)};
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  color: ${palette.charcoal[400]};
`;
