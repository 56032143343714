import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { primaryColors, secondaryColors } from '../../styles/variables/colors';

const StyledSelectComponent = styled.div`
  div.css-1hb7zxy-IndicatorsContainer > span {
    display: none;
  }
  div.css-1s2u09g-control {
    height: ${px2Rem(56)};
    border-radius: 0;
    border: ${px2Rem(1)} solid ${secondaryColors.c03};
    color: ${primaryColors.c01};
  }
`;

export { StyledSelectComponent };
