import { IStepInsuranceContent } from './types';
import {
  Button,
  Copy,
  RayloModal,
  SectionMessageBase,
  SectionMessageWithIcon,
  Spacer,
  BorderWithLabel,
  RadioSelect,
  Container,
  Divider,
  BulletList,
  TextLink
} from '@raylo-tech/raylopay-ui';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { StyledStepInsurance } from './styles';
import { useState } from 'react';
import { Icon } from 'elements';
import classNames from 'classnames';
import { px2Rem } from 'utils/px2Rem';
import 'styles/modal.css';

const bulletItems = [
  'Loss & theft',
  'Accidental damage',
  'Liquid damage',
];

export const StepInsuranceContent = ({
  onRadioSelect,
  insuranceTotal,
  onSubmit,
  onShowPolicyInfo,
  merchantPrice,
}: IStepInsuranceContent
) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [insuranceSelected, setInsuranceSelected] = useState<boolean | null>(null);

  const handleInsuranceChange = (value: boolean) => {
    setInsuranceSelected(value);
    onRadioSelect?.(value);
  };

  const handleNext = () => {
    if (insuranceSelected) {
      onSubmit();
      return;
    }
    setModalOpen(true);
  };

  return (
    <StyledStepInsurance data-testid="step-insurance-updated">
      <Copy fontSize={24} lineHeight={30} color={primaryColors.c01} bold>
        What would you do if your device was <em>damaged, lost or stolen?</em>
      </Copy>
      <Spacer height={32} />
      <div style={{ paddingBottom: px2Rem(32) }}>
        <Copy fontSize={16} lineHeight={20} color={primaryColors.c01} bold>
          Adding protection now could help save you money later.
        </Copy>
      </div>
      <div
        onClick={() => handleInsuranceChange(true)}
        style={{ cursor: 'pointer' }}
      >
        <BorderWithLabel
          activeColor={primaryColors.b01}
          active={!!insuranceSelected}
          defaultColor={secondaryColors.c03}
          hoverColor={secondaryColors.c02}
          labelText="Recommended"
          labelColor={primaryColors.b01}
        >
          <Container padding="24px" width="auto">
            <RadioSelect dataTestId="with-insurance" active={!!insuranceSelected}>
              <Container>
                <Copy bold lineHeight={22} fontSize={18}>Raylo insurance</Copy>
                <Copy
                  color={secondaryColors.c02}
                  fontSize={16}
                  lineHeight={20}
                >
                  only <span>£{insuranceTotal}/mo</span>
                </Copy>
              </Container>
            </RadioSelect>
            <Spacer height={24} />
            <Divider />
            <Spacer height={24} />
            <BulletList items={bulletItems} bulletIcon="Success" paddingRight={12} marginBottom={12} />
            <Spacer height={16} />
            <div style={{ width: 'fit-content' }}>
              <TextLink fontSize={14} fontColor={primaryColors.b01} onClick={onShowPolicyInfo} dataTestId="show-policy-info">
                View Policy Summary
              </TextLink>
            </div>
          </Container>
        </BorderWithLabel>
      </div>
      <Spacer height={32} />
      <div
        className={classNames('no-insurance', { active: insuranceSelected === false })}
        onClick={() => handleInsuranceChange(false)}
        style={{ cursor: 'pointer' }}
      >
        <RadioSelect dataTestId="without-insurance" active={insuranceSelected === false}>
          <Copy bold lineHeight={22} fontSize={18}>No insurance cover</Copy>
        </RadioSelect>
        <Spacer height={24} />
        <Divider />
        <Spacer height={24} />
        <Container flexRow>
          <Icon name="OrangeAlert" size="24px" />
          <Copy fontSize={14} color={primaryColors.c01} margin={`0 0 0 ${px2Rem(12)}`}>
            Without insurance, the current cost to replace the device if lost or stolen will be {merchantPrice}.
          </Copy>
        </Container>
      </div>
      <Spacer height={32} />
      <SectionMessageBase
        content={(
          <Copy fontSize={14} lineHeight={20} color={primaryColors.c01}>
            Since it&apos;s tied to your Raylo lease, you won&apos;t be able to add insurance later.
          </Copy>
        )}
      />
      <Spacer height={32} />
      <Button onClick={handleNext} disabled={insuranceSelected === null} dataTestId="insurance-next-button">
        {insuranceSelected ? 'Add insurance' : 'Next'}
      </Button>
      {modalOpen && (
        <RayloModal
          dataTestId="insurance-policy-modal"
          modalOpen={modalOpen}
          title="Are you sure?"
          handleCloseModal={() => setModalOpen(false)}
          primaryButtonText="Back to insurance"
          primaryButtonOnClick={() => {
            setModalOpen(false);
            setInsuranceSelected(null);
          }}
          secondaryButtonText="Continue with no insurance"
          secondaryButtonOnClick={() => {
            setModalOpen(false);
            onSubmit?.();
          }}
        >
          <Copy fontSize={16} lineHeight={20} color={primaryColors.c01}>
            Raylo insurance is great value and hassle-free. Plus, your monthly price stays fixed over the term.
          </Copy>
          <Spacer height={24} />
          <SectionMessageWithIcon
            icon="RayloDesignInfo"
            iconSize={16}
            header="Did you know?"
            bulletList={[
              "Most home insurance policies don't cover loss and theft outside your home.",
              'A claim on your home insurance could raise your premium with high excesses.',
            ]}
          />
        </RayloModal>
      )}
    </StyledStepInsurance>
  );
};
