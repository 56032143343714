import { Badge, Button, Copy, TextLink } from '@raylo-tech/raylopay-ui';
import ContinueIcon from '../Icons/ContinueIcon';
import { primaryColors } from '../../../../styles/variables/colors';
import React, { ReactElement } from 'react';
import UpgradeIcon from '../Icons/UpgradeIcon';
import dayjs from 'dayjs';
import CancelIcon from '../Icons/CancelIcon';
import PurchaseIcon from '../Icons/PurchaseIcon';

export const endOfLeaseOptionsContent = ({
  formattedMonthlyAmount,
  monthlyPaymentDate,
  finalPaymentDate,
  productName,
  handleUpgrade,
  handleCancel,
}: {
  formattedMonthlyAmount: string;
  monthlyPaymentDate: string;
  finalPaymentDate: string;
  productName: string;
  handleUpgrade: () => void;
  handleCancel: () => void;
}): EndOfLeaseOptionsContent[] => {
  const ReachedEndOfLease = dayjs(finalPaymentDate).isBefore(dayjs());

  return [
    {
      type: 'continue',
      badge: (
        <Badge
          type="rounded"
          color="darkGrey"
          fontSize={12}
          textTransform="uppercase"
          fontWeight="var(--font-weight-regular)"
        >DEFAULT
        </Badge>
      ),
      icon: ContinueIcon,
      title: 'Continue leasing',
      subTitle: 'Same device and price, more flexibility',
      bannerTitle: 'Continue',
      copy: 'You don’t need to do anything, simply carry on paying monthly for your current device. Enjoy the flexibility of monthly rolling. Upgrade, cancel or purchase at any time.',
      sectionMessageTitle: `Price: Continue paying ${formattedMonthlyAmount}/mo`,
      sectionMessageBulletPoints: [
        <Copy key="end-of-lease-continue-bullet-point-1" color={primaryColors.c01} fontSize={14}>If you pay by Direct
          Debit, keep this set up to avoid missed payments and fees.
        </Copy>,
      ],
      footer: null,
      links: [
        {
          text: 'About continuing to lease',
          onClick: () => window.location.assign('https://help.raylo.com/en/articles/5402051-continue-leasing-the-same-device'),
        },
      ],
      cta: null,
    },
    {
      type: 'upgrade',
      badge: (
        <Badge
          type="rounded"
          color="blue"
          icon
          fontSize={12}
          textTransform="uppercase"
          fontWeight="var(--font-weight-regular)"
        >PRE-APPROVED
        </Badge>
      ),
      icon: UpgradeIcon,
      title: 'Upgrade',
      subTitle: 'Swap to any new device',
      bannerTitle: 'Upgrade',
      copy: 'Stay up-to-date with the latest tech, get the best value for money, and it’s greener too. Choose any new device and return your old one when you\'re ready.',
      sectionMessageTitle: 'Price: Free upgrade',
      sectionMessageBulletPoints: [
        <Copy key="end-of-lease-upgrade-bullet-point-1" color={primaryColors.c01} fontSize={14}>No upgrade fee. Simply
          pay the new monthly instalments for your chosen upgrade.
        </Copy>,
        <Copy key="end-of-lease-upgrade-bullet-point-2" color={primaryColors.c01} fontSize={14}>Free returns.
          You&apos;ll keep paying <span style={{ fontWeight: 700 }}>{formattedMonthlyAmount}/mo</span> until you
          return.
        </Copy>,
        <Copy key="end-of-lease-upgrade-bullet-point-3" color={primaryColors.c01} fontSize={14}>Once you return, you’ll
          make a final payment of <span style={{ fontWeight: 700 }}>{formattedMonthlyAmount}</span>, as payments are made
          monthly after usage.
        </Copy>,
      ],
      footer: `Upgrade is subject to eligibility. Return before your monthly payment date on the ${dayjs(monthlyPaymentDate).format('Do')} to avoid paying for the following month.`,
      links: [
        {
          text: 'About upgrading and returning',
          onClick: () => window.location.assign('https://help.raylo.com/en/articles/5402048-how-do-i-upgrade'),
        },
      ],
      cta: ReachedEndOfLease ? <Button onClick={handleUpgrade} medium>SEE UPGRADES</Button> : null,
    },
    {
      type: 'cancel',
      badge: null,
      icon: CancelIcon,
      title: 'Cancel',
      subTitle: `Cancel and return your ${productName}`,
      bannerTitle: 'Cancel',
      copy: 'We’ll be sad to see you go, but you’re welcome to come back anytime. Return your old device when you\'re ready.',
      sectionMessageTitle: 'Price: Free cancellation',
      sectionMessageBulletPoints: [
        <Copy key="end-of-lease-cancel-bullet-point-1" color={primaryColors.c01} fontSize={14}>No cancellation
          fee.
        </Copy>,
        <Copy key="end-of-lease-cancel-bullet-point-2" color={primaryColors.c01} fontSize={14}>Free returns. You&apos;ll
          keep paying <span style={{ fontWeight: 700 }}>{formattedMonthlyAmount}/mo</span> until you return.
        </Copy>,
        <Copy key="end-of-lease-cancel-bullet-point-3" color={primaryColors.c01} fontSize={14}>Once you return, you’ll
          make a final payment of <span style={{ fontWeight: 700 }}>{formattedMonthlyAmount}</span>, as payments are made
          monthly after usage.
        </Copy>,
      ],
      footer: `Return before your monthly payment date on the ${dayjs(monthlyPaymentDate).format('Do')} to avoid paying for the following month.`,
      links: [
        {
          text: 'About cancelling and returning',
          onClick: () => window.location.assign('https://help.raylo.com/en/articles/5920071-how-do-i-cancel-at-the-end-of-my-lease'),
        },
      ],
      cta: ReachedEndOfLease ? <Button onClick={handleCancel} dataTestId="cancel-lease" medium secondary>CANCEL</Button> : null,
    },
    {
      type: 'purchase',
      badge: null,
      icon: PurchaseIcon,
      title: 'Purchase',
      subTitle: `Pay to own your ${productName}`,
      bannerTitle: 'Purchase',
      copy: ReachedEndOfLease ? 'Contact our team to discuss purchasing your device.' : `Your purchase price will be available in your account from ${dayjs(finalPaymentDate).format('Do MMM YYYY')}. Contact our team to discuss purchasing your device.`,
      sectionMessageTitle: ReachedEndOfLease ?
          <UpgradablePurchaseSectionTitle /> :
        `Price: Available from ${dayjs(finalPaymentDate).format('Do MMM YYYY')}`,
      sectionMessageBulletPoints: [
        ReachedEndOfLease ? (
          <Copy key="end-of-lease-purchase-bullet-point-1" color={primaryColors.c01} fontSize={14}>The total price is made up
            of <span style={{ fontWeight: 700 }}>a purchase price + a final payment of {formattedMonthlyAmount}</span>, as
            payments are made monthly after usage.
          </Copy>
        ) : null,
        <Copy key="end-of-lease-cancel-bullet-point-1" color={primaryColors.c01} fontSize={14}>The purchase price is a
          one-off payment based on what it costs Raylo to replace the device in our inventory.
        </Copy>,
        <Copy key="end-of-lease-cancel-bullet-point-2" color={primaryColors.c01} fontSize={14}>Previous payments do not
          decrease the purchase price, because they only cover using the device.
        </Copy>,
      ].filter(a => a !== null) as React.ReactElement[],
      footer: 'Purchase is subject to eligibility. Prices can fluctuate weekly based on fair market value.',
      links: [
        {
          text: 'About purchasing',
          onClick: () => window.location.assign('https://help.raylo.com/en/articles/5701197-can-i-purchase-my-raylo-device-after-the-lease'),
        },
      ],
      cta: null,
    },
  ];
};

export type EndOfLeaseOptionsContent = {
  type: 'continue' | 'upgrade' | 'cancel' | 'purchase';
  badge: React.ReactElement | null;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.ReactElement>;
  title: string;
  subTitle: string;
  bannerTitle: string;
  copy: string;
  sectionMessageTitle: string | React.ReactElement;
  sectionMessageBulletPoints: string[] | React.ReactElement[];
  footer: string | null;
  links: {
    text: string;
    onClick: () => void;
  }[];
  cta: ReactElement | null;
};

const UpgradablePurchaseSectionTitle = () => {
  return (
    <Copy fontSize={14} color={primaryColors.c01} bold lineHeight={20}>
      Price: <TextLink href="https://help.raylo.com/en/articles/8218154-purchasing-your-device" fontSize={14}>Calculate your purchase price here </TextLink>
    </Copy>
  );
};
