import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';
import { useCheckoutDecisionContext } from '../hooks/useCheckoutDecisionContext';
import { DecisionAcceptedContent } from './DecisionAcceptedContent/DecisionAcceptedContent';
import { DecisionAcceptedMerchantContent } from './DecisionAcceptedContent/DecisionAcceptedMerchantContent';

export const DecisionAccepted = () => {
  const { loading, redirectUrl } = useCheckoutDecisionContext();

  if (loading) {
    return <LoadingScreen />;
  }

  if (redirectUrl) {
    return <DecisionAcceptedMerchantContent />;
  }
  return <DecisionAcceptedContent />;
};
