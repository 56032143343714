import { primaryColors } from 'styles/variables/colors';

export const terms = {
  border: 'none',
  subHeader: {
    color: primaryColors.c01,
  },
  copy: {
    color: primaryColors.c01,
  },
  table: {
    borderColor: primaryColors.c01,
  },
};
