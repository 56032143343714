import React from 'react';
import Container from '../../../elements/Container';
import Spacer from '../../../elements/Spacer';
import { StyledProductImageCard } from './ProductImageCard.styles';
import { IProductImageCard } from './ProductImageCard.types';

export const ProductImageCard = ({
  dataTestId,
  imageUrl,
}: IProductImageCard) => {
  return (
    <StyledProductImageCard data-testid={dataTestId}>
      <Container center noPadding width="100%">
        <Spacer height={24} aria-hidden="true" />
        <Container height="125px" width="100%" centerHorizontal>
          <Container
            backgroundImage={imageUrl}
            backgroundImageSize="contain"
            backgroundImagePosition="50%"
            height="100%"
          />
        </Container>
        <Spacer height={24} aria-hidden="true" />
      </Container>
    </StyledProductImageCard>
  );
};
