import { px2Rem } from '../../../utils/px2Rem';
import {
  primaryColors,
  secondaryColors,
  uiStateColors
} from '../../variables/colors';

export const stripeFormStyles = (isMobile: boolean) => {
  return {
    variables: {
      borderRadius: '0px',
    },
    rules: {
      '.Input': {
        padding: `${px2Rem(20)} ${px2Rem(12)}`,
        fontWeight: '400',
        fontSize: px2Rem(14),
        lineHeight: px2Rem(16),
        border: `${px2Rem(1)} solid ${secondaryColors.c03}`,
        color: primaryColors.c01,
        boxShadow: 'none',
      },
      '.Input::placeholder': {
        color: secondaryColors.c03,
      },
      '.Input--invalid': {
        border: `${px2Rem(1)} solid ${secondaryColors.c03}`,
        boxShadow: 'none',
        color: primaryColors.c01,
      },
      '.Input:focus': {
        border: `${px2Rem(1)} solid ${primaryColors.b01}`,
        boxShadow: `0 0 0 1px ${primaryColors.b01}`,
      },
      '.TermsText': {
        fontSize: px2Rem(14),
        lineHeight: px2Rem(17),
        color: secondaryColors.c02,
      },
      '.Label': {
        fontSize: px2Rem(16),
        lineHeight: px2Rem(16),
        fontWeight: '700',
        marginTop: isMobile ? px2Rem(12) : px2Rem(8),
        marginBottom: px2Rem(8),
      },
      '.Label--invalid': {
        color: uiStateColors.error,
      },
      '.Error': {
        margin: `${px2Rem(8)} 0`,
        fontSize: px2Rem(14),
        color: uiStateColors.error,
      },
      '.Tab': {
        borderRadius: px2Rem(5),
        boxShadow: 'none',
      },
      '.Tab--selected': {
        borderColor: primaryColors.b01,
        color: primaryColors.b01,
        boxShadow: `0 0 0 1px ${primaryColors.b01}`,
      },
      '.Tab--selected:focus': {
        borderColor: primaryColors.b01,
        color: primaryColors.b01,
        boxShadow: `0 0 0 1px ${primaryColors.b01}`,
      },
      '.TabIcon--selected': {
        fill: primaryColors.b01,
      },
      '.TabLabel--selected': {
        color: primaryColors.b01,
      },
    },
  };
};
