import React from 'react';

import { SoldOut } from 'screens/Errors/SoldOut';
import { InvalidChoice } from 'screens/Errors/InvalidChoice';
import { CatchAll } from 'screens/Errors/CatchAll';
import { Abandoned } from 'screens/Errors/Abandoned';
import { BadLogin } from 'screens/Errors/BadLogin';
import { NotReady } from 'screens/Errors/NotReady';

import { FRONTEND_URL } from 'utils/constants';
import { OuterWrapper } from 'styles/layout';

import { CheckoutProvider } from 'utils/useCheckoutContext';
import { Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react';
const SentryRoute = Sentry.withSentryRouting(Route);

export const ErrorRouter = () => {

  const goToFrontend = () => window.location = FRONTEND_URL
  const goToLoginPage = () => {
    if(FRONTEND_URL.endsWith('/')) {
      window.location = FRONTEND_URL + "login"
    } else {
      window.location = FRONTEND_URL + "/login"
    }
  }

  return (
    <OuterWrapper>
      <CheckoutProvider>
        <Switch>
          <SentryRoute path="/errors/not-ready">
            <NotReady />
          </SentryRoute>
          <SentryRoute path="/errors/sold-out">
            <SoldOut onClick={goToFrontend} />
          </SentryRoute>
          <SentryRoute path="/errors/invalid-choice">
            <InvalidChoice onClick={goToFrontend} />
          </SentryRoute>
          <SentryRoute path="/errors/oops">
            <CatchAll onClick={goToFrontend} />
          </SentryRoute>
          <SentryRoute path="/errors/login">
            <BadLogin onClick={goToLoginPage} />
          </SentryRoute>
          <SentryRoute path="/errors/expired">
            <Abandoned onClick={goToFrontend} />
          </SentryRoute>
          <SentryRoute path="/errors">
            <CatchAll onClick={goToFrontend} />
          </SentryRoute>
        </Switch>
      </CheckoutProvider>
    </OuterWrapper>
  )
}
