import { useEffect, useState } from 'react';
import { useMedia } from 'use-media';
import { useUiContext } from 'utils/useUiContext'
import {QL_HEADER} from 'screens/Checkout/graphQL/queries';
import { Header } from 'components/Checkout/Header';
import { getOptionValue } from 'utils/getOptionValue';
import useRayloQuery from 'utils/useRayloQuery'

export const PhoneHeader = ({ checkoutToken }) => {
  const { showSummary, setShowSummary } = useUiContext();
  const mobile = useMedia({maxWidth: 450});

  const { data: { checkout }, loading } = useRayloQuery(QL_HEADER, {
    variables: {
      token: checkoutToken,
    },
  })

  const [phone, setPhone] = useState({})

  useEffect(() => {

    if(checkout && checkout.items) {

      const variant = checkout.items[0].variant

      setPhone({
        model: variant.product.displayName,
        storage: getOptionValue(variant, 'storage').displayName,
        color: getOptionValue(variant, 'colour').displayName,
      })
    }

  }, [checkout])

  const closeModals = (event) => {
    event.preventDefault();
    setShowSummary(false)
  }


  if(loading) return (<div></div>)


  return (
    <Header
      summaryVisible={false}
      phone={phone}
      showSummary={showSummary}
      mobileHeader={mobile}
      onBack={closeModals}
      onToggle={() => setShowSummary(!showSummary)} />
  )
}
