import { primaryColors } from 'styles/variables/colors';

export const terms = {
  border: '1px solid #333',
  subHeader: {
    color: primaryColors.p01,
  },
  copy: {
    color: primaryColors.w01,
  },
  table: {
    borderColor: primaryColors.p01,
  },
};
