import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { rayloDark } from '../rayloDark';

export const form = {
  ...rayloDark.form,
  textInput: {
    ...rayloDark.form?.textInput,
    labelColor: primaryColors.c01,
    borderColor: secondaryColors.c03,
    color: primaryColors.c01,
    placeholderColor: secondaryColors.c03,
    focus: {
      borderColor: primaryColors.b01,
    },
  },
};
