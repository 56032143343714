import React from 'react';
import { Copy } from '@raylo-tech/raylopay-ui';
import { useHistory, useParams } from 'react-router';
import Spacer from 'elements/Spacer';
import Header from 'elements/Header';
import { primaryColors } from 'styles/variables/colors';
import { PaymentOutcomeScreen } from './PaymentOutcomeScreen';

export const ArrearsPaymentSuccess = () => {
  const { currency, amount } = useParams<{ currency: string; amount: string }>();

  const history = useHistory();

  return (
    <PaymentOutcomeScreen
      title="Payment successful"
      preContent={(
        <>
          <Header
            level={4}
            color={primaryColors.c01}
            fontSize={16}
            centered
          >
            Thank you
          </Header>
        </>
      )}
      mainContent={(
        <>
          <Copy fontSize={16} textAlign="center" bold>
            Amount paid: {currency}{parseFloat(amount).toFixed(2)}
          </Copy>
          <Spacer height={24} />
          <Copy fontSize={16} textAlign="center">
            Your account will be updated soon.
          </Copy>
        </>
      )}
      onClick={() => history.push('/account')}
      buttonText="view account overview"
      iconName="PaymentSuccessful"
    />
  );
};
