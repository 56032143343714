import styled from 'styled-components';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

export const StyledProductCard = styled.div`
  background-color: ${primaryColors.w01};
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.10);
  padding: ${px2Rem(16)};
  display: flex;
  flex-direction: row;

  img {
    width: ${px2Rem(79)};
    height: auto;
  }
`;
