import { PropsWithChildren } from 'react';
import { StyledTypography } from './styles';
import { TypographyProps } from './types';

export const Typography = ({
  variant,
  bold,
  medium,
  italic,
  color,
  element,
  children,
  ...otherProps
}: PropsWithChildren<TypographyProps>) => {
  const elementType = (() => {
    // If `element` is provided, use it as the element type
    if (element) {
      return element;
    }
    // If `variant` is provided and is a heading element, use it as the element type
    if (variant && ['h1', 'h2', 'h3', 'h4'].includes(variant)) {
      return variant;
    }
    // Otherwise, default to 'p', which is part of the `StyledTypography` component (`styled.p`)
  })();

  return (
    <StyledTypography
      as={elementType}
      $variant={variant}
      $bold={bold}
      $medium={medium}
      $italic={italic}
      $color={color}
      {...otherProps}
    >
      {children}
    </StyledTypography>
  );
};
