import styled from 'styled-components';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { CSS_GLOBALS } from 'styles/globalAppStyles';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

export const StyledCOS = styled.div`
  --cos-text-color: ${primaryColors.c01};
  --cos-link-color: ${primaryColors.b01};

  background-color: ${primaryColors.w01};
  min-height: calc(100vh - ${CSS_GLOBALS.vars.navBarHeight});
  width: 100%;
  padding: ${px2Rem(16)} ${px2Rem(24)} ${px2Rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media (min-width: ${LEGACY_BREAKPOINTS.desktop + 1}px) {
    padding: ${px2Rem(36)} ${px2Rem(24)};
  }
`;

export const StyledCOSSidebar = styled.div`
  --cos-text-color: ${primaryColors.w01};
  --cos-link-color: ${primaryColors.w01};

  display: none;
  position: fixed;
  left: 66.666vw;
  top: 0;
  width: 33.333vw;
  height: 100vh;
  padding: ${px2Rem(36)} ${px2Rem(24)};
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: ${primaryColors.b01};

  @media (min-width: ${LEGACY_BREAKPOINTS.desktop + 1}px) {
    display: flex;
  }
`;

export const StyledCOSMainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${px2Rem(24)};
  color: var(--cos-text-color);
  width: 100%;
  max-width: ${px2Rem(430)};
`;

export const StyledCOSItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${px2Rem(16)};
`;
