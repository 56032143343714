import styled from 'styled-components';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

export const ContinuousPaymentAuthorityWrapper = styled.div<{
  isMobile: boolean;
}>`
  ${({ isMobile }) => `
    padding: ${isMobile ? '100px' : '125px'} 24px;
    min-height: 100vh;
    width: 100%;
    background-color: ${primaryColors.w01};
    color: ${primaryColors.c01};
    display: flex;
    justify-content: center;

    .info-callout {
      line-height: ${px2Rem(20)};
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        height: 100%;
        background-color: ${secondaryColors.c05};
        width: ${px2Rem(20)};
        position: absolute;
        right: 0;
        bottom: 0;
        height: ${px2Rem(isMobile ? 200 : 300)};
      }
    }

    .cpa-body {
      padding-bottom: ${px2Rem(20)};
    }
  `}
`;
