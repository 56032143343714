import {
  ApolloClient,
  ApolloLink,
  Observable,
  InMemoryCache,
  createHttpLink
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'unfetch';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { MU_TOKEN } from './useClientContext';
import { GRAPHQL_ENDPOINT, CLIENT_ID, FRONTEND_URL } from './constants';
import store from 'store'
import { captureErrorEvent } from './errorTracking'
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/react';

const httpOptions = { uri: GRAPHQL_ENDPOINT, fetch: fetch }

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = store.get('token');

  const userToken = store.get('userToken')
  const override = store.get('override')

  let uri = GRAPHQL_ENDPOINT
  if (process.env.REACT_APP_DYNAMIC_GRAPHQL_ENABLED && store.get('endpoint')) {
    uri = store.get('endpoint')
  }

  operation.setContext(({ headers = {} }) => {

    const newHeaders = {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }

    if (userToken) {
      newHeaders['X-User-Token'] = userToken
    }

    if (override && override === 'Y') {
      newHeaders['Feature-Similar-Checkout-Details-Bypass-Enabled'] = true
    }

    return ({
      headers: newHeaders,
      uri
    })
  });
  return forward(operation);
})

const sentryTransactionMiddleware = new ApolloLink((operation, forward) => {
  const type = operation.query.definitions[0].operation
  if (type === "mutation") {
    return Sentry.startSpan({ name: `${type}-${operation.operationName}` }, () => forward(operation))
  } else {
    return forward(operation);
  }
})



const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.type) {
        case 'UNPROCESSABLE': {
          store.remove('checkoutToken'); // is this going to cause an issue
          window.location = FRONTEND_URL
          return null
        }
        case 'NOT_READY': {
          captureErrorEvent("GraphQL NOT_READY Error", err)
          window.location = `/errors/not-ready`
          return null
        }
        case 'UNAUTHORIZED':

          if (err.authorizations && err.authorizations.client && !err.authorizations.user) {
            captureErrorEvent(
              "[GraphQL UNAUTHORIZED Error]",
              { graphQLErrors: graphQLErrors, networkError: networkError, operation: operation, forward: forward, error: err }
            )

            store.remove('userToken') // is this going to cause an issue if it's still in the cookies but has been removed here?
            window.location = "/login"
            return
          }

          return new Observable(observer => {
            apolloSimpleClient.mutate({
              mutation: MU_TOKEN,
              variables: { clientId: CLIENT_ID }
            }).then(({ data: { authenticateClient: { clientAuthToken } } }) => {

              store.set('token', clientAuthToken);
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  // Re-add old headers
                  ...headers,
                  // Switch out old access token for new one
                  authorization: `Bearer ${clientAuthToken}` || null,
                }
              }))

            }).then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
              }
              // Retry last failed request
              forward(operation).subscribe(subscriber)
            }).catch(error => {
              // No refresh or client token available, we force user to login
              observer.error(error)
            })
          })
        default:
          console.error(`GraphQL error from ${operation.operationName} query`, err)

          captureErrorEvent(
            `GraphQL error from ${operation.operationName} query`,
            { graphQLErrors: graphQLErrors, networkError: networkError, operation: operation, forward: forward, error: err }
          )
      }
    }
  }
});

const httpLink = createHttpLink(httpOptions)
const batchHttpLink = new BatchHttpLink(httpOptions)

const sentryLink = new SentryLink({
  setTransaction: false,
  attachBreadcrumbs: {
    includeVariables: false,
    includeResponse: false,
    includeError: true,
    includeQuery: true
  }
});

const maxRetryAttempts = 5;
const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (count === 1) {
      console.error(`GraphQL network error - query: ${operation.operationName}. Retrying`);
    } else if (count === maxRetryAttempts) {
      console.error(`GraphQL network error - query: ${operation.operationName}. Maximum retry limit reached.`);
      captureErrorEvent(
        `GraphQL network error - query: ${operation.operationName}`,
        { graphQLErrors: error, operation: operation, }
      );
    }
    return !!error && count !== maxRetryAttempts;
  }
});

const cache = new InMemoryCache()

let links = [
  sentryTransactionMiddleware,
  sentryLink,
  authMiddleware,
  retryLink,
  errorLink
]

if (process.env.REACT_APP_BATCH_GRAPHQL_ENABLED) {
  links.push(batchHttpLink)
} else {
  links.push(httpLink)
}

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from(links),
  cache,
  resolvers: {}
})

export const freshApolloClient = new ApolloClient({
  link: ApolloLink.from(links),
  cache: new InMemoryCache()
})

const apolloSimpleClient = new ApolloClient({
  link: httpLink,
  cache
})
