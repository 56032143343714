import { px2Rem } from '../../../utils/px2Rem';
import styled from 'styled-components';
import { SkeletonLoadingBlockProps } from './SkeletonLoadingBlock.types';
import { palette } from '../../../colors';

const StyledSkeletonLoadingBlock = styled.div<SkeletonLoadingBlockProps>`
  ${({ $width, $height, $borderRadius }) => `
      @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
      }
      height: ${$height ? px2Rem($height) : '100%'};
      width: ${$width ? px2Rem($width) : '100%'};
      position: relative;
      overflow: hidden;
      background-color: ${palette.charcoal[100]};
      border-radius: ${px2Rem($borderRadius ?? 0)};

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
        animation: shimmer 2s infinite;
        content: '';
      }
  `}
`;

export { StyledSkeletonLoadingBlock };
