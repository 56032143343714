import styled from 'styled-components';
import { CSS_FONT_WEIGHT } from '../../constants';

const StyledDualFontWeightHeader = styled.span`
  & span > span {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  }
`;

export { StyledDualFontWeightHeader };
