import styled from 'styled-components';
import colors from './colors';
import { secondaryColors } from './variables/colors';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';


const RayloInsuranceText = styled.div`
  color: ${colors.rayloLight};

  h1, h2, h3 {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    margin: 3em 0 1em 0;
    line-height: 1.625;
  }
  h1 {
    font-size: 1.1em;
    margin-top: 0;
  }
  h2 {
    font-size: 0.875em;
    color: ${colors.rayloPink};
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};

  }
  h2 + h3 {
    margin-top: 0em;
  }
  h3 {
    font-size: 0.875em;
  }

  th {
    color: ${colors.rayloPink};
  }

  td {
    color: ${colors.white};
  }

  td, th {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 0.875em;
  }


  a {
    color: ${secondaryColors.p02};
  }


  p, li {
    color: ${colors.rayloLight};
    font-size: 0.875em;
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    line-height: 1.625;
    margin-bottom: 1em;

    span.generated {
      color: ${secondaryColors.p02};
    }
  }
  li {
    margin-bottom: 0;
  }
`

const RayloLegalText = styled.div`
  color: ${({theme}) => theme?.terms?.copy?.color};
  border: ${({theme}) => theme?.terms?.border};
  padding: 10px;
  margin-bottom: 20px;

  @media (min-width: 541px) {
    ${({lightMode}) => !lightMode && `
      height: 400px;
      overflow-y: scroll;
    `}
  }

  table.important {
    border-collapse: collapse;
    margin: 2em 0;
    border: 1px solid ${({theme}) => theme?.terms?.table?.borderColor};
  }

  table.important td, table.important th {
    padding: 10px;
    vertical-align: top;
    text-align: left;
  }

  table.non-return-fees {
    border-collapse: collapse;
    border: 1px solid #6C6C6C;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  table.non-return-fees td, table.non-return-fees th {
    padding: 4px;
    vertical-align: top;
    text-align: center;
  }
  table.non-return-fees td {
    border-bottom: 1px solid #6C6C6C;
  }

  table.damage {
    border-collapse: collapse;
    border: 1px solid #6C6C6C;
    margin-top: 2em;
  }

  table.damage td, table.damage th {
    padding: 4px;
    vertical-align: top;
    text-align: left;
  }
  table.damage td {
    border-bottom: 1px solid #6C6C6C;
  }

  h1, h2, h3 {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    margin: 3em 0 1em 0;
    line-height: 1.625;
  }
  h1 {
    font-size: 1.1em;
    margin-top: 0;
  }
  h2 {
    font-size: 0.875em;
    color: ${({theme}) => theme?.terms?.subHeader?.color};
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};

  }
  h2 + h3 {
    margin-top: 0em;
  }
  h3 {
    font-size: 0.875em;
  }

  th {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 0.875em;
    color: ${({theme}) => theme?.terms?.subHeader?.color};

  }

  table.centered {
    text-align: center;
  }

  p {
    font-size: 0.875em;
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    line-height: 1.625;
    margin-bottom: 1em;

    span.generated {
      color: ${({theme}) => theme?.terms?.subHeader?.color};
    }
  }

  ::-webkit-scrollbar {
    ${({lightMode}) => !lightMode && `
      -webkit-appearance: none;
      width: 10px;
      background:#333;
    `}
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: ${colors.rayloPink};
  }
`

export const RayloLegalTable = styled.table`
  border-collapse: collapse;
  border:1px solid ${colors.raylo3Dark};
  margin: 30px 0;


  * {
    text-align: left;
  }

  td, th {
    border: 1px solid ${colors.raylo3Dark};
    padding: 7px;
  }

  th:first-of-type {
    width: 60%;
  }

  .double-border {
    border-top: 2px solid ${colors.raylo3Dark};
  }
`
