import { useHistory, useParams } from 'react-router'
import { find } from 'lodash-es'
import * as A from 'styles/account';
import * as T from 'styles/type';
import { AccountFooter } from 'components/Account/AccountFooter';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackButton } from 'components/common/BackButton';
import { Button } from 'components/common/Button';
import { PolicyBanner } from 'components/Account/PolicyBanner';
import { Link } from 'components/common/Link/Link';
import { QL_CLAIM } from './queries';
import { Modal } from 'components/common/Modal';
import { Helmet } from 'react-helmet';
import useInsurance from './useInsuranceContext';

export const InsuranceStolen = () => {
  const { orderId } = useParams()
  const { policy, loading } = useInsurance({ query: QL_CLAIM, isLostOrStolen: true, orderId });
  const history = useHistory()

  if (loading || !(policy && policy.id)) return (<Modal visible text="Loading..." />)
  const existingClaim = find(policy.claims, o => o.reason.enum === "THEFT" && o.state === "STARTED")

  return (
    <>
      <Helmet><title>Theft - Raylo</title></Helmet>
      <A.AccountWrapper>
        <A.AccountForm>
          <A.AccountFormContainer>
            <FadeIn>
              <div style={{ marginBottom: 33 }}><BackButton withText onClick={() => history.push(`/account/insurance/${orderId}`)} />
              </div>
            </FadeIn>

            <FadeIn>
              <div style={{ marginBottom: 40 }}>
                <T.RayloBodyHeading style={{ marginBottom: 8 }}>Theft</T.RayloBodyHeading>
                <T.RayloSubHeading>Before you make a claim, there are a few things you should know:</T.RayloSubHeading>
              </div>
            </FadeIn>

            <SlideDownFadeIn>
              <T.Steps>
                <li><strong>There is an excess of £125 that you will have to pay</strong></li>

                <li>
                  <strong>Within 24 hours confirm that the device has been stolen.</strong> <br></br>If your device is a phone we will block the IMEI for you.
                </li>
              </T.Steps>
            </SlideDownFadeIn>

            {existingClaim ?
              <>
                <SlideDownFadeIn>
                  <div style={{ marginBottom: 20, marginTop: 20 }}>
                    <T.RayloWarningText>You already have a claim in progress.</T.RayloWarningText>
                  </div>
                </SlideDownFadeIn>

                <SlideDownFadeIn>
                  <Button buttonStyle="secondary" onClick={() => history.push(`/account/insurance/${orderId}`)}>Back</Button>
                </SlideDownFadeIn>
              </>
              :

              <SlideDownFadeIn>
                <Button buttonStyle="primaryBlue" onClick={() => history.push(`/account/insurance/stolen/block/${orderId}`)}>Continue</Button>
              </SlideDownFadeIn>
            }

            <SlideDownFadeIn>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <T.RayloBodyType>Any questions? <Link href="" onClick={() => window.Intercom('show')}>Contact Us</Link></T.RayloBodyType>
              </div>
            </SlideDownFadeIn>
          </A.AccountFormContainer>
        </A.AccountForm>
      </A.AccountWrapper>

      <PolicyBanner />

      <AccountFooter />
    </>
  )
}
