import { ReactNode } from 'react';
import ITestDataId from 'types/ITestDataId.type';

export enum ButtonTypes {
  p01 = 'P01',
  p02 = 'P02',
  b01 = 'B01',
  w01 = 'W01'
}

export interface IButton extends ITestDataId {
  text?: string;
  onClick?: () => void;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  buttonColor?: ButtonTypes;
  active?: boolean;
  disabled?: boolean;
  buttonType?: 'solid' | 'ghost';
  type?: 'button' | 'submit' | 'reset' | undefined;
  formId?: string;
  fullWidth?: boolean;
  selected?: boolean;
  children?: ReactNode;
  iconText?: boolean;
  fontSize?: number;
  styles?: string;
}
