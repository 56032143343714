import { px2Rem } from 'utils/px2Rem';
import styled, { keyframes } from 'styled-components';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';

const rotate = keyframes`
  0% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
  33% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
  100% { -webkit-transform: rotate(10deg); transform:rotate(10deg); }
`;

const StyledAmazonCardAnimation = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  & img {
    width: 100%;
  }
  @media (min-width: ${LEGACY_BREAKPOINTS.mobile}px) and (max-width: ${LEGACY_BREAKPOINTS.desktop}px) {
    & img {
      width: 80%;
      margin-left: ${px2Rem(32)};
      margin-top: ${px2Rem(32)};
    }
  }
`;

const StyledAmazonCard = styled.div`
  position: absolute;
  right: 32px;
  top: 50px;
  transform: rotate(10deg);
  animation: ${rotate} 1500ms linear;
  @media (max-width: ${LEGACY_BREAKPOINTS.tablet}px) {
    top: -20px;
  }
`;
const StyledAmazonCardBottom = styled.div`
  position: absolute;
  right: 32px;
  top: 50px;
  @media (max-width: ${LEGACY_BREAKPOINTS.tablet}px) {
    top: -20px;
  }
`;

export { StyledAmazonCardAnimation, StyledAmazonCard, StyledAmazonCardBottom };
