import styled from 'styled-components';
import { IProgressBar } from './ProgressBar.types';
import { palette } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';

export const StyledProgressBar = styled.div<IProgressBar>`
  background-color: ${({ colour }) => colour || palette.pink[300]};
  width: ${({ currentProgress, totalProgress }) => `${(currentProgress / totalProgress) * 100}%`};
  height: ${px2Rem(4)};
  transition: width 0.5s ease-in-out;
`;
