import { FadeIn } from 'components/animations/Transitions';
import { Modal } from 'components/common/Modal';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { useEffect, useState } from 'react';
import { useCheckoutAddressContext } from 'screens/Checkout/containers/StepAddress/hooks/useCheckoutAddressContext';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { primaryColors, uiStateColors } from 'styles/variables/colors';
import AddressCheck from '../AddressCheck';
import AddressNew from '../AddressNew';
import { IStepAddressContent } from './types';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const StepAddressContent = ({
  dataTestId,
  onSuccess,
}: IStepAddressContent) => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'your details',
    checkoutScreen: 'add address',
    when: 'on page load',
  });
  const {
    loading,
    address,
    addressCheck,
    newAddress,
    setAddressCheck,
    addPreviousAddress,
    formSubmitted,
    editAddress,
    formErrors,
  } = useCheckoutAddressContext();
  const { checkoutMode } = useCheckoutContext();
  const [newCustomer, setNewCustomer] = useState(false);

  useEffect(() => {
    if ((address || newAddress) && !addPreviousAddress) {
      setAddressCheck(true);
    }
  }, [address, newAddress]);

  useEffect(() => {
    if (formSubmitted && !addPreviousAddress) {
      onSuccess();
    }
  }, [formSubmitted, addPreviousAddress]);

  useEffect(() => {
    setNewCustomer(checkoutMode === CheckoutMode.NEW_CUSTOMER);
  }, [checkoutMode]);

  if (loading) {
    return <Modal visible text="Loading..." />;
  }

  return (
    <FadeIn>
      <>
        <Header level={2} color={primaryColors.c01} dataTestId={dataTestId}>
          {newCustomer && !addPreviousAddress && 'Your details continued'}
          {!editAddress && !newCustomer && 'Is this address correct?'}
          {addPreviousAddress && 'Previous Address'}
        </Header>
        {addressCheck ? <AddressCheck /> : <AddressNew />}
        {formErrors?.map(({ message, index }: any) => (
          <FadeIn key={index}>
            <div style={{ marginBottom: 48 }}>
              <Copy color={uiStateColors.error}>
                {message || <span>&nbsp;</span>}
              </Copy>
            </div>
          </FadeIn>
        ))}
      </>
    </FadeIn>
  );
};
