import { Customer } from 'graphql/generated/graphql';

// Define the message format
type MessageFormat<A extends string, P extends unknown | undefined = unknown> = {
  action: A;
  payload: P;
};

// Create a union type of all possible messages
type Message =
  MessageFormat<'USER_IS_LOGGED_IN', boolean> |
  MessageFormat<'USER_HAS_LOGGED_OUT', boolean> |
  MessageFormat<'CUSTOMER_DATA_RECEIVED', Customer>;

export const sendMessageToApp = (message: Message) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  }
};
