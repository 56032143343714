import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { IHeader } from './Header.types';
import { CSS_FONT_WEIGHT } from '../../constants';

const StyledHeader = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }
`;

const StyledHeaderContent = styled.span<IHeader>`
  ${({ centered }) =>
      centered &&
      `
  display: block;
  text-align: center;
  `}
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${px2Rem(lineHeight)};`}
  & span {
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    ${({ subColor }) => subColor && `color: ${subColor};`}
    ${({ subFontWeight }) => subFontWeight && `font-weight: ${CSS_FONT_WEIGHT.vars.regular};`}
  }
`;

export { StyledHeaderContent, StyledHeader };
