import { Helmet } from 'react-helmet';
import * as T from 'styles/type';
import * as L from 'styles/layout';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';
import { PhoneHeader } from 'screens/Checkout/components/PhoneHeader';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const DecisionReferred = () => {
  const { checkoutToken } = useCheckoutContext();

  return (
    <div className="checkout--index">
      <Helmet>
        <title>Extra Checks - Raylo</title>
      </Helmet>
      {process.env.NODE_ENV !== 'test' && (
        <div className="checkout--header">
          <PhoneHeader checkoutToken={checkoutToken} />
        </div>
      )}
      <div className="checkout--main">
        <div className="checkout--step">
          <L.SignupFeedbackContainer>
            <SlideDownFadeIn>
              <div>
                <T.RayloBodyHeading>
                  <strong>
                    We need to run a few extra checks before we can complete
                    your order.
                  </strong>
                </T.RayloBodyHeading>
                <T.RayloBodyType style={{ marginBottom: 12 }}>
                  There’s nothing more for you to do at this stage and we will
                  be back in touch shortly.
                </T.RayloBodyType>
                <T.RayloBodyType>
                  Sorry for the delay, but we hope your device will be worth the
                  wait.
                </T.RayloBodyType>
              </div>
            </SlideDownFadeIn>
          </L.SignupFeedbackContainer>
        </div>
      </div>
      <FadeIn>
        <BlocksAnimation />
      </FadeIn>
    </div>
  );
};
