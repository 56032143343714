import styled from 'styled-components';
import { palette } from '../../colors';

const StyledTextWithIcon = styled.div``;

const StyledTextLinkWrapper = styled.div`
  font-size: 14px;
  color: ${palette.blue[500]};
  line-height: 12px;
`;

export { StyledTextWithIcon, StyledTextLinkWrapper };
