import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';
import styled from 'styled-components';
import { primaryColors, secondaryColors, uiStateColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

const StyledStepInsurance = styled.div`
  p {
    em {
      font-style: normal;
      color: ${secondaryColors.c02};
    }

    span {
      color: ${uiStateColors.success};
      font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    }
  }

  .no-insurance {
    padding: ${px2Rem(24)};
    box-shadow: 0 0 0 ${px2Rem(1)} ${secondaryColors.c03};
    border-radius: ${px2Rem(4)};

    &:hover {
      box-shadow: 0 0 0 ${px2Rem(2)} ${secondaryColors.c02};
    }

    &.active {
      box-shadow: 0 0 0 ${px2Rem(2)} ${primaryColors.b01};

      &:hover {
        box-shadow: 0 0 0 ${px2Rem(2)} ${primaryColors.b01};
      }
    }
  }
`;

export { StyledStepInsurance };
