export const errorDisplayMessages = [
  {
    code: 'email_verification_failed',
    displayMessage:
      "We couldn't verify your email address. Please check and try again",
  },
  {
    code: 'phone_number_verification_failed',
    displayMessage: 'Please enter a valid UK mobile phone number',
  },
];
