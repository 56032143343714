import Container from 'elements/Container';
import { StepTerms } from 'screens/Checkout/components/StepTerms';
import { IStepTerms } from './types';

export const StepTermsContainer = ({
  dataTestId,
  checkoutToken,
  onBack,
  onSuccess,
}: IStepTerms) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepTerms
        checkoutToken={checkoutToken}
        onBack={onBack}
        onSuccess={onSuccess}
      />
    </Container>
  );
};
