import { palette } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import { ASSETS_SVGS_BASE_URL, CSS_FONT_WEIGHT } from '../../../constants';
import styled from 'styled-components';

export const StyledDropdownSubMenu = styled.div<{
  $active: boolean;
  $dropdownHeight: number;
}>`
  ${({ $active, $dropdownHeight }) => `
    cursor: pointer;
    color: ${palette.white};
    position: relative;

    button {
      border: none;
      background: transparent;
      color: ${palette.white};
      padding: ${px2Rem(7.5)} ${px2Rem(8)};
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: ${px2Rem(14)};
      border-radius: ${px2Rem(4)};
      background-color: transparent;
      transition: background-color 0.3s ease-out;

      &:hover {
        background-color: ${palette.blue[400]};
      }

      &:after {
        content: '';
        display: block;
        background-image: url("${ASSETS_SVGS_BASE_URL}/chevron-down-white.svg");
        background-repeat: no-repeat;
        background-size: ${px2Rem(12)};
        background-position: center right;
        transition: transform 0.3s ease;
        transform: rotate(${$active ? '180deg' : '0deg'});
        transform-origin: center;
        width: ${px2Rem(12)};
        height: ${px2Rem(12)};
        margin: ${$active ? `0 ${px2Rem(5)} 0 0` : `0 0 0 ${px2Rem(5)}`};
        position: relative;
        left: ${px2Rem($active ? 2 : -2)};
      }
    }

    ul {
      display: block;
      transition: height 0.3s ease;
      padding: 0;
      list-style: none;
      margin: 0;
      z-index: 1000;
      background-color: ${palette.white};
      position: absolute;
      top: 100%;
      right: 0;
      border-radius: ${px2Rem(4)};
      box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
      height: ${$active ? px2Rem($dropdownHeight) : 0};
      overflow: hidden;
      min-width: ${px2Rem(300)};

      li {
        padding: 0;

        &:hover {
          background-color: ${palette.charcoal[100]};
        }
        a {
          padding: ${px2Rem(16)} ${px2Rem(24)};
          width: 100%;
          display: block;
          color: ${palette.charcoal[500]};
          text-wrap: nowrap;
          font-weight: ${CSS_FONT_WEIGHT.vars.medium};
        }
      }
    }
  `};
`;
