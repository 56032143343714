import styled from 'styled-components';
import colors from './colors';
import backIcon from 'public/back-icon.svg';
import checkboxTick from 'public/icon--checkbox-tick.svg';
import {px2Rem} from "../utils/px2Rem"
import {primaryColors} from "./variables/colors"
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

export const RayloButton = styled.button`
  display: inline-block;
  cursor: pointer;
    padding: ${props => props.limitWidth ? '0.875em 1em' : `${px2Rem(12)} ${px2Rem(20)}`};
  appearance: none;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  font-size: 0.8125em;
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  letter-spacing: 0.1px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;

  transition: all .35s;

  border-color: ${props => props.buttonStyle ? {
    primaryBlue: `${colors.rayloBlue}`,
    primaryPink: `${colors.rayloPink}`,
    darkPink: `${colors.rayloPink3Dark}`,
    outlined: `white`,
    secondary: primaryColors.w01,
  }[props.buttonStyle] : primaryColors.w01};

  background: ${props => props.buttonStyle ? {
    primaryBlue: `${colors.rayloBlue}`,
    primaryPink: `${colors.rayloPink}`,
    darkPink: `${colors.rayloPink3Dark}`,
    secondary: 'none',
    outlined: 'rgba(255, 255, 255, 0.2)',
  }[props.buttonStyle] : `${colors.rayloBlue}`};

  color: ${props => props.buttonStyle ? {
    primaryBlue: `${colors.rayloLight}`,
    primaryPink: `${colors.rayloDark}`,
    darkPink: `${colors.rayloLight}`,
    secondary: `${colors.rayloLight}`,
    outlined: `${colors.rayloLight}`,
  }[props.buttonStyle] : `${colors.rayloLight}`};

  :disabled {
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;

    :hover {
      background: none;
      border-color: rgba(255, 255, 255, 0.4);
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    padding: ${props => props.limitWidth ? '1em 1em' : '1em 2.25em'};
  }

  &:hover {
    border-color: ${props => props.buttonStyle ? {
      primaryBlue: `${colors.rayloBlue2Dark}`,
      primaryPink: `${colors.rayloPink3Light}`,
      secondary: 'rgba(255, 255, 255, 1)',
    }[props.buttonStyle] : 'rgba(255, 255, 255, 0.4)'};

    background: ${props => props.buttonStyle ? {
      primaryBlue: `${colors.rayloBlue2Dark}`,
      primaryPink: `${colors.rayloPink3Light}`,
    }[props.buttonStyle] : `${colors.rayloBlue}`};
  }

  ${props => props.alignRight ? `
    margin: 0 0 0 auto;
    display: block;
  ` : ''}
`

export const RayloBackButton = styled.a`
  display: inline-block;
  cursor: pointer;
  width: 44px;
  height: 44px;
  border: 1px solid ${colors.raylo3Dark};
  background-image: url('${backIcon}');
  background-repeat: no-repeat;
  background-position: center;
  transition: all .35s;

  &:hover {
    border: 1px solid ${colors.rayloLight};
  }
`

export const BackForwardContainer = styled.div`
  display: flex;
  align-items: center;
  height: 44px;

  ${RayloButton} {
    height: 100%;
  }
`

export const PhoneModelRadioButton = styled.div`
  display: inline-block;

  &:not(:last-of-type) {
    margin-right: 12px;
    margin-bottom: 12px;
  }

  @media (max-width: 799px) {
    display: block;
    width: 100%;
    &:not(:last-of-type) {
      margin-right: 0;
    }
  }

  @media (max-width: 340px) {
    display: block;

    &:not(:last-of-type) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  label {
    display: inline-block;
    cursor: pointer;
    padding: 0.625em 1em 0.6875em;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6);
    opacity: 0.6;
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 0.8125em;
    color: ${colors.rayloLight};
    letter-spacing: 0.1px;
    text-align: center;
    position: relative;
    transition: all .35s;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      box-shadow: 0 0 0 2px ${colors.rayloLight};
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      @media (min-width: 1025px) {
        opacity: 1;

        &::after {
          opacity: 1;
        }
      }
    }

    @media (max-width: 799px) {
      width: 100%;
    }
  }

  input {
    border: 0;
  	clip: rect(0 0 0 0);
  	height: 1px;
  	margin: -1px;
  	overflow: hidden;
  	padding: 0;
  	position: absolute;
  	width: 1px;
    outline: 0;
  }

  input:checked {
    & + label {
      box-shadow: 0 0 0 2px ${colors.rayloLight};
      opacity: 1;
    }
  }
`


export const TermRadioButton = styled.div`
  display: inline-block;

  &:not(:last-of-type) {
    margin-right: 12px;
    margin-bottom: 12px;
  }

  @media (max-width: 799px) {
    display: block;
  }

  @media (max-width: 340px) {
    display: block;
  }

  label {
    display: inline-block;
    cursor: pointer;
    padding: 0.625em 1em 0.6875em;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6);
    opacity: 0.6;
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 0.8125em;
    color: ${colors.rayloLight};
    letter-spacing: 0.1px;
    text-align: center;
    position: relative;
    transition: all .35s;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      opacity: 0;
      box-shadow: 0 0 0 2px ${colors.rayloLight};
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      @media (min-width: 1025px) {
        opacity: 1;

        &::after {
          opacity: 1;
        }
      }
    }

    @media (max-width: 799px) {
      width: 100%;
    }
  }

  input {
    border: 0;
  	clip: rect(0 0 0 0);
  	height: 1px;
  	margin: -1px;
  	overflow: hidden;
  	padding: 0;
  	position: absolute;
  	width: 1px;
    outline: 0;
  }

  input:checked {
    & + label {
      box-shadow: 0 0 0 2px ${colors.rayloLight};
      opacity: 1;
    }
  }
`


export const PhoneStorageRadioButton = styled.div`
  display: inline-block;
  &:not(:last-of-type) {
    margin-right: 20px;
  }

  @media (max-width: 400px) {
    display: block;

    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  label {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 48px;
    height: 48px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6);
    border-radius: 100%;
    opacity: ${props => props.outOfStock ? '0.2' : '0.6'};
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
    color: ${colors.rayloLight};
    font-size: 0.625em;
    letter-spacing: 0.08px;
    text-decoration: ${props => props.outOfStock ? 'line-through' : 'none'};
    text-align: center;
    transition: opacity .35s;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      opacity: 0;
      box-shadow: 0 0 0 2px ${colors.rayloLight};
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      @media (min-width: 1025px) {
        opacity: ${props => props.outOfStock ? '0.2': '1'};

        &::after {
          opacity: ${props => props.outOfStock ? '0': '1'};
        }
      }
    }
  }

  input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    outline: 0;
  }

  input:checked {
    & + label {
      opacity: 1;
      box-shadow: 0 0 0 2px ${colors.rayloLight};

      &::after {
        opacity: 1;
      }
    }
  }
`

export const CountrySelectRadioButton = styled.div`
  input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    outline: 0;

    &:checked + label {
      border: 1px solid ${colors.rayloLight};
      opacity: 1;
    }
  }

  label {
    display: inline-block;
    cursor: pointer;
    padding: 0.875em 2.25em;
    opacity: 0.7;
    border: 1px solid ${primaryColors.w01};
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 0.8125em;
    color: ${colors.rayloLight};
    letter-spacing: 0.1px;
    text-align: center;
    text-transform: uppercase;
    transition: all .35s;

    &:hover {
      border: 1px solid ${colors.rayloLight};
      opacity: 1;
    }
  }
`

export const PhoneColorRadioButton = styled.div`
  flex: 1;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  input {
    appearance: none;
    background: none;
    outline: 0;
    visibility: hidden;
    border: 0;
  	clip: rect(0 0 0 0);
  	height: 1px;
  	margin: -1px;
  	overflow: hidden;
  	padding: 0;
  	position: absolute;
  	width: 1px;

    &:checked {
      & + .color-dot {
        border: 2px solid ${colors.rayloLight};
      }
      & ~ span {
        opacity: 1;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    pointer-events: ${props => props.outOfStock ? 'none' : 'initial'};

    &:hover {
      @media (min-width: 1024px) {
        span {
          opacity: 1;
        }

        .color-dot {
          border: 2px solid ${colors.rayloLight};
        }
      }
    }
  }

  span {
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
    font-size: 0.875em;
    color: ${colors.rayloLight};
    letter-spacing: 0.11px;
    opacity: ${props => props.outOfStock ? '0.2' : '0.6'};
    transition: opacity .35s;

    text-decoration: ${props => props.outOfStock ? 'line-through' : 'none'};
  }

  .color-dot {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid #7277FF;
    margin-right: 20px;
    transition: border .35s;
    opacity: ${props => props.outOfStock ? '0.2' : '1'};

    &::after {
      content: ' ';
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      border: 1px solid #7277FF;
      top: 50%;
      left: 50%;
      margin-top: -8px;
      margin-left: -8px;
      background-color: ${props => props.hex ? props.hex : '#7277FF'};
    }
  }
`

export const MarketingRadioButton = styled.div`
  label {
    display: flex;
    align-items: center;

    input {
      appearance: none;
      outline: 0;
      display: inline-block;
      cursor: pointer;
      width: 16px;
      height: 16px;
      margin: 0;
      border: 1px solid ${colors.raylo3Dark};
      border-radius: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 75%;
      transition: all .35s;

      &:checked {
        background-image: url('${checkboxTick}');
      }
    }

    span {
      color: ${colors.rayloLight};
      font-size: 0.875em;
      line-height: 1.625;
      margin-left: 12px;
    }
  }
`

export const AccountButton = styled.button`
  display: inline-block;
  outline: 0;
  cursor: pointer;
  background: 0;
  padding: 6px 16px;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.75em;
  color: ${colors.rayloDark};
  border: 1px solid ${colors.rayloPink3Dark};
  letter-spacing: 0.09px;
  text-align: center;
  text-transform: uppercase;
  line-height: 2.14;
  transition: all .35s;

  &:hover {
    background-color: ${colors.rayloPink3Dark};
    color: ${colors.rayloLight};
  }
`

const PayNowButton = styled(AccountButton)`
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  border: 1px solid ${colors.rayloPink3Dark};
  text-transform: none;
`

export const BlueAccountButton = styled(AccountButton)`
  border: 1px solid ${colors.rayloBlue3Dark};

  &:hover {
    background-color: ${colors.rayloBlue};
    color: ${colors.rayloLight};
  }
`

const ViewFullStatement = styled(AccountButton)`
  border: 1px solid #E0E0E0;

  &:hover {
    background-color: #E0E0E0;
    color: ${colors.rayloDark};
  }
`

export const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -12px;

  > button {
    margin: 12px;
  }
`

export const UnderlineButton = styled.button`
  display: inline-block;
  cursor: pointer;
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  font-size: 14px;
  color: ${colors.rayloLight};
  line-height: normal;
  appearance: none;
  background: none;
  outline: 0;
  border: 0;
  padding: 0;

  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: ${colors.rayloPink};
    width: 100%;
    margin-top: ${props => props.inline ? '1px' : '6px'};
  }
`
