import gql from 'graphql-tag';

export const CUSTOMER_ANALYTICS_ID = gql`
  query CustomerAnalyticsId {
    customer {
      id
      analyticsId
    }
  }
`;
