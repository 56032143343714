import { sessionInfo as googleAnalytics4Session } from '../googleAnalytics4/tracking';

const additionalAnalytics = () => {
  const { clientId, sessionId } = googleAnalytics4Session();
  return {
    ga_client_id: clientId,
    ga_session_id: sessionId,
  };
};

export const track = (eventName: string, properties?: object): void => {
  analytics?.track(eventName, {
    ...properties,
    ...additionalAnalytics(),
  });
};

export const identify = (id: string, callback?: () => void): void => {
  analytics?.identify(id, { ...additionalAnalytics() }, callback);
};

export const reset = (): void => {
  analytics?.reset();
};
