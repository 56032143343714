import StepOpenBankingContent from 'screens/Checkout/components/StepOpenBankingContent';
import { StepOpenBankingProvider } from './hooks/useStepOpenBankingContext';

export const StepOpenBanking = () => {
  return (
    <StepOpenBankingProvider>
      <StepOpenBankingContent />
    </StepOpenBankingProvider>
  );
};
