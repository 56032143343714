import gql from 'graphql-tag';

export const ACCEPTABLE_DATA_SUBSCRIPTION_OPTION_ENUM = gql`
  query {
    __type(name: "AcceptableDataSubscriptionOption") {
      name
      enumValues {
        name
      }
    }
  }
`;
