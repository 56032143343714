import { ReactElement, useEffect, useState } from 'react';
import useMedia from 'use-media';
import CancelOrder from 'components/CancelOrder';
import CancelMerchantOrder from 'components/CancelMerchantOrder';
import { InfoBox } from 'components/Account/InfoBox';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { YourTechStatus } from 'screens/Account/YourTechStatus';
import {
  primaryColors,
  secondaryColors,
  uiStateColors
} from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { formatDisplayPrice } from 'utils/formatDisplayPrice';
import { StyledDeviceLeaseProfileCard, UpgradeIconWrapper } from './styles';
import { IDeviceLeaseProfileCard } from './types';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import pluralize from 'pluralize';
import { DeviceLeasePrimaryAction } from './DeviceLeasePrimaryAction';
import ImgixImage from 'elements/ImgixImage';
import { DeviceLeaseSecondaryAction } from './DeviceLeaseSecondaryAction';
import TextLink from 'elements/TextLink';
import { isTV } from 'screens/Account/utils/parseOrder.helper';

export const DeviceLeaseProfileCard = ({
  dataTestId,
  parsedOrder,
  subscriptionId,
  hasDeviceInArrears,
}: IDeviceLeaseProfileCard) => {
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });
  const [orderState, setOrderState] = useState<string>('');
  const { setDomainCookie, redirectToProductsApp } = useSharedCustomer();

  const upgradeAvailable: boolean = parsedOrder.upgradeAvailable && !hasDeviceInArrears;

  const cancelled = [YourTechStatus.orderCancelled, YourTechStatus.leaseCancelled].includes(orderState);

  useEffect(() => {
    setOrderState(parsedOrder.status);
  }, [parsedOrder, isMobile]);

  const upgradeOnClick = () => {
    setDomainCookie('checkoutContext', CheckoutMode.UPGRADE);
    setDomainCookie('subscriptionId', subscriptionId);
    redirectToProductsApp(pluralize.plural(parsedOrder.orderItem.variant.product.category.displayName.toLowerCase()));
  };

  const UpgradeIcon = () => (
    <UpgradeIconWrapper onClick={upgradeOnClick}>
      <Icon name="RoundArrowBack" color="white" size={px2Rem(22)} />
    </UpgradeIconWrapper>
  );

  const statusColour = (): string => {
    switch (orderState) {
      case YourTechStatus.arrears:
        return uiStateColors.error;
      case YourTechStatus.upgradeAvailable:
        return uiStateColors.success;
      default:
        return primaryColors.c01;
    }
  };

  const isStatusBold = (): boolean => {
    return [YourTechStatus.arrears, YourTechStatus.upgradeAvailable].includes(
      orderState
    );
  };

  const cancelOrder = (): void => {
    setOrderState(YourTechStatus.orderCancelled);
  };

  const statusText = (): string => {
    switch (orderState) {
      case YourTechStatus.arrears:
        return `${parsedOrder?.subscription?.arrearsAmount?.formattedValue} overdue`;
      case YourTechStatus.upgradeAvailable:
        return upgradeAvailable ? orderState : YourTechStatus.active;
      default:
        return orderState || '';
    }
  };

  const statusAction = (): ReactElement | null => {
    switch (orderState) {
      case YourTechStatus.orderPlaced:
        if (parsedOrder.directCancellationSupported) {
          const cancelNotAllowed = parsedOrder.fulfillment || isTV(parsedOrder.orderItem.variant.product.category);
          return cancelNotAllowed ? (
            <InfoBox
              linkText="Cancel order"
              title="Sorry, you can't cancel right now"
              content="You need to wait until your order has arrived, then you will be able to cancel."
              dataTestId="cancel-order-message"
            />
          ) : (
            <CancelOrder
              orderId={parsedOrder.orderId}
              onComplete={cancelOrder}
            />
          );
        } else {
          return (
            <CancelMerchantOrder
              merchantName={parsedOrder.merchant.displayName}
              merchantReference={parsedOrder.merchantReference}
            />
          );
        }
      default:
        return null;
    }
  };

  const isInArrears: boolean = orderState === YourTechStatus.arrears;

  const deviceImageUrl: string = `${parsedOrder.image?.url}?trim=color&auto=format&fm=webp`;

  return (
    <StyledDeviceLeaseProfileCard data-testid={dataTestId}>
      <Container
        flexRow={!isMobile}
        boxShadow
        padding="0"
        maxWidth={px2Rem(1220)}
        height={!isMobile ? px2Rem(371) : 'initial'}
        styles={{ position: 'relative' }}
      >
        <Container
          center
          backgroundColor={secondaryColors.b04}
          width={isMobile ? '100%' : '40%'}
          height={isMobile ? px2Rem(224) : undefined}
          styles={{ position: 'relative' }}
        >
          <ImgixImage
            alt={parsedOrder.model}
            src={deviceImageUrl}
            width={165}
            height={192}
            imageWidths={[165, 200, 250, 300, 350, 400, 416]}
            sizes={[
              `(max-width: ${LEGACY_BREAKPOINTS.mobile}px) 165px`,
              `(max-width: ${LEGACY_BREAKPOINTS.largeDesktop}px) calc(100vw * 0.4)`,
              '416px',
            ].join(', ')}
            loading="eager"
            styles={{
              height: '100%',
              width: isMobile ? 'auto' : undefined,
              objectFit: isMobile ? undefined : 'contain',
            }}
          />
          {upgradeAvailable && <UpgradeIcon />}
        </Container>
        <Container
          width={isMobile ? '100%' : '60%'}
          backgroundColor="#FFF"
          padding={px2Rem(24)}
        >
          <Spacer height={24} />
          <Header level={3} color={primaryColors.c01} fontSize={16}>
            {parsedOrder.model}
          </Header>
          <Spacer height={12} />
          <Copy>{parsedOrder.color}</Copy>
          <Spacer height={12} />
          <Copy>{parsedOrder.storage}</Copy>
          <Spacer height={24} />
          <Header level={3} color={primaryColors.c01} fontSize={16}>
            Status
          </Header>
          <Spacer height={12} />
          <Container padding="0" flexRow>
            {isInArrears && (
              <Icon
                name="RedAlert"
                styles={`
              display: inline-block;
              margin-right: ${px2Rem(8)};
              vertical-align: middle;
              `}
              />
            )}
            <Copy color={statusColour()} bold={isStatusBold()}>
              {statusText()}
            </Copy>
            &nbsp;
            {statusAction()}
          </Container>
          <Spacer height={24} />
          <DeviceLeasePrimaryAction
            order={parsedOrder}
            onUpgrade={upgradeOnClick}
            accountInArrears={hasDeviceInArrears}
          />
          <Header level={3} color={primaryColors.c01} fontSize={16}>
            Monthly payments
          </Header>
          <Spacer height={12} />
          <Copy dataTestId="monthly-payments">{cancelled ? 'None' : `£${formatDisplayPrice(parsedOrder.payMonthly)}`}</Copy>
          <Spacer height={24} />
          <TextLink link={`/account/end-of-lease-options/${parsedOrder.orderId}`} dataTestId="eol-options-link">
            See end-of-lease options
          </TextLink>
          <Spacer height={24} />
          <DeviceLeaseSecondaryAction
            order={parsedOrder}
            upgradeAvailable={upgradeAvailable}
            onUpgrade={upgradeOnClick}
          />
        </Container>
      </Container>
    </StyledDeviceLeaseProfileCard>
  );
};
