import React from 'react';
import * as C from 'styles/Card';

interface ICardProps {
  width?: number;
  styles?: string;
  children: React.ReactNode;
}

export const Card = ({ width, styles, children }: ICardProps) => (
  <C.RayloCard width={width} styles={styles}>
    {children}
  </C.RayloCard>
);
