import React from 'react';
import { palette } from '../../../colors';
import Container from '../../../elements/Container';
import Icon from '../../../elements/Icon';
import { px2Rem } from '../../../utils/px2Rem';
import { StyledCheckoutHeaderBar } from './CheckoutHeaderBar.styles';
import { ICheckoutHeaderBar } from './CheckoutHeaderBar.types';

export const CheckoutHeaderBar = ({ dataTestId }: ICheckoutHeaderBar) => {
  return (
    <StyledCheckoutHeaderBar data-testid={dataTestId}>
      <Container
        width="100vw"
        height={px2Rem(60)}
        backgroundColor={palette.blue[500]}
        centerVertical
        padding={`0 0 0 ${px2Rem(24)}`}
      >
        <Icon name="RayloLogo" dataTestId="RayloLogo" />
      </Container>
    </StyledCheckoutHeaderBar>
  );
};
