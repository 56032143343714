import React, { useEffect } from 'react';
import { activateOptimize } from 'utils/optimize'

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import { Button } from 'components/common/Button';
import { FadeIn } from 'components/animations/Transitions';
import { Helmet } from 'react-helmet';
import { useUiContext } from 'utils/useUiContext';
import Container from 'elements/Container';
import { primaryColors } from 'styles/variables/colors';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import DoubleButtonFullWidth from 'components/DoubleButtonFullWidth';
export const SoldOut = ({buttonStyle = 'primaryBlue', onClick}) => {

  const {lightMode} = useUiContext()
  
  useEffect(() => {
    activateOptimize()
  })

  if(lightMode) {
    return (
      <Container backgroundColor={primaryColors.w01}>
        <Header level={2} color={primaryColors.c01}>We’re sorry, but we’re sold out of that device</Header>
        <Spacer height={40} />
        <DoubleButtonFullWidth
          singleButton
          topButtonText='Pick Another'
          topButtonOnClick={onClick}
        />
      </Container>
    )
  }

  return(
  <L.ErrorContainer>
  <Helmet><title>Sold Out - Sorry - Raylo</title></Helmet>
    <FadeIn>
      <T.RayloBodyHeading><strong>We’re sorry</strong>, but we’re sold out of that device</T.RayloBodyHeading>
    </FadeIn>

    <FadeIn>
      <Button
        buttonStyle={buttonStyle}
        onClick={onClick}
        style={{ height: '44px' }}>
        Pick another
      </Button>
    </FadeIn>
  </L.ErrorContainer>
)}
