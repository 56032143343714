import useMedia from 'use-media';
import { useHistory } from 'react-router';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledInsuranceBanner } from './styles';
import { IInsuranceBanner } from './types';

export const InsuranceBanner = ({ dataTestId, orderId }: IInsuranceBanner) => {
  const history = useHistory();
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

  const handleOnClick = () => {
    history.push(`insurance/${orderId}`);
  };

  return (
    <StyledInsuranceBanner data-testid={dataTestId}>
      <Container
        alignCenter
        backgroundColor={secondaryColors.p05}
        boxShadow={!isMobile}
      >
        <Container
          width={isMobile ? 'initial' : px2Rem(415)}
          alignCenter
          padding="0"
          styles={{
            textAlign: 'center',
          }}
        >
          <Spacer height={12} />
          <Icon
            name="CheckShield"
            color={secondaryColors.p02}
            size={px2Rem(24)}
          />
          <Spacer height={12} />
          <Header level={3} color={primaryColors.c01}>
            Great news — your device is insured against damage, loss, and theft
          </Header>
          <Spacer height={16} />
          <Copy color={primaryColors.c01}>
            If anything bad happens to your device, <br /> we’ve got you covered
          </Copy>
          <Spacer height={24} />
          <Button
            text="make a claim"
            size="medium"
            buttonType="ghost"
            buttonColor={ButtonTypes.b01}
            onClick={handleOnClick}
          />
          <Spacer height={24} />
        </Container>
      </Container>
    </StyledInsuranceBanner>
  );
};
