import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { palette } from '@raylo-tech/raylopay-ui';

export const StyledLink = styled.a`
  color: ${palette.blue[500]};
  text-decoration: none;
  font-size: ${px2Rem(14)};
  line-height: ${px2Rem(20)};

  &:hover {
    text-decoration: underline;
  }
`;
