import gql from 'graphql-tag';

export interface ICheckoutDecisionQuery {
  checkout: ICheckout;
}

export interface ICheckout {
  id: string;
  state: string;
  decision: {
    id: string;
    outcome: string;
    outcomeReason: string;
  };
}
export const CHECKOUT_DECISION_QUERY = gql`
  query CheckoutDecisionQuery($checkoutToken: String!) {
    checkout(token: $checkoutToken) {
      id
      state
      decision {
        id
        outcome
        outcomeReason
      }
    }
  }
`;
