import { Container, Spacer } from 'elements';
import { CheckoutHeaderBar } from '@raylo-tech/raylopay-ui';
import { px2Rem } from 'utils/px2Rem';
import { StyledCheckoutWrapper } from './styles';
import { ICheckoutWrapper } from './types';

export const CheckoutWrapper = ({
  dataTestId,
  className,
  children,
}: ICheckoutWrapper) => {
  return (
    <StyledCheckoutWrapper className={className} data-testid={dataTestId}>
      <CheckoutHeaderBar />
      <Spacer height={64} />
      <Container width="100vw" centerVertical padding={`0 ${px2Rem(24)}`}>
        {children}
      </Container>
    </StyledCheckoutWrapper>
  );
};
