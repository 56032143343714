import styled from 'styled-components';

const StyledTradeInView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

export { StyledTradeInView };
