import React from 'react';
import Copy from 'elements/Copy';
import { useHistory } from 'react-router-dom';
import { PaymentOutcomeScreen } from 'screens/Account/Payment/ProcessingPayment/PaymentOutcomeScreen';

export const DepositPaymentFailure = () => {
  const history = useHistory();

  return (
    <PaymentOutcomeScreen
      title="Unfortunately we were unable to take payment "
      mainContent={(
        <>
          <Copy fontSize={16} textAlign="center" bold>
            Please try again making sure your details are correct
          </Copy>
          <Copy fontSize={16} textAlign="center">
            If your payment is still declined please speak with your bank.
          </Copy>
        </>
      )}
      onClick={() => history.push('/checkout/decision/pay-deposit')}
      buttonText="try again"
      iconName="PaymentUnsuccessful"
    />
  );
};
