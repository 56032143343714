import React from 'react';
import { StyledTrustpilotBullets } from './TrustpilotBullets.styles';
import { TrustpilotBulletsProps } from './types';
import Trustpilot from './trustpilot';

const TrustpilotBullets = ({ listItems }: TrustpilotBulletsProps) => (
  <StyledTrustpilotBullets>
    <ul>
      {listItems.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
    <Trustpilot />
  </StyledTrustpilotBullets>
);

export default TrustpilotBullets;
