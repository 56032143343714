import { useLazyQuery } from '@apollo/client'

const useRayloLazyQuery = (query, options = {}) => {
  const [getFunction, result] = useLazyQuery(query, options)
  return [
    getFunction,
    {
    ...result,
    data: result.data ? result.data : {}
    }
  ]

}

export default useRayloLazyQuery