import gql from 'graphql-tag';

export const MERCHANTS_QUERY = gql`
  query MerchantsQuery {
    merchants {
      id
      displayName
    }
  }
`;
