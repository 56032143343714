import Copy from 'elements/Copy';
import Spacer from 'elements/Spacer';
import Select from 'react-select';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledSelectComponent } from './styles';
import { ISelectComponent } from './types';

/**
 * @deprecated This component has been replaced with the `<SelectDropdown />` component in `@raylo-tech/raylopay-ui`.
 */
export const SelectComponent = ({
  dataTestId,
  options,
  onChange,
  value,
  label,
  subCopy,
  isSearchable,
}: ISelectComponent) => {
  const customStyles = {
    control: (base: object) => ({
      ...base,
      boxShadow: 'none',
      height: px2Rem(56),
      borderRadius: '0',
      // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles: object) => {
      return {
        ...styles,
        color: primaryColors.c01,
      };
    },
  };

  return (
    <StyledSelectComponent data-testid={dataTestId}>
      <Copy color={primaryColors.c01} bold>
        {label}
      </Copy>
      {subCopy && (
        <>
          <Spacer height={12} />
          {subCopy}
        </>
      )}
      <Spacer height={12} />
      <Select
        options={options}
        onChange={onChange}
        value={value ?? ''}
        styles={customStyles}
        isSearchable={isSearchable || true}
      />
    </StyledSelectComponent>
  );
};
