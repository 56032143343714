import { primaryColors, secondaryColors } from 'styles/variables/colors';

export const form = {
  toggle: {
    color: primaryColors.c01,
    borderColor: secondaryColors.c03,
    textColor: primaryColors.c01,
    activeColor: primaryColors.b01,
  },
  textInput: {
    labelColor: 'white',
    borderColor: 'white',
    color: 'white',
    placeholderColor: 'white',
    focus: {
      borderColor: 'white',
    },
  },
};
