import { getSessionStorage, setSessionStorage } from '../handleSessionStorage';

const USER_EMAIL = 'userEmail';
export const getUserEmail = (): string | null => {
  return getSessionStorage(USER_EMAIL);
};

export const setUserEmail = (email: string) => {
  setSessionStorage(USER_EMAIL, email);
};
