import { Typography } from '@raylo-tech/raylopay-ui';
import { StyledDropdown } from './styles';
import { px2Rem } from 'utils/px2Rem';

const FormDropdown = ({
  dataTestId,
  name,
  options,
  error,
  field,
  value,
  handleOnBlur,
  onChange,
}: {
  dataTestId?: string;
  name: string;
  options: { id: string; label: string }[];
  error?: string;
  field: string;
  value?: string;
  handleOnBlur: (type: string, field: string, required: boolean, name: string) => void;
  onChange: (field: string, value?: string) => void;
}) => (
  <div style={{ marginBottom: px2Rem(30) }} data-testid={dataTestId}>
    <Typography bold element="label">
      {name}
    </Typography>
    <StyledDropdown
      data-testid={`${dataTestId}-select`}
      required
      key={name}
      id={field}
      name={field}
      value={value ?? 'default'}
      onChange={e => onChange(field, e.target.value)}
      onBlur={() => handleOnBlur('dropdown', field, true, name)}
    >
      <option value="default" disabled>
        Select...
      </option>
      {options.map(option => (
        <option key={option.id} value={option.id}>
          {option.label}
        </option>
      ))}
    </StyledDropdown>
    {error && (
      <Typography
        data-testid={`${dataTestId}-error`}
        variant="body2"
        color="error.500"
      >
        {error}
      </Typography>
    )}
  </div>
);

export default FormDropdown;
