import Copy from 'elements/Copy';
import { RadioButtonBox, Container } from '@raylo-tech/raylopay-ui';
import Spacer from 'elements/Spacer';
import { useState, Fragment } from 'react';
import { primaryColors, uiStateColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledRadioButtonBoxGroup } from './styles';
import { IRadioButtonBoxGroup } from './types';

export const formattedMonthlyPriceSubCopy = (
  <Container
    flexRow
    width="100%"
    styles={{ flexWrap: 'wrap', justifyContent: 'flex-end' }}
  >
    <Copy color={uiStateColors.success} fontSize={12} textAlign="end">
      Total flexibility,
    </Copy>
    <Copy color={uiStateColors.success} fontSize={12} textAlign="end">
      &nbsp;cancel anytime
    </Copy>
  </Container>
);

export const RadioButtonBoxGroup = ({
  dataTestId,
  options,
  flexRow,
  label,
  onChange,
  subCopy,
  initialActiveIndex,
}: IRadioButtonBoxGroup) => {
  const [activeIndex, setActiveindex] = useState<number | undefined>(
    initialActiveIndex
  );

  const handleSelect = (index: number | string) => {
    const i = index as number;
    setActiveindex(i);
    onChange(options[i]?.label, label);
  };

  return (
    <StyledRadioButtonBoxGroup data-testid={dataTestId}>
      <Copy bold color={primaryColors.c01}>
        {label}
      </Copy>
      <Spacer height={12} />
      {subCopy && subCopy}
      <Spacer height={24} />
      <Container spaceBetween styles={{ gap: `${px2Rem(16)}` }} flexRow={flexRow} noPadding>
        {options.map((option: any, i: number) => (
          <Fragment key={`radio-button${i}`}>
            <Container>
              <RadioButtonBox
                dataTestId={`${dataTestId}Button`}
                label={option.label}
                active={activeIndex === i}
                index={i}
                onClick={handleSelect}
                large={option.large}
                formattedMonthlyPriceSubCopy={formattedMonthlyPriceSubCopy}
              />
            </Container>
            {!flexRow && <Spacer height={64} />}
          </Fragment>
        ))}
      </Container>
    </StyledRadioButtonBoxGroup>
  );
};
