import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router'
import { find } from 'lodash-es';

import * as A from 'styles/account';
import * as T from 'styles/type';

import { AccountFooter } from 'components/Account/AccountFooter';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackButton } from 'components/common/BackButton';
import { Button } from 'components/common/Button';
import { PolicyBanner } from 'components/Account/PolicyBanner';
import { Link } from 'components/common/Link/Link';
import { QL_CLAIM, MU_CLAIM } from './queries';
import { Modal } from 'components/common/Modal';
import { useMutation } from '@apollo/client';
import useRayloQuery from 'utils/useRayloQuery';
import { findOrderItem } from './utils/customers';
import useInsurance from './useInsuranceContext';

import { Helmet } from 'react-helmet';


export const InsuranceDamage = () => {
  const history = useHistory()
  const { orderId } = useParams()
  const { policy, loading } = useInsurance({ query: QL_CLAIM, isLostOrStolen: true, orderId })

  const [updateMutation] = useMutation(MU_CLAIM, {
    update: (proxy, {data: {createInsuranceClaim: { insuranceClaim, errors }}}) => {
      window.location = policy.claimUrl
    }
  })

  const onClaim = () => {

    updateMutation({
      variables: {
        insurancePolicyId: policy.id,
        reason: "ACCIDENTAL_DAMAGE"
      }
    })
  }
  if(loading || !(policy?.id)) return (<Modal visible text="Loading..." />)

  const existingClaim = find(policy.claims, o => o.reason.enum === "ACCIDENTAL_DAMAGE" && o.state === "STARTED")

  return (
    <>

      <Helmet><title>Accidental Damage - Raylo</title></Helmet>
      <A.AccountWrapper>
        <A.AccountForm>
          <A.AccountFormContainer>
            <FadeIn>
              <div style={{ marginBottom: 33 }}>
              <BackButton withText onClick={() => history.push(`/account/insurance/${orderId}`)} />
              </div>
            </FadeIn>

            <FadeIn>
              <div style={{ marginBottom: 40 }}>
                <T.RayloBodyHeading style={{ marginBottom: 8 }}>Accidental Damage</T.RayloBodyHeading>
                <T.RayloSubHeading>Before you make a claim, there are a few things you should know:</T.RayloSubHeading>
              </div>
            </FadeIn>

            <SlideDownFadeIn>
              <T.Steps>
                <li><strong>There is an excess of £89 that you will have to pay</strong></li>


              </T.Steps>
            </SlideDownFadeIn>

            {existingClaim ?
              <>
                <SlideDownFadeIn>
                  <div style={{ marginBottom: 20, marginTop: 20 }}>
                    <T.RayloWarningText>You already have a claim in progress for Accidental Damage.</T.RayloWarningText>
                  </div>
                </SlideDownFadeIn>

                <SlideDownFadeIn>
                  <Button buttonStyle="secondary" onClick={() => history.push(`/account/insurance/${orderId}`)}>Back</Button>
                </SlideDownFadeIn>
              </>
            :

              <>
                <SlideDownFadeIn>
                  <div style={{ marginBottom: 20, marginTop: 20 }}>
                    <T.RayloWarningText>Clicking ‘Make a Claim’ below will redirect you to the Pier Insurance website where you can complete the claim process</T.RayloWarningText>
                  </div>
                </SlideDownFadeIn>

                <SlideDownFadeIn>
                  <Button buttonStyle="primaryBlue" onClick={onClaim}>Make a Claim</Button>
                </SlideDownFadeIn>
              </>
            }

            <SlideDownFadeIn>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <T.RayloBodyType>Any questions? <Link href="" onClick={() => window.Intercom('show')}>Contact Us</Link></T.RayloBodyType>
              </div>
            </SlideDownFadeIn>
          </A.AccountFormContainer>
        </A.AccountForm>
      </A.AccountWrapper>

      <PolicyBanner />

      <AccountFooter />
    </>
  )
  }
