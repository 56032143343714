import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { useStepBankContext } from 'screens/Checkout/containers/StepBank/hooks/useStepBankContext';
import { primaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import { StyledDirectDebitError } from './styles';

export const DirectDebitError = ({ dataTestId }: ITestDataId) => {
  const { setConfirmDetails, setFormSubmitted } = useStepBankContext();
  const handleSubmit = () => {
    setConfirmDetails(false);
    setFormSubmitted(false);
  };
  return (
    <StyledDirectDebitError data-testid={dataTestId}>
      <Container noPadding>
        <Header level={2} color={primaryColors.c01}>
          It looks like your bank account doesn&apos;t support direct debits
        </Header>
        <Spacer height={32} />
        <Copy color={primaryColors.c01}>
          This sometimes happens if someone enters their savings account details
          by mistake. Please check that you&apos;ve entered valid Current
          Account details and try again.
        </Copy>
        <Spacer height={32} />
        <Button
          size="large"
          fullWidth
          buttonColor={ButtonTypes.b01}
          onClick={handleSubmit}
          text="try again"
        />
      </Container>
    </StyledDirectDebitError>
  );
};
