import { primaryColors, secondaryColors } from 'styles/variables/colors';

export const typography = {
  dualHeader: {
    color: primaryColors.c01,
    subColor: secondaryColors.c02,
  },
  textLink: {
    color: primaryColors.b01,
  },
};
