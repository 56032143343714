
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory()

// https://www.pullrequest.com/blog/adding-segment-for-react/
let prevPath = null;

// listen and notify Segment of client-side page updates
history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    if(window.analytics) {
      window.analytics.page();
    }
  }
});
