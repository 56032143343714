import { StyledDualToneHeader } from './styles';
import { IDualToneHeader } from './types';

export const DualToneHeader = ({
  dataTestId,
  children,
  fontSize,
  fontColor,
  subFontColor,
}: IDualToneHeader) => {
  return (
    <StyledDualToneHeader
      fontSize={fontSize}
      fontColor={fontColor}
      subFontColor={subFontColor}
    >
      <h2 data-testid={dataTestId}>{children}</h2>
    </StyledDualToneHeader>
  );
};
