import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { ISpacer } from './types';

const StyledSpacer = styled.div<ISpacer>`
  height: ${({ height, mobile }) => height && !mobile && px2Rem(height)};
  @media (max-width: 460px) {
    height: ${({ height, mobile }) => height && mobile && px2Rem(height)};
  }
`;

export { StyledSpacer };
