import { logo } from './logo';
import { navBar } from './navBar';
import { rayloLight } from '../rayloLight';

const affordableMobiles = {
  ...rayloLight,
  logo,
  navBar,
};

export { affordableMobiles };
