import React from 'react';
import { palette } from '../../colors';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Icon from '../../elements/Icon';
import { px2Rem } from '../../utils/px2Rem';
import { StyledInfoBox } from './InfoBox.styles';
import { IInfoBox } from './InfoBox.types';

export const InfoBox = ({ dataTestId, icon, copy }: IInfoBox) => {
  return (
    <StyledInfoBox data-testid={dataTestId}>
      <Container
        backgroundColor={palette.charcoal[100]}
        flexRow
        padding={`0 ${px2Rem(16)}`}
        height={px2Rem(67)}
        center
        styles={{
          width: 'initial',
        }}
      >
        <Container fitContent>
          <Icon name={icon} $width={22} dataTestId="trustbuilderIcon" />
        </Container>
        <Container height="100%" centerVertical margin="0 0 0 16px">
          <Copy color={palette.charcoal[500]} fontSize={14}>
            {copy}
          </Copy>
        </Container>
      </Container>
    </StyledInfoBox>
  );
};
