import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { primaryColors } from 'styles/variables/colors';

export const StyledFormNavbar = styled.div`
  width: 100%;
  background-color: ${primaryColors.b01};
`;

export const StyledCloseButtonContainer = styled.div`
  text-align: right;
`;

export const ButtonWithoutStyle = styled.button`
  border: none;
  background: none;
  width: fit-content;
  padding: 0;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  height: ${px2Rem(83)};
  padding: ${px2Rem(32)} ${px2Rem(24)};
`;
