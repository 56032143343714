import { useEffect } from 'react';
import { Container, Copy, Spacer } from '@raylo-tech/raylopay-ui';
import { primaryColors } from 'styles/variables/colors';
import { StyledRecommendations } from './styles';
import { px2Rem } from 'utils/px2Rem';
import { useSharedCustomer } from 'utils/useSharedCustomer';

export const Recommendations = ({ preApprovalLimit }: { preApprovalLimit: number }) => {
  const { readUserToken } = useSharedCustomer();

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== process.env.REACT_APP_PRODUCTS_DOMAIN) return;
        const sender = event.source as WindowProxy;
        const userToken = readUserToken();

        if (preApprovalLimit && userToken) {
          sender?.postMessage({ context: 'customer', limit: preApprovalLimit, token: userToken }, process.env.REACT_APP_PRODUCTS_DOMAIN);
        }
      }, false
    );
  }, []);
  // note: scrolling attribute is deprecated but no other alternatives yet suitable - https://github.com/davidjbradshaw/iframe-resizer/issues/1142#issuecomment-1959544213

  return (
    <>
      <StyledRecommendations data-testid="recommendations">
        <Copy bold fontSize={24} color={primaryColors.w01}>You&apos;ve unlocked an exclusive range of premium tech</Copy>
        <Spacer height={12} />
        <Copy fontSize={18} color={primaryColors.w01}>Pre-approved with unbeatable Raylo prices and free next-day DPD delivery</Copy>
      </StyledRecommendations>
      <Container height={px2Rem(570)}>
        <iframe
          style={{ border: 0, overflowY: 'hidden', height: '100%', width: '100%', backgroundColor: primaryColors.c01 }}
          scrolling="no"
          title="recommendations"
          id="raylo-recommendations"
          src={`${process.env.REACT_APP_PRODUCTS_DOMAIN}/recommendations`}
        />
      </Container>
    </>
  );
};
