import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';

export const button = {
  background: {
    p01: primaryColors.p01,
    b01: primaryColors.b01,
    p02: secondaryColors.p02,
  },
  hover: {
    p01: secondaryColors.p04,
    b01: secondaryColors.b02,
    p02: primaryColors.p01,
  },
  fontColor: {
    black: '#000',
    white: '#FFF',
  },
  active: {
    p01: secondaryColors.p03,
    b01: secondaryColors.b02,
    p02: secondaryColors.p03,
  },
  disabled: {
    background: secondaryColors.c05,
    color: 'rgba(45, 45, 45, 0.3)',
  },
  ghostType: {
    color: '#FFF',
    background: 'none',
    border: `solid ${px2Rem(1)} rgba(255, 255, 255, 0.4)`,
    hover: {
      border: `solid ${px2Rem(1)} ${primaryColors.w01}`,
    },
    active: {
      border: `solid ${px2Rem(1)} ${primaryColors.w01}`,
    },
    disabled: {
      background: 'none',
      color: secondaryColors.c03,
      border: `solid ${px2Rem(1)} ${secondaryColors.c03}`,
      opacity: '0.4',
    },
  },
};
