import { useCheckoutContext } from 'utils/useCheckoutContext';
import { StyledCOSMainContent, StyledCOSSidebar } from './styles';
import { useCheckoutOrderSummaryData } from './useCheckoutOrderSummaryData';
import { CheckoutOrderSummaryCartItem } from './CheckoutOrderSummaryCartItem';

type CheckoutOrderSidebarSummaryProps = {
  checkoutToken: string;
  displayOnly?: boolean;
};

export const CheckoutOrderSidebarSummary = ({
  checkoutToken,
  displayOnly,
}: CheckoutOrderSidebarSummaryProps) => {
  const { merchantHasChangeProductOption } = useCheckoutContext();

  const {
    checkoutHasLoaded,
    variant,
    variantImageUrl,
    changeProductURL,
    onChangeProduct,
  } = useCheckoutOrderSummaryData({ checkoutToken });

  if (!checkoutHasLoaded) {
    return null;
  }

  return (
    <StyledCOSSidebar>
      <StyledCOSMainContent>
        <CheckoutOrderSummaryCartItem
          displayOnly={displayOnly}
          variantDisplayName={variant.displayName}
          variantImageUrl={variantImageUrl}
          changeProductURL={changeProductURL}
          merchantHasChangeProductOption={merchantHasChangeProductOption}
          onChangeProduct={onChangeProduct}
        />
      </StyledCOSMainContent>
    </StyledCOSSidebar>
  );
};
