import React from 'react';
import TrustPilotBar from '../../../components/TrustPilotBar';
import Container from '../../../elements/Container';
import Icon from '../../../elements/Icon';
import Spacer from '../../../elements/Spacer';
import { px2Rem } from '../../../utils/px2Rem';
import Divider from '../Divider';

import { StyledRayloHeader } from './RayloHeader.styles';
import { IRayloHeader } from './RayloHeader.types';

export const RayloHeader = ({ dataTestId, onClose }: IRayloHeader) => {
  return (
    <StyledRayloHeader data-testid={dataTestId}>
      <Spacer height={24} />
      <Container flexRow maxWidth={px2Rem(414)}>
        <Container>
          <Icon name="RayloDesignRpLogo" dataTestId="rp-logo" />
        </Container>
        {onClose && (
          <Container
            alignRight
            cursorPointer
            fitContent
            onClick={onClose}
            dataTestId="close-button"
          >
            <Icon name="RayloDesignCustomClose" dataTestId="nav-close-icon" />
          </Container>
        )}
      </Container>
      <Spacer height={24} />
      <Divider height={16} />
      <TrustPilotBar RPHeader />
    </StyledRayloHeader>
  );
};
