import styled from 'styled-components';

const Wrapper = styled.div`
  & a {
    text-decoration: none;
  }
`;

interface Props {
  styles?: string;
}

const StyledBackArrowHeader = styled.div<Props>`
  display: flex;
  height: 50px;
  justify-content: center;
  ${({ styles }) => styles}
`;

export { StyledBackArrowHeader, Wrapper };
