import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

function formatDateRange(dateObj: any) {
  const { min, max } = dateObj;

  const minDate = dayjs(min);
  const maxDate = dayjs(max);

  if (minDate.isSame(maxDate, 'day')) {
    const day = minDate.day();
    return `on ${dayOfTheWeek(day)}, ${minDate.format('Do MMMM')}`;
  }

  const formattedDateString = minDate.isSame(maxDate, 'year') ?
    'Do MMMM' :
    'Do MMMM YYYY';

  const startFormat = minDate.isSame(maxDate, 'month') ?
    'Do' :
    formattedDateString;
  const endFormat = formattedDateString;

  return `between ${minDate.format(startFormat)} and ${maxDate.format(
    endFormat
  )}`;
}

const dayOfTheWeek = (day: number) => {
  switch (day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

export { formatDateRange };
