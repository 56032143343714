import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ACCEPTABLE_EMAIL_MUTATION } from '../../graphql/mutations/acceptableData/createAcceptableDataMutation.graphql';
import Spacer from '../../elements/Spacer';
import { useClientContext } from '../../utils/useClientContext';
import Button from '../../elements/Button';

export const EmailGenerator = () => {
  const { token } = useClientContext();

  const [emailInfo, setEmailInfo] = useState('');

  const [generateEmailMutation, { data }] = useMutation(
    ACCEPTABLE_EMAIL_MUTATION,
    {
      variables: {
        token,
      },
    }
  );

  useEffect(() => {
    if (data) {
      const { email } = data.createAcceptableData;
      setEmailInfo(`${email} copied to clipboard`);
      navigator.clipboard.writeText(email);
    }
  }, [data]);

  return (
    <div>
      <Button onClick={() => generateEmailMutation()}>
        Generate unique email
      </Button>
      <Spacer height={12} />
      <div data-testid="uniqueEmailContent">{emailInfo}</div>
    </div>
  );
};
