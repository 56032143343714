// GQL
import gql from 'graphql-tag';

export const QL_VARIANTS_PAGINATED = gql`
  query VariantsPaginatedQuery($merchantId: ID!, $first: Int, $after: String) {
    variantsConnection(forCheckout: true, merchantId: $merchantId, first: $first, after: $after) {
      nodes {
          id
          displayName
          costSummaries(merchantId: $merchantId) {
            id
            includesInsurance
            termLength
          }
        }
        pageInfo {
            endCursor
            hasNextPage
        }
    }
  }`;
