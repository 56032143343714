import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';
import { CSS_FONT_WEIGHT } from '../../constants';

const StyledCarouselSection = styled.section`
  max-width: 100vw;
  --slide-height: 19rem;
  --slide-spacing: ${px2Rem(16)};
  @media (max-width: 400px) {
    --slide-size: 90%;
  };
  @media (min-width: 400px) and (max-width: 500px) {
    --slide-size: 75%;
  };
  @media (min-width: 500px) and (max-width: 700px) {
    --slide-size: 60%;
  };
  @media (min-width: 700px) and (max-width: 900px) {
    --slide-size: 40%;
  }
  @media (min-width: 900px) and (max-width: 1200px) {
    --slide-size: 32%;
  }
  @media (min-width: 1200px) {
    --slide-size: 27%;
  }
`;

const StyledCarouselViewPort = styled.div`
  overflow: hidden;
  padding-top: ${px2Rem(12)};
`;

const StyledCarouselSlide = styled.div`
  flex: 0 0 var(--slide-size);
  min-width: 0;
  max-width: ${px2Rem(320)};
  padding-left: var(--slide-spacing);
`;

const StyledCarouselContainer = styled.div<{ centreContent: boolean }>`
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  padding-left: ${px2Rem(8)};
  ${({ centreContent }) => centreContent && `
    justify-content: center;
  `};
`;

const StyleCarouselControls = styled.div`
  display: flex;
  padding-bottom: ${px2Rem(12)};
  justify-content: space-between;
  gap: 1.2rem;
  padding: ${px2Rem(32)} ${px2Rem(24)} ${px2Rem(12)} ${px2Rem(24)};
  @media (min-width: calc(var(--slide-size) * 5 + var(--slide-spacing) * 5) {
    display: none;
  }
`;

const StyledCarouselButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
`;

const StyledCarouselButton = styled.button`
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: ${palette.blue[500]};
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: ${px2Rem(44)};
  height: ${px2Rem(44)};
  z-index: 1;
  border-radius: 50%;
  color: ${palette.white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${palette.blue[400]};
  }

  &:focus {
    background-color: ${palette.blue[300]};
  }

  &:disabled {
    background-color: ${palette.charcoal[100]};
  }
`;

const StyledIconButton = styled.div`
  background-color: ${palette.blue[500]};
  width: ${px2Rem(24)};
  height: ${px2Rem(24)};
  border-radius: 50%;
  color: ${palette.charcoal[300]};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${palette.blue[400]};
  }

  &:focus {
    background-color: ${palette.blue[300]};
  }
`;

const StyledViewAllButtonContainer = styled.div<{ centreContent: boolean }>`
  padding: ${px2Rem(12)} 0 ${px2Rem(12)} ${px2Rem(24)};
  display: flex;
  p {
    text-decoration-line: underline;
    font-size: ${px2Rem(14)};
    font-weight: ${CSS_FONT_WEIGHT.vars.medium};
  }

  ${({ centreContent }) => centreContent && `
    justify-content: center;
  `};
`;

const StyledCarouselDots = styled.div`
  display: flex;
  padding: ${px2Rem(8)};
  align-items: flex-start;
  gap: ${px2Rem(8)};
  border-radius: ${px2Rem(99)};
  background: ${palette.charcoal[100]};
  backdrop-filter: blur(${px2Rem(10)});
`;

const StyledCarouselDotsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCarouselDot = styled.button<{ selected: boolean; clickDirection?: string }>`
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: ${px2Rem(8)};
  height: ${px2Rem(8)};
  align-items: center;
  background-color: ${palette.charcoal[300]};
  justify-content: center;
  border-radius: 50%;

  &:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
  }

  ${({ selected, clickDirection }) => selected && `
    width: ${px2Rem(24)};
    height: ${px2Rem(8)};
    border-radius: ${px2Rem(99)};
    background-color: ${palette.charcoal[400]};
    animation: growDot .25s ease-in-out;
    transform-origin: ${clickDirection === 'prev' ? '100%' : '0%'};
    @keyframes growDot {
      from {
        transform: scaleX(0.5);
      }
      to {
        transform: scaleX(1);
      }
    }
  `}
`;

export {
  StyledCarouselSection,
  StyledCarouselViewPort,
  StyledCarouselContainer,
  StyledCarouselSlide,
  StyleCarouselControls,
  StyledCarouselButtons,
  StyledCarouselButton,
  StyledCarouselDots,
  StyledCarouselDotsContainer,
  StyledCarouselDot,
  StyledIconButton,
  StyledViewAllButtonContainer
};
