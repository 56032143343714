import Container from 'elements/Container';
import { StepStripe } from 'screens/Checkout/components/StepStripe';
import { IStepStripe } from './types';

export const StepStripeContainer = ({
  dataTestId,
  checkoutToken,
  onBack,
  onSuccess,
  onChangeAddress,
}: IStepStripe) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepStripe
        checkoutToken={checkoutToken}
        onBack={onBack}
        onChangeAddress={onChangeAddress}
        onSuccess={onSuccess}
      />
    </Container>
  );
};
