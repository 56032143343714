import { useEffect, useMemo, useState } from 'react';
import constate from 'constate';
import store from 'store';
import { QL_CHECKOUT_BASE } from 'screens/Checkout/graphQL/queries';
import { setErrorContext } from 'utils/errorTracking';
import { useUiContext } from './useUiContext';
import { getThemeFromString } from './getThemeFromString';
import { isFeatureEnabled } from './isFeatureEnabled';
import { useMerchantContext } from 'utils/useMerchantContext';
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';
import { useAnalyticsContext } from './AnalyticsProvider';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { SHOW_UPDATED_CHECKOUT_STEPS } from 'utils/constants';
import { stepsOrder } from 'utils/stepsOrder';
import { CheckoutStep } from 'screens/Checkout/enums/checkoutSteps';
import { CheckoutBaseQueryQuery, CheckoutBaseQueryQueryVariables } from 'graphql/generated/graphql';
import { useQuery } from '@apollo/client';
import { ConsumerTypesEnum } from 'screens/Checkout/enums/consumerTypes';
import { consumerTypeMoneyField } from '@raylo-tech/raylopay-ui';

const useCheckout = () => {
  const [currentStep, setCurrentStep] = useState('');
  const [customerDetails, setCustomerDetails] = useState();
  const [hasDeliveryDate, setHasDeliveryDate] = useState(false);
  const [merchantHasInsuranceOption, setMerchantHasInsuranceOption] =
    useState(false);
  const [merchantHasChangeProductOption, setMerchantHasChangeProductOption] =
    useState(false);
  const [checkoutMode, setCheckoutMode] = useState<CheckoutMode>(
    CheckoutMode.NEW_CUSTOMER
  );

  const [consumerType, setConsumerType] = useState<ConsumerTypesEnum>(ConsumerTypesEnum.PERSONAL);
  const [checkoutCategoryId, setCheckoutCategoryId] = useState<string>('');
  const [checkoutCategoryDisplayName, setCheckoutCategoryDisplayName] = useState<string>('');
  const [canAddTradeIn, setCanAddTradeIn] = useState<boolean>(false);

  const { setTheme, setIsRayloPay, setShowSummary } = useUiContext();
  const { merchantId } = useMerchantContext();
  const { readDomainCookie } = useSharedCustomer();
  const { setAnalyticsIdFromCheckout } = useAnalyticsContext();

  let checkoutToken: string = 'A';
  if (process.env.NODE_ENV !== 'test') {
    checkoutToken = readDomainCookie('checkoutToken') || store.get('checkoutToken');
  }

  const growthbook = useGrowthBook();
  const showUpdatedCheckoutSteps = growthbook?.getFeatureValue(SHOW_UPDATED_CHECKOUT_STEPS, false);

  const { data, loading, called } = useQuery<CheckoutBaseQueryQuery, CheckoutBaseQueryQueryVariables>(QL_CHECKOUT_BASE, {
    variables: {
      token: checkoutToken,
    },
    skip: !checkoutToken,
  });

  useEffect(() => {
    const queryString = window.location.search;
    if (queryString.includes('consumerType=business') || store.get('consumerType') === 'business') {
      setConsumerType(ConsumerTypesEnum.BUSINESS);
      store.set('consumerType', 'business');
    }
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    const features = data?.checkout?.merchant?.features;

    if (features) {
      setMerchantHasInsuranceOption(!!isFeatureEnabled(features, 'insurance'));
      setMerchantHasChangeProductOption(
        !!isFeatureEnabled(features, 'change_variant')
      );

      if (!isFeatureEnabled(features, 'amend_customer_details')) {
        setShowSummary(false);
        setCheckoutMode(CheckoutMode.HIRE_AGREEMENT_ONLY);
        return;
      }
    }

    const checkoutContext = readDomainCookie('checkoutContext');

    if (checkoutContext === CheckoutMode.UPGRADE) {
      setCheckoutMode(CheckoutMode.UPGRADE);
    } else if (checkoutContext === CheckoutMode.ADDITIONAL_TECH) {
      setCheckoutMode(CheckoutMode.ADDITIONAL_TECH);
    } else {
      setCheckoutMode(CheckoutMode.NEW_CUSTOMER);
    }
  }, [data, setShowSummary, readDomainCookie]);

  useEffect(() => {
    if (!checkoutToken) {
      return;
    }
    setAnalyticsIdFromCheckout(checkoutToken);
  }, [checkoutToken, setAnalyticsIdFromCheckout]);

  useEffect(() => {
    if (data?.checkout?.id) {
      setErrorContext('checkout', { id: data.checkout.id });
      const currentCategory = data.checkout.items[0].variant?.product?.category;
      setCheckoutCategoryId(currentCategory?.id);
      setCheckoutCategoryDisplayName(currentCategory?.displayName ?? '');
      setCanAddTradeIn(!!data.checkout.canAddTradeIn);
    }

    const brandingTheme = data?.checkout?.merchant?.brandingTheme;

    if (brandingTheme) {
      setTheme(getThemeFromString(brandingTheme) as any);
      setIsRayloPay(brandingTheme !== 'rayloLight');
    }

    setHasDeliveryDate(!!data?.checkout?.knownDeliveryDates);
  }, [data, merchantHasInsuranceOption, merchantId, setIsRayloPay, setTheme]);

  const checkout = data?.checkout;

  const condition = {
    state: checkout?.state,
    outcome: checkout?.decision?.outcome,
    reason: checkout?.decision?.outcomeReason,
  };

  const progressBarSteps = useMemo(() => {
    return stepsOrder({
      checkout,
      checkoutMode,
      merchantHasInsuranceOption,
      showUpdatedCheckoutSteps,
      consumerType,
    }) as CheckoutStep[];
  }, [
    checkout,
    checkoutMode,
    merchantHasInsuranceOption,
    showUpdatedCheckoutSteps,
    consumerType,
  ]);

  const consumerMoneyField = useMemo(() => consumerTypeMoneyField[consumerType], [consumerType]);

  return {
    checkoutID: data?.checkout?.id,
    checkout: data?.checkout,
    checkoutCustomerInfo: data?.checkout?.customerInfo,
    progressBarSteps,
    currentStep,
    customerDetails,
    hasCheckoutToken: !!checkoutToken,
    checkoutToken,
    checkoutCategoryId,
    checkoutCategoryDisplayName,
    canAddTradeIn,
    checkoutCalled: called,
    checkoutLoading: loading,
    condition,
    hasDeliveryDate,
    merchantHasInsuranceOption,
    merchantHasChangeProductOption,
    checkoutMode,
    setCurrentStep,
    setCustomerDetails,
    showUpdatedCheckoutSteps,
    consumerType,
    setConsumerType,
    consumerMoneyField,
  };
};

const [CheckoutProvider, useCheckoutContext] = constate(useCheckout);
export { CheckoutProvider, useCheckoutContext };
