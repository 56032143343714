import Container from 'elements/Container';
import StepAddressContent from 'screens/Checkout/components/StepAddressContent';
import { CheckoutAddressProvider } from './hooks/useCheckoutAddressContext';
import { IStepAddress } from './types';

export const StepAddressContainer = ({
  dataTestId,
  onSuccess,
}: IStepAddress) => {
  return (
    <CheckoutAddressProvider>
      <Container dataTestId={dataTestId} noPadding>
        <StepAddressContent onSuccess={onSuccess} />
      </Container>
    </CheckoutAddressProvider>
  );
};
