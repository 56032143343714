import Container from 'elements/Container';
import Divider from 'elements/Divider';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import Spacer from 'elements/Spacer';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledRayloModal } from './styles';
import { IRayloModal } from './types';

export const RayloModal = ({
  dataTestId,
  children,
  onClose,
  header = '',
}: IRayloModal) => {
  return (
    <StyledRayloModal data-testid={dataTestId}>
      {onClose && (
        <>
          <Container
            alignRight
            width="90%"
            maxWidth={px2Rem(500)}
            noPadding
            onClick={onClose}
            cursorPointer
          >
            <Container
              backgroundColor={primaryColors.p01}
              width={px2Rem(50)}
              height={px2Rem(50)}
              center
              padding="4px 0 0 0"
            >
              <Icon name="Close" size={px2Rem(24)} color={primaryColors.c01} />
            </Container>
          </Container>
          <Spacer height={12} />
        </>
      )}
      <Container
        noPadding
        backgroundColor={primaryColors.w01}
        styles={{
          position: 'relative',
          width: '90%',
          maxHeight: '80vh',
          overflow: 'scroll',
        }}
        maxWidth={px2Rem(500)}
      >
        <Container
          padding={`0 ${px2Rem(16)}`}
          center
          backgroundColor={primaryColors.w01}
          styles={{
            position: 'sticky',
            top: '0',
          }}
        >
          <Spacer height={24} />
          <Icon name="ShieldPhone" color={secondaryColors.p02} size="45px" />
          <Spacer height={16} />
          <Header level={2} color={primaryColors.b01}>
            {header}
          </Header>
          <Spacer height={16} />
        </Container>
        <Container padding={`0 ${px2Rem(24)}`}>
          <Divider width="100%" color={secondaryColors.c04} />
          <Spacer height={16} />
          {children}
        </Container>
      </Container>
    </StyledRayloModal>
  );
};
