import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';
import { IHeader } from './types';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

const StyledHeaderContent = styled.span<IHeader>`
  ${({ centered }) =>
      centered &&
      `
  display: block;
  text-align: center;
  `}
  ${({ color }) => color && `color: ${color};`}
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  & span {
    ${({ subColor }) => subColor && `color: ${subColor};`}
  }
`;

export { StyledHeaderContent };
