import pill from 'public/footer-logo.svg';
import facebookIcon from 'public/icon--facebook.svg';
import instagramIcon from 'public/icon--instagram.svg';
import twitterIcon from 'public/icon--twitter.svg';

import 'styles/footer.css';
import { useSharedCustomer } from 'utils/useSharedCustomer';

export const AccountFooter = () => {
  const { customerIsInMobileApp } = useSharedCustomer();
  if (customerIsInMobileApp) return null;

  return (
  <div className="footer" data-testid="account-footer">
    <div className="footer--container">
      <div className="footer--grid">
        <div className="footer--address">
          <img src={pill} alt="Raylo Pill" className="raylo-pill" />
          <p>Raylo Group Limited is authorised and regulated by the Financial Conduct Authority (841488).</p>
          <p>Raylo is a trading name of Raylo Group Limited. Raylo Group Limited is registered in England and Wales company number 11554120.</p>
          <p><strong>Head office</strong>5 New Street Square, City of London, EC4A 3TW</p>
        </div>
        <div className="footer--links">
          <div className="footer__title">Raylo</div>
          <div className="footer__text"><a href="https://app.raylo.com/login" className="footer__link">My account</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/products" className="footer__link">Get&nbsp;started</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/sustainability" className="footer__link">Sustainability</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/#how-it-works" className="footer__link">How it works</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/#pricing" className="footer__link">Pricing</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/blog" className="footer__link">Blog</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/brands/new" className="footer__link">New phones</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/brands/refurbished" className="footer__link">Refurbished phones</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/about" className="footer__link">About</a></div>
        </div>
        <div className="footer--links">
          <div className="footer__title">Customer service</div>
          <div className="footer__text"><a href="https://help.raylo.com/" className="footer__link">Help</a></div>
          <div className="footer__text"><a href="https://help.raylo.com/en/articles/3271547-contact-raylo" className="footer__link">Contact us</a></div>
          <div className="footer__text"><a href="https://jobs.eu.lever.co/raylo" className="footer__link">Careers</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/post" className="footer__link">Guides</a></div>
          <div className="footer__text"><a href="https://www.raylo.com/legal" className="footer__link">Legal</a></div>
          <div className="footer__text"><a href="https://raylo.connect.studentbeans.com/uk" className="footer__link">Students</a></div>
        </div>
      </div>

      <div className="footer--lower">
        <div className="footer--legal">&copy; Copyright of Raylo {new Date().getFullYear()}. All rights reserved.</div>
        <div className="social-icons">
          <a href="https://www.facebook.com/joinraylo/" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" width={18} />
          </a>
          <a href="https://www.instagram.com/joinraylo/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" width={18} />
          </a>
          <a href="https://twitter.com/raylo" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" width={18} />
          </a>
        </div>
      </div>
    </div>
  </div>
  );
};
