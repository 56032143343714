import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';

import * as A from 'styles/account';
import * as T from 'styles/type';
import { px2Rem } from 'utils/px2Rem';
import { useHistory } from 'react-router';
import useMedia from 'use-media';
import { checkInArrears } from 'helpers/subscriptions/checkInArrears';
import {LEGACY_BREAKPOINTS} from 'styles/LEGACY_BREAKPOINTS';

export const OverduePayment = ({ subscription }) => {
  if (checkInArrears(subscription)) {
    const history = useHistory();
    const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });

    return (
      <A.PaymentRow>
        <A.PaymentRowHeader>
          <T.PaymentRowLabel>Amount Overdue</T.PaymentRowLabel>
        </A.PaymentRowHeader>

        <A.PaymentRowData>
          <div style={{
            width: '100%',
            position: 'relative',
          }}
          >
            <T.Payment overdue style={{ marginRight: 18 }}>{subscription.arrearsAmount.formattedValue}</T.Payment>
            <Button
              text="pay now"
              buttonColor={ButtonTypes.b01}
              styles={`
                position: absolute;
                right: 0;
                width: ${isMobile ? px2Rem(189) : px2Rem(209)};
              `}
              size="small"
              onClick={() => history.push(`/account/payment/${subscription.id}`)}
            />
          </div>
        </A.PaymentRowData>
      </A.PaymentRow>
    );
  } else {
    return null;
  }
};
