import styled from 'styled-components';
import { IStyledTermsAndConditions } from './types';

const StyledTermsAndConditions = styled.div<IStyledTermsAndConditions>`
  transition: height 500ms;
  overflow: auto;
  ${({ height }) => height && `height: ${height};`};
`;

export { StyledTermsAndConditions };
