import React, { useState } from 'react';
import { StyledTextAreaContainer, StyledTextArea, StyledFloatingLabel } from './TextArea.styles';
import { TextAreaProps } from './TextArea.types';

export const TextArea = ({ height, floatingLabel, dataTestId, onChange }: TextAreaProps) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <StyledTextAreaContainer height={height} data-testid={dataTestId}>
      <StyledTextArea
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        $isFocused={isFocused}
      />
      <StyledFloatingLabel $isFocused={isFocused} value={value}>
        {floatingLabel}
      </StyledFloatingLabel>
    </StyledTextAreaContainer>
  );
};
