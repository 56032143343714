import styled from 'styled-components';
import { StyledBannerButtonWithIconProps } from './BannerButtonWithIcon.types';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';

const StyledBannerButtonWithIcon = styled.div<StyledBannerButtonWithIconProps>`
  ${({
      borderRadius,
      fontSize,
      lineHeight,
      backgroundColor,
      color,
      makeWholeBannerClickable,
    }) => `
    background-color: ${backgroundColor ?? palette.blue[500]};
    color: ${color ?? palette.white};
    border-radius: ${px2Rem(borderRadius ?? 4)};
    font-size: ${px2Rem(fontSize ?? 16)};
    line-height: ${px2Rem(lineHeight ?? 20)};
    position: relative;
    padding: ${px2Rem(16)} ${px2Rem(24)};
    cursor: ${makeWholeBannerClickable ? 'pointer' : 'auto'};

    @media (max-width: 600px) {
      padding: ${px2Rem(16)} ${px2Rem(20)};
    }

    button {
      border: none;
      background: none;
      color: white;
      margin: 0;
      padding: 0;
      text-decoration: underline;
      font-size: ${px2Rem(16)};
      line-height: ${px2Rem(20)};
      padding-top: ${px2Rem(8)};
      cursor: pointer;
    }

    p {
      width: fit-content;
    }

    .animation {
      height: ${px2Rem(120)};
      width: ${px2Rem(120)};
      position: absolute;
      bottom: ${px2Rem(8)};
      right: 0;
    }
  `}
`;

export { StyledBannerButtonWithIcon };
