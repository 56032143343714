import React from 'react';
import { IBorderWithLabel } from './BorderWithLabel.types';
import { StyledBorderWithLabel, StyledBorderWrapper } from './BorderWithLabel.styles';
import Copy from '../../elements/Copy';

export const BorderWithLabel = ({
  defaultColor,
  activeColor,
  hoverColor,
  labelText,
  labelColor,
  children,
  active,
  dataTestId,
}: IBorderWithLabel) => {
  return (
    <StyledBorderWrapper>
      <StyledBorderWithLabel
        data-testid={dataTestId}
        $activeColor={activeColor}
        $active={!!active}
        $hoverColor={hoverColor}
        $defaultColor={defaultColor}
        $labelColor={labelColor}
      >
        <Copy>
          { labelText }
        </Copy>
        { children }
      </StyledBorderWithLabel>
    </StyledBorderWrapper>
  );
};
