import { RayloModal } from '@raylo-tech/raylopay-ui';
import ITestDataId from 'types/ITestDataId.type';
import { useCheckoutLeaseTermsContext } from 'screens/Checkout/containers/CheckoutLeaseTermsContainer/hooks/useCheckoutLeaseTermsContext';

interface ICancelMerchantOrderModal extends ITestDataId {
  modalOpen: boolean;
  onCloseModal: () => void;
}

export const CancelMerchantOrderModal = ({
  modalOpen,
  onCloseModal,
  dataTestId,
}: ICancelMerchantOrderModal) => {
  const { merchantFailureUrl } = useCheckoutLeaseTermsContext();
  const redirectToMerchant = () => {
    if (merchantFailureUrl) {
      window.location.replace(merchantFailureUrl);
    }
  };

  return (
    <RayloModal
      dataTestId={dataTestId}
      modalOpen={modalOpen}
      title="Cancel order"
      copy={[
        'Are you sure you want to cancel and return to merchant?',
        "You'll lose your progress on this order and will have to start again.",
      ]}
      handleCloseModal={onCloseModal}
      primaryButtonText="CONTINUE ORDER"
      primaryButtonOnClick={onCloseModal}
      secondaryButtonText="CANCEL ORDER"
      secondaryButtonOnClick={redirectToMerchant}
    />
  );
};
