import { px2Rem } from 'utils/px2Rem';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { rayloDark } from '../rayloDark';

export const button = {
  ...rayloDark?.button,
  ghostType: {
    ...rayloDark?.button?.ghostType,
    color: primaryColors.c01,
    background: 'none',
    border: `solid ${px2Rem(1)} ${primaryColors.c01}`,
    hover: {
      border: `solid ${px2Rem(2)} ${primaryColors.c01}`,
    },
    active: {
      border: `solid ${px2Rem(1)} ${primaryColors.c01}`,
    },
  },
  disabled: {
    ...rayloDark?.button?.disabled,
    background: secondaryColors.c05,
    color: 'rgba(45, 45, 45, 0.3)',
    opacity: '1',
  },
};
