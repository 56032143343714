export const firstLettersToLowerCase = (text: string) => {
  const stringArray = text.split(' ');
  const newArray = stringArray.map((word) => {
    if (word) {
      const wordArray = word.split('');
      wordArray[0] = word[0].toLowerCase();
      wordArray.join('');
      return wordArray.join('');
    }
    return null;
  });
  return newArray.join(' ');
};
