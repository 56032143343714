import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import constate from 'constate';
import {
  IStartSubscriptionTerminationMutation,
  START_SUBSCRIPTION_TERMINATION
} from '../graphql/start_subscription_termination.graphql';
import { IStartAssetReturnMutation, START_ASSET_RETURN } from '../graphql/start_asset_return.graphql';
import { IGetCustomerDeviceOrderSubscription } from 'screens/Account/DeviceProfile/graphql/deviceProfile.graphql';
import {
  GET_SUBSCRIPTION,
  IGetSubscriptionQuery,
  IGetSubscriptionQueryOrder,
  IGetSubscriptionQueryOrderItem
} from 'screens/Account/Forms/graphql/subscription.graphql';
import { getActiveAssetReturn } from 'utils/subscription/getActiveAssetReturn';

const useCancelLease = ({ subscriptionId }: { subscriptionId: string }) => {
  const [showError, setShowError] = useState(false);

  const [startAssetReturn] = useMutation(START_ASSET_RETURN,
    {
      variables: {
        subscriptionId,
      },
      onCompleted(data: IStartAssetReturnMutation) {
        if (data.start.success) {
          getSubscription();
          startPolling(2000);
        } else {
          setShowError(true);
        }
      },
      onError() {
        setShowError(true);
      },
    }
  );

  const [startSubscriptionTerminationMutation] = useMutation(START_SUBSCRIPTION_TERMINATION);

  const startSubscriptionTermination = (variables: object, needsAssetReturn: boolean) => {
    startSubscriptionTerminationMutation({ variables, onCompleted(data: IStartSubscriptionTerminationMutation) {
      if (data.start.success) {
        if (needsAssetReturn) {
          startAssetReturn();
        } else {
          window.location.assign('/account');
        }
      } else {
        setShowError(true);
      }
    },
    onError() {
      setShowError(true);
    },
    });
  };

  const [getSubscription, { startPolling, stopPolling }] = useLazyQuery(GET_SUBSCRIPTION, {
    onCompleted(data: IGetSubscriptionQuery) {
      const orderItem = findOrderItem(data, subscriptionId);
      if (!orderItem) return;

      const trackingUrl = getCustomerCollectionUrl(orderItem);

      if (trackingUrl) {
        stopPolling();
        window.location.assign(trackingUrl);
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return {
    showError,
    startSubscriptionTermination,
  };
};

const findOrderItem = (data: IGetSubscriptionQuery, subscriptionId: string) => {
  return data.customer.orders.find((order: IGetSubscriptionQueryOrder) => {
    return order.items.some((item: IGetSubscriptionQueryOrderItem) => item.subscription?.id === subscriptionId);
  })?.items[0];
};

const getCustomerCollectionUrl = (orderItem: IGetSubscriptionQueryOrderItem) => {
  const assetReturn = getActiveAssetReturn(orderItem.subscription as Partial<IGetCustomerDeviceOrderSubscription>);
  return assetReturn?.customerCollectionUrl;
};

const [CancelLeaseProvider, useCancelLeaseContext] = constate(useCancelLease);
export { CancelLeaseProvider, useCancelLeaseContext };
