import React, { useRef, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import colors from 'styles/colors';
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

export const Tooltip = ({ direction, children }) => {
  const tooltip = useRef(null);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipOffset, setTooltipOffset] = useState(0);
  const [tooltipDirection, setTooltipDirection] = useState('');

  useLayoutEffect(() => {
    setTooltipWidth(tooltip?.current?.clientWidth);
    setTooltipOffset(tooltip?.current?.offsetParent?.offsetLeft);
    setTooltipDirection(tooltip?.current?.attributes?.direction.value);

    const viewportWidth = window?.innerWidth;
    const tooltipRight = (tooltipOffset + 16);
    const tooltipRightSpace = (viewportWidth - tooltipRight);
    const tooltipRightAvailableSpace = tooltipRightSpace - tooltipWidth;
    const availableSpace = tooltipOffset - tooltipWidth;

    if (tooltipDirection === 'right' && tooltipRightAvailableSpace <= 0) {
      tooltip.current.style.width = tooltipRightSpace + 16 + 'px';
    } else if (tooltipDirection === 'left' && availableSpace <= 0) {
      tooltip.current.style.width = tooltipOffset + 16 + 'px';
    } else {
      tooltip.current.style.width = '250px';
    }
  }, [tooltipWidth, tooltipOffset, tooltipDirection]);

  return (
    <RayloTooltipContainer>
      <RayloTooltipTrigger direction={direction}>
        <svg width="2px" height="10px" viewBox="0 0 2 10">
          <g id="UI-Elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="tooltip-i" transform="translate(-252.000000, -6371.000000)" fill="#FFFFFF" fillRule="nonzero">
              <g id="Tooltip" transform="translate(120.000000, 6208.000000)">
                <g id="Group" transform="translate(125.000000, 160.000000)">
                  <path d="M7.34,3.2 L8.88,3.2 L8.88,4.67 L7.34,4.67 L7.34,3.2 Z M7.55,6 L8.67,6 L8.67,13 L7.55,13 L7.55,6 Z" id="i"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </RayloTooltipTrigger>

      <RayloTooltip direction={direction} ref={tooltip}>
        <p>{children}</p>
      </RayloTooltip>
    </RayloTooltipContainer>
  );
};

Tooltip.defaultProps = {
  direction: 'left',
};

const RayloTooltipContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 16px;
  width: 16px;
`;

const RayloTooltipTrigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: ${colors.rayloBlue};
  position: absolute;
  top: 0;
  left: ${props => props.direction === 'left' ? 'unset' : '0'};
  right: ${props => props.direction === 'right' ? 'unset' : '0'};
  cursor: help;
  z-index: 2;
  overflow: hidden;

  svg {
    position: relative;
    #tooltip-i {
      transition: fill .25s;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    left: ${props => props.direction === 'left' ? 'unset' : '-16px'};
    right: ${props => props.direction === 'right' ? 'unset' : '-16px'};
    background-color: ${colors.rayloPink};
    transition: transform .3s;
  }

  &:hover, &:focus {
    #tooltip-i {
      fill: ${colors.rayloBlue};
    }

    &::before {
      transform: ${props => props.direction ? { left: 'translateX(-16px)', right: 'translateX(16px)' }[props.direction] : '0'};
    }
  }
`;

const RayloTooltip = styled.div`
  position: absolute;
  top: 0;
  left: ${props => props.direction === 'left' ? 'unset' : '0'};
  right: ${props => props.direction === 'right' ? 'unset' : '0'};
  z-index: -1;
  background: ${colors.rayloBlue};
  padding: 28px 16px;
  width: 250px;
  opacity: 0;
  transition: all .35s .15s;

  p {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    font-size: 0.875em;
    color: ${colors.rayloLight};
    letter-spacing: 0.5px;
    line-height: 1.28;
  }

  ${RayloTooltipTrigger}:hover ~ & {
    opacity: 1;
    z-index: 1;
  }
`;
