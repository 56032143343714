import {useEffect} from 'react';
import {Overview} from 'screens/Account/Overview/Overview';
import {Payments} from 'screens/Account/Payments/Payments';
import {Insurance} from 'screens/Account/Insurance';
import {InsuranceDamage} from 'screens/Account/InsuranceDamage';
import {InsuranceLiquid} from 'screens/Account/InsuranceLiquid';
import {InsuranceLost} from 'screens/Account/InsuranceLost';
import {InsuranceBlock} from 'screens/Account/InsuranceBlock';
import {InsuranceStolen} from 'screens/Account/InsuranceStolen';
import {InsuranceCrime} from 'screens/Account/InsuranceCrime';
import {InsuranceClaim} from 'screens/Account/InsuranceClaim';
import {Referrals} from 'screens/Account/Referrals';
import {YourAccount} from 'screens/Account/YourAccount';
import * as Sentry from '@sentry/react';
// NAVIGATION
import {Route, Switch} from 'react-router-dom';
import store from 'store';
import {DeviceProfile} from './DeviceProfile/DeviceProfile';
import {PaymentScreen} from './Payment/PaymentScreen';
import {ArrearsPaymentStatusScreen} from 'screens/Account/Payment/ProcessingPayment/ArrearsPaymentStatusScreen';
import {AccountWrapper} from './AccountWrapper/AccountWrapper';
import {FormProvider} from './Forms/context/useFormContext';
import {PostUpgradeForm} from './Forms/SubscriptionReturns/PostUpgradeForm';
import {CancelLeaseForm} from "./Forms/SubscriptionReturns/CancelLeaseForm";
import { track } from 'integrations/segment/events';
import { CheckoutMode } from '../Checkout/enums/checkoutModes';
import { RedirectToRayloUrl } from './RedirectToRayloUrl';
import { AccountProvider } from './hooks/useAccountContext';
import { sendMessageToApp } from 'utils/sendMessageToApp';
import EndOfLeaseOptions from "./EndOfLeaseOptions";

const SentryRoute = Sentry.withSentryRouting(Route);

export const AccountRouter = () => {
  const userToken = store.get("userToken");

  useEffect(() => {
    if (userToken) {
      sendMessageToApp({ action: 'USER_IS_LOGGED_IN', payload: true });
      return;
    }
    sendMessageToApp({ action: 'USER_IS_LOGGED_IN', payload: false });

    track('Login Event', {
      page: 'Account',
      event: 'User redirected to /login'
    });
    window.location.assign(`/login?return-to=${window.location.pathname + window.location.search}`);
  }, [userToken]);

  return (
    <Switch>
      <SentryRoute path={["/account/redirect/:target?", "/account/add-tech/:target?", "/account/add/:target?"]}>
        <AccountProvider>
          <RedirectToRayloUrl checkoutContext={CheckoutMode.ADDITIONAL_TECH}/>
        </AccountProvider>
      </SentryRoute>
      <SentryRoute path="/account/upgrade/:subscriptionId/:target?">
        <AccountProvider>
          <RedirectToRayloUrl checkoutContext={CheckoutMode.UPGRADE}/>
        </AccountProvider>
      </SentryRoute>
      <SentryRoute exact path="/account/start-return/:subscriptionId">
        <FormProvider>
          <PostUpgradeForm/>
        </FormProvider>
      </SentryRoute>
      <SentryRoute exact path="/account/cancel-lease/:subscriptionId">
        <FormProvider>
          <CancelLeaseForm/>
        </FormProvider>
      </SentryRoute>
      <SentryRoute exact path="/account/end-of-lease-options/:orderId">
        <AccountWrapper showFooter>
          <EndOfLeaseOptions/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/payment/:incomingPaymentId/processing/:amount/:currency">
        <AccountWrapper section="payment/processing" showFooter>
          <ArrearsPaymentStatusScreen/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/payment/:subscriptionId">
        <AccountWrapper section="payment" showFooter>
          <PaymentScreen/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/payments/:orderId">
        <AccountWrapper>
          <Payments/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/damage/:orderId">
        <AccountWrapper>
          <InsuranceDamage/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/liquid/:orderId">
        <AccountWrapper>
          <InsuranceLiquid/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/lost/claim/:orderId">
        <AccountWrapper>
          <InsuranceClaim lost={true}/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/lost/block/:orderId">
        <AccountWrapper>
          <InsuranceBlock lost={true}/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/lost/:orderId">
        <AccountWrapper>
          <InsuranceLost/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/stolen/claim/:orderId">
        <AccountWrapper>
          <InsuranceClaim stolen={true}/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/stolen/crime/:orderId">
        <AccountWrapper>
          <InsuranceCrime/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/stolen/block/:orderId">
        <AccountWrapper>
          <InsuranceBlock stolen={true}/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/stolen/:orderId">
        <AccountWrapper>
          <InsuranceStolen/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/insurance/:orderId">
        <AccountWrapper>
          <Insurance/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/refer-a-friend">
        <AccountWrapper section="refer-a-friend">
          <Referrals/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/your-account">
        <AccountWrapper section="account">
          <YourAccount/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account/:orderId">
        <AccountWrapper section="view-device">
          <DeviceProfile/>
        </AccountWrapper>
      </SentryRoute>
      <SentryRoute path="/account">
        <AccountWrapper>
          <Overview/>
        </AccountWrapper>
      </SentryRoute>
    </Switch>
  );
};
