import { captureErrorEvent } from "utils/errorTracking"
import { useHistory } from "react-router"

export function useErrorNavigator() {
  const history = useHistory()
  function run(message, context, url = "/errors/oops") {
    captureErrorEvent(message, context)
    history.push(url)
  }
  return run
}
