import React from 'react';
import styled from 'styled-components';
import { orderBy, filter } from 'lodash-es';
import colors from 'styles/colors';

import dayjs from 'dayjs';
import { CSS_FONT_FAMILY, CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';

export const InsuranceClaims = ({ claims, policy }) => {
  const orderedClaims = orderBy(
    filter(claims, o => o.state === 'STARTED')
    , ['createdAt'], ['asc']);

  if (orderedClaims.length > 0) {
    return (

      <InsuranceClaimsOuter>
        <InsuranceClaimsInner>
          <InsuranceClaimsHeader>
            <h5 className="heading">Your current insurance claims</h5>
          </InsuranceClaimsHeader>

          <InsuranceClaimsWrapper>
            {orderedClaims.map((claim, i) => (
              <InsuranceClaim key={i}>
                <InsuranceClaimLeft>
                  <InsuranceClaimDate>{dayjs(claim.createdAt).format('DD/MM/YY')}</InsuranceClaimDate>

                  <InsuranceClaimInfo>
                    <InsuranceClaimType>
                      <span>{claim.reason.displayName}</span>
                    </InsuranceClaimType>
                  </InsuranceClaimInfo>
                </InsuranceClaimLeft>

                <InsuranceClaimRight>

                  <InsuranceClaimTotal>
                    <a href={policy.claimUrl}>MORE DETAILS</a>
                  </InsuranceClaimTotal>
                </InsuranceClaimRight>
              </InsuranceClaim>
            )
            )}
          </InsuranceClaimsWrapper>
        </InsuranceClaimsInner>
      </InsuranceClaimsOuter>
    );
  } else {
    return null;
  }
};

const InsuranceClaimsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 88px;

  @media (max-width: 768px) {
    margin-bottom: 28px;
  }

  .card-icon {
    margin-right: 20px;
  }

  .heading {
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    font-size: 1.25em;
    color: #2D2D2D;
    line-height: 1.5;
  }
`;

const InsuranceClaimsOuter = styled.section`
background-color: #fff;
padding: 75px 0;
`;

const InsuranceClaimsInner = styled.div`
max-width: 900px;
margin: 0 auto;
padding: 0 40px;

@media (max-width: 540px) {
  padding: 0 20px;
}
`;

const InsuranceClaimsWrapper = styled.ol`
list-style-type: none;
margin: 0;
padding: 0;
`;

const InsuranceClaim = styled.li`
display: flex;
justify-content: space-between;
padding-bottom: 20px;
border-bottom: 1px solid #E0E0E0;

&:not(:last-of-type) {
  margin-bottom: 20px;
}

@media (max-width: 675px) {
  flex-direction: column;
}
`;

const InsuranceClaimLeft = styled.div`
display: flex;
align-items: flex-start;
width: 50%;

@media (max-width: 675px) {
  margin-bottom: 20px;
  width: auto;
}

@media (max-width: 420px) {
  justify-content: space-between;
}
`;

const InsuranceClaimRight = styled.div`
display: flex;
align-items: flex-end;

@media (max-width: 675px) {
  align-self: flex-end;
}

@media (max-width: 420px) {
  justify-content: space-between;
  width: 100%;
}
`;

const InsuranceClaimDate = styled.span`
display: inline-block;
font-weight: ${CSS_FONT_WEIGHT.vars.medium};
font-size: 0.875em;
width: 30%;
color: ${colors.rayloDark};
line-height: 1.5;

@media (max-width: 675px) {
  width: auto;
  margin-right: 28px;
}
`;

const InsuranceClaimInfo = styled.div`
font-weight: ${CSS_FONT_WEIGHT.vars.regular};
font-size: 0.875em;
color: ${colors.raylo2Dark};
line-height: 1.5;
width: 70%;

@media (max-width: 675px) {
  width: auto;
}
`;

const InsuranceClaimType = styled.div`
&:not(:last-of-type) {
  margin-bottom: 8px;
}

span:first-of-type {
  font-family: ${CSS_FONT_FAMILY.vars.mono};
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  margin-right: 12px;
}

span {
  @media (max-width: 420px) {
    display: block;
  }
}
`;

const InsuranceClaimStatus = styled.div`
display: flex;
align-items: center;
font-weight: ${CSS_FONT_WEIGHT.vars.regular};
font-size: 0.875em;
color: ${colors.raylo2Dark};
line-height: 1.5;
margin-right: 48px;

img {
  margin-right: 12px;
}
`;

const InsuranceClaimTotal = styled.div`
display: flex;
align-items: center;
font-family: ${CSS_FONT_FAMILY.vars.mono};
font-size: 0.875em;
color: ${colors.rayloBlue3Dark};
line-height: 1.5;

span, a {
  margin-right: 12px;
  text-decoration: none;
  color: inherit;
}
`;
