import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Icon from 'elements/Icon';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledCheckBox } from './styles';
import { ICheckBox } from './types';

/**
 * @deprecated This component has been replaced with the `<FormCheckbox />` component in `@raylo-tech/raylopay-ui`.
 */

export const CheckBox = ({
  dataTestId,
  onClick,
  active,
  value,
  name,
  id,
  inputTestId,
  label,
}: ICheckBox) => {
  return (
    <Container flexRow noPadding>
      <Container noPadding width={px2Rem(26)}>
        <StyledCheckBox
          data-testid={dataTestId}
          onClick={onClick}
          active={active}
        >
          <input
            readOnly
            data-testid={inputTestId}
            type="checkbox"
            value={value}
            name={name}
            id={id}
            checked={active}
            style={{
              display: 'none',
            }}
          />
          {active && <Icon name="CheckSqaured" color="white" />}
        </StyledCheckBox>
      </Container>
      <Container noPadding>
        {label && (
          <Container
            padding={`${px2Rem(2)} 0 0 ${px2Rem(12)}`}
            centerHorizontal
          >
            <Copy color={primaryColors.c01}>{label}</Copy>
          </Container>
        )}
      </Container>
    </Container>
  );
};
