import React from 'react';
import Container from '../../elements/Container';
import { StyledLearnMoreModalContent } from './LearnMoreModalContent.styles';
import { ILearnMoreModalContent } from './LearnMoreModalContent.types';
import Spacer from '../../elements/Spacer';
import InfoBox from '../InfoBox';
import TrustbuilderBox from '../TrustbuilderBox';
import TrustPilotBar from '../TrustPilotBar';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';
import FAQs from '../../templates/FAQs';
import { ITrustbuilderBox } from '../TrustbuilderBox/TrustbuilderBox.types';
import { IInfoBox } from '../InfoBox/InfoBox.types';

const items: ITrustbuilderBox[] = [
  {
    title: 'Lease for less',
    copy: 'Leasing means lower prices with no upfront cost and it’s interest-free',
    icon: 'RayloDesignWallet',
  },
  {
    title: 'Choose a term',
    copy: 'Pick 12 or 24 months for regular upgrades or 36 months for lowest prices',
    icon: 'RayloDesignLaptop',
  },
  {
    title: 'Upgrade for free',
    copy: 'At the end, either upgrade for free or leave us by returning your device',
    icon: 'RayloDesignRocket',
  },
];

const infoItems: IInfoBox[] = [
  {
    icon: 'Dashboard',
    copy: 'Applying has no impact on your credit score',
  },
  {
    icon: 'Stopwatch',
    copy: 'Apply in just minutes, hassle free and get an instant decision',
  },
  {
    icon: 'Insurance',
    copy: 'Optional insurance, including cover against accidental damage',
  },
  {
    icon: 'Ecology',
    copy: 'Our refurb and reuse approach means your returned tech gets as many lives as possible',
  },
];

export const LearnMoreModalContent = ({
  dataTestId,
  showFooter,
  showTrustPilotBanner,
  showFAQs,
}: ILearnMoreModalContent) => {
  return (
    <StyledLearnMoreModalContent data-testid={dataTestId}>
      {showTrustPilotBanner && (
        <Container>
          <TrustPilotBar dataTestId="learn-more-trust-pilot" />
        </Container>
      )}
      <Container
        padding={px2Rem(20)}
        styles={{
          width: 'initial',
        }}
        backgroundColor={palette.white}
      >
        {items.map(({ title, copy, icon }, i) => (
          <div key={i}>
            <TrustbuilderBox icon={icon} copy={copy} title={title} />
            {i !== items.length - 1 && <Spacer height={32} />}
          </div>
        ))}
        <Spacer height={32} />
        {infoItems.map(({ icon, copy }, i) => (
          <div key={i}>
            <InfoBox icon={icon} copy={copy} />
            {i !== infoItems.length - 1 && <Spacer height={16} />}
          </div>
        ))}
        {showFAQs && (
          <FAQs showFooter={showFooter} />
        )}
      </Container>
    </StyledLearnMoreModalContent>
  );
};
