import { useEffect, useState } from 'react';
import constate from 'constate';
import { INameEmailValues } from 'screens/Checkout/components/StepNameEmailContent/types';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import useRayloQuery from 'utils/useRayloQuery';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { useMutation } from '@apollo/client';

interface IStepNameEmailContext {
  checkoutToken?: string;
  onSuccess: () => void;
}

const useStepNameEmail = ({ onSuccess }: IStepNameEmailContext) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [values, setValues] = useState<INameEmailValues | undefined>();
  const [presetValues, setPresetValues] = useState<
    INameEmailValues | undefined
  >();
  const [formErrors, setFormErrors] = useState<any>(null);

  const { checkoutToken } = useCheckoutContext();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepNameEmail.query, {
    variables: {
      token: checkoutToken,
    },
  });

  const [updateMutation] = useMutation(CheckoutSteps.stepNameEmail.mutation, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { errors },
        },
      }
    ) => {
      if (errors && errors.length > 0) {
        setFormErrors(errors);
      } else {
        setFormErrors(null);
        onSuccess();
      }
    },
  });

  useEffect(() => {
    if (formSubmitted) {
      onSuccess();
    }
  }, [formSubmitted]);

  useEffect(() => {
    if (values?.firstName) {
      updateMutation({
        variables: {
          ...values,
          checkoutToken,
        },
      });
    }
  }, [values]);

  useEffect(() => {
    if (checkout?.customerInfo?.firstName) {
      setPresetValues({
        firstName: checkout?.customerInfo?.firstName,
        middleName: checkout?.customerInfo?.middleName,
        lastName: checkout?.customerInfo?.lastName,
        email: checkout?.customerInfo?.email,
      });
    }
  }, [!loading]);

  return {
    formErrors,
    presetValues,
    formSubmitted,
    setValues,
    setFormSubmitted,
  };
};

const [StepNameEmailProvider, useStepNameEmailContext] =
  constate(useStepNameEmail);
export { StepNameEmailProvider, useStepNameEmailContext };
