import React from 'react';
import secureShield from 'public/icons/icon_secure_shield.svg';
import { SecurePaymentInfoStyles } from 'styles/screens/Payment/SecurePaymentInfoStyles';
import Copy from '../../elements/Copy';
import { secondaryColors } from '../../styles/variables/colors';
import { px2Rem } from '../../utils/px2Rem';
import Divider from '../../elements/Divider';

export const SecurePaymentInfo = () => {
  return (
    <SecurePaymentInfoStyles>
      <Divider
        color={secondaryColors.c04}
        styles={`margin: ${px2Rem(24)} 0;`}
      />
      <img
        src={secureShield}
        alt="Secure payment shield icon"
        style={{ display: 'inline' }}
      />
      <Copy
        fontSize={14}
        color={secondaryColors.c02}
        bold
        styles={`
            display: inline;
            vertical-align: top;
            margin-left: ${px2Rem(8)};
            line-height: 30px;
            `}
      >
        Secure payment
      </Copy>
    </SecurePaymentInfoStyles>
  );
};
