import { palette } from '../../colors';
import { StyledRadioDisplayBox, StyledRadioDisplayInner, StyledRadioDisplayLabelWrapper, StyledRadioDisplayMeta, StyledRadioDisplayPrice } from './RadioButtonBox.styles';
import { IRadioButtonBox } from './RadioButtonBox.types';
import Typography from '../../foundations/Typography';

export const RadioButtonBox = ({
  dataTestId,
  label,
  labelSubCopy,
  active,
  onClick,
  large,
  medium,
  isColumn,
  index,
  pricePlan,
  formattedMonthlyPrice,
  formattedMonthlyPriceSuffix,
  formattedMonthlyPriceSubCopy,
  borderColor,
  activeBorderColor,
  textColor,
  hoverBorderColor,
  optionUnavailable,
  layout = 'row',
  textAlignment,
}: IRadioButtonBox) => {
  return (
    <StyledRadioDisplayBox
      isColumn={isColumn}
      data-testid={dataTestId}
      onClick={() => onClick(index)}
      active={active}
      large={large}
      medium={medium}
      borderColor={borderColor}
      activeBorderColor={activeBorderColor}
      textColor={textColor}
      hoverBorderColor={hoverBorderColor}
      optionUnavailable={optionUnavailable}
      textAlignment={textAlignment}
    >
      {!pricePlan ?
        label :
          (
            <StyledRadioDisplayInner $layout={layout}>
              <StyledRadioDisplayLabelWrapper>
                <Typography
                  variant="body2"
                  bold={active}
                  color="charcoal.500"
                  style={{
                    whiteSpace: 'nowrap',
                    margin: 0,
                  }}
                >
                  {label}
                </Typography>
                {labelSubCopy}
              </StyledRadioDisplayLabelWrapper>
              <StyledRadioDisplayMeta $layout={layout}>
                <StyledRadioDisplayPrice>
                  <Typography
                    // In `column` layout, the price is displayed in a smaller font size
                    variant={layout === 'column' ? 'body2' : 'h4'}
                    // In `column` layout, the price is displayed in bold
                    bold={active || layout === 'column'}
                    style={{ margin: 0 }}
                  >
                    {formattedMonthlyPrice}
                  </Typography>
                  <Typography variant="body2" style={{ margin: 0 }}>/mo</Typography>
                  {formattedMonthlyPriceSuffix}
                </StyledRadioDisplayPrice>
                {formattedMonthlyPriceSubCopy}
              </StyledRadioDisplayMeta>
            </StyledRadioDisplayInner>
          )}
    </StyledRadioDisplayBox>
  );
};
