import React from 'react';
import DualFontWeightCopy from '../../components/DualFontWeightCopy/DualFontWeightCopy';
import Copy from '../../elements/Copy';
import { BulletList } from '../../components/BulletList/BulletList';
import { px2Rem } from '../../utils/px2Rem';

export const faqsFooterContent = 'Raylo Group Limited is authorised and regulated by the Financial Conduct Authority (841488)';

export const faqsData = [
  {
    title: 'Do I need to worry about wear and tear?',
    body: <Copy lineHeight={20} fontSize={14}>Don&apos;t worry, we offer a fair wear and tear policy. It&apos;s ok to return your device with obvious signs of use such as some deep scratching (2 or fewer deep scratches on front glass that are less than 3cm in length), small dents, discoloration or changes in the paintwork.</Copy>,
  },
  {
    title: 'What if my device gets damaged?',
    body: <DualFontWeightCopy lineHeight={20} fontSize={14}>If you return the phone to us damaged, we may charge you for the cost of repair as outlined by the <span>Repair Charges</span> set out in your agreement. You can opt for Raylo insurance at checkout which covers you against accidental damage, loss and theft.</DualFontWeightCopy>,
  },
  {
    title: 'When does my lease begin and end?',
    body: <Copy lineHeight={20} fontSize={14}>The lease begins when your order is confirmed and your first payment is due 14 days later. Your lease ends when you return your device, after your chosen lease term.</Copy>,
  },
  {
    title: 'Can I cancel after I order?',
    body: <Copy lineHeight={20} fontSize={14}>Sure! If you have a change of heart you can cancel and return for free within the first 14 days.</Copy>,
  },
  {
    title: 'What happens when it’s time to upgrade?',
    body: (
      <>
        <Copy styles={{ paddingBottom: px2Rem(16) }} lineHeight={20} fontSize={14}>At the end of your lease you can simply upgrade for free and return your old device. You have 14 days to transfer your data – plenty of time.</Copy>
        <BulletList
          items={[
            'Keep the device and continue paying monthly',
            "Cancel your lease and return your device to Raylo. If you're unable to return your device you’ll need to pay a fair fee",
          ]}
          bulletIcon="Ellipse24RayloBlue"
        />
      </>
    ),
  },
  {
    title: 'Do you run a soft search credit check?',
    body: <Copy lineHeight={20} fontSize={14}>Yes, at Raylo we only soft credit check when you sign up. The benefit of a soft credit check is there&apos;s no impact on your credit rating by applying.</Copy>,
  },
  {
    title: 'Can I trust Raylo?',
    body: <Copy lineHeight={20} fontSize={14}>Of course! We&apos;re rated 4.5 on Trustpilot. We&apos;re also partners of Natwest and Telefonica – you&apos;re in safe hands.</Copy>,
  },
];
