import { FadeIn } from 'components/animations/Transitions';
import MissingAddress from 'components/MissingAddress';
import Button from 'elements/Button';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Spacer from 'elements/Spacer';
import TextInput from 'elements/TextInput';
import { useEffect, useState } from 'react';
import { useCheckoutAddressContext } from 'screens/Checkout/containers/StepAddress/hooks/useCheckoutAddressContext';
import { secondaryColors, uiStateColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useScript } from 'utils/useScript';
import ITestDataId from 'types/ITestDataId.type';
import { StyledStepAddressNew } from './styles';

const fields = [{ element: 'search', field: '' }];
const options = {
  key: process.env.REACT_APP_LOQATE_KEY,
  search: { countries: 'GBR' },
};

export const StepAddressNew = ({ dataTestId }: ITestDataId) => {
  const [control, setControl] = useState<any>();
  const [missingAddress, setMissingAddress] = useState<boolean>(false);
  const [addressTypeError, setAddressTypeError] = useState<boolean>(false);

  const {
    setNewAddress,
    setNewAddressSet,
    addPreviousAddress,
    setPreviousAddress,
    setAddressCheck,
  } = useCheckoutAddressContext();
  const [scriptLoaded, scriptError] = useScript(
    'https://services.postcodeanywhere.co.uk/js/address-3.91.js'
  );

  useEffect(() => {
    if (scriptLoaded && !scriptError) {
      if (window?.pca?.Address) {
        setControl(new window.pca.Address(fields, options));
      }
    }
  }, [scriptLoaded, scriptError, missingAddress]);

  useEffect(() => {
    if (control) {
      control.listen('populate', (address2: any) => {
        setAddressTypeError(false);
        const addedAddress = {
          line1: address2.Line1,
          line2: address2.Line2,
          line3: address2.Line3,
          city: address2.City,
          region: address2.Province,
          postcode: address2.PostalCode,
          countryIsoAlpha2: address2.CountryIso2,
        };
        if (addPreviousAddress) {
          setPreviousAddress(addedAddress);
          setAddressCheck(true);
        } else {
          setNewAddress(addedAddress);
        }
        if (address2.Type === 'Commercial') {
          setAddressTypeError(true);
        } else {
          setNewAddressSet(true);
        }
      });
    }
  }, [control]);

  const reset = () => {
    setMissingAddress(false);
  };

  if (missingAddress) {
    return <MissingAddress onBack={reset} />;
  }

  return (
    <FadeIn>
      <StyledStepAddressNew data-testid={dataTestId}>
        <>
          <Container noPadding height={px2Rem(80)}>
            <TextInput
              id="search"
              placeHolderText="Start typing to search..."
            />
          </Container>
          {addressTypeError && (
            <>
              <Copy color={uiStateColors.error}>
                Sorry - we can only ship to residential properties
              </Copy>
              <Spacer height={12} />
            </>
          )}
          <Copy fontSize={14} color={secondaryColors.c02} dataTestId={`${dataTestId}-helper-copy`}>
            {addPreviousAddress ? 'This should be the address where you lived before your current address.' :
              'This should be your current home address. Please make sure these details are up to date with your bank.'}
          </Copy>
          <Spacer height={40} />
          <Container noPadding width="100%">
            <Button
              buttonType="ghost"
              onClick={() => setMissingAddress(true)}
              size="large"
              text="Can't find my address"
              dataTestId="confirm-address-not-found"
            />
          </Container>
        </>
      </StyledStepAddressNew>
    </FadeIn>
  );
};
