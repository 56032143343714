import React from 'react';

interface IHireAgreement {
  details: any;
}

export const HireAgreement = ({ details }: IHireAgreement) => {
  return (
    <div data-sentry-block style={{ height: '100%', margin: '0' }}>
      <iframe
        title="Hire Agreement"
        srcDoc={details.htmlHireAgreement}
        width="100%"
        style={{ border: 'none', height: 'calc(100% - 5px)' }}
      />
    </div>
  );
};
