import React from 'react';
import * as B from 'styles/buttons';

export const Button = ({ buttonStyle, alignRight, disabled, limitWidth, children, type = 'button', onClick, className = '', dataTestId }) => (
  <B.RayloButton
    type={type}
    buttonStyle={buttonStyle}
    alignRight={alignRight}
    disabled={disabled}
    onClick={onClick}
    limitWidth={limitWidth}
    className={className}
    data-testid={dataTestId}
  >
    {children}
  </B.RayloButton>
);
