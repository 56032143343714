import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledRayloPayLogo = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2Rem(16)};
  svg g {
    fill: ${({ theme }) => theme?.navBar?.rayloPayLogoColor};
  }
`;

export { StyledRayloPayLogo };
