import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledDoubleButtonFullWidth = styled.div`
  & button {
    width: 100%;
    font-size: ${px2Rem(16)};
  }
`;

export { StyledDoubleButtonFullWidth };
