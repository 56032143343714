import Container from 'elements/Container';
import Header from 'elements/Header';
import Icon from 'elements/Icon';
import ITestDataId from 'types/ITestDataId.type';
import { StyledTrustPilotBanner, IconWrapper, TitleWrapper } from './styles';

export const TrustPilotBanner = ({ dataTestId }: ITestDataId) => {
  return (
    <StyledTrustPilotBanner data-testid={dataTestId}>
      <Container flexRow centerVertical padding="0">
        <IconWrapper>
          <Icon name="TrustPilotLogo" size="32px" />
        </IconWrapper>
        <TitleWrapper>
          <Header level={3}>Trustpilot</Header>
        </TitleWrapper>
      </Container>
      <Container alignRight centerHorizontal padding="0">
        <img
          src="https://emailsignature.trustpilot.com/signature/en-GB/3/5cdd6c38b4acc6000128faf9/stars.png"
          alt="Our rating on Trustpilot"
          width="100"
        />
      </Container>
    </StyledTrustPilotBanner>
  );
};
