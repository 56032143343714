import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import ITestDataId from 'types/ITestDataId.type';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import TradeInJourneyDecision from '../TradeInJourneyDecision';
import TradeInJourneyStepOne from '../TradeInJourneyStepOne';
import TradeInJourneyStepTwo from '../TradeInJourneyStepTwo';
import { StyledTradeInJourney } from './styles';
import { useEffect } from 'react';

export const TradeInJourney = ({ dataTestId }: ITestDataId) => {
  const { stepIndex, tradeInExists } = useStepTradeInContext();

  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'trade in',
    checkoutScreen: 'want to trade in',
    when: 'on page load',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepIndex]);

  return (
    <StyledTradeInJourney data-testid={dataTestId}>
      {stepIndex === 0 && <TradeInJourneyStepOne />}
      {stepIndex === 1 && <TradeInJourneyStepTwo />}
      {stepIndex === 2 && <TradeInJourneyDecision />}
      {tradeInExists && <TradeInJourneyDecision />}
    </StyledTradeInJourney>
  );
};
