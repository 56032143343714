import Container from 'elements/Container';
import Icon from 'elements/Icon';
import { primaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { StyledBackArrow } from './styles';
import { IBackArrow } from './types';

export const BackArrow = ({ dataTestId, onClick, color }: IBackArrow) => {
  return (
    <StyledBackArrow data-testid={dataTestId}>
      <Container center onClick={onClick} maxWidth={px2Rem(40)} cursorPointer>
        <Icon
          name="BackArrow"
          color={color || primaryColors.c01}
          size={px2Rem(32)}
          dataTestId="back-arrow-icon"
        />
      </Container>
    </StyledBackArrow>
  );
};
