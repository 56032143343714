import FormCollection from 'components/FormCollection';
import { TrustpilotBullets, Typography, TextLink } from '@raylo-tech/raylopay-ui';
import { Button } from 'elements';
import { ButtonTypes } from 'elements/Button/types';
import { useMutation } from '@apollo/client';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import FormErrorMessages from 'components/FormErrorMessages';
import { useState } from 'react';
import { IFormErrorMessages } from 'components/FormErrorMessages/types';
import { FormValues } from '../CheckoutStepsContainer/types';
import { px2Rem } from 'utils/px2Rem';

const StepEmail = ({
  onSuccess,
  checkoutToken,
  emailPresetValue,
  setPresetValues,
}: {
  onSuccess: () => void;
  checkoutToken?: string;
  emailPresetValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPresetValues: (val?: any) => void;
}) => {
  const [formErrors, setFormErrors] = useState<IFormErrorMessages['formErrors']>([]);
  const [updateEmailMutation] = useMutation(CheckoutSteps.stepEmail.mutation, {
    update: (
      _,
      {
        data: {
          updateCheckout: { errors },
        },
      }
    ) => {
      if (errors && errors.length > 0) {
        setFormErrors(errors);
      } else {
        setFormErrors(null);
        onSuccess();
      }
    },
  });

  const onSubmit = (vals: FormValues) => {
    setPresetValues((oldState: FormValues) => ({
      ...oldState,
      ...vals,
    }));

    updateEmailMutation({
      variables: {
        ...vals,
        checkoutToken,
      },
    });
  };

  return (
    <>
      <Typography
        bold
        variant="h2"
        element="h2"
        style={{ paddingBottom: px2Rem(24) }}
      >
        Let's get started
      </Typography>
      <Typography
        variant="body1"
        style={{ paddingBottom: px2Rem(24) }}
      >
        This will take less than 5 minutes
      </Typography>
      <FormCollection
        dataTestId="step-email"
        presetValues={{ email: emailPresetValue }}
        fields={[
          {
            field: 'email',
            name: 'Email address',
            type: 'email',
            required: true,
          },
        ]}
        handleSubmit={onSubmit}
        formId="step-email"
      />
      <TrustpilotBullets
        listItems={
          [
            'Join 100,000+ subscribers',
            'Reduce your carbon impact by 56% when you lease instead of buy',
            '£0 today, return for free within 14 days',
          ]
        }
      />
      <Typography
        style={{ padding: `${px2Rem(24)} 0 ${px2Rem(16)}` }}
        variant="body2"
        color="charcoal.400"
      >
        By clicking next you are accepting our{' '}
        <TextLink
          href="https://www.raylo.com/legal"
          target="_blank"
          fontSize={14}
        >
          privacy policy
        </TextLink>
      </Typography>
      <FormErrorMessages formErrors={formErrors} />
      <Button
        type="submit"
        size="large"
        text="Next"
        fullWidth
        buttonColor={ButtonTypes.b01}
        formId="step-email"
      />
    </>
  );
};

export default StepEmail;
