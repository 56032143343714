// Generated with util/create-component.js
import React from 'react';
import styled from 'styled-components';
import Copy from '../../elements/Copy';

import { DualFontWeightCopyProps } from './DualFontWeightCopy.types';
import { CSS_FONT_WEIGHT } from '../../constants';

const StyledDualFontWeightCopy = styled.div`
  & p > span {
    font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  }
`;

/**
 * @deprecated This component has been replaced with the `<Typography />` component in `@raylo-tech/raylopay-ui`.
 */

const DualFontWeightCopy: React.FC<DualFontWeightCopyProps> = ({
  children,
  fontSize,
  fontColor,
  lineHeight,
}) => (
  <StyledDualFontWeightCopy data-testid="DualFontWeightCopy">
    <Copy fontSize={fontSize} color={fontColor} lineHeight={lineHeight}>
      {children}
    </Copy>
  </StyledDualFontWeightCopy>
);

export default DualFontWeightCopy;
