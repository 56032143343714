import styled from 'styled-components';
import { px2Rem } from 'utils/px2Rem';

const StyledTrustPilotBanner = styled.div`
  display: flex;
`;

const IconWrapper = styled.span`
  & svg {
    color: ${({ theme }) => theme?.components?.trustPilot?.iconColor};
    margin-top: ${px2Rem(-4)};
  }
  margin-right: ${px2Rem(8)};
`;

const TitleWrapper = styled.span`
  & h3 {
    color: ${({ theme }) => theme?.components?.trustPilot?.titleColor};
    font-size: ${px2Rem(20)};
  }
`;
export { StyledTrustPilotBanner, IconWrapper, TitleWrapper };
