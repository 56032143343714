import { StyledImgixImage } from './styles';
import { IImgixImage } from './types';

type GetImageSrcProps = {
  src: string;
  width: number;
  height: number;
};

const getImageSrc = ({ src, width, height }: GetImageSrcProps) => {
  if (!src) {
    return '';
  }

  try {
    const url = new URL(src);

    if (!url.host.endsWith('imgix.net')) {
      return url.toString();
    }

    const params = url.searchParams;

    params.set('w', width.toString());
    params.set('q', (params.get('q') || 80).toString());
    params.set('ar', `${width}:${height}`);

    return url.href;
  } catch {
    return src;
  }
};

export const ImgixImage = ({
  alt,
  src,
  width,
  height,
  imageWidths,
  sizes,
  loading,
  styles,
  dataTestId,
}: IImgixImage) => {
  const allSizes = imageWidths.flatMap(imageWidth => [
    imageWidth,
    imageWidth * 2,
  ]);
  const uniqueSizes = Array.from(new Set(allSizes)).sort((a, b) => a - b);
  const srcSet = uniqueSizes
    .map((imageWidth) => {
      const url = getImageSrc({ src, width: imageWidth, height });

      return `${url} ${imageWidth}w`;
    })
    .join(', ');

  return (
    <StyledImgixImage
      src={getImageSrc({ src, width, height })}
      alt={alt}
      loading={loading ?? 'lazy'}
      width={width}
      height={height}
      srcSet={srcSet}
      sizes={sizes}
      style={styles}
      data-testid={dataTestId}
    />
  );
};
