export const fields = [
  {
    field: 'accountType',
    name: 'Is this a business or personal account?',
    type: 'radio-checkbox',
    required: true,
    options: [
      {
        id: 'BUSINESS',
        label: 'BUSINESS',
      },
      {
        id: 'PERSONAL',
        label: 'PERSONAL',
      },
    ],
  },
  {
    field: 'accountName',
    name: 'Name of account holder(s)',
    type: 'text',
    required: true,
  },
  {
    field: 'accountNumber',
    name: 'Account number',
    type: 'number',
    pattern: 'd*',
    required: true,
    maxLength: 8,
  },
  {
    field: 'sortCode',
    name: 'Sort code',
    type: 'number',
    pattern: 'd*',
    required: true,
    maxLength: 6,
  },
];
