import { RadioButton, Typography } from '@raylo-tech/raylopay-ui';
import { IFormRadioList } from './types';
import { px2Rem } from 'utils/px2Rem';
import { capitalize } from 'lodash-es';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { ConsumerTypesEnum } from 'screens/Checkout/enums/consumerTypes';

const FormRadioList = ({ onChange, options, name, errorMessage, value }: IFormRadioList) => {
  const { consumerType } = useCheckoutContext();
  const isBusinessCheckout = consumerType === ConsumerTypesEnum.BUSINESS;

  return (
    <>
      <Typography
        bold
        style={{ paddingBottom: px2Rem(16) }}
      >
        {isBusinessCheckout ? 'Type of lease' : 'Is this a business or personal account?'}
      </Typography>
      {options.map(({ label }, i: number) => (
        <RadioButton
          checked={value === label}
          dataTestId={`radio-${label}`}
          label={capitalize(label)}
          key={label}
          name={name}
          onChange={onChange}
          errorMessage={errorMessage}
          marginBottom={i === options.length - 1 ? 44 : 16}
        />
      ))}
    </>
  );
};

export default FormRadioList;
