import Container from 'elements/Container';
import Copy from 'elements/Copy';
import { primaryColors } from 'styles/variables/colors';
import { StyledDirectDebitTotalDetails } from './styles';
import { IDirectDebitTotalDetails } from './types';

export const DirectDebitTotalDetails = ({
  dataTestId,
  backgroundColor,
  padding,
}: IDirectDebitTotalDetails) => {
  return (
    <StyledDirectDebitTotalDetails data-testid={dataTestId}>
      <Container
        noPadding={!padding}
        backgroundColor={backgroundColor}
        padding={padding}
      >
        <Copy color={primaryColors.c01} lineHeight={22} bold>
          Soft search
        </Copy>
        <Copy color={primaryColors.c01} lineHeight={22}>
          The benefit of a soft search is there&apos;s no impact on your credit
          score. At Raylo, only if we say &ldquo;Yes&rdquo; and approve your
          application will a full credit search be undertaken.
        </Copy>
      </Container>
    </StyledDirectDebitTotalDetails>
  );
};
