import styled from 'styled-components'
import { CSS_FONT_WEIGHT } from '@raylo-tech/raylopay-ui';
import colors from './colors';

import checkboxTick from 'public/icon--checkbox-tick.svg';

/**
 * @deprecated This component has been replaced with the `<Input />` component in `@raylo-tech/raylopay-ui`.
 */
const RayloInput = styled('input')`
  appearance: none;
  outline: 0;
  border: 0;
  padding: 0;
  margin: 0;
  background: 0;

  &::selection {
    background-color: ${colors.rayloBlue};
    color: ${colors.rayloPink};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    -webkit-text-fill-color: ${colors.rayloPink} !important;
  }
`
/**
 * @deprecated This component has been replaced with the `<Input />` component in `@raylo-tech/raylopay-ui`.
 */
export const RayloTextInput = styled(RayloInput)`
  display: block;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.875em;
  height: 45px;
  color: ${colors.rayloPink};
  padding: 0.5em 1em 0.5em 1em;
  border: ${props => props.error ? `1px solid ${colors.rayloPink}` : `1px solid ${colors.raylo3Dark}`};
  border-radius: 0;
  width: 100%;
  transition: border .35s;

  &::placeholder {
    color: ${colors.raylo2Dark};
  }

  &:focus {
    border: 1px solid ${colors.rayloLight};
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }
`

export const RayloFieldHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`

export const RayloFieldLabel = styled.label`
  display: block;
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  color: ${colors.rayloLight};
  line-height: 2.14;
  opacity: ${props => props.isFocused ? '1' : '0.7'};
  transition: opacity .35s;
`
const RayloFieldLabelFake = styled.div`
  display: block;
  font-size: 0.875em;
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  color: ${colors.rayloLight};
  line-height: 2.14;
  opacity: ${props => props.isFocused ? '1' : '0.7'};
  transition: opacity .35s;
`


export const RayloFieldContent = styled.label`
  display: block;
  background-color: ${colors.rayloDark};
  padding: 14px 20px;

  @media (max-width: 450px) {
    margin-bottom: 12px;
  }
`

/**
 * @deprecated This component has been replaced with the `<Input />` component in `@raylo-tech/raylopay-ui`.
 */
export const RayloTextInputWithLoader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1em;
  width: 100%;
  border: ${props => props.isFocused ? `1px solid ${colors.rayloLight}` : props.error ? `1px solid ${colors.rayloPink}` : `1px solid ${colors.raylo3Dark}`};
  color: ${colors.rayloPink};
  transition: all .35s;

  input {
    display: block;
    width: 100%;
    height: 45px;
    padding: 0.5em 1em 0.5em 1em;
    border: 0;
    outline: 0;
    background: 0;
    font-weight: ${CSS_FONT_WEIGHT.vars.regular};
    font-size: 0.875em;
    color: inherit;

    &::placeholder {
      color: ${colors.raylo2Dark};
    }

    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
`

/**
 * @deprecated This component has been replaced with the `<FormCheckbox />` component in `@raylo-tech/raylopay-ui`.
 */
export const RayloCheckbox = styled(RayloInput)`
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid ${colors.raylo3Dark};
  border-radius: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  transition: all .35s;

  &:focus {
    border-color: ${colors.rayloLight};
  }

  &:checked {
    background-image: url('${checkboxTick}');
  }
`

export const RayloCheckboxLabel = styled.span`
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.875em;
  line-height: 1.625;
  margin-left: 12px;
  color: ${colors.rayloLight};
  cursor: pointer;
`

export const RayloError = styled.p`
  opacity: ${props => props.visible ? '1' : '0'};
  margin-top: 2px;
  margin-bottom: 0;
  font-weight: ${CSS_FONT_WEIGHT.vars.regular};
  font-size: 0.875em;
  color: ${colors.rayloPink3Dark} !important;
  letter-spacing: 0.5px;
  line-height: 2;
  text-align: left;
  transition: opacity .25s;
`

export const RayloFormRow = styled.div`
  display: block;
  margin-bottom: 18px;

  @media (max-width: 450px) {
    margin-bottom: 0;
  }
`
