import gql from 'graphql-tag';

export const CREATE_MERCHANT_CHECKOUT = gql`
  mutation CreateMerchantCheckout($merchantId: ID!, $payload: String!) {
    createMerchantCheckout(merchantId: $merchantId, payload: $payload) {
      checkout {
        token
      }
      errors {
        message
      }
    }
  }
`;
