import React from 'react';

import * as A from 'styles/account';
import * as T from 'styles/type';
import dayjs from 'dayjs';
import {Header} from "@raylo-tech/raylopay-ui";

export const NextPayment = ({ nextPaymentScheduleEvent }) => {
  if (nextPaymentScheduleEvent) {
    return (
      <A.PaymentRow>
        <A.PaymentRowHeader>
          <T.PaymentRowLabel date-testid="next-payment-label">
            <Header level={4} fontSize={16}>Next Payment</Header>
          </T.PaymentRowLabel>
        </A.PaymentRowHeader>

        <A.PaymentRowData>
          <div>
            <T.Payment>{dayjs(nextPaymentScheduleEvent.paymentOn).format('DD/MM/YY')}</T.Payment>
          </div>

          <div>
            <T.Payment>{nextPaymentScheduleEvent.amount.formattedValue}</T.Payment>
          </div>
        </A.PaymentRowData>
      </A.PaymentRow>
    );
  } else {
    return null;
  }
};
