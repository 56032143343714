import { useState } from 'react';
import { RayloModal } from '@raylo-tech/raylopay-ui';
import Copy from 'elements/Copy';
import TextLink from 'elements/TextLink';
import { primaryColors } from 'styles/variables/colors';

export const InfoBox = ({
  linkText,
  title,
  dataTestId,
  content,
  children,
}: {
  linkText: string;
  title: string;
  dataTestId: string;
  content?: string;
  children?: React.ReactNode;
}) => {
  if ((!content && !children) || (!!content && !!children)) {
    throw new Error('InfoBox must have exactly one of "content" or "children" props');
  }

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <TextLink onClick={openModal} dataTestId={dataTestId}>{linkText}</TextLink>

      <RayloModal
        dataTestId={`${dataTestId}-info-box`}
        modalOpen={modalOpen}
        title={title}
        handleCloseModal={closeModal}
        primaryButtonText="Okay"
        primaryButtonOnClick={closeModal}
      >
        {content ? <Copy fontSize={16} lineHeight={20} color={primaryColors.c01}>{content}</Copy> : children}
      </RayloModal>
    </>
  );
};
