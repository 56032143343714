import RayloModal from 'components/RayloModal';
import Copy from 'elements/Copy';
import Spacer from 'elements/Spacer';
import { primaryColors } from 'styles/variables/colors';
import { useUiContext } from 'utils/useUiContext';
import Container from 'elements/Container';
import Icon from 'elements/Icon';
import { px2Rem } from 'utils/px2Rem';
import ITestDataId from 'types/ITestDataId.type';

export const DirectDebitModal = ({ dataTestId }: ITestDataId) => {
  const { setShowModal, setShowDDModal } = useUiContext();
  const handleModalClose = () => {
    setShowModal(false);
    setShowDDModal(false);
  };
  return (
    <RayloModal
      header="The Direct Debit Guarantee"
      onClose={handleModalClose}
      dataTestId={dataTestId}
    >
      <Container noPadding alignCenter>
        <Icon name="DirectDebit" color={primaryColors.c01} size={px2Rem(70)} />
      </Container>
      <Copy color={primaryColors.c01} lineHeight={22}>
        This Guarantee is offered by all Banks and Building Societies that
        accept instructions to pay Direct Debits.
      </Copy>
      <Spacer height={32} />
      <Copy color={primaryColors.c01} lineHeight={22}>
        If there are any changes to the amount, date or frequency of your Direct
        Debit GoCardless re Raylo (181151) will notify you 5 working days in
        advance of your account being debited or as otherwise agreed. If you
        request GoCardless re Raylo (181151) to collect a payment, confirmation
        of the amount and date will be given to you at the time of the request.
      </Copy>
      <Spacer height={32} />
      <Copy color={primaryColors.c01} lineHeight={22}>
        If an error is made in the payment of your Direct Debit, by GoCardless
        re Raylo (181151) or your Bank or Building Society, you are entitled to
        a full and immediate refund of the amount from your Bank or Building
        Society.
      </Copy>
      <Spacer height={32} />
      <Copy color={primaryColors.c01} lineHeight={22}>
        If you receive a refund you are not entitled to, you must pay it back
        when GoCardless re Raylo (181151) asks you to.
      </Copy>
      <Spacer height={32} />
      <Copy color={primaryColors.c01} lineHeight={22}>
        You can cancel a Direct Debit at any time by simply contacting your Bank
        or Building Society. Written confirmation may be required. Please also
        notify us.
      </Copy>
      <Spacer height={48} />
    </RayloModal>
  );
};
