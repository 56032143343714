export const productCardDownsellData = [
  {
    img: 'https://raylo.imgix.net/iphone-se-2020-black.png?trim=color&fm=avif&w=400',
    condition: 'Refurbished',
    displayName: 'Apple iPhone SE (2020) 128GB Black',
    price: '7.99',
  },
  {
    img: 'https://raylo.imgix.net/galaxy-s20-fe-5g-blue.jpg?trim=color&fm=avif',
    condition: 'Refurbished',
    displayName: 'Samsung Galaxy S20 FE 5G 128GB Blue',
    price: '7.99',
  },
  {
    img: 'https://raylo.imgix.net/airpods-2-gen-white.png?trim=color&fm=avif&w=400',
    condition: 'Brand new',
    displayName: 'Apple AirPods (2nd gen) White',
    price: '7.99',
  },
  {
    img: 'https://raylo.imgix.net/apple-watch-series-5-44mm-black.jpg?trim=color&fm=avif',
    condition: 'Refurbished',
    displayName: 'Apple Watch (2019) 44mm Black',
    price: '7.99',
  },
];
