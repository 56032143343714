import Container from 'elements/Container';
import StepBankContent from 'screens/Checkout/components/StepBankContent';
import { StepBankProvider } from './hooks/useStepBankContext';
import { IStepBank } from './types';

export const StepBankContainer = ({ dataTestId, onSuccess }: IStepBank) => {
  return (
    <StepBankProvider onSuccess={onSuccess}>
      <Container dataTestId={dataTestId} noPadding>
        <StepBankContent />
      </Container>
    </StepBankProvider>
  );
};
