import React, {useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router'

import * as A from 'styles/account';
import * as T from 'styles/type';

import { AccountFooter } from 'components/Account/AccountFooter';
import { FadeIn, SlideDownFadeIn } from 'components/animations/Transitions';
import { BackButton } from 'components/common/BackButton';
import { Button } from 'components/common/Button';
import { PolicyBanner } from 'components/Account/PolicyBanner';
import { Link } from 'components/common/Link/Link';
import { QL_ASSET, MU_BLOCK } from './queries';
import { Modal } from 'components/common/Modal';
import { useMutation } from '@apollo/client';
import useRayloQuery from 'utils/useRayloQuery';
import { findOrderItem } from './utils/customers';

export const InsuranceBlock = ({lost, stolen}) => {
  const history = useHistory()
  const { orderId } = useParams()

  const { data: { customer }, loading } = useRayloQuery(QL_ASSET, {fetchPolicy: "network-only"})

  const [updateMutation] = useMutation(MU_BLOCK, {
    onCompleted: ({ createBlockRequest: { asset } }) => {
      if(lost) {
        history.push(`/account/insurance/lost/claim/${orderId}`)
      } else {
        history.push(`/account/insurance/stolen/crime/${orderId}`)
      }
    }
  })
  const [asset, setAsset] = useState({})

  useEffect(() => {
    if (!customer) return;

    const orderItem = findOrderItem(orderId, customer);

    if(!orderItem?.costSummary?.includesInsurance) {
      history.push('/account');
    }

    const subscription = orderItem?.subscription;

    if(subscription) {
      setAsset(subscription.activeAsset)

      if(subscription.activeAsset?.blockState !== "UNBLOCKED") {
        if(lost) {
          history.push(`/account/insurance/lost/claim/${orderId}`)
        } else {
          history.push(`/account/insurance/stolen/crime/${orderId}`)
        }
      }
    } else {
      history.push("/account")
    }

  }, [customer, orderId])

  const onBlock = (e) => {
    updateMutation({
      variables: {
        assetId: asset.id
      }
    })
  }

  if(loading || !asset.id) return (<Modal visible text="Loading..." />)

  const backUrl = lost ? `/account/insurance/lost/${orderId}` : `/account/insurance/stolen/${orderId}`
  return (
    <>
      <A.AccountWrapper>
        <A.AccountForm>
          <A.AccountFormContainer>
            <FadeIn>
              <div style={{ marginBottom: 33 }}>
                <BackButton withText onClick={() => history.push(backUrl)} />
              </div>
            </FadeIn>

            <FadeIn>
              <div style={{ marginBottom: 40 }}>
                <T.RayloBodyHeading style={{ marginBottom: 0 }}>We'll need to block your phone before you can make an insurance claim.</T.RayloBodyHeading>
              </div>
            </FadeIn>

            <SlideDownFadeIn>
              <T.RayloBodyType>If you have lost your phone or suspect that it has been stolen, it is important that you instruct us to block the phone within 24 hours. Doing so will prevent someone else from using your phone on any mobile network.</T.RayloBodyType>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <T.RayloBodyType>If you recover your phone later, we can unblock it, but the unblocking process can take a number of days.</T.RayloBodyType>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <T.RayloWarningText>Clicking ‘Block your Phone’ below will immediately begin the blocking process.</T.RayloWarningText>
              </div>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 44 }}>
                  <Button buttonStyle="secondary" onClick={() => history.push(backUrl)}>Go Back</Button>
                </div>
                <div>
                  <Button buttonStyle="darkPink" onClick={onBlock}>Block Your Phone</Button>

                </div>
              </div>
            </SlideDownFadeIn>

            <SlideDownFadeIn>
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <T.RayloBodyType>Any questions? <Link href="" onClick={() => window.Intercom('show')}>Contact Us</Link></T.RayloBodyType>
              </div>
            </SlideDownFadeIn>
          </A.AccountFormContainer>
        </A.AccountForm>
      </A.AccountWrapper>

      <PolicyBanner />

      <AccountFooter />
    </>
  )
}
