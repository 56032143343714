import { useEffect, useState } from 'react';
import constate from 'constate';
import useRayloQuery from 'utils/useRayloQuery';
import { CheckoutSteps } from 'screens/Checkout/graphQL/queries';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useMutation } from '@apollo/client';
import { CheckoutMode } from 'screens/Checkout/enums/checkoutModes';

interface IUseCheckoutMobile {
  onSuccess: () => void;
}

const useCheckoutMobile = ({ onSuccess }: IUseCheckoutMobile) => {
  const [number, setNumber] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<string>();
  const [formErrors, setFormErrors] = useState<any>();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [presetValues, setPresetValues] = useState<object>();

  const { checkoutToken, checkoutMode } = useCheckoutContext();

  const {
    data: { checkout },
    loading,
  } = useRayloQuery(CheckoutSteps.stepMobile.query, {
    variables: {
      token: checkoutToken,
    },
  });

  const [updateMutation] = useMutation(CheckoutSteps.stepMobile.mutation, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { errors },
        },
      }
    ) => {
      if (errors && errors.length > 0) {
        setFormErrors(errors);
      } else {
        onSuccess();
      }
    },
  });
  const [updateMutation2] = useMutation(CheckoutSteps.stepMobile.mutation2, {
    update: (
      proxy,
      {
        data: {
          updateCheckout: { errors },
        },
      }
    ) => {
      if (errors && errors.length > 0) {
        setFormErrors(errors);
      } else {
        onSuccess();
      }
    },
  });

  useEffect(() => {
    const mobilePhoneNumber =
      checkout?.customerInfo?.mobilePhoneNumber ||
      checkout?.customer?.mobilePhoneNumber;
    const dob = checkout?.customerInfo?.dateOfBirth;

    setNumber(mobilePhoneNumber);
    setPresetValues({
      mobilePhoneNumber,
      dateOfBirth: dob,
    });
  }, [checkout]);

  useEffect(() => {
    if (formSubmitted) {
      if (checkoutMode === CheckoutMode.NEW_CUSTOMER) {
        updateMutation({
          variables: {
            mobilePhoneNumber: number,
            dateOfBirth,
            checkoutToken: checkout.token,
          },
        });
      } else {
        updateMutation2({
          variables: {
            mobilePhoneNumber: number,
            checkoutToken: checkout.token,
          },
        });
      }
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  return {
    loading,
    formErrors,
    number,
    presetValues,
    setFormErrors,
    setNumber,
    setDateOfBirth,
    setFormSubmitted,
  };
};

const [CheckoutMobileProvider, useCheckoutMobileContext] =
  constate(useCheckoutMobile);
export { CheckoutMobileProvider, useCheckoutMobileContext };
