import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { track } from 'integrations/segment/events';
import * as A from 'styles/account';
import { PROCESS_INCOMING_PAYMENT_MUTATION } from 'graphql/mutations/incomingPayment/processIncomingPaymentMutation.graphql';
import {
  CUSTOMER_INCOMING_PAYMENTS,
  ICustomerIncomingPaymentsQuery
} from 'graphql/queries/customer/incomingPayment/incomingPayments.graphql';
import { IncomingPaymentStatus } from 'graphql/mutations/incomingPayment/incomingPaymentStatuses.enum';
import { PaymentStatus } from './PaymentStatus.enum';
import { PaymentProcessing } from './PaymentProcessing';
import { ArrearsPaymentFailed } from './ArrearsPaymentFailed';
import { ArrearsPaymentSuccess } from './ArrearsPaymentSuccess';

export const ArrearsPaymentStatusScreen = () => {
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.Initial);
  const [trackedAccountViewedEvent, setTrackedAccountViewedEvent] =
    useState(false);

  const { incomingPaymentId, amount } = useParams<{ incomingPaymentId: string; amount: string }>();

  const [processIncomingPayment] = useMutation(
    PROCESS_INCOMING_PAYMENT_MUTATION,
    {
      variables: {
        incomingPaymentId,
        amount: parseFloat(amount),
      },
    }
  );

  const customerIncomingPaymentsQuery = useQuery(CUSTOMER_INCOMING_PAYMENTS, {
    pollInterval: 1000,
    notifyOnNetworkStatusChange: true,
    onCompleted(data: ICustomerIncomingPaymentsQuery) {
      const subscription = data.customer?.subscriptions.find(s =>
        s.incomingPayments.find((incomingPayment) => {
          return incomingPayment.id === incomingPaymentId;
        })
      );
      const incomingPaymentState = subscription?.incomingPayments.find(
        incomingPayment => incomingPayment.id === incomingPaymentId
      )?.state;
      if (
        incomingPaymentState === IncomingPaymentStatus.Cancelled ||
        incomingPaymentState === IncomingPaymentStatus.Declined
      ) {
        customerIncomingPaymentsQuery.stopPolling();
        if (!trackedAccountViewedEvent) {
          track('My Account Viewed', {
            screen: 'payment failure',
            when: 'on page load',
            url: window.location.href,
          });
        }
        setTrackedAccountViewedEvent(true);
        setPaymentStatus(PaymentStatus.Failed);
      }
      if (incomingPaymentState === IncomingPaymentStatus.Accepted) {
        customerIncomingPaymentsQuery.stopPolling();
        if (!trackedAccountViewedEvent) {
          track('My Account Viewed', {
            screen: 'payment success',
            when: 'on page load',
            url: window.location.href,
          });
        }
        setTrackedAccountViewedEvent(true);
        setPaymentStatus(PaymentStatus.Successful);
      }
    },
  });

  useEffect(() => {
    processIncomingPayment();
  }, []);

  const renderPaymentStatus = () => {
    switch (paymentStatus) {
      case PaymentStatus.Successful: {
        return <ArrearsPaymentSuccess />;
      }
      case PaymentStatus.Failed: {
        return <ArrearsPaymentFailed />;
      }
      default: {
        return <PaymentProcessing />;
      }
    }
  };

  return <A.AccountContainer>{renderPaymentStatus()}</A.AccountContainer>;
};
