import gql from 'graphql-tag';

export interface IGetSubscriptionQuery {
  customer: {
    orders: IGetSubscriptionQueryOrder[];
  };
}

export interface IGetSubscriptionQueryOrder {
  id: string;
  items: IGetSubscriptionQueryOrderItem[];
}

export interface IGetSubscriptionQueryOrderItem {
  id: string;
  subscription: {
    id: string;
    createdAt: string;
    cancellationPeriodCategory: string;
    assetReturns: {
      id: string;
      customerCollectionUrl: string;
      state: string;
    }[];
    costSummary: {
      id: string;
      termLength: number;
    };
  } | null;
  variant: {
    id: string;
    product: {
      id: string;
      displayName: string;
      category: {
        id: string;
        displayName: string;
      };
    };
    images: IGetSubscriptionQueryOrderItemImages[];
    optionValues: IGetSubscriptionQueryOrderItemOptionValues[];
    condition: string;
  };
}

interface IGetSubscriptionQueryOrderItemImages {
  id: string;
  tag: string;
  url: string;
}

interface IGetSubscriptionQueryOrderItemOptionValues {
  id: string;
  displayName: string;
  optionType: {
    displayName: string;
  };
}

export const GET_SUBSCRIPTION = gql`
  query SubscriptionAssetReturnQuery {
    customer {
      id
      orders {
        id
        items {
          id
          subscription {
            id
            createdAt
            cancellationPeriodCategory
            costSummary {
              id
              termLength
            }
            assetReturns {
              id
              customerCollectionUrl
              state
            }
          }
          variant {
            id
            condition
            product {
              id
              displayName
              category {
                id
                displayName
              }
            }
            images {
              id
              tag
              url
            }
            optionValues {
              id
              displayName
              optionType {
                displayName
              }
            }
          }
        }
      }
    }
  }
`;
