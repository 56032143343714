
import gql from 'graphql-tag';

export const QL_INTERCOM = gql`
  query AccountIntercomQuery {
  customer {
    id
    email
    messagingDetails {
      intercom {
        appId
        userHash
        userId
      }
    }
  }
}`

export const MU_BLOCK = gql`
mutation AccountCreateBlockRequestMutation(
  $assetId: ID!
) {
  createBlockRequest(
    assetId: $assetId
  ) {
    asset {
      id
      blockState
    }
  }
}`

export const MU_CLAIM = gql`
  mutation AccountCreateClaimMutation(
    $insurancePolicyId: ID!,
    $reason: InsuranceClaimReasonType!
  ) {
    createInsuranceClaim(
      input: {
        insurancePolicyId: $insurancePolicyId,
        reason: $reason
      }
    ) {
      insuranceClaim {
        id
      }
      errors {
        code
        field
        message
      }
    }
}`

export const QL_CLAIM = gql`
  query AccountClaimQuery {
  customer {
    id
    orders {
      id
      items {
        id
        costSummary {
          includesInsurance
        }
        subscription {
          id
          insurancePolicy {
            id
            claimUrl
            claims {
              id
              state
              reason {
                enum
              }
            }
          }
          activeAsset {
            id
            blockState
          }
        }
      }
    }
  }
}`

export const QL_ASSET = gql`
  query AccountAssetQuery {
  customer {
    id
    orders {
      id
      items {
        id
        costSummary {
          includesInsurance
        }
        subscription {
          id
          activeAsset {
            id
            imeiNumber
            blockState
          }
        }
      }
    }
  }
}`

export const QL_IMEI = gql`
  query AccountImeiQuery {
  customer {
    id
    orders {
      id
      items {
        id
        costSummary {
          includesInsurance
        }
        subscription {
          id
          activeAsset {
            id
            imeiNumber
          }
        }
      }
    }
  }
}`

export const QL_INSURANCE = gql`
  query AccountInsuranceQuery {
  customer {
    id
    orders {
      id
      items {
        id
        costSummary {
          includesInsurance
        }
        subscription {
          id
          insurancePolicy {
            id
            claimUrl
            claims {
              createdAt
              externalReference
              id
              reason {
                displayName
                enum
              }
              state
              updatedAt
            }
          }
        }
        fulfillmentItem {
          id
          dispatchedAt
        }
      }
    }
  }
}`

export const QL_CUSTOMER_SIMPLE = gql`
  query AccountSimpleCustomerQuery {
  customer {
    id
    firstName
    lastName
    email
    address {
      id
      line1
      line2
      line3
      postcode
      region
      city
      country {
        id
        name
      }
    }
  }
}`


export const QL_REFERRALS = gql `
  query AccountReferralsQuery {
  customer {
    id
    referralInfo {
      url
      successfulReferralCount
      pendingReferralCount
      unsuccessfulReferralCount
      externalReference
      referrals {
        id
        rewardAvailableAt
        name
        state {
          displayName
          enum
        }
      }
    }
  }
}`

export const QL_REFERRAL_PROGRAMS = gql`
  query AccountReferralProgramsQuery {
  referralProgram {
    reward {
      referralDescription
    }
  }
}`
