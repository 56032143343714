import { SlideDownFadeIn } from 'components/animations/Transitions';
import BackArrow from 'components/BackArrow';
import BulletList from 'components/BulletList';
import RadioButtonBoxGroup from 'components/RadioButtonBoxGroup';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Copy from 'elements/Copy';
import DualToneHeader from 'elements/DualToneHeader';
import { IFormattedTradeInAnswer } from '../../containers/StepTradeIn/types';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import Spacer from 'elements/Spacer';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import { primaryColors } from 'styles/variables/colors';
import ITestDataId from 'types/ITestDataId.type';
import { StyledTradeInJourneyStepTwo } from './styles';

export const TradeInJourneyStepTwo = ({ dataTestId }: ITestDataId) => {
  const { setStepIndex } = useStepTradeInContext();
  const {
    productName,
    tradeInAnswers,
    visibleTradeInQuestions,
    tradeInQuestionsList,
    gradeSlug,
    handleResponseToQuestions,
  } = useStepTradeInContext();

  const questionNotes = (notes: string) => {
    const editedNotes = notes.replace(/- /g, '').split(/\n/);

    return (
      <>
        <Copy color={primaryColors.c01}>
          <>{editedNotes.shift()}</>
        </Copy>
        <Spacer height={12} />
        <BulletList listItems={editedNotes.slice(1)} />
      </>
    );
  };

  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'trade in',
    checkoutScreen: 'trade in condition questions',
    when: 'on page load',
  });

  const activeOptionIndex = (questionId: string) => {
    if (!(Object.keys(tradeInAnswers).length > 0)) {
      return -1;
    }

    const answerIds = tradeInQuestionsList[questionId].answers?.map(
      (answer: IFormattedTradeInAnswer) => {
        return answer.id;
      }
    );

    return answerIds?.indexOf(tradeInAnswers[questionId]);
  };

  const handleAnswerOnChange = (
    value: string,
    groupLabel?: string | undefined
  ) => {
    const currentQuestionId = Object.values(tradeInQuestionsList).find(
      question => question.label === groupLabel
    )?.id;

    if (!currentQuestionId) {
      return;
    }

    const currentQuestion = tradeInQuestionsList[currentQuestionId];
    const selectedAnswer = currentQuestion.answers?.find(
      (answer: IFormattedTradeInAnswer) => answer.label === value
    );

    handleResponseToQuestions(selectedAnswer);
  };

  return (
    <StyledTradeInJourneyStepTwo data-testid={dataTestId}>
      <BackArrow onClick={() => setStepIndex(0)} />
      <DualToneHeader>
        Just a few more questions about your <span>{productName}</span>
      </DualToneHeader>
      <Spacer height={24} />
      {visibleTradeInQuestions.map((questionId: string, index: number) => {
        const question = tradeInQuestionsList[questionId];
        {
          return (
            <SlideDownFadeIn key={questionId}>
              <>
                <Spacer height={24} />
                <RadioButtonBoxGroup
                  options={question.answers}
                  label={question.label}
                  initialActiveIndex={activeOptionIndex(questionId)}
                  subCopy={
                    <>{question.notes && questionNotes(question.notes)}</>
                  }
                  onChange={handleAnswerOnChange}
                  flexRow
                  dataTestId={`TradeInQuestion${index + 1}`}
                />
              </>
            </SlideDownFadeIn>
          );
        }
      })}
      <Spacer height={24} />
      <Button
        size="large"
        dataTestId="tradeInNextButton"
        buttonColor={ButtonTypes.b01}
        onClick={() => setStepIndex(2)}
        disabled={!gradeSlug}
        buttonType="solid"
        text="Next"
        fullWidth
      />
    </StyledTradeInJourneyStepTwo>
  );
};
