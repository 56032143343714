import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { primaryColors } from 'styles/variables/colors';
import useMedia from 'use-media';
import { StyledStatusBanner } from './styles';
import { IStatusBanner } from './types';

export const StatusBanner = ({
  dataTestId,
  children,
  buttonOnClick,
  buttonText,
  showButton,
  flexRow,
}: IStatusBanner) => {
  const isMobile = useMedia({ maxWidth: LEGACY_BREAKPOINTS.mobile });
  return (
    <StyledStatusBanner data-testid={dataTestId}>
      <Container backgroundColor={primaryColors.b01}>
        <Container
          flexRow={flexRow}
          center
          styles={{
            textAlign: 'center',
          }}
        >
          {children}
        </Container>
        {showButton && (
          <Container alignCenter>
            <Button
              buttonColor={ButtonTypes.b01}
              buttonType="ghost"
              onClick={buttonOnClick}
              size="medium"
              text={buttonText}
              fullWidth={isMobile}
            />
          </Container>
        )}
      </Container>
    </StyledStatusBanner>
  );
};
