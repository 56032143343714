import { ILottieAnimation } from './LottieAnimation.types';
import { useEffect, useRef } from 'react';

export const LottieAnimation = ({
  dataTestId,
  isStopped,
  isPaused,
  isLoop,
  autoplay,
  animationData,
  height,
  width,
  className,
}: ILottieAnimation) => {
  const lottieLoaded = useRef(false);
  const defaultOptions = {
    loop: isLoop || false,
    autoplay: autoplay ?? true,
    animationData,
  };

  const animationRef = useRef(null);

  async function getLottie() {
    if (!animationRef.current || lottieLoaded.current) return;
    lottieLoaded.current = true;
    // use webflow lottie if available
    const webflowLottie = window?.Webflow?.require('lottie').lottie;

    if (webflowLottie) {
      webflowLottie.loadAnimation({
        ...defaultOptions,
        container: animationRef.current,
      });
      return;
    }

    // use lottie-web if webflow lottie is not available
    const lottie = await import('lottie-web');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lottie.default.loadAnimation({
      ...defaultOptions,
      container: animationRef.current,
    });
  }

  useEffect(() => {
    if (lottieLoaded.current) return;
    getLottie();
  }, []);

  return (
    <div data-testid={dataTestId} className={className}>
      <div ref={animationRef} style={{ height, width, margin: '0 auto' }} />
    </div>
  );
};
