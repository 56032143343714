import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';

const StyledSelectDropdown = styled.div`
  div {
    font-size: ${px2Rem(14)}!important;
    line-height: ${px2Rem(24)}!important;
  }

  .single-value {
    color: ${palette.charcoal[500]};
  }

  .single-value-disabled {
    color: ${palette.charcoal[300]};
  }

  img {
    margin-right: ${px2Rem(8)};
  }

  .icon-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: ${px2Rem(4)};

    img {
      margin-right: ${px2Rem(12)};
    }
  }
`;

export { StyledSelectDropdown };
