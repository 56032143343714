import Container from 'elements/Container';
import { Divider, Icon, Spacer } from '@raylo-tech/raylopay-ui';
import { px2Rem } from 'utils/px2Rem';

const RayloPayHeader = () => (
  <Container maxWidth={px2Rem(422)} padding={`${px2Rem(16)} ${px2Rem(24)} 0 ${px2Rem(24)}`}>
    <Spacer height={16} />
    <Container noPadding>
      <Icon name="RayloDesignRpLogo" dataTestId="rp-logo" />
    </Container>
    <Spacer height={8} />
    <Divider height={16} />
  </Container>
);

export default RayloPayHeader;
