import * as React from 'react';
import { SVGProps } from 'react';

interface IChevronLeftProps extends SVGProps<SVGSVGElement> {
  color: string;
}

const ChevronLeft = ({ color, ...props }: IChevronLeftProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill={color}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.573.5 9.75 1.677 4.927 6.5l4.823 4.823L8.573 12.5l-6-6 6-6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ChevronLeft;
