import { primaryColors } from 'styles/variables/colors';
import styled from 'styled-components';

const StyledInsuranceModal = styled.div`
  p {
    margin-bottom: 16px;
  }

  table {
    border: 1px solid ${primaryColors.c01};
    margin-bottom: 16px;
    td,
    th {
      border: 1px solid ${primaryColors.c01};
      padding: 7px;
    }

    th:first-of-type {
      width: 60%;
    }

    .double-border {
      border-top: 2px solid ${primaryColors.c01};
    }
  }
`;

export { StyledInsuranceModal };
