import React from 'react';
import animationData from '../../../animations/orderPlaced.json';
import LottieAnimation from '../../LottieAnimation';

export const OrderPlacedAnimation = () => {
  const props = {
    animationData,
    height: 124,
    width: 124,
    isLoop: true,
  };
  return <LottieAnimation dataTestId="order-placed-animation" {...props} />;
};
