import gql from 'graphql-tag';

export const CANCEL_ORDER = gql`
  mutation CancelOrder(
    $orderId: ID!
    $reason: CancelOrderReasonType!
    $customerNotes: String
  ) {
    cancelOrder(
      orderId: $orderId
      reason: $reason
      customerNotes: $customerNotes
    ) {
      success
      errors {
        message
      }
    }
  }
`;
