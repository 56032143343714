import FormCollection from 'components/FormCollection';
import { useStepNameEmailContext } from 'screens/Checkout/containers/StepNameEmail/hooks/useStepNameEmailContext';
import { INameEmailValues } from './types';
import { nameFields } from 'screens/Checkout/containers/StepPersonalDetails/constants';

export const fields = [
  ...nameFields,
  {
    field: 'email',
    name: 'Email address',
    type: 'email',
    required: true,
  },
];

interface IForm {
  onSubmit: (values: INameEmailValues) => void;
}

export const FormComponent = ({ onSubmit }: IForm) => {
  const { presetValues } = useStepNameEmailContext();

  return (
    <FormCollection
      fields={fields}
      handleSubmit={onSubmit}
      formId="nameEmail"
      presetValues={presetValues}
    />
  );
};
