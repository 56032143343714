export const nameFields = [
  {
    field: 'firstName',
    name: 'First Name (Legal)',
    tooltip: 'Please enter your legal first name as it appears on official documents.',
    type: 'text',
    required: true,
  },
  {
    field: 'middleName',
    name: 'Middle Name(s)',
    type: 'text',
    required: true,
    displayOptionalText: true,
  },
  {
    field: 'lastName',
    name: 'Last Name (Legal)',
    tooltip: 'Please enter your legal last name as it appears on official documents.',
    type: 'text',
    required: true,
  },
];

export const personalDetailsFields = [
  ...nameFields,
  {
    field: 'mobilePhoneNumber',
    name: 'Mobile Number',
    type: 'tel',
    required: true,
    tooltip:
      'We need a mobile phone number so we can contact you if there are any problems with your order',
  },
  {
    field: 'dateOfBirth',
    name: "What's your date of birth?",
    type: 'text',
    pattern: 'd*',
    required: true,
  },
];
