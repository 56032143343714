import Container from 'elements/Container';
import StepMobileContent from 'screens/Checkout/components/StepMobileContent';
import { CheckoutMobileProvider } from './hooks/useCheckoutMobileContext';
import { IStepMobileJob } from './types';

export const StepMobileJobContainer = ({
  dataTestId,
  onSuccess,
}: IStepMobileJob) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <CheckoutMobileProvider onSuccess={onSuccess}>
        <StepMobileContent />
      </CheckoutMobileProvider>
    </Container>
  );
};
