import { IButton } from './Button.types';
import { palette } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import styled from 'styled-components';
import { CSS_FONT_WEIGHT } from '../../../constants';

const buttonMediumStyles = `
  height: ${px2Rem(49)};
`;

const buttonSmallStyles = `
  height: ${px2Rem(42)};
`;

const buttonXSmallStyles = `
  height: ${px2Rem(30)};
  font-size: ${px2Rem(12)};
  width: fit-content;
  padding: 0 ${px2Rem(15)};
`;

const buttonSecondaryStyles = `
  background-color: ${palette.white};
  border-color: ${palette.charcoal[400]};
  color: ${palette.charcoal[500]};
`;

const buttonSecondaryStateSyles = `
  &:hover {
    background-color: ${palette.white};
    border-color: ${palette.charcoal[400]};
    border-width: ${px2Rem(2)};
    color: ${palette.charcoal[500]};
  }
  &:focus {
    background-color: ${palette.white};
    border-color: ${palette.charcoal[400]};
    border-width: ${px2Rem(2)};
    color: ${palette.charcoal[500]};
  }
  &:disabled {
    background-color: ${palette.white};
    border-color: ${palette.charcoal[300]};
  }
`;

const StyledButton = styled.button<IButton>`
  cursor: pointer;
  width: 100%;
  height: ${px2Rem(58)};
  border: solid ${px2Rem(1)} ${palette.blue[500]};
  background-color: ${palette.blue[500]};
  color: ${palette.white};
  letter-spacing: ${px2Rem(0.6)};
  font-size: ${px2Rem(16)};
  text-transform: uppercase;
  font-weight: ${CSS_FONT_WEIGHT.vars.medium};

  &:hover {
    background-color: ${palette.blue[300]};
    border-color: ${palette.blue[300]};
  }
  &:focus {
    border-color: ${palette.blue[300]};
    background-color: ${palette.blue[200]};
  }
  &:disabled {
    border-color: ${palette.charcoal[100]};
    background-color: ${palette.charcoal[100]};
    color: ${palette.charcoal[300]};
  }
  ${({ medium }) => medium && buttonMediumStyles}
  ${({ small }) => small && buttonSmallStyles}
  ${({ xsmall }) => xsmall && buttonXSmallStyles}
  ${({ secondary }) => secondary && buttonSecondaryStyles}
  ${({ secondary }) => secondary && buttonSecondaryStateSyles}
`;

export {
  StyledButton,
  buttonMediumStyles,
  buttonSmallStyles,
  buttonXSmallStyles,
  buttonSecondaryStyles
};
