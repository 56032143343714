import Container from 'elements/Container';
import StepTradeInContent from 'screens/Checkout/components/StepTradeInContent';
import { StepTradeInProvider } from './hooks/useStepTradeInContext';
import { IStepTradeIn } from './types';

export const StepTradeInContainer = ({
  dataTestId,
  onSuccess,
}: IStepTradeIn) => {
  return (
    <Container dataTestId={dataTestId} noPadding>
      <StepTradeInProvider onSuccess={onSuccess}>
        <StepTradeInContent />
      </StepTradeInProvider>
    </Container>
  );
};
