import { navBar } from './navBar';
import { terms } from './terms';
import { checkout } from './checkout';
import { rayloDark } from '../rayloDark';
import { button } from './buttons';
import { form } from './form';
import { components } from './components';
import { logo } from './logo';

const rayloLight = {
  ...rayloDark,
  button,
  form,
  components,
  checkout,
  terms,
  navBar,
  logo,
};

export { rayloLight };
