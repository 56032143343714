import styled from 'styled-components';
import { palette } from '../../colors';
import { px2Rem } from '../../utils/px2Rem';
import { ASSETS_SVGS_BASE_URL } from '../../constants';

export const StyledTrustpilotBullets = styled.div`
  background-color: ${palette.blue[500]};
  padding: ${px2Rem(24)} ${px2Rem(20)};

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: ${palette.white};
    font-size: ${px2Rem(14)};
    line-height: ${px2Rem(17)};
    
    li {
      padding: 0 0 ${px2Rem(12)} ${px2Rem(32)};
      position: relative;

      &:before {
        content: '';
        background-image: url("${ASSETS_SVGS_BASE_URL}/pink-tick.svg");
        width: ${px2Rem(24)};
        height: ${px2Rem(16)};
        background-size: ${px2Rem(24)} ${px2Rem(16)};
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:last-of-type {
        padding: 0 0 ${px2Rem(24)} ${px2Rem(32)};
      }
    }
  }
`;
