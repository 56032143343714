import store from 'store';

export function extractExperimentData(value: string) {
  const url = new URL(value);
  const urlParams = new URLSearchParams(url?.search);
  const experimentName = urlParams.get('experiment_name');
  const experimentContent = urlParams.get('experiment_content');

  if (experimentName && experimentContent) {
    return {
      experimentName,
      experimentContent,
    };
  }
  return null;
}

export function setExperimentVariables(url: string) {
  const experimentData = extractExperimentData(url);

  if (experimentData) {
    const { experimentName, experimentContent } = experimentData;
    store.set('experiment_name', experimentName);
    store.set('experiment_variable', experimentContent);
  }
}
