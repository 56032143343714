import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet'
import store from 'store'
import {activateOptimize} from 'utils/optimize'

// HOOKS
import {useUiContext} from 'utils/useUiContext'

// GRAPHQL
import {QL_RISK_PRICING} from 'screens/Checkout/graphQL/queries';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import colors from 'styles/colors';
import * as B from 'styles/buttons';
import {FadeIn, SlideDownFadeIn} from 'components/animations/Transitions';
import {Button} from 'components/common/Button';
import {Modal} from 'components/common/Modal';

import Currency from 'react-currency-formatter';

import {PhoneHeader} from 'screens/Checkout/components/PhoneHeader';
import {getOptionValue} from 'utils/getOptionValue'
import useRayloQuery from 'utils/useRayloQuery'
import {useHistory} from 'react-router';
import {useCheckoutContext} from 'utils/useCheckoutContext';
import {useErrorNavigator} from "utils/useErrorNavigator"
import classNames from 'classnames'
import {useSegmentCheckoutTracking} from 'integrations/segment/hooks'
import {VariantOptionSlugs} from "types/variants/options/variantOptionSlugs"
import {useMerchantContext} from "utils/useMerchantContext";
import { useSharedCustomer } from 'utils/useSharedCustomer';
import { CheckoutOrderSummary } from 'components/Checkout/CheckoutOrderSummary/CheckoutOrderSummary';
import { CheckoutOrderSidebarSummary } from 'components/Checkout/CheckoutOrderSummary/CheckoutOrderSidebarSummary';

export const RiskPricing = () => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'counter offer',
    checkoutScreen: 'counter offer details',
    when: 'on page load'
  });

  const history = useHistory()
  const errorNav = useErrorNavigator()
  const {checkoutToken} = useCheckoutContext()
  const {merchantId} = useMerchantContext()
  const {showSummary } = useUiContext()
  const { setDomainCookie } = useSharedCustomer();

  const [isSubmitting, setIsSubmitting] = useState(false)

  const {data: {checkout}, error, loading} = useRayloQuery(QL_RISK_PRICING, {
    variables: {
      checkoutToken,
      merchantId
    },
    skip: !merchantId
  })

  const actionJumpToTerms = () => {
    store.set('checkoutToken', checkoutToken)
    setDomainCookie('checkoutToken', checkoutToken)
    history.push("/checkout/step/sign")
  }
  const showTerms = () => {
    if (isSubmitting) {
      return
    }
    setIsSubmitting(true)
    actionJumpToTerms()
  }

  useEffect(() => {
    activateOptimize()
  })

  if (error) {
    errorNav('Error in risk pricing', error)
  }

  if (loading || !checkout) {
    return <Modal visible text="Loading..."/>
  }

  const item = checkout?.items[0];
  const variant = item?.variant
  const product = variant?.product
  const costSummary = item?.costSummary

  const storage = getOptionValue(variant, VariantOptionSlugs.Storage).displayName
  const color = getOptionValue(variant, VariantOptionSlugs.Colour).displayName
  const watchCaseSize = getOptionValue(variant, VariantOptionSlugs.WatchCaseSize).displayName

  return (
    <div className={classNames("checkout--index", {showSummary})}>
      <Helmet><title>
        Your Subscription - Raylo
      </title></Helmet>
      <div className="checkout--header"><PhoneHeader checkoutToken={checkoutToken}/></div>
      <div className="checkout--main">
        <div className="checkout--step">
          <FadeIn>
            <T.RayloBodyHeading margin="34px">Your Subscription</T.RayloBodyHeading>
          </FadeIn>

          <FadeIn>
            <T.RayloBodyHeading margin="24px">
              <strong>{product.displayName} {storage} {color} {watchCaseSize}</strong>
              {costSummary.includesInsurance && <><br/>including insurance</>}</T.RayloBodyHeading>
          </FadeIn>

          <FadeIn>
            <div style={{marginBottom: 28, paddingBottom: 28, borderBottom: '1px solid #434343'}}>
              <T.RayloWarningText>Total Monthly Payment: <span style={{color: '#FFF', marginLeft: 10}}><Currency
                quantity={costSummary.recurring.totalAmount.valueAfterTax.value}
                currency={costSummary.currencyCode}/></span></T.RayloWarningText>
              <T.RayloBodyType style={{color: colors.rayloPink}}>(This is an <span
                style={{color: '#FFF'}}>increase of <Currency
                quantity={costSummary.recurring.adjustmentAmount.valueAfterTax.value}
                currency={costSummary.currencyCode}/> per month</span>, compared with the standard price of <Currency
                quantity={costSummary.recurring.totalAmount.valueAfterTax.value - costSummary.recurring.adjustmentAmount.valueAfterTax.value}
                currency={costSummary.currencyCode}/> per month)</T.RayloBodyType>
            </div>
          </FadeIn>

          <SlideDownFadeIn>
            <div style={{display: 'flex', marginBottom: 20}}>
              <div style={{marginRight: 40}}>
                <Button buttonStyle="secondary" onClick={() => history.push(`/checkout/decision/later`)}>No
                  Thanks</Button>
              </div>
              <div>
                <Button buttonStyle="primaryBlue" onClick={() => showTerms(checkout.state)}>Continue to T&Cs</Button>
              </div>
            </div>
          </SlideDownFadeIn>

          <SlideDownFadeIn>
            <T.RayloWarningText>Not sure? No problem! You can always <B.UnderlineButton inline
                                                                                        onClick={() => history.push(`/checkout/decision/later`)}>Decide
              Later</B.UnderlineButton>.<br/>This will be valid for 5 days</T.RayloWarningText>
          </SlideDownFadeIn>
        </div>
      </div>

      <FadeIn>
        <div>
          <div className={classNames("checkout--summary", {showSummary})}>
            <CheckoutOrderSummary
              checkoutToken={checkoutToken}
              displayOnly
            />
          </div>
          <CheckoutOrderSidebarSummary
            checkoutToken={checkoutToken}
            displayOnly
          />
        </div>
      </FadeIn>
    </div>
  )

}
