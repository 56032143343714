import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { Modal } from 'components/common/Modal';
import {
  GET_SUBSCRIPTION,
  IGetSubscriptionQuery,
  IGetSubscriptionQueryOrder,
  IGetSubscriptionQueryOrderItem
} from 'screens/Account/Forms/graphql/subscription.graphql';
import { SubscriptionCancellationCategory } from 'screens/Account/DeviceProfile/graphql/deviceProfile.graphql';
import { CancelLeaseProvider } from 'screens/Account/Forms/context/useCancelLeaseContext';

import { CoolingOffForm } from './CoolingOffForm';
import { EndOfLeaseForm } from './EndOfLeaseForm';

export const CancelLeaseForm = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const [orderItem, setOrderItem] = useState<IGetSubscriptionQueryOrderItem | undefined>(undefined);

  const { data } = useQuery(GET_SUBSCRIPTION);

  useEffect(() => {
    if (!data) {
      return;
    }
    setOrderItem(findOrderItem(data, subscriptionId));
  }, [data, subscriptionId]);

  if (!orderItem) {
    return <Modal dataTestId="loading-modal" visible text="Loading..." />;
  }

  const form = () => {
    switch (orderItem!.subscription!.cancellationPeriodCategory) {
      case SubscriptionCancellationCategory.COOLING_OFF:
        return <CoolingOffForm orderItem={orderItem} />;
      case SubscriptionCancellationCategory.END_OF_INITIAL_LEASE_TERM:
        return <EndOfLeaseForm orderItem={orderItem} />;
      default:
        return null;
    }
  };

  return (
    <CancelLeaseProvider subscriptionId={subscriptionId}>
      {form()}
    </CancelLeaseProvider>
  );
};

const findOrderItem = (data: IGetSubscriptionQuery, subscriptionId: string) => {
  return data.customer.orders.find((order: IGetSubscriptionQueryOrder) => {
    return order.items.some((item: IGetSubscriptionQueryOrderItem) => item.subscription?.id === subscriptionId);
  })?.items[0];
};
