import StepLeaseTermsContent from 'screens/Checkout/components/StepLeaseTermsContent';
import { CheckoutLeaseTermsProvider } from './hooks/useCheckoutLeaseTermsContext';
import { ICheckoutLeaseTermsContainer } from './types';

export const CheckoutLeaseTermsContainer = ({
  onSuccess,
  checkoutToken,
}: ICheckoutLeaseTermsContainer) => {
  return (
    <CheckoutLeaseTermsProvider
      onSuccess={onSuccess}
      checkoutToken={checkoutToken}
    >
      <StepLeaseTermsContent />
    </CheckoutLeaseTermsProvider>
  );
};
