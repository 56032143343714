import React from 'react';

import { ClientProvider } from './useClientContext';
import { UiProvider } from './useUiContext';

export const StateProvider = ({children, costSummary = null}) =>(
  <ClientProvider>
    <UiProvider costSummary={costSummary}>
      {children}
    </UiProvider>
  </ClientProvider>
);
