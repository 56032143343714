import { Spacer } from '@raylo-tech/raylopay-ui';
import { Loader } from 'components/common/Loader';
import { Container, Copy } from 'elements';
import { primaryColors } from 'styles/variables/colors';
import { ILoadingScreen } from './types';

export const LoadingScreen = ({ darkMode }: ILoadingScreen) => {
  return (
    <Container
      dataTestId="loading"
      backgroundColor={darkMode ? primaryColors.bk01 : primaryColors.w01}
      width="100vw"
      height="100vh"
      center
      styles={{
        zIndex: 1,
      }}
    >
      <Loader type="quad" />
      <Spacer height={16} />
      <Copy
        color={darkMode ? primaryColors.w01 : primaryColors.c01}
        dataTestId="loading-copy"
      >
        Loading...
      </Copy>
    </Container>
  );
};
