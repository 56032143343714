import React from 'react';
import { StyledPricePlanOptions } from './PricePlanOptions.styles';
import { IPricePlanOptions, IPricePlan } from './PricePlanOptions.types';
import Copy from '../../elements/Copy';
import Spacer from '../../elements/Spacer';
import Container from '../../elements/Container';
import { palette } from '../../colors';
import RadioButtonBox from '../RadioButtonBox';

export const PricePlanOptions = ({
  dataTestId,
  pricePlans,
  onClick,
  activeIndex,
}: IPricePlanOptions) => {
  if (!pricePlans) {
    return null;
  }
  return (
    <StyledPricePlanOptions data-testid={dataTestId}>
      <Copy color={palette.charcoal[500]} fontSize={16}>
        Pick your lease term
      </Copy>
      <Spacer height={12} />
      <Container noPadding>
        {pricePlans?.map(
          ({ label, id, formattedMonthlyPrice, formattedMonthlyPriceSubCopy }: IPricePlan, i: number) => (
            <div key={id}>
              <RadioButtonBox
                dataTestId={`option-${i}`}
                formattedMonthlyPrice={formattedMonthlyPrice}
                onClick={onClick}
                pricePlan
                label={label}
                active={id === activeIndex}
                index={id}
                large
                formattedMonthlyPriceSubCopy={formattedMonthlyPriceSubCopy}
              />
              <Spacer height={20} />
            </div>
          )
        )}
      </Container>
    </StyledPricePlanOptions>
  );
};
