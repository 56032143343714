import { useState } from 'react';
import { SlideDownFadeIn } from 'components/animations/Transitions';
import Button from 'elements/Button';
import { ButtonTypes } from 'elements/Button/types';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import TextLink from 'elements/TextLink';
import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { StyledStepUpgradeContent } from './styles';
import { IStepUpgradeContent } from './types';

export const StepUpgradeContent = ({
  dataTestId,
  showFields,
  device,
  oldDevice,
  isSubmitting,
  onSubmit,
}: IStepUpgradeContent) => {
  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'upgrade',
    checkoutScreen: "let's upgrade you",
    when: 'on page load',
  });
  const [showTip, setShowTip] = useState<boolean>(false);
  return (
    <StyledStepUpgradeContent data-testid={dataTestId}>
      <SlideDownFadeIn visible={showFields}>
        <Container noPadding>
          <Spacer height={40} />
          <Header level={2} color={primaryColors.c01}>
            Great. Let&apos;s upgrade you
          </Header>
          <Spacer height={32} />
          <Copy color={primaryColors.c01} lineHeight={22}>
            After you receive your new {device}, we&apos;ll send you an email
            letting you know how to return your current {oldDevice}.
          </Copy>
        </Container>
      </SlideDownFadeIn>
      <SlideDownFadeIn visible={showFields}>
        <Container noPadding>
          <Spacer height={32} />
          <Copy color={primaryColors.c01} fontSize={14}>
            Prefer to{' '}
            <TextLink
              fontSize={14}
              onClick={() => setShowTip(!showTip)}
              dataTestId="show-more"
            >
              keep your {oldDevice}
            </TextLink>{' '}
            instead?
          </Copy>
        </Container>
      </SlideDownFadeIn>
      {showTip && (
        <SlideDownFadeIn visible={showFields}>
          <>
            <Spacer height={32} />
            <Container
              backgroundColor={secondaryColors.c04}
              dataTestId="more-info"
            >
              <Copy color={primaryColors.c01}>
                If you’re happy with the device you’ve been using, then just
                carry on paying monthly.
              </Copy>
              <Spacer height={12} />
              <Copy color={primaryColors.c01}>
                If you want to purchase the device, please contact us to discuss
                after you have ordered your {device}.
              </Copy>
            </Container>
          </>
        </SlideDownFadeIn>
      )}
      <Container noPadding>
        <Spacer height={40} />
        <Button
          dataTestId="step-upgrade-continue"
          buttonColor={ButtonTypes.b01}
          disabled={isSubmitting}
          onClick={onSubmit}
          size="large"
          text="Let's Go"
        />
      </Container>
    </StyledStepUpgradeContent>
  );
};
