import useMedia from 'use-media';
import DeviceCard from 'components/DeviceCard';
import Container from 'elements/Container';
import Grid, { GridColumn } from 'elements/Grid';
import Header from 'elements/Header';
import Spacer from 'elements/Spacer';
import { Fragment, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { rayloLight } from 'styles/themes/rayloLight';
import { px2Rem } from 'utils/px2Rem';
import { LEGACY_BREAKPOINTS } from 'styles/LEGACY_BREAKPOINTS';
import { YourTechStatus } from 'screens/Account/YourTechStatus';

type YourDevicesProps = {
  orders: any[];
};

export const Devices = ({
  orders,
}: YourDevicesProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const isTablet = useMedia({ maxWidth: LEGACY_BREAKPOINTS.tablet });
  const maxWidth = isTablet ? 560 : 1360;

  const isInArrears = orders.some(
    (order: any) => order?.status === YourTechStatus.arrears
  );

  return (
    <ThemeProvider theme={rayloLight}>
      <Container center>
        <Spacer height={12} />
        <Header level={2} fontSize={24}>
          Your tech
        </Header>
        <Spacer height={12} />
        <Container
          alignCenter
          maxWidth={`${px2Rem(maxWidth)}`}
          noPadding
        >
          <Grid center>
            {orders?.map((order: any, i: number) => (
              <Fragment key={`${order}-${i}`}>
                <GridColumn
                  columns={2}
                  sm={1}
                  md={1}
                  colPadding={`0 ${px2Rem(32)} ${px2Rem(32)} ${px2Rem(32)}`}
                  key={i}
                >
                  <div ref={ref}>
                    <DeviceCard
                      key={i}
                      order={order}
                      isInArrears={isInArrears}
                    />
                  </div>
                </GridColumn>
              </Fragment>
            ))}
          </Grid>
        </Container>
      </Container>
    </ThemeProvider>
  );
};
