import { DEFAULT_TERM_LENGTH } from '../constants';
import type {
  NavDataModel,
  CategoryItem,
  ProductCategoriesQuery,
  Category,
  ProductsQuery,
  ProductsWithDefaultVariant
} from '../types/navData.types';
import pluralize from 'pluralize';

const cloneDeep = (obj: any) => JSON.parse(JSON.stringify(obj));

// currently no way to order these programmatically
export const orderedCategories = [
  'Phones',
  'Laptops',
  'Tablets',
  'Consoles',
  'Watches',
  'Headphones',
  'Desktops',
  'TVs',
  'Speakers',
];

export const reorderCategories = (navData: NavDataModel): NavDataModel => {
  const categories: NavDataModel['categories'] = cloneDeep(navData.categories);

  const orderedCategoriesData = categories.sort((a, b) => {
    return orderedCategories.indexOf(a.name) - orderedCategories.indexOf(b.name);
  });

  return { categories: orderedCategoriesData };
};

const addDefaultVariantToProduct = (products: ProductsQuery[]) => {
  return products.flatMap((product) => {
    const availableVariants = product?.variants.filter(variant => variant.available).sort((a, b) => a.position - b.position);
    const defaultVariant = availableVariants?.[0];
    if (!defaultVariant) return null;
    return {
      defaultVariant,
      displayName: product?.displayName ?? '',
      catDisplayName: product?.category.displayName,
      make: product?.manufacturer.displayName,
      variantSlug: `${defaultVariant.slug}-${DEFAULT_TERM_LENGTH}-months`,
      position: product?.position,
    };
  }).filter(prod => !!prod);
};

const createCategoryItem = (category: Category, categoryProducts: ProductsWithDefaultVariant[]) => {
  const catName = category.displayName;
  if (!catName) return;
  const categoryItem: CategoryItem = {
    name: catName === 'TV' ? 'TVs' : pluralize(catName),
    makes: [],
  };

  const makeNames = categoryProducts.filter(prod => prod?.catDisplayName === catName)
    .map(product => product?.make);
  const uniqueMakes = [...new Set(makeNames)];
  const makes = uniqueMakes.map(name => ({ name, models: [] }));

  const models = makes.map((make) => {
    const filteredModels = categoryProducts.filter(prod => prod?.catDisplayName === catName && prod.make === make.name);

    const models = filteredModels.map(model => ({
      displayName: model?.displayName,
      slug: model?.variantSlug,
    }));
    return { ...make, models };
  });

  categoryItem.makes = models;

  return categoryItem;
}

export const getNavDataModel = (
  categories: ProductCategoriesQuery['productCategories'],
  products: ProductsQuery[],
): NavDataModel => {
  const data: NavDataModel = {
    categories: [],
  };

  const availableProducts = products.filter(prod => prod?.available);
  const productsWithDefaultVariant = addDefaultVariantToProduct(availableProducts);
  const filteredProducts = productsWithDefaultVariant.filter(prod => !!prod?.catDisplayName && !!prod.make)
    .sort((a, b) => (a?.position ?? 0) - (b.position ?? 0)) as ProductsWithDefaultVariant[];

  categories.forEach((category) => {
    const categoryItem = createCategoryItem(category, filteredProducts);
    data.categories.push(categoryItem as CategoryItem);
  });

  return reorderCategories(data);
};