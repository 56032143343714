import React from 'react';
import { Copy } from '@raylo-tech/raylopay-ui';
import { useHistory } from 'react-router';
import Spacer from 'elements/Spacer';
import { track } from 'integrations/segment/events';
import { PaymentOutcomeScreen } from './PaymentOutcomeScreen';
import Header from '../../../../elements/Header';
import { primaryColors } from '../../../../styles/variables/colors';

export const ArrearsPaymentFailed = () => {
  const history = useHistory();

  return (
    <PaymentOutcomeScreen
      title="Payment unsuccessful"
      preContent={(
        <Header
          level={4}
          color={primaryColors.c01}
          fontSize={16}
          centered
        >
          Something went wrong
        </Header>
      )}
      mainContent={(
        <>
          <Copy fontSize={16} textAlign="center">
            Please try again ensuring your card details are correct and that you
            have sufficient funds in your account.
          </Copy>
          <Spacer height={32} />
          <Copy fontSize={16} textAlign="center">
            If you can’t pay the full amount, try making a partial payment. If your payment is still declined, try
            another card.
          </Copy>
        </>
      )}
      subContent={(
        <>
          <Spacer height={24} />
          <Copy fontSize={16} textAlign="center">
            <span style={{ fontWeight: 'bold' }}>Need help?</span> Use our&nbsp;
            <span
              onClick={() => Intercom('show')}
              style={{ textDecoration: 'underline', color: primaryColors.b01, cursor: 'pointer' }}
            >
              web chat
            </span>
          </Copy>
        </>
      )}
      onClick={() => {
        track('My Account Try Again Clicked', {
          screen: 'payment failure',
          when: "on clicking the 'Try Again' button",
          url: window.location.href,
        });
        history.goBack();
      }}
      buttonText="try again"
      iconName="PaymentUnsuccessful"
    />
  );
};
