import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from '../../colors';
import { CSS_FONT_WEIGHT } from '../../constants';

const RoundNumber = styled.div<{ small?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ small }) => (small ? `${px2Rem(20)}` : `${px2Rem(32)}`)};
  min-width: ${({ small }) => (small ? `${px2Rem(20)}` : `${px2Rem(32)}`)};
  height: ${({ small }) => (small ? `${px2Rem(20)}` : `${px2Rem(32)}`)};
  border-radius: 50%;
  background-color: ${palette.blue[200]};
  color: ${palette.white};
  font-weight: ${CSS_FONT_WEIGHT.vars.bold};
  font-size: ${({ small }) => (small ? `${px2Rem(12)}` : `${px2Rem(16)}`)};
`;

export { RoundNumber };
