import { useEffect } from 'react';
import styled from 'styled-components';

import { Button } from 'components/common/Button';

// HOOKS
import { QL_VARIANTS_PAGINATED } from 'screens/Products/queries';
import { EndpointForm } from "components/EndpointForm";
import useRayloLazyQuery from "utils/useRayloLazyQuery";
import { FadeIn } from "components/animations/Transitions";
import { px2Rem } from "utils/px2Rem";
import { Loader } from "components/common/Loader";
import { useMerchantContext } from "utils/useMerchantContext";
import { getRandomPickUrl } from 'utils/getRandomPickUrl';
import { CheckoutMode } from "screens/Checkout/enums/checkoutModes";
import { useSharedCustomer } from "utils/useSharedCustomer";

const VariantsList = () => {
  function pickUrl(variant, costSummary) {
    let url = `/pick/${variant.id}/${costSummary.id}`;

    if (checkoutMode === CheckoutMode.UPGRADE) {
      url = `${url}/${subscriptionId}`;
    }
    return url;
  }

  const { merchantId } = useMerchantContext();
  const { readDomainCookie } = useSharedCustomer();
  const checkoutMode = readDomainCookie('checkoutContext');
  const subscriptionId = readDomainCookie('subscriptionId');


  let [getVariants,
    {
      data: { variantsConnection },
      loading: loadingVariants,
      fetchMore
    }] = useRayloLazyQuery(QL_VARIANTS_PAGINATED, { skip: !merchantId });

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      window.location.href = 'https://raylo.com';
      return;
    }

    if (!merchantId) {
      return;
    }
    getVariants({
      notifyOnNetworkStatusChange: true,
      variables:
      {
        merchantId,
        after: null,
        first: 10,
      }
    });
  }, [merchantId]);

  const feelingLucky = () => {
    window.location = getRandomPickUrl(variantsConnection?.nodes)
  }

  const loadMoreVariants = () => {
    if (!fetchMore) return;

    fetchMore({
      variables: {
        merchantId,
        after: variantsConnection.pageInfo.endCursor,
        first: 50,
      },
      notifyOnNetworkStatusChange: true,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          variantsConnection: {
            nodes: previousResult.variantsConnection.nodes.concat(fetchMoreResult.variantsConnection.nodes),
            pageInfo: fetchMoreResult.variantsConnection.pageInfo
          }
        };
      }
    });
  };

  if (!variantsConnection?.nodes) return (<FadeIn>
    <div style={{ position: 'absolute', top: `calc(50% - ${px2Rem(15)})`, left: `calc(50% - ${px2Rem(15)})` }}>
      <Loader type="quad" />
    </div>
  </FadeIn>);

  const hasNextPage = !!variantsConnection?.pageInfo?.hasNextPage;

  return (
    <div data-testid='devtools-container'>
      {process.env.REACT_APP_DYNAMIC_GRAPHQL_ENABLED === 'true' ? <EndpointForm /> : null}

      <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
        <div style={{ margin: 5 }}><Button onClick={() => feelingLucky()}>Feeling Lucky?</Button></div>
      </div>

      {checkoutMode === CheckoutMode.ADDITIONAL_TECH &&
          <div style={{margin: 20, display: 'flex', justifyContent: 'center'}}>
          <div style={{margin: 5, backgroundColor: '#ffcec7', color: 'black', padding: '20px'}}>IN ADD TECH CHECKOUT MODE</div>
        </div>
      }

      {checkoutMode === CheckoutMode.UPGRADE &&
          <div style={{margin: 20, display: 'flex', justifyContent: 'center'}}>
            <div style={{margin: 5, backgroundColor: '#ffcec7', color: 'black', padding: '20px'}}>IN UPGRADE CHECKOUT MODE</div>
          </div>
      }

      <VariantTable data-testid="variant-table">
        <thead>
        <tr>
          <th>Phone</th>
          <th>Plans (Insurance-Term)</th>
        </tr>
        </thead>
        <tbody>
          {variantsConnection.nodes.map((variant) => {

            return (
              <tr key={variant.id} data-testid="variant">
                <td>{variant.displayName}</td>
                <td>
                  {variant.costSummaries.map((summary) => {
                    const title = `${variant.displayName} - ${summary.termLength} months`
                    return (
                      <a key={summary.id} title={title} href={pickUrl(variant, summary)} data-testid='price-plan-link'>
                        {summary.includesInsurance ? "Y" : "N"}-{summary.termLength}
                      </a>
                    )
                  })}
                </td>

              </tr>
            );
          })}</tbody>
      </VariantTable>
      {loadingVariants && (<div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
        <Loader type="quad" />
      </div>)}
      {hasNextPage && <div style={{ margin: 20, display: 'flex', justifyContent: 'center' }}>
        <div style={{ margin: 5 }}><Button dataTestId="load-more-variant-button" disabled={loadingVariants} onClick={() => loadMoreVariants()}>{loadingVariants ? 'LOADING...' : 'LOAD MORE VARIANTS'}</Button></div>
      </div>}
    </div>
  )
}

export default VariantsList

const VariantTable = styled.table`
  color: white;
  border-collapse: collapse;
  width: 100%;
  margin: 20px;
  td, th {
    text-align: left;
    vertical-align: middle;
    padding: 5px;
  }
  tr td {
    border-bottom: 1px solid #333;
    font-size: 13px;
  }
  a {
    color: white;
    display: inline-block;
    margin: 5px;
    padding: 2px 4px;
    background-color: #404EFF;
    text-decoration: none;
    font-size: 12px;
  }
`
