import { useSegmentCheckoutTracking } from 'integrations/segment/hooks';
import { useStepTradeInContext } from 'screens/Checkout/containers/StepTradeIn/hooks/useStepTradeInContext';
import ITestDataId from 'types/ITestDataId.type';
import TradeInJourney from '../TradeInJourney';
import { StyledStepTradeInContent } from './styles';
import useMedia from 'use-media';
import { LEGACY_BREAKPOINTS } from '../../../../styles/LEGACY_BREAKPOINTS';
import TradeInView from 'checkout/Screens/components/TradeInView';

export const StepTradeInContent = ({ dataTestId }: ITestDataId) => {
  const isSmallScreen = useMedia({ maxWidth: LEGACY_BREAKPOINTS.desktop });

  useSegmentCheckoutTracking({
    eventName: 'Checkout Step Viewed',
    checkoutArea: 'trade in',
    checkoutScreen: 'trade in available screen',
    when: 'on page load',
  });

  const TradeInViewComponent = () => {
    return (
      <TradeInView isSmallScreen={isSmallScreen} />
    );
  };

  const { tradeInAccepted, tradeInExists } = useStepTradeInContext();
  return (
    <StyledStepTradeInContent data-testid={dataTestId}>
      {tradeInAccepted || tradeInExists ? <TradeInJourney /> : <TradeInViewComponent />}
    </StyledStepTradeInContent>
  );
};
