import { Button, Header, SectionMessageWithIcon, Spacer } from '@raylo-tech/raylopay-ui';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { IGetSubscriptionQueryOrderItem } from 'screens/Account/Forms/graphql/subscription.graphql';
import { IReturnItemOverview } from './ReturnItemOverview.types';

export const ReturnItemOverview = (
  {
    onNext,
    onClose,
    orderItem,
    header,
    children,
    primaryButtonText,
    secondaryButtonText,
  }: IReturnItemOverview) => {
  return (
    <>
      <Header fontSize={24}>{header}</Header>
      <Spacer height={24} />
      <SectionMessageWithIcon
        dataTestId="order-item-section-message"
        header={orderItem.variant.product.displayName}
        copy={productSubTitle(orderItem)}
        iconUrl={imageUrl(orderItem)}
        maxIconHeight={48}
        backgroundColor={primaryColors.w01}
        borderColor={secondaryColors.c04}
        iconLeftAlign={true}
      />
      <Spacer height={24} />
      {children}
      <Spacer height={24} />
      <Button onClick={onNext} dataTestId="form-primary-button">{primaryButtonText}</Button>
      <Spacer height={24} />
      <Button onClick={onClose} secondary dataTestId="form-secondary-button">{secondaryButtonText}</Button>
    </>
  );
};

const imageUrl = (orderItem: IGetSubscriptionQueryOrderItem) => {
  const url = orderItem.variant.images.find(image => image.tag === 'front')?.url;
  if (!url) return;

  const imageHeight = 48;
  return `${url}?trim=color&fm=avif&h=${imageHeight}`;
};

const productSubTitle = (orderItem: IGetSubscriptionQueryOrderItem) => {
  const includedOptionTypes = ['Storage', 'Colour'];
  const optionValueNames = orderItem.variant.optionValues
    .filter(optionValue => includedOptionTypes.includes(optionValue.optionType.displayName))
    .map(optionValue => optionValue.displayName);

  return optionValueNames.join(', ');
};
