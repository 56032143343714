import styled from 'styled-components';
import { px2Rem } from '../../../utils/px2Rem';
import { palette } from '../../../colors';
import { ITextArea } from './TextArea.types';

export const StyledTextAreaContainer = styled.div<ITextArea>`
    position: relative;
    display: flex;
    width: 100%;
    height: ${({ height }) => height && px2Rem(height)};
`;

export const StyledTextArea = styled.textarea<ITextArea>`
    width: 100%;
    height: 100%;
    padding: ${px2Rem(22)} ${px2Rem(10)};
    border: 1px solid ${palette.charcoal[300]};
    ${({ $isFocused }) => $isFocused && `
        outline: 1px solid ${palette.blue[500]};
        outline-offset: 0px;
        border-color: ${palette.blue[500]};
        background-color: ${palette.charcoal[100]};
    `}
    &:hover {
    ${({ $isFocused }) => !$isFocused && `
        border-color: ${palette.charcoal[500]};
        background-color: ${palette.charcoal[100]};
    `}
    }
    margin-top: ${px2Rem(12)};
    font-size: ${px2Rem(16)};
    line-height: ${px2Rem(24)};
    color: ${palette.charcoal[500]};
    resize: none;
`;

export const StyledFloatingLabel = styled.label<ITextArea>`
    position: absolute;
    pointer-events: none;
    top: ${props => (props.$isFocused || props.value ? '0' : px2Rem(10))};
    left: ${px2Rem(10)};
    font-size: ${props => (props.$isFocused || props.value ? px2Rem(12) : px2Rem(16))};
    color: ${props => (props.$isFocused ? palette.blue[500] : palette.charcoal[400])};
    padding: ${px2Rem(12)} 0;
    transition: all 0.2s ease-in-out;
    line-height: ${px2Rem(24)};
`;
