import { useStepOpenBankingContext } from 'screens/Checkout/containers/StepOpenBanking/hooks/useStepOpenBankingContext';
import ITestDataId from 'types/ITestDataId.type';
import AccountScore from '../AccountScore';
import { StyledStepOpenBankingContent } from './styles';

export const StepOpenBankingContent = ({ dataTestId }: ITestDataId) => {
  const { providerName } = useStepOpenBankingContext();
  return (
    <StyledStepOpenBankingContent data-testid={dataTestId}>
      {providerName === 'account_score' && <AccountScore />}
    </StyledStepOpenBankingContent>
  );
};
