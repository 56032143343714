import { px2Rem } from './../../../utils/px2Rem';
import styled from 'styled-components';

const StyledLeaseTotalBar = styled.div`
  & button {
    height: ${px2Rem(44)};
    width: 100%;
  }
`;

export { StyledLeaseTotalBar };
