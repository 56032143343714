import { Typography } from '@raylo-tech/raylopay-ui';
import { px2Rem } from 'utils/px2Rem';

type CheckoutOrderSummaryInfoProps = {
  title?: string;
  rows: string[];
  dataTestId?: string;
};

export const CheckoutOrderSummaryInfo = ({
  title,
  rows,
  dataTestId,
}: CheckoutOrderSummaryInfoProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: px2Rem(8),
      }}
      data-testid={dataTestId}
    >
      {title && (
        <Typography
          bold
          data-testid={dataTestId ? `${dataTestId}-title` : undefined}
          // Mask PII
          data-sentry-mask
        >
          {title}
        </Typography>
      )}
      {rows.map((row, rowIndex) => (
        <Typography
          key={`${rowIndex}-${row}`}
          data-testid={dataTestId ? `${dataTestId}-row-${rowIndex}` : undefined}
          // Mask PII
          data-sentry-mask
        >
          {row}
        </Typography>
      ))}
    </div>
  );
};
