import { ReactComponent as PoweredByRayloPay } from 'public/raylo-pay/r-plogo.svg';
import { IRayloPayLogo } from './types';
import { StyledRayloPayLogo } from './styles';

export const RayloPayLogo = ({ dataTestId, merchantLogo }: IRayloPayLogo) => {
  return (
    <StyledRayloPayLogo data-testid={dataTestId}>
      <img src={merchantLogo} alt="" />
      <PoweredByRayloPay />
    </StyledRayloPayLogo>
  );
};
