import gql from 'graphql-tag';

export interface ICheckoutIncomingPaymentsQuery {
  checkout: {
    id: string;
    incomingPayments: {
      id: string;
      state: string;
      partnerReferences: {
        id: string;
        referenceType: string;
        reference: string;
      }[];
    }[];
  };
}
export const CHECKOUT_INCOMING_PAYMENTS = gql`
  query CheckoutIncomingPaymentsQuery($token: String!) {
    checkout(token: $token) {
      id
      incomingPayments {
        id
        state
        partnerReferences {
          id
          referenceType
          reference
        }
      }
    }
  }
`;
