import Copy from 'elements/Copy';
import NumberedList from 'components/NumberedList';
import React from 'react';
import BulletList from 'components/BulletList';
import ITradeInCopy from './types';
import { primaryColors } from 'styles/variables/colors';

export const TradeInCopy = ({
  dataTestId,
  tradeInValues,
  tradeInAssessed,
}: ITradeInCopy) => {
  const listItem = (title: string, listItems: string[]) => {
    return (
      <React.Fragment>
        <Copy
          color={primaryColors.c01}
          fontSize={16}
          lineHeight={22}
          styles="paddingBottom: 12px;"
        >
          {title}
        </Copy>
        <BulletList listItems={listItems} />
      </React.Fragment>
    );
  };

  const recurringAmount = tradeInValues?.recurring?.amount?.formattedValue;
  const recurringTermLength = tradeInValues?.recurring?.termLength;

  const valuationWithValues = [
    "Answer a few questions and discover your device's value instantly.",
    `Earn up to ${recurringAmount}/mo for ${recurringTermLength} months, totalling ${tradeInValues?.total?.formattedValue}.`,
  ];

  const valuationWithoutValues = [
    "Answer a few questions and discover your device's value instantly.",
  ];

  const valuationMessages = tradeInValues ? valuationWithValues : valuationWithoutValues;

  const getInstantValuation = listItem('Get your instant valuation:', valuationMessages);

  const chooseHowYoullSend = listItem('Send it', [
    'After receiving your new device, return the old one via your local post office, DPD collection, or DPD drop-off. No printing needed.',
  ]);

  const enjoyCashback = listItem('Get cashback', [
    `Automatically receive ${recurringAmount ? `${recurringAmount}` : 'cashback'} monthly into your bank account${recurringTermLength ? ` for ${recurringTermLength} months` : ''}.`,
  ]);

  const defaultItems = [chooseHowYoullSend, enjoyCashback];

  return (
    <NumberedList
      dataTestId={dataTestId}
      listItems={tradeInAssessed ? defaultItems : [getInstantValuation, ...defaultItems]}
    />
  );
};
