import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import constate from 'constate';
import useRayloQuery from 'utils/useRayloQuery';
import { QL_DECISION } from 'screens/Checkout/graphQL/queries';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useMerchantContext } from 'utils/useMerchantContext';
import { ICheckoutItemCostSummary } from 'types/Checkout/types';
import { useSharedCustomer } from 'utils/useSharedCustomer';

type DeliveryAddress = {
  line1: string;
  line2: string;
  line3: string;
  city: string;
  postcode: string;
};

const useCheckoutDecision = () => {
  const [merchantName, setMerchantName] = useState<string | undefined>();
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [productDisplayName, setProductDisplayName] = useState<string>('');
  const [productImageUrl, setProductImageUrl] = useState<string>('');
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>();
  const [deliveryEstimate, setDeliveryEstimate] = useState<object>();
  const [oldDevice, setOldDevice] = useState<string | undefined>();
  const [feeSummary, setFeeSummary] = useState<object>();
  const [costSummary, setCostSummary] = useState<ICheckoutItemCostSummary>();
  const [displayDecision, setDisplayDecision] = useState<boolean>(false);
  const { setUserToken } = useSharedCustomer();

  const { checkoutToken } = useCheckoutContext();
  const { merchantId } = useMerchantContext();

  const {
    data: { checkout },
    loading,
    error,
    stopPolling,
  } = useRayloQuery(QL_DECISION, {
    variables: {
      checkoutToken,
      merchantId,
    },
    pollInterval: 500,
    skip: !merchantId,
  });

  const setCheckoutState = (checkout: any) => {
    const checkoutItem = checkout.items[0];
    const productName = checkoutItem?.variant?.product?.displayName;
    const imageUrl = checkoutItem?.variant?.images?.[0]?.url;
    const delivery = checkoutItem?.deliveryEstimatedBetween;
    setRedirectUrl(checkout.redirectUrl);
    setMerchantName(checkout?.merchant?.displayName);
    setProductDisplayName(productName);
    setProductImageUrl(imageUrl);
    setDeliveryAddress(checkout?.address);
    setDeliveryEstimate(delivery);
    const { originSubscription } = checkoutItem || {};
    if (originSubscription) {
      setOldDevice(
        originSubscription.activeAsset.variant.product?.displayName
      );
      setFeeSummary(originSubscription.feeSummary?.currentNonReturnFee);
      setCostSummary(
        originSubscription.costSummary?.recurring?.totalAmount?.valueAfterTax
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      stopPolling();
      setDisplayDecision(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (checkout && checkout.items) {
      if ((checkout.decision?.outcome !== 'ACCEPTED') || (checkout.decision?.outcome === 'ACCEPTED' && checkout.customer)) {
        stopPolling();
        setDisplayDecision(true);

        if (checkout.decision?.outcome == 'ACCEPTED' && checkout.customer.userToken) {
          setUserToken(checkout.customer.userToken);
        }
      }

      setCheckoutState(checkout);
    }
  }, [checkout]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  const handleRedirect = () => {
    window.location.replace(redirectUrl);
  };

  const preApprovalLimit = checkout?.customer?.preApproval?.recurringTaxableAmount?.valueAfterTax.value;

  return {
    merchantName,
    redirectUrl,
    loading,
    error,
    productDisplayName,
    productImageUrl,
    deliveryAddress,
    deliveryEstimate,
    oldDevice,
    feeSummary,
    costSummary,
    handleRedirect,
    checkoutId: checkout?.id,
    displayDecision,
    customerPreApproval: preApprovalLimit,
    originalRecurringAmount: checkout?.items[0].costSummary.recurring.totalAmount.valueAfterTax.value,
  };
};

const [CheckoutDecisionProvider, useCheckoutDecisionContext] =
  constate(useCheckoutDecision);
export { CheckoutDecisionProvider, useCheckoutDecisionContext };
