import { ReactElement } from 'react';
import { Copy, Spacer, TextLink, SectionMessageBase } from '@raylo-tech/raylopay-ui';

export enum CancellationCategory {
  END_OF_LEASE = 'end_of_lease',
  COOLING_OFF = 'cooling_off'
}

export interface ICopy {
  heading: string;
  sectionMessageHeading: string;
  bulletPoints: string[];
  footer: ReactElement | null;
}

interface CopyList {
  'I have changed my mind about leasing and want to get my device elsewhere': ICopy;
  'I only realised I would be leasing the device after signing up': ICopy;
  'I don’t want to pay anything at the end of the lease term to keep the device': ICopy;
  'I am not happy with the size of the device': ICopy;
  'I am not happy with the storage size of the device': ICopy;
  'I ordered the wrong device and want to get a different one': ICopy;
  'I ordered the correct device but have now changed my mind': ICopy;
  'The battery health is lower than I expected': ICopy;
  'The cosmetic condition of the device doesn’t meet my expectations': ICopy;
  'The device won’t power on': ICopy;
  'The screen isn’t working properly': ICopy;
  'The device isn’t charging properly': ICopy;
  'The speaker isn’t working properly': ICopy;
  'The camera isn’t working properly': ICopy;
  'Other': ICopy;
  'I have found a better monthly price somewhere else': ICopy;
  'I can no longer afford the monthly payments': ICopy;
  "I don't think it is cost effective to lease the device": ICopy;
  'The delivery took too long': ICopy;
  'It was a gift for someone and they no longer need it': ICopy;
  'I have been gifted a device': ICopy;
  'I got my old device repaired': ICopy;
  "The customer service didn't meet my expectations": ICopy;
  "I didn't realise I had placed an order": ICopy;
  "I don't think leasing is the best option for me": ICopy;
  "I didn't realise I was leasing the device until after I had received it": ICopy;
  'The cost to keep the device is too high': ICopy;
  'The device is lost': ICopy;
  'The device was stolen': ICopy;
  "I added an additional device recently and I don't need both": ICopy;
  'I no longer use the device': ICopy;
  'I want to give the device to someone else': ICopy;
  'My device is damaged': ICopy;
  'My device has a functional fault': ICopy;
}

const COOLING_OFF_COPY: Partial<CopyList> = {
  'I have changed my mind about leasing and want to get my device elsewhere': {
    heading: 'Are you sure you want to cancel? You’ll be missing out',
    sectionMessageHeading: 'By cancelling you’ll miss out on:',
    bulletPoints: ['Super low monthly payments', 'A lifetime warranty', 'UK based support', 'A sustainable choice', 'Flexibility to upgrade for free, keep paying monthly or contact us to discuss purchasing at the end of your lease'],
    footer: <Copy> We’d love to see if we could help change your mind. <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">Contact us</TextLink> if there is anything we can do </Copy>,
  },
  'I only realised I would be leasing the device after signing up': {
    heading: 'Are you sure you want to cancel? You’ll be missing out',
    sectionMessageHeading: 'By cancelling you’ll miss out on:',
    bulletPoints: ['Super low monthly payments', 'A lifetime warranty', 'UK based support', 'A sustainable choice', 'Flexibility to upgrade for free, keep paying monthly or contact us to discuss purchasing at the end of your lease'],
    footer: <Copy> We’d love to see if we could help change your mind. <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">Contact us</TextLink> if there is anything we can do </Copy>,
  },
  'I don’t want to pay anything at the end of the lease term to keep the device': {
    heading: 'Are you sure you want to cancel? You’ll be missing out',
    sectionMessageHeading: 'By cancelling you’ll miss out on:',
    bulletPoints: ['Super low monthly payments', 'A lifetime warranty', 'UK based support', 'A sustainable choice', 'Flexibility to upgrade for free, keep paying monthly or contact us to discuss purchasing at the end of your lease'],
    footer: <Copy> We’d love to see if we could help change your mind. <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">Contact us</TextLink> if there is anything we can do </Copy>,
  },
  'I am not happy with the size of the device': {
    heading: "We're happy you're choosing to stay with Raylo",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: (
      <>
        <Copy>To get a different device, first you&apos;ll need to continue this cancellation and return your device.</Copy>
        <Spacer height={12} />
        <Copy>Next, you&apos;ll need to order again. We&apos;ll need to re-run our checks, including a new soft credit search. This will not affect your credit score. </Copy>
        <Spacer height={12} />
        <Copy>Although unlikely, there is a chance your order might not be accepted or the price might be different.</Copy>
      </>),
  },
  'I am not happy with the storage size of the device': {
    heading: "We're happy you're choosing to stay with Raylo",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: (
      <>
        <Copy>To get a different device, first you&apos;ll need to continue this cancellation and return your device.</Copy>
        <Spacer height={12} />
        <Copy>Next, you&apos;ll need to order again. We &apos;ll need to re-run our checks, including a new soft credit search. This will not affect your credit score. </Copy>
        <Spacer height={12} />
        <Copy>Although unlikely, there is a chance your order might not be accepted or the price might be different.</Copy>
      </>),
  },
  'I ordered the wrong device and want to get a different one': {
    heading: "We're happy you're choosing to stay with Raylo",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: (
      <>
        <Copy>To get a different device, first you&apos;ll need to continue this cancellation and return your device.</Copy>
        <Spacer height={12} />
        <Copy>Next, you&apos;ll need to order again. We’ll need to re-run our checks, including a new soft credit search. This will not affect your credit score. </Copy>
        <Spacer height={12} />
        <Copy>Although unlikely, there is a chance your order might not be accepted or the price might be different.</Copy>
      </>),
  },
  'I ordered the correct device but have now changed my mind': {
    heading: "We're happy you're choosing to stay with Raylo",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: (
      <>
        <Copy>To get a different device, first you&apos;ll need to continue this cancellation and return your device.</Copy>
        <Spacer height={12} />
        <Copy>Next, you&apos;ll need to order again. We’ll need to re-run our checks, including a new soft credit search. This will not affect your credit score. </Copy>
        <Spacer height={12} />
        <Copy>Although unlikely, there is a chance your order might not be accepted or the price might be different.</Copy>
      </>),
  },
  'The battery health is lower than I expected': {
    heading: "We're sorry to hear there is a problem with your device.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> All Raylo devices are covered by a Lifetime Warranty. If you&apos;d prefer to return the device for a free repair or replacement please submit a request with our team <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">here</TextLink>.</Copy>,
  },
  'The cosmetic condition of the device doesn’t meet my expectations': {
    heading: "We're sorry to hear there is a problem with your device.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> All Raylo devices are covered by a Lifetime Warranty. If you&apos;d prefer to return the device for a free repair or replacement please submit a request with our team <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">here</TextLink>.</Copy>,
  },
  'The device won’t power on': {
    heading: "We're sorry to hear there is a problem with your device.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> All Raylo devices are covered by a Lifetime Warranty. If you&apos;d prefer to return the device for a free repair or replacement please submit a request with our team <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">here</TextLink>.</Copy>,
  },
  'The screen isn’t working properly': {
    heading: "We're sorry to hear there is a problem with your device.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> All Raylo devices are covered by a Lifetime Warranty. If you&apos;d prefer to return the device for a free repair or replacement please submit a request with our team <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">here</TextLink>.</Copy>,
  },
  'The device isn’t charging properly': {
    heading: "We're sorry to hear there is a problem with your device.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> All Raylo devices are covered by a Lifetime Warranty. If you&apos;d prefer to return the device for a free repair or replacement please submit a request with our team <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">here</TextLink>.</Copy>,
  },
  'The speaker isn’t working properly': {
    heading: "We're sorry to hear there is a problem with your device.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> All Raylo devices are covered by a Lifetime Warranty. If you&apos;d prefer to return the device for a free repair or replacement please submit a request with our team <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">here</TextLink>.</Copy>,
  },
  'The camera isn’t working properly': {
    heading: "We're sorry to hear there is a problem with your device.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> All Raylo devices are covered by a Lifetime Warranty. If you&apos;d prefer to return the device for a free repair or replacement please submit a request with our team <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">here</TextLink>.</Copy>,
  },
  'Other': {
    heading: "We're sorry to hear there is a problem with your device.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> All Raylo devices are covered by a Lifetime Warranty. If you&apos;d prefer to return the device for a free repair or replacement please submit a request with our team  <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/4094747-raylo-s-lifetime-warranty">here</TextLink>.</Copy>,
  },
  'I have found a better monthly price somewhere else': {
    heading: "Are you sure you want to cancel? You'll be missing out",
    sectionMessageHeading: 'If you cancel you’ll be missing out on:',
    bulletPoints: ['No upfront cost', 'A lifetime warranty', 'Free upgrades', 'UK based support with an Excellent Trustpilot rating', 'Most sustainable way to get a new device'],
    footer: null,
  },
  'I can no longer afford the monthly payments': {
    heading: "We're sorry to see you go",
    sectionMessageHeading: 'We’d love you to stay with Raylo by cancelling this order and reapplying for:',
    bulletPoints: ['A lower priced device', 'A refurbished device', 'A longer term for lower pricing'],
    footer: <Copy>We understand your reason for cancelling, it&apos;s important you can afford the monthly payments. </Copy>,
  },
  "I don't think it is cost effective to lease the device": {
    heading: "Are you sure you want to cancel? You'll be missing out",
    sectionMessageHeading: 'If you cancel you’ll be missing out on:',
    bulletPoints: ['No upfront cost', 'A lifetime warranty', 'Free upgrades', 'UK based support with an Excellent Trustpilot rating', 'Most sustainable way to get a new device'],
    footer: null,
  },
  'The delivery took too long': {
    heading: "We're sorry your order was late, we experienced greater demand than expected.",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy> We’d love to see if we could help change your mind.  <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">Contact us</TextLink> if there is anything we can do. </Copy>,
  },
  'It was a gift for someone and they no longer need it': {
    heading: 'Thanks for choosing Raylo.',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>We hope to welcome you back for your next device.</Copy>,
  },
  'I have been gifted a device': {
    heading: 'Thanks for choosing Raylo.',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>We hope to welcome you back soon.</Copy>,
  },
  'I got my old device repaired': {
    heading: 'Thanks for choosing Raylo.',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>We hope to welcome you back soon.</Copy>,
  },
  "The customer service didn't meet my expectations": {
    heading: "We're sorry the customer service hasn't met your expectations",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: (
      <>
        <Copy>You may be required to pay an Early Cancellation Charge. <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/6124572-how-do-i-calculate-my-early-cancellation-charge">Find out more</TextLink>.</Copy>
        <Spacer height={12} />
        <Copy>We&apos;d love the chance to make it up to you. Our UK based customer support team can easily be reached <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">here</TextLink></Copy>
      </>
    ),
  },
  "I didn't realise I had placed an order": {
    heading: "Are you sure you want to cancel? You'll be missing out",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>We&apos;ve sent you an email to confirm your order. Are you sure you don&apos;t want to continue leasing your new device?</Copy>,
  },
};

const END_OF_LEASE_COPY: Partial<CopyList> = {
  "I don't think leasing is the best option for me": {
    heading: 'Are you sure you want to end your lease? You’ll be missing out',
    sectionMessageHeading: 'By ending your lease you’ll miss out on:',
    bulletPoints: ['Super low monthly payments', 'UK based support with an Excellent Trustpilot rating', 'A sustainable choice', 'A lifetime warranty', 'Flexibility to upgrade for free, keep paying monthly or contact us to discuss purchasing at the end of your lease'],
    footer: <Copy><TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">Contact us</TextLink> if there is anything we can do to help.</Copy>,
  },
  "I didn't realise I was leasing the device until after I had received it": {
    heading: 'Are you sure you want to end your lease? You’ll be missing out',
    sectionMessageHeading: 'By ending your lease you’ll miss out on:',
    bulletPoints: ['Super low monthly payments', 'UK based support with an Excellent Trustpilot rating', 'A sustainable choice', 'A lifetime warranty', 'Flexibility to upgrade for free, keep paying monthly or contact us to discuss purchasing at the end of your lease'],
    footer: <Copy><TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">Contact us</TextLink> if there is anything we can do to help.</Copy>,
  },
  'The cost to keep the device is too high': {
    heading: 'Are you sure you want to end your lease? You’ll be missing out',
    sectionMessageHeading: 'By ending your lease you’ll miss out on:',
    bulletPoints: ['Super low monthly payments', 'UK based support with an Excellent Trustpilot rating', 'A sustainable choice', 'A lifetime warranty', 'Flexibility to upgrade for free, keep paying monthly or contact us to discuss purchasing at the end of your lease'],
    footer: <Copy><TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">Contact us</TextLink> if there is anything we can do to help.</Copy>,
  },
  'I have found a better monthly price somewhere else': {
    heading: "Are you sure you want to cancel? You'll be missing out",
    sectionMessageHeading: 'If you cancel you’ll be missing out on:',
    bulletPoints: ['No upfront cost', 'A lifetime warranty', 'Free upgrades', 'UK based support with an Excellent Trustpilot rating', 'Most sustainable way to get a new device'],
    footer: null,
  },
  'I can no longer afford the monthly payments': {
    heading: "We're sorry to see you go",
    sectionMessageHeading: 'It’s important you can afford the monthly payments, but we’d love you to stay with Raylo by considering upgrading to:',
    bulletPoints: ['A lower priced device', 'A refurbished device', 'A longer term for lower pricing'],
    footer: null,
  },
  "I don't think it is cost effective to lease the device": {
    heading: "Are you sure you want to cancel? You'll be missing out",
    sectionMessageHeading: 'If you cancel you’ll be missing out on:',
    bulletPoints: ['No upfront cost', 'A lifetime warranty', 'Free upgrades', 'UK based support with an Excellent Trustpilot rating', 'Most sustainable way to get a new device'],
    footer: null,
  },
  'The device is lost': {
    heading: 'We’re sorry your device is lost',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: (
      <>
        <Copy>You need to pay a Non-Return Fee to cover the loss.</Copy>
        <Spacer height={12} />
        <Copy>Your regular monthly payments will continue until the Non-return fee is paid. After you’ve paid, we’ll stop the payments and block the device.</Copy>
        <Spacer height={12} />
        <Copy>We&apos;ll email you instructions on how to pay the Non-return Fee.</Copy>
        <Spacer height={12} />
        <SectionMessageBase
          header="Have Raylo insurance?"
          content={(
            <>
              <Copy>You can submit a claim instead of continuing this cancellation and paying the Non-Return Fee.</Copy>
              <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3223899-how-to-submit-an-insurance-claim">Submit a claim.</TextLink>
            </>
          )}
        />
      </>
    ),
  },
  'The device was stolen': {
    heading: 'We’re sorry your device was stolen',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: (
      <>
        <Copy>You need to pay a Non-Return Fee to cover the loss.</Copy>
        <Spacer height={12} />
        <Copy>Your regular monthly payments will continue until the Non-return fee is paid. After you’ve paid, we’ll stop the payments and block the device.</Copy>
        <Spacer height={12} />
        <Copy>We&apos;ll email you instructions on how to pay the Non-return Fee.</Copy>
        <Spacer height={12} />
        <SectionMessageBase
          header="Have Raylo insurance?"
          content={(
            <>
              <Copy>You can submit a claim instead of continuing this cancellation and paying the Non-Return Fee.</Copy>
              <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3223899-how-to-submit-an-insurance-claim">Submit a claim.</TextLink>
            </>
          )}
        />
      </>
    ),
  },
  'I want to give the device to someone else': {
    heading: 'It’s great to hear your old device is getting a new lease of life with someone else',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>Don’t forget you can refer a friend to sign up for a Raylo lease instead and you’ll both receive a £30 voucher.</Copy>,
  },
  "I added an additional device recently and I don't need both": {
    heading: 'Thanks for choosing Raylo for your recent device.',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>If there’s anything we can help with please reach out to our team.</Copy>,
  },
  'I no longer use the device': {
    heading: 'Have you checked out your upgrade options?',
    sectionMessageHeading: 'By cancelling you’ll miss out on:',
    bulletPoints: ['30 day free cancellation on all orders', 'Super low monthly payments and free upgrades', 'UK based support', 'A sustainable choice', 'A lifetime warranty', 'Flexibility to keep paying monthly or contact us to discuss purchasing at the end of your lease'],
    footer: <Copy><TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">Contact us</TextLink> if there is anything we can do to help.</Copy>,
  },
  "The customer service didn't meet my expectations": {
    heading: "We're sorry the customer service hasn't met your expectations",
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>We&apos;d love the chance to make it up to you. Our UK based customer support team can easily be reached <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3271547-contact-raylo">here</TextLink></Copy>,
  },
  'My device is damaged': {
    heading: 'Have you checked out your upgrade options?',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>Please note that if your device is returned damaged, we may charge you for the cost of repair as outlined by the Repair Charges set out in your agreement. <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/3330261-returning-your-tech-damaged">Find out more</TextLink>.</Copy>,
  },
  'My device has a functional fault': {
    heading: 'We’re sorry to hear there’s a problem with your device, you can upgrade with Raylo and return your device when you have received your upgrade. ',
    sectionMessageHeading: '',
    bulletPoints: [],
    footer: <Copy>If you&apos;d prefer to return the device for repair or replacement, please submit a request with our team <TextLink noUnderline={true} href="https://help.raylo.com/en/articles/6842721-how-do-i-return-my-tech-under-raylo-s-lifetime-warranty">here</TextLink>. All Raylo devices are covered by a Lifetime Warranty.</Copy>,
  },
};
export const subscriptionCancellationCopy = (cancellationCategory: CancellationCategory, selectedReason: string | null): ICopy | undefined => {
  if (!selectedReason) return;

  switch (cancellationCategory) {
    case CancellationCategory.COOLING_OFF:
      return COOLING_OFF_COPY[selectedReason as keyof CopyList];
    case CancellationCategory.END_OF_LEASE:
      return END_OF_LEASE_COPY[selectedReason as keyof CopyList];
  }
};
