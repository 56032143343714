import React from 'react';

// VISUAL COMPONENTS
import * as T from 'styles/type';
import * as L from 'styles/layout';
import { Button } from 'components/common/Button';
import { FadeIn } from 'components/animations/Transitions';

import { Helmet } from 'react-helmet';
export const BadLogin = ({onClick}) => (
  <L.ErrorContainer>
  <Helmet><title>Sorry - Login - Raylo</title></Helmet>
    <FadeIn>
      <T.RayloBodyHeading><strong>We’re sorry</strong>, there was a problem logging you in.</T.RayloBodyHeading>
    </FadeIn>

    <FadeIn>
      <Button
        buttonStyle='primaryBlue'
        onClick={onClick}
        style={{ height: '44px' }}>
        Try again
      </Button>
    </FadeIn>
  </L.ErrorContainer>
)
