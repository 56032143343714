import { gql } from '@apollo/client';

export interface IStartSubscriptionTerminationMutation {
  start: {
    success: boolean;
    errors: {
      message: string;
    }[];
  };

}

export const START_SUBSCRIPTION_TERMINATION = gql`
    mutation StartSubscriptionTerminationMutation($subscriptionId: ID!, $reason: String!, $customerNotes: String) {
        start: startSubscriptionTermination(subscriptionId: $subscriptionId, reason: $reason, customerNotes: $customerNotes) {
            success
            errors {
                message
            }
        }
    }
`;
