import { Link } from 'react-router-dom';
import * as A from 'styles/account';
import * as T from 'styles/type';
import * as L from 'styles/layout';
import logo from 'public/raylo-logo.svg';
import userIcon from 'public/icon--user.svg';
import { FadeIn } from 'components/animations/Transitions';
import { Button } from 'components/common/Button';
import { BlocksAnimation } from 'components/animations/BlocksAnimation';
import { AccountFooter } from 'components/Account/AccountFooter';
import { ILayoutTypes } from './Layout.Types';

export const Layout = ({
  heading,
  copy,
  buttonLabel,
  buttonLink,
  buttonOnClick,
}: ILayoutTypes) => {
  const Heading = ({ children }: any) => {
    return (
      <>
        <img src={userIcon} alt="user icon" style={{ marginBottom: 20 }} />

        <div style={{ marginBottom: 40 }}>
          <T.RayloBodyHeading style={{ marginBottom: 0 }}>
            {children}
          </T.RayloBodyHeading>
        </div>
      </>
    );
  };

  return (
    <div>
      <L.PushDown>
        <A.AccountWrapper>
          <A.Logo>
            <img src={logo} alt="Raylo" />
          </A.Logo>
          <A.AccountForm>
            <A.AccountFormContainer>
              <Heading>{heading}</Heading>
              {copy && (
                <FadeIn>
                  <div style={{ marginBottom: 40 }}>
                    <T.RayloBodyType style={{ marginBottom: 0 }}>
                      {copy}
                    </T.RayloBodyType>
                  </div>
                </FadeIn>
              )}
              {buttonLabel && buttonLink && (
                <FadeIn>
                  <Link to={`/${buttonLink}`}>
                    <Button
                      buttonStyle="secondary"
                      alignRight={false}
                      disabled={false}
                      limitWidth={null}
                      onClick={buttonOnClick}
                      dataTestId="login-layout-button"
                    >
                      {buttonLabel}
                    </Button>
                  </Link>
                </FadeIn>
              )}
              {buttonLabel && !buttonLink && (
                <FadeIn>
                  <Button
                    buttonStyle="secondary"
                    alignRight={false}
                    disabled={false}
                    limitWidth={null}
                    onClick={buttonOnClick}
                    dataTestId="login-layout-button"
                  >
                    {buttonLabel}
                  </Button>
                </FadeIn>
              )}
            </A.AccountFormContainer>
          </A.AccountForm>
          <BlocksAnimation />
        </A.AccountWrapper>
        <AccountFooter />
      </L.PushDown>
    </div>
  );
};
