import React from 'react';
import { StyledRadioSelectButton, StyledActiveElement, StyledRadioSelectContainer } from './RadioSelect.styles';
import { IRadioSelect } from './RadioSelect.types';

export const RadioSelect = ({ active, children, dataTestId }: IRadioSelect) => {
  return (
    <>
      <StyledRadioSelectContainer data-testid={dataTestId}>
        <StyledRadioSelectButton $active={active} data-testid={`${dataTestId}--select`}>
          { active && <StyledActiveElement data-testid={`${dataTestId}--active`} /> }
        </StyledRadioSelectButton>
        { children }
      </StyledRadioSelectContainer>
    </>
  );
};
