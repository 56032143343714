import CheckBox from 'elements/CheckBox';
import Container from 'elements/Container';
import Copy from 'elements/Copy';
import Grid from 'elements/Grid';
import Icon from 'elements/Icon';
import TextLink from 'elements/TextLink';
import { useStepBankContext } from 'screens/Checkout/containers/StepBank/hooks/useStepBankContext';
import { primaryColors, secondaryColors } from 'styles/variables/colors';
import { px2Rem } from 'utils/px2Rem';
import { useUiContext } from 'utils/useUiContext';
import { StyledDirectDebitInfo } from './styles';
import { IDirectDebitInfo } from './types';

export const DirectDebitInfo = ({ dataTestId }: IDirectDebitInfo) => {
  const { setShowModal, setShowDDModal } = useUiContext();
  const { setDirectDebitMandateAuthorised, directDebitMandateAuthorised } =
    useStepBankContext();
  const handleInfoClick = () => {
    setShowModal(true);
    setShowDDModal(true);
  };

  const handleCheckboxClick = () => {
    setDirectDebitMandateAuthorised(!directDebitMandateAuthorised);
  };

  return (
    <StyledDirectDebitInfo data-testid={dataTestId}>
      <Container backgroundColor={secondaryColors.c05}>
        <Grid>
          <Container width="16%" padding="0">
            <CheckBox
              active={directDebitMandateAuthorised}
              onClick={handleCheckboxClick}
              dataTestId="directDebitCheckbox"
            />
          </Container>
          <Container width="60%" padding="0">
            <Copy color={primaryColors.c01} lineHeight={20}>
              I confirm I am the account holder and the only person required to
              authorised Direct Debits on this account and I agree to the{' '}
              <TextLink onClick={handleInfoClick}>
                Direct Debit guarantee
              </TextLink>
            </Copy>
          </Container>
          <Container width="24%" padding="0" alignRight>
            <Icon
              color={primaryColors.c01}
              name="DirectDebit"
              size={px2Rem(70)}
              margin={`${px2Rem(-6)} 0 0 0`}
            />
          </Container>
        </Grid>
      </Container>
    </StyledDirectDebitInfo>
  );
};
