import * as Sentry from '@sentry/browser';

export const getSessionStorage = (key: string): string | null =>
  runWithExceptionLogging('getSessionStorage', () => window.sessionStorage.getItem(key));

export const getSessionStorageObject = <T>(key: string): T | null => {
  const item = getSessionStorage(key);
  return item ? JSON.parse(item) as T : null;
};

export const tryGetSessionStorageObject = <T>(key: string): T | null => {
  try {
    return getSessionStorageObject(key);
  } catch {
    return null;
  }
};

export const removeSessionStorage = (key: string): void =>
  runWithExceptionLogging('removeSessionStorage', () => window?.sessionStorage?.removeItem(key));

export const setSessionStorage = (key: string, value: string | object): void => {
  runWithExceptionLogging('setSessionStorage', () => {
    const stringValue = typeof value === 'string' ?
      value :
      JSON.stringify(value);
    window.sessionStorage.setItem(key, stringValue);
  });
};

function runWithExceptionLogging<T>(operation: string, fn: () => T): T {
  try {
    return fn();
  } catch (ex) {
    Sentry.captureException(ex, {
      extra: {
        operation,
        sessionStorage: getSessionStorageAvailabilityDescription(),
      },
    });
    throw ex;
  }
}

function getSessionStorageAvailabilityDescription() {
  try {
    return window?.sessionStorage ?
      'available' :
      'unavailable';
  } catch (ex) {
    return `error: ${ex}`;
  }
}
