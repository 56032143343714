import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useMedia from 'use-media';
import Container from 'elements/Container';
import { SLUGS } from 'screens/Checkout/helpers/slugs';
import { useUiContext } from 'utils/useUiContext';
import { px2Rem } from 'utils/px2Rem';
import { CheckoutStep } from 'screens/Checkout/enums/checkoutSteps';
import StepAddressContainer from '../StepAddress';
import StepAddressCheckContainer from '../StepAddressCheck';
import StepBankContainer from '../StepBank';
import StepEmail from '../StepEmail/StepEmail';
import StepInsuranceContainer from '../StepInsurance';
import StepMobileJobContainer from '../StepMobileJob';
import StepNameEmailContainer from '../StepNameEmail';
import StepSignContainer from '../StepSign';
import StepStripeContainer from '../StepStripe';
import { StepTermsContainer } from '../StepTerms/StepTerms';
import StepTradeInContainer from '../StepTradeIn';
import StepUpgradeContainer from '../StepUpgrade';
import { StyledCheckoutStepsContainer } from './styles';
import type { FormValues, ICheckoutStepsContainer } from './types';
import CheckoutLeaseTermsContainer from '../CheckoutLeaseTermsContainer';
import StepPersonalDetails from '../StepPersonalDetails/StepPersonalDetails';
import StepBusinessType from '../StepBusinessType/StepBusinessType';
import StepLeaseType from '../StepLeaseType/StepLeaseType';
import { useCheckoutContext } from 'utils/useCheckoutContext';

export const CheckoutStepsContainer = ({
  dataTestId,
  step,
  checkoutToken,
  nextStep,
}: ICheckoutStepsContainer) => {
  const history = useHistory();
  const isSmallMobile = useMedia({ maxWidth: 500 });
  const { setShowSummary, setShowReview, showSummary } = useUiContext();
  const { consumerType } = useCheckoutContext();

  // moving state here as hopefully we can kill useStepNameEmailContext,
  // useCheckoutMobileContext etc once a/b test is over
  const [presetValues, setPresetValues] = useState<FormValues | undefined>({});

  useEffect(() => {
    window.scroll(0, 0);
  }, [history?.location?.pathname, showSummary]);

  useEffect(() => {
    setPresetValues(oldVals => ({
      ...oldVals,
      leaseType: consumerType,
    }));
  }, [consumerType]);

  return (
    <StyledCheckoutStepsContainer data-testid={dataTestId}>
      <Container alignCenter padding={`0 ${px2Rem(24)} ${px2Rem(24)}`}>
        <Container
          width={isSmallMobile ? '100%' : px2Rem(414)}
          noPadding
        >
          {step === CheckoutStep.STEP_LEASE && (
            <CheckoutLeaseTermsContainer
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_LEASE)}
            />
          )}
          {step === CheckoutStep.STEP_UPGRADE && (
            <StepUpgradeContainer
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_UPGRADE)}
            />
          )}
          {step === CheckoutStep.STEP_INSURANCE && (
            <StepInsuranceContainer
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_INSURANCE)}
            />
          )}
          {step === CheckoutStep.STEP_TRADE_IN && (
            <StepTradeInContainer
              slug={SLUGS[step]}
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_TRADE_IN)}
            />
          )}
          {step === CheckoutStep.STEP_TERMS && (
            <StepTermsContainer
              slug={SLUGS[step]}
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_TERMS)}
            />
          )}
          {step === CheckoutStep.STEP_EMAIL && (
            <StepEmail
              emailPresetValue={presetValues?.email}
              setPresetValues={setPresetValues}
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_EMAIL)}
            />
          )}
          {step === CheckoutStep.STEP_PERSONAL_DETAILS && (
            <StepPersonalDetails
              presetValues={presetValues}
              setPresetValues={setPresetValues}
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_PERSONAL_DETAILS)}
            />
          )}
          {step === CheckoutStep.STEP_NAME_EMAIL && (
            <StepNameEmailContainer
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_NAME_EMAIL)}
            />
          )}
          {step === CheckoutStep.STEP_ADDRESS && (
            <StepAddressContainer
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_ADDRESS)}
            />
          )}
          {step === CheckoutStep.STEP_LEASE_TYPE && (
            <StepLeaseType
              presetValue={presetValues?.leaseType}
              onSuccess={() => nextStep(CheckoutStep.STEP_LEASE_TYPE)}
            />
          )}
          {step === CheckoutStep.STEP_BUSINESS_TYPE && (
            <StepBusinessType
              presetValues={presetValues}
              setPresetValues={setPresetValues}
              onSuccess={() => nextStep(CheckoutStep.STEP_BUSINESS_TYPE)}
            />
          )}
          {step === CheckoutStep.STEP_ADDRESS_CHECK && (
            <StepAddressCheckContainer
              slug={SLUGS[step]}
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_ADDRESS_CHECK)}
            />
          )}
          {step === CheckoutStep.STEP_MOBILE_JOB && (
            <StepMobileJobContainer
              slug={SLUGS[step]}
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_MOBILE_JOB)}
            />
          )}
          {step === CheckoutStep.STEP_BANK && (
            <StepBankContainer
              slug={SLUGS[step]}
              checkoutToken={checkoutToken}
              onSuccess={() => {
                setShowReview(true);
                setShowSummary(true);
                nextStep(CheckoutStep.STEP_BANK);
              }}
            />
          )}
          {step === CheckoutStep.STEP_STRIPE && (
            <StepStripeContainer
              slug={SLUGS[step]}
              checkoutToken={checkoutToken}
              onSuccess={() => nextStep(CheckoutStep.STEP_STRIPE)}
            />
          )}
          {step === CheckoutStep.STEP_SIGN && <StepSignContainer />}
        </Container>
      </Container>
    </StyledCheckoutStepsContainer>
  );
};
