import React, { FC } from 'react';
import Copy from '../../elements/Copy';
import { StyledInput, StyledInputWrapper, StyledLabel, StyledPrefixSpan } from './Input.styles';
import { IInputProps } from './input.types';
import { palette } from '../../colors';

export const Input: FC<IInputProps> = ({ ...props }) => {
  const { dataTestId, type, name, label, errorMessage, prefix, inputRef } = props;

  return (
    <div>
      {label && <StyledLabel data-testid={`${dataTestId}-input-label`} htmlFor={name}>{label}</StyledLabel>}
      <StyledInputWrapper>
        {prefix && <StyledPrefixSpan data-testid={`${dataTestId}-input-prefix`}>{prefix}</StyledPrefixSpan>}
        <StyledInput ref={inputRef} data-testid={`${dataTestId}-input`} {...props} />
      </StyledInputWrapper>
      {errorMessage && <Copy dataTestId={`${dataTestId}-input-error`} color={palette.error[500]}>{errorMessage}</Copy>}
    </div>
  );
};
