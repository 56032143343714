import { useEffect, useState } from 'react';
import constate from 'constate';
import { QL_OPEN_BANKING } from 'screens/Checkout/graphQL/queries';
import {
  MU_START_BANKING_CONNECTION,
  MU_VERIFY_BANKING_CONNECTION
} from 'screens/Checkout/graphQL/mutations';
import { useCheckoutContext } from 'utils/useCheckoutContext';
import { useLazyQuery, useMutation } from '@apollo/client';
import { OPEN_BANKING_CONFIG } from 'utils/constants';
import { useHistory } from 'react-router';

enum BankConnectionStates {
  VERIFIED = 'VERIFIED',
  REFUTED = 'REFUTED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  AUTHORIZED = 'AUTHORIZED'
}

const INITIAL_FORM_VALUES = {
  client: 'raylo',
};

const useStepOpenBanking = () => {
  const [providerName, setProviderName] = useState<string | undefined>();
  const [heading, setHeading] = useState<string | undefined>();
  const [formFields, setFormFields] = useState<any>(INITIAL_FORM_VALUES);
  const [formAction, setFormAction] = useState<string | undefined>();
  const [bankDetails, setBankDetails] = useState<
    { sortCode: string; accountNumber: string } | undefined
  >();
  const [bankConnectionState, setBankConnectionState] = useState();
  const [showWaiting, setShowWaiting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { checkoutToken } = useCheckoutContext();
  const history = useHistory();

  const [
    getOpenBankingData,
    { data: openBankingData, startPolling, stopPolling },
  ] = useLazyQuery(QL_OPEN_BANKING);

  const [startBankingConnection] = useMutation(MU_START_BANKING_CONNECTION);
  const [verifyBankingConnection, { loading: verifyBankConnectionLoading }] =
    useMutation(MU_VERIFY_BANKING_CONNECTION);

  useEffect(() => {
    if (checkoutToken) {
      getOpenBankingData({
        variables: {
          checkoutToken,
          callbackUri: OPEN_BANKING_CONFIG.callbackUri,
        },
      });
    }
  }, [checkoutToken]);

  useEffect(() => {
    const params = new URLSearchParams(window?.location?.search);
    if (params.get('status') === 'connecting') {
      setSubmitted(true);
    }
  }, [window?.location?.search]);

  useEffect(() => {
    const openBankingDataCheckout = openBankingData?.checkout || {};
    const {
      customerInfo = '',
      bankingConnection,
      address = '',
      paymentInfo,
    } = openBankingDataCheckout;
    const { email, firstName, lastName, mobilePhoneNumber = '' } = customerInfo;
    const { postcode = '' } = address;
    const {
      provider = '',
      oauthRedirectUri = '',
      state = '',
    } = bankingConnection || {};
    const externalRef = oauthRedirectUri?.split('=')?.pop();

    // Note: This does not provide the port on localhost. This is because consents.online errors if the port is included. We're in discussions with Equifax (their parent company) to resolve this.
    const urlCallback = `https://${window.location.hostname}/checkout/decision/bank-connection`;

    setFormAction(process.env.REACT_APP_OPEN_BANKING_FORM_ACTION);
    setProviderName(provider);
    setFormFields({
      ...formFields,
      email,
      firstName,
      lastName,
      telephone: mobilePhoneNumber,
      postcode,
      externalRef,
      completeUrl: `${urlCallback}?status=connecting`,
      cancelUrl: urlCallback,
    });
    setBankDetails({
      sortCode: paymentInfo?.bankBranchIdentifier,
      accountNumber: paymentInfo?.bankAccountNumber,
    });
    setBankConnectionState(state);
  }, [openBankingData]);

  useEffect(() => {
    if (bankConnectionState === BankConnectionStates.REFUTED) {
      setShowWaiting(false);
      stopPolling();
      setHeading(
        "Sorry, it looks like you've connected with a different bank account to the one you've given us for your direct debit"
      );
    } else {
      setHeading(
        'We need a little more information before we dispatch your new tech.'
      );
    }
    if (bankConnectionState === BankConnectionStates.VERIFIED) {
      history.push('/checkout/decision');
    }
  }, [bankConnectionState]);

  useEffect(() => {
    if (submitted) {
      verifyBankingConnection({
        variables: {
          checkoutToken,
        },
      });
    }
  }, [submitted]);

  useEffect(() => {
    setShowWaiting(verifyBankConnectionLoading);
  }, [verifyBankConnectionLoading]);

  useEffect(() => {
    if (!openBankingData?.bankingConnection?.oauthRedirectUri && submitted) {
      startPolling(2000);
    } else {
      stopPolling();
    }
    setShowWaiting(
      submitted && bankConnectionState !== BankConnectionStates.REFUTED
    );
  }, [submitted, bankConnectionState]);

  return {
    providerName,
    formFields,
    formAction,
    bankDetails,
    heading,
    bankConnectionState,
    BankConnectionStates,
    showWaiting,
    checkoutToken,
    startBankingConnection,
  };
};

const [StepOpenBankingProvider, useStepOpenBankingContext] =
  constate(useStepOpenBanking);
export { StepOpenBankingProvider, useStepOpenBankingContext };
